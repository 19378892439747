<app-card
  [cardTitle]="displayHeader ? 'pages.cybersocxdr.insight.insight' : undefined"
>
  @if(displayHeader){
  <div class="d-flex gap-2" action>
    <select
      class="form-select rounded"
      (change)="onSelectPeriod($event.target.value)"
    >
      <option
        *ngFor="let periodOption of periodOptions"
        [value]="periodOption"
        [selected]="periodOption === period"
      >
        {{
          periodOption === 'last7d' || periodOption === 'last30d'
            ? ('pages.cybersocxdr.filters.dayInterval'
              | translate : { days: periodOption === 'last7d' ? 7 : 30 })
            : ('pages.cybersocxdr.filters.' + periodOption | translate)
        }}
      </option>
    </select>
    <select
      class="form-select rounded"
      (change)="onFilterSelect($event.target.value)"
    >
      <option value="">
        {{ 'pages.cybersocxdr.insight.allCategories' | translate }}
      </option>
      <option
        *ngFor="let category of InsightCategory | keyvalue"
        [value]="category.value"
      >
        {{ 'pages.cybersocxdr.insight.' + category.value | translate }}
      </option>
    </select>
    <div class="dropdown">
      <button
        class="btn btn-secondary rounded dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ 'pages.cybersocxdr.insight.sortBy' | translate }}
      </button>
      <ul class="dropdown-menu">
        <li *ngFor="let category of InsightCategory | keyvalue">
          <a class="dropdown-item" (click)="onSortSelect(category.value)">{{
            'pages.cybersocxdr.insight.' + category.value | translate
          }}</a>
        </li>
      </ul>
    </div>
  </div>
  }

  <div class="row">
    <div class="col-12">
      <ngb-carousel
        #carousel
        [showNavigationIndicators]="false"
        [showNavigationArrows]="carrouselInsights.length > 1"
      >
        @for (insights of carrouselInsights; track $index) {
        <ng-template ngbSlide>
          <div class="carousel">
            <div class="row g-3">
              @for (insight of insights; track insight) {
              <div class="{{ insightsLength > 1 ? 'col-6' : 'col-12' }}">
                <app-insight [insight]="insight"></app-insight>
              </div>
              }
            </div>
          </div>
        </ng-template>
        }
      </ngb-carousel>
    </div>
  </div>
</app-card>
