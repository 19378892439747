import { Component, Input, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ModalService } from 'src/app/shared/modals/modal.service';

@Component({
  selector: 'app-rating-answer',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './rating-answer.component.html',
  styleUrl: './rating-answer.component.scss'
})
export class RatingAnswerComponent {

  @Input() data: any[];

  public average: number = 0;
  protected nps : number = 0;
  public maxRange = 10;

  constructor(
    private modalService : ModalService
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      // Generate user regions data
      if(this.data[0]?.average !== undefined && this.data[0]?.average !== null) {
        this.average = Number(this.data[0].average.toFixed(2));
      }
      if(this.data[0]?.nps !== undefined && this.data[0]?.nps !== null) {
        this.nps = Number(this.data[0].nps.toFixed(2));
      }
    }
  }

  openModal() {
    this.modalService.openRatingAnswerModal();
  }

}
