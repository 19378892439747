import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ACTIONS, PAGES, TYPES, constants } from 'src/app/shared/constants';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Utilities } from 'src/app/shared/utilities';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { Case, Milestone } from 'src/app/shared/model/itsm';
import { DateRange, SupportCaseSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import FileSaver from 'file-saver';
import * as Highcharts from 'highcharts';
import { TranslateService } from '@ngx-translate/core';
import { ChartColors, ChartType, PrioritiesOrder } from 'src/app/shared/model/activity';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { FileExtension } from 'src/app/shared/model/shared-items';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationAudience, NotificationCategory } from 'src/app/shared/model/notification';
import { ReportPdfAccessLevel } from 'src/app/shared/model/files';


@Component({
  selector: 'app-report-live-itsm',
  templateUrl: './report-live-itsm.component.html',
  styleUrls: ['./report-live-itsm.component.scss']
})
export class ReportLiveItsmComponent implements OnInit {

  // search fields
  type: string;
  dateFrom: string;
  dateTo: string;
  minDate: string;
  maxDate: string;
  types: string[] = ['All', 'Change Request', 'Incident', 'Service Request'];
  priorities: PrioritiesOrder[] = [PrioritiesOrder.all, PrioritiesOrder.critical, PrioritiesOrder.high, PrioritiesOrder.medium, PrioritiesOrder.low];
  selectedTypes: any = ['All'];
  selectedPriorities: any = ['All'];
  ticketError: boolean;
  loading: boolean;

  loadingPDF: boolean;
  caseTypeCV: string[];
  milestoneType: Milestone[];

  tickets: Case[];

  incidents: Case[];
  changes: Case[];
  serviceReqs: Case[];
  problems: Case[];
  alerts: Case[];

  currentSlaType: string;
  slaCustomerMet: number;
  slaCustomerTotal: number;

  sev1TotalFirstResponse: number;
  sev1MetFirstResponse: number;
  sev2TotalFirstResponse: number;
  sev2MetFirstResponse: number;
  sev3TotalFirstResponse: number;
  sev3MetFirstResponse: number;
  sev4TotalFirstResponse: number;
  sev4MetFirstResponse: number;

  // data
  cases: any;
  casesCount: number;
  searchCriteria: SupportCaseSearchCriteria;
  searched: boolean;
  casesLoaded: boolean;
  dateRanges: DateRange[];

  form: FormGroup;
  public isMSCollapsed = false;

  toggleShowGraph = true;
  toggleShowPie = false;
  Highcharts: typeof Highcharts = Highcharts;
  chartDataLoaded: boolean;
  annualChartDataLoaded: boolean;


  updateCategoryPie: boolean;

  catPieChartOptions: any;
  allCasesDonutChartOptions: any;
  updateDonutFlag = false;

  //Modal
  closeResult: string;
  PDFName='';
  reportOption = 'pdf';
  pdfAccessLevel = '0';
  generateReportLoading = false;

  //constraints
  private maxDiffDays = 93;


  get typesFormArray() {
    return this.form.controls.types as FormArray;
  }

  constructor(
    private itsmService: ItsmService,
    private alertService: AlertService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private userActivity: UserActivityService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.searchCriteria = new SupportCaseSearchCriteria();

    this.dateFrom = Utilities.get1MonthAgo();
    this.dateTo = Utilities.getToday();
    this.dateRanges = [DateRange.created];
    this.minDate = '2021-09-01';
    this.maxDate = '2030-12-12';
    this.loadCache();
    this.form = this.formBuilder.group({
      types: new FormArray([])
    });
  }

  onTypeSelect(event: any) {

    if(this.selectedTypes.length > 1 && this.selectedTypes.includes('All')){
      this.selectedTypes = this.selectedTypes.filter(t => t == 'All');
    }
  }

  onPrioritySelect(event: any) {

    if(this.selectedPriorities.length > 1 && this.selectedPriorities.includes('All')){
      this.selectedPriorities = this.selectedPriorities.filter(p => p == 'All');
    }
  }

  validateDates(): boolean {
    if (!this.dateFrom && !this.dateTo) {
      return true;
    }
    const min = new Date(this.minDate);
    const max = new Date(this.maxDate);
    const to = new Date(this.dateTo);
    const from = new Date(this.dateFrom);

    if (to < min || from < min || to > max || from > max) {
      return false;
    }
    return to > from;
  }

  /**
   * Cache the CV values for the dropdowns
   */
  loadCache() {
    Promise.all([
      this.itsmService.getCachedCV('type'),
    ])
      .then(res => {
        this.caseTypeCV = res[0];
      })
      .catch(err => {
        this.alertService.handlerError(err);
      });
  }

  generate() {

    const jsonForm = {
      fromCreatedDate: this.dateFrom,
      toCreatedDate: this.dateTo,
      types: this.selectedTypes,
      priorities: this.selectedPriorities
    };

    const to = new Date(this.dateTo);
    const from = new Date(this.dateFrom);

    const diff = Math.abs(from.getTime() - to.getTime());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

    if (diffDays > this.maxDiffDays) {
      this.alertService.handlerError(this.translateService.instant('pages.reports.itsm.timeframesRestricted'));
    }

    this.loading = true;

    this.searchCriteria.createdFrom = jsonForm.fromCreatedDate;
    this.searchCriteria.createdTo = jsonForm.toCreatedDate;
    this.searchCriteria.limit = undefined;
    if(jsonForm.types.toString() != 'All'){
      this.searchCriteria.type = jsonForm.types.toString();
    }

    if(jsonForm.priorities.toString() != 'All') {
      this.searchCriteria.priority = jsonForm.priorities.toString();
    }
    if(this.searchCriteria.checkDateRanges(this.dateRanges)) {
      this.itsmService.getLiveReport(this.searchCriteria)
      .then(data => {
        this.cases = data;

        this.incidents = this.cases.filter(a => a.type === 'Incident');
        this.changes = this.cases.filter(a => a.type === 'Change Request');
        this.serviceReqs = this.cases.filter(a => a.type === 'Service Request');
        this.problems = this.cases.filter(a => a.type === 'Problem');
        this.alerts = this.cases.filter(a => a.type === 'Alert');

        this.slaCustomerTotal = this.cases.length;
        this.slaCustomerMet = this.cases.filter(a => a.madeSla === 'true').length;

        this.sev1TotalFirstResponse = this.cases.filter(a => a.priority === PrioritiesOrder.critical).length;
        this.sev1MetFirstResponse = this.cases.filter(a => a.priority === PrioritiesOrder.critical  && a.madeSla === 'true').length;

        this.sev2TotalFirstResponse = this.cases.filter(a => a.priority === PrioritiesOrder.high).length;
        this.sev2MetFirstResponse = this.cases.filter(a => a.priority === PrioritiesOrder.high &&  a.madeSla === 'true').length;

        this.sev3TotalFirstResponse = this.cases.filter(a => a.priority === PrioritiesOrder.medium).length;
        this.sev3MetFirstResponse = this.cases.filter(a => a.priority === PrioritiesOrder.medium && a.madeSla === 'true').length;

        this.sev4TotalFirstResponse = this.cases.filter(a => a.priority === PrioritiesOrder.low).length;
        this.sev4MetFirstResponse = this.cases.filter(a => a.priority === PrioritiesOrder.low && a.madeSla === 'true').length;


        this.updateDonutFlag = true;
        this.drawGraph();
        this.drawCasePieChart();
        this.loading = false;
      })
      .catch(err => {
        this.alertService.handlerError(err);
        this.loading = false;
      });
    } else {
      this.loading = false;
      this.alertService.handlerError(
        this.translateService.instant('pages.dashboard.errors.cantGenerateReport')
        + ' - '
        + this.translateService.instant('pages.dashboard.errors.invalidDateRange')
      );
    }
  }

  generatePDF() {
    this.loadingPDF = true;
    this.searchCriteria.createdFrom = this.dateFrom;
    this.searchCriteria.createdTo = this.dateTo;
    if(this.searchCriteria.checkDateRanges(this.dateRanges)) {
      this.itsmService.getLivePDFReport(this.searchCriteria)
        .then(val => {
          const file = new Blob([val], { type: val.type });
          const fileName = `OrangeCyberdefense-ServiceManagementReport-${this.dateFrom}.${FileExtension.pdf}`;
          FileSaver.saveAs(file, fileName);
        })
        .catch(err => {
          this.alertService.handlerError(err);
        })
        .finally( () => {
          this.loadingPDF = false;
        });
    } else {
      this.loading = false;
      this.alertService.handlerError(
        this.translateService.instant('pages.dashboard.errors.cantGenerateReport')
        + ' - '
        + this.translateService.instant('pages.dashboard.errors.invalidDateRange')
      );
    }
  }

  updateGraphView(toggleShowGraph: boolean, toggleShowPie: boolean) {
    this.toggleShowGraph = toggleShowGraph;
    this.toggleShowPie = toggleShowPie;
  }

  drawGraph() {
    this.catPieChartOptions = {
      colors: constants.graphContrastingColors,
      chart: {
        type: ChartType.column
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      title: {
        text: 'Percentage of cases resolved within SLA'
      },
      subtitle: {
        text: 'By Priority'
      },
      xAxis: {
        categories: [PrioritiesOrder.critical, PrioritiesOrder.high, PrioritiesOrder.medium, PrioritiesOrder.low],
        crosshair: true
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: 'Percentage'
        }
      },
      tooltip: {
        headerFormat: '<span style = "font-size:10px"><b>{point.y:.0f}</b></span>',
        pointFormat: '', footerFormat: '', shared: true, useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
        {
          name: 'SLA Percentage Resolved',
          data: [
            { y: ((this.sev1MetFirstResponse / this.sev1TotalFirstResponse) * 100), color: ChartColors.purple },
            { y: ((this.sev2MetFirstResponse / this.sev2TotalFirstResponse) * 100), color: ChartColors.yellow },
            { y: ((this.sev3MetFirstResponse / this.sev3TotalFirstResponse) * 100), color: ChartColors.blue },
            { y: ((this.sev4MetFirstResponse / this.sev4TotalFirstResponse) * 100), color: ChartColors.green }
          ]
        }
      ]
    };
  }

  drawCasePieChart() {
    this.allCasesDonutChartOptions = {
      colors:  constants.graphContrastingColors,
      chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false
      },
      credits: {
          enabled : false
      },
      legend: {
          enabled : true,
          floating: true,
          align: 'left',
          verticalAlign: 'top',
          itemDistance: 250,
          layout: 'vertical',
          x: 50,
          y: 0,
          width: 200,
          overlap: true
      },
      title: {
          text: 'Cases',
          align: 'center',
          verticalAlign: 'middle',
          y: 15
      },
      tooltip: {
          headerFormat: '',
          pointFormat: '{point.name}: {point.y}%'
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: false,
                  overflow: 'allow',
                  crop: false,
                  style: {
                      fontWeight: 'bold'
                  }
              },
              startAngle: -90,
              endAngle: -180,
              center: ['50%', '50%'],
              size: '75%',
              showInLegend: true
          }
      },
      series : [{
        type: ChartType.pie,
        name: 'Cases',
        data: [
          {
            name: PrioritiesOrder.critical,
            y: ((this.sev1MetFirstResponse / this.sev1TotalFirstResponse) * 100),
            color: ChartColors.purple
          },
          {
            name: PrioritiesOrder.high,
            y: ((this.sev2MetFirstResponse / this.sev2TotalFirstResponse) * 100),
            color: ChartColors.yellow
          },
          {
            name: PrioritiesOrder.medium,
            y: ((this.sev3MetFirstResponse / this.sev3TotalFirstResponse) * 100),
            color: ChartColors.blue
          },
          {
            name: PrioritiesOrder.low,
            y: ((this.sev4MetFirstResponse / this.sev4TotalFirstResponse) * 100),
            color: ChartColors.green
          }
        ]
      }]
    };
  }

  exportReport(content){
    this.openModal(content);
  }

  openModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      if(result == 'save'){
        this.generateCsvOrPdfReport();
      }

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  generateCsvOrPdfReport(){
    this.generateReportLoading = true;
    this.searchCriteria = new SupportCaseSearchCriteria();

    this.searchCriteria.count = false;
    this.searchCriteria.limit = undefined;
    this.searchCriteria.export = true;
    this.searchCriteria.pdfName = this.PDFName +  ' - ' + constants.accessLevels[this.pdfAccessLevel]
    +  '.' + (this.reportOption === FileExtension.csv ? FileExtension.csv : FileExtension.pdf );
    this.searchCriteria.pdfAccessLevel = this.pdfAccessLevel;
    this.searchCriteria.resolutionReport = true;

    const to = new Date(this.dateTo);
    const from = new Date(this.dateFrom);
    const diff = Math.abs(from.getTime() - to.getTime());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

    this.searchCriteria.createdFrom = this.dateFrom;
    this.searchCriteria.createdTo = this.dateTo;
    if(this.selectedTypes.toString() != 'All'){
      this.searchCriteria.type = this.selectedTypes.toString();
    }
    if(this.selectedPriorities.toString() != 'All') {
      this.searchCriteria.priority = this.selectedPriorities.toString();
    }

    if (diffDays > this.maxDiffDays) {
      this.alertService.handlerError(this.translateService.instant('pages.reports.itsm.timeframesRestricted'));
    }
    else {
      this.itsmService.caseSearch(this.searchCriteria)
      .then(() => {
        this.alertService.addSuccess(this.translateService.instant('pages.generic.reportGenerated'));
        this.generateReportLoading = false;
        this.userActivity.logActivity(TYPES.reports, PAGES.supportResolutionReport, ACTIONS.generateReport);
        const fullName =
          `${Utilities.capitalizeFirstLetter(ReportPdfAccessLevel[this.searchCriteria.pdfAccessLevel])}/${this.searchCriteria.pdfName}`;
        this.notificationService.sendNotification(
          NotificationCategory.generateReport,
          {title: fullName, content: fullName},
          NotificationAudience.admins
        );
      })
      .catch(err => {
        this.alertService.handlerError(err);
        this.generateReportLoading = false;
      });
    }

  }

  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
