@if (assessment) {
<div class="container mt-4">
  <app-card>
    <!-- Header with status badge and title -->
    <div class="mb-4 p-3 assessment-header">
      <div class="text-center mt-2">
        <div class="d-flex justify-content-center align-items-center">
          <fa-icon
            [icon]="['fas', 'file-alt']"
            class="report-icon me-2 text-primary"
          ></fa-icon>
          <h2 class="mb-0">{{ assessment.title }}</h2>
        </div>
      </div>
    </div>

    <div class="card-body">
      <section class="mb-4">
        <div class="row">
          <div class="col">
            <h4 class="ps-3 mb-3">
              {{
                'pages.ethicalhacking.assessments.details.perimeters'
                  | translate
              }}
            </h4>

            @if (assessment.perimeters?.length) {
            <div class="perimeter-list ms-2">
              <div class="row g-3">
                @for (perimeter of assessment.perimeters; track perimeter.id) {
                <div class="col-md-6 col-lg-4">
                  <div
                    class="perimeter-item p-2 border-start border-primary border-2 rounded-start"
                  >
                    <div class="d-flex align-items-center">
                      <fa-icon
                        [icon]="['fas', 'globe']"
                        class="text-primary me-2"
                      ></fa-icon>
                      <div>
                        <div
                          class="perimeter-name"
                          title="{{ perimeter.name }}"
                        >
                          {{ perimeter.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
            } @else {
            <p class="text-muted ms-2 mb-0">
              {{
                'pages.ethicalhacking.assessments.details.noData' | translate
              }}
            </p>
            }
          </div>
          <div class="col-auto">
            <div
              class="badge assessment-status-badge rounded-pill py-2 px-3 fs-6"
              [ngClass]="getAssessentStatusClass(assessment.status)"
            >
              {{
                'pages.ethicalhacking.assessments.status.' + assessment.status
                  | translate
              }}
            </div>
          </div>
        </div>
      </section>

      <section class="mb-4">
        <div class="row">
          <div class="col">
            <h4 class="ps-3 mb-3">
              {{
                'pages.ethicalhacking.assessments.details.vulnerabilities'
                  | translate
              }}
            </h4>
          </div>
        </div>
        <div class="ps-4 pe-4">
          <severity [items]="openVulnerabilitiesBySeverity" />
        </div>
      </section>

      <!-- Sections as a report-like display -->
      <section class="mb-4">
        @if (assessment.sections?.length) {
        <div class="report-sections ms-3">
          <!-- Root Sections -->
          @for (rootSection of getRootSections(assessment.sections); track
          rootSection.id; let rootIndex = $index) {
          <div class="section-container mb-4">
            <!-- Section Header -->
            <h3 class="text-primary">
              <span class="section-number me-2">{{ rootIndex + 1 }}.</span>
              {{ rootSection.name }}
            </h3>

            <!-- Section Description (if exists) -->
            @if (rootSection.description) {
            <div class="section-description ms-3 mb-3">
              <div
                class="markdown-content"
                [innerHTML]="rootSection.description"
              ></div>
            </div>
            }

            <!-- Subsections -->
            @for (childSection of getChildSections(assessment.sections,
            rootSection.id); track childSection.id; let childIndex = $index) {
            <div class="subsection-container ms-4 mb-3">
              <!-- Subsection Header -->
              <h5 class="subsection-title border-start border-4 ps-2">
                <span class="section-number me-2"
                  >{{ rootIndex + 1 }}.{{ childIndex + 1 }}</span
                >
                {{ childSection.name }}
              </h5>

              <!-- Subsection Description (if exists) -->
              @if (childSection.description) {
              <div class="subsection-description ms-4">
                <div
                  class="markdown-content"
                  [innerHTML]="childSection.description"
                ></div>
              </div>
              }
            </div>
            }
          </div>
          }
        </div>
        } @else {
        <p class="text-muted ms-3">
          {{ 'pages.ethicalhacking.assessments.details.noData' | translate }}
        </p>
        }
      </section>

      <!-- Timestamps -->
      <section class="mt-5">
        <div class="d-flex justify-content-between text-muted small">
          <div>
            <fa-icon [icon]="['fas', 'calendar-plus']" class="me-1"></fa-icon>
            {{ assessment.created_at | date : 'short' }}
          </div>
          <div>
            <fa-icon [icon]="['fas', 'calendar-check']" class="me-1"></fa-icon>
            {{ assessment.updated_at | date : 'short' }}
          </div>
        </div>
      </section>
    </div>
  </app-card>
</div>
} @else if (loading) {
<div class="container mt-4 text-center">
  <div class="spinner-border text-primary">
    <output class="visually-hidden">{{
      'pages.ethicalhacking.assessments.details.loading' | translate
    }}</output>
  </div>
  <p class="mt-2">
    {{ 'pages.ethicalhacking.assessments.details.loading' | translate }}
  </p>
</div>
}
