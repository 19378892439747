import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";
import { Anonymous } from "src/app/cybersocxdr/utils";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
  selector: 'anonymous-button',
  standalone: true,
  imports: [
    TranslateModule,
    FontAwesomeModule
  ],
  templateUrl: './anonymous-button.component.html',
  styleUrl: './anonymous-button.component.scss'
})
export class AnonymousButtonComponent {
  /**
   * @public
   * @desc Event emitted when the anonymous button is clicked
   */
  @Output()
  public onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  protected state: boolean = Anonymous.isEnabled();

  /**
   * @protected
   * @desc Handles the click event on the anonymous button.
   */
  protected onBtnClick(): void {
    this.onClick.emit();
  }
}
