import { Directive, effect, ElementRef, inject, input, InputSignal, Renderer2 } from "@angular/core";

enum BsDialogAttr {
  TOGGLE = 'data-bs-toggle',
  TARGET = 'data-bs-target',
}

@Directive({
  standalone: true,
  selector: '[dialog]'
})
export class DialogOpenerDirective {
  private readonly caller: ElementRef = inject(ElementRef);
  private readonly renderer: Renderer2 = inject(Renderer2);

  /**
   * @public
   * @desc Input for the dialog opener directive.
   */
  public readonly dialog: InputSignal<string> = input.required<string>();

  constructor() {
    effect((): void => this.updateCallerAttribute());
  }

  /**
   * @private
   * @desc Updates the caller element by setting the data-bs-toggle and data-bs-target attributes.
   */
  private updateCallerAttribute(): void {
    this.setModalAttr();
    this.setTargetAttr();
  }

  /**
   * @private
   * @desc Sets the data-bs-toggle attribute on the caller element.
   */
  private setModalAttr(): void {
    this.setAttrToCaller(BsDialogAttr.TOGGLE, 'modal');
  }

  /**
   * @private
   * @desc Sets the data-bs-target attribute on the caller element.
   */
  private setTargetAttr(): void {
    this.setAttrToCaller(BsDialogAttr.TARGET, `#${this.dialog()}`);
  }

  /**
   * @private
   * @desc Sets an attribute on the caller element.
   *
   * @param attrName - the name of the attribute
   * @param attrValue - the value of the attribute
   */
  private setAttrToCaller(attrName: string, attrValue: string): void {
    this.renderer.setAttribute(this.caller.nativeElement, attrName, attrValue);
  }
}
