import { VulnerabilitySeverity } from './vulnerabilities-model';

export enum AssessmentStatusValues {
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  RETEST_SCHEDULED = 'retest_scheduled',
  RETEST_ACTIVE = 'retest_active',
  UNDER_REVIEW = 'under_review',
  RETEST_UNDER_REVIEW = 'retest_under_review',
  REVIEW_COMPLETED = 'review_completed',
  RETEST_REVIEW_COMPLETED = 'retest_review_completed',
  RETEST_COMPLETED = 'retest_completed',
  RETEST_REQUESTED = 'retest_requested',
  CONTINUOUS_MONITORING = 'continuous_monitoring',
}

export class AssessmentSectionDto {
  id: string;
  parent_id: string;
  name: string;
  description: string;
}

export class AssessmentPerimeterDto {
  id: string;
  name: string;
}

export class AssessmentDto {
  id: string;
  client_id: string;
  status: AssessmentStatusValues;
  title: string;
  description: string;
  perimeters: AssessmentPerimeterDto[];
  created_at: string;
  updated_at: string;
  sections: AssessmentSectionDto[];
  vulnerabilities_repartition: Partial<Record<VulnerabilitySeverity, number>>;
}
