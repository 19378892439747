/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MfdRecord, MfdSearch } from '../model/maliciousfiledetection';
import { LocalStorageItem } from '../model/shared-items';
import { FilescanHttpService } from '../httpServices/filescan-http.service';
import { ACTIONS, PAGES, TYPES } from '../constants';
import { UserActivityService } from './user-activity.service';

@Injectable()
export class MfdService {

  private fileScanURL = environment.endpoints.fileScan;

  constructor(
      private filescanHttpService: FilescanHttpService,
      private userActivity: UserActivityService
    ) {
  }

  /** Add to the cache the records pass as arguments */
  updateCacheWithRecords(records: MfdRecord[]) {
    const localCache = localStorage.getItem(LocalStorageItem.vitalRecords);
    if(localCache === '' || localCache == null) {
      const newCache = {};
      records.forEach(record => {
        const hash = record.fileinfo.sha256;
        newCache[hash] = record;
      });
      localStorage.setItem(LocalStorageItem.vitalRecords, JSON.stringify(newCache));
    }
    else {
      const parsed = JSON.parse(localCache);
      records.forEach(record => {
        const hash = record.fileinfo.sha256;
        parsed[hash] = record;
      });
      localStorage.setItem(LocalStorageItem.vitalRecords, JSON.stringify(parsed));
    }
  }

  upload(file: File): Promise<MfdRecord> {
    return this.filescanHttpService.postVitalUpload(file)
        .then(response => {
          const r = response as MfdRecord;
          this.updateCacheWithRecords([r]);
          this.userActivity.logActivity(TYPES.services, PAGES.mfdUpload, ACTIONS.fileScan);
          return r;
        })
        .catch(this.handleError);
  }

  /** Get a record stored in local cache */
  getLocalRecord(sha256: string){
    const localCache = localStorage.getItem(LocalStorageItem.vitalRecords);
    if(localCache === '' || localCache == null) {
      return null;
    }
    else {
      const parsed = JSON.parse(localCache);
      const record = parsed[sha256];
      if(record == null) {
        return null;
      }
      else {
        return record as MfdRecord;
      }
    }
  }

  /** search for a file for all scanned files */
  search(): Promise<MfdSearch> {
    return this.filescanHttpService.search()
        .then(response => {
          const r = response as MfdSearch;
          this.updateCacheWithRecords(r.records);
          return r;
        })
        .catch(this.handleError);
  }

  /** get vital quota from userpreferences */
  getQuota(): Promise<number> {
    return this.filescanHttpService.getVitalQuota()
      .then(response => {
        const r = response as {vitalQuota: number};
        return r.vitalQuota;
      })
      .catch(this.handleError);
  }

  /** get scan history for a tenant */
  getHistory(): Promise<{email: string; filename: string; time: number}[]> {
    return this.filescanHttpService.getVitalHistory()
      .catch(this.handleError);
  }

  /** update vital quota to userpreferences */
  updateQuota(value: number): Promise<void> {
    const url = this.fileScanURL + 'updateVitalQuota';

    return this.filescanHttpService.postUpdateVitalQuota(value)
      .then(response => {
        const r = response as {quotaUpdated: number; success: boolean};
        if(!r.success) {throw Error('error from user preferences');}
        return;
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}

/** This is an example Mfd response returned by the scan endpoint */
const exampleMfdRecord: MfdRecord = {
  avira: {
    dateAnalyseLast: 1652788580844,
    engine: '3.85.1',
    error: false,
    update: '08/05/22',
    virus: []
  },
  bitdefender: {
    dateAnalyseLast: 1652788580844,
    engine: '4.0.2',
    error: false,
    update: '08/05/22',
    virus: []
  },
  clamav: {
    dateAnalyseLast: 1652788580844,
    engine: '0.103.5',
    error: false,
    update: '08/05/22',
    virus: []
  },
  comodo: {
    dateAnalyseLast: 1652788580844,
    engine: '5.0.163652.1142',
    error: false,
    update: '08/05/22',
    virus: []
  },
  drweb: {
    dateAnalyseLast: 1652788580844,
    engine: '10.1.0.0.1503311845',
    error: false,
    update: '08/05/22',
    virus: []
  },
  eset: {
    dateAnalyseLast: 1652788580844,
    engine: '7.1.558.0',
    error: false,
    update: '08/05/22',
    virus: []
  },
  fileinfo: {
    md5: '098f6bcd4621d373cade4e832627b4f6',
    sha1: 'a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
    sha256: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
    dateAnalysisLast: 1652788580844,
    exifTools: {},
    mimeEncoding: 'us-ascii',
    mimeType: 'text/plain',
    size: 12289,
    ssdeep: '3:Hn:Hn',
    trid: [
      '53,5% (.ODT) OpenDocument Text Document (52549/2/7)',
      '37,2% (XMIND) XMind Workbook (36500/1/16)',
      '4.0% (.) Zip document container (generic) (4008/2)',
      '4.0% (.Zip) ZIP compressed archive (4000/1)',
      '1.0% (.PG/Bin) PrintFox/PageFox bitmap (640x800) (1000/1)'
    ],
    virus: []
  },
  fsecure: {
    dateAnalyseLast: 1652788580844,
    engine: '1.0  build 0069',
    error: false,
    update: '07/05/22',
    virus: []
  },
  kaspersky: {
    dateAnalyseLast: 1652788580844,
    engine: '10.1.1.6421',
    error: false,
    update: '01/01/70',
    virus: []
  },
  link: [],
  mdatp: {
    dateAnalyseLast: 1652788580844,
    engine: '101.65.77',
    error: false,
    update: '08/05/22',
    virus: []
  },
  meta: {
    dateAnalyseLast: 1652792612837,
    dateSubmissionFirst: 1652448388457,
    dateSubmissionLast: 1652788580844,
    filename: 'test-yara.txt',
    hash: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
    password: '',
    size: 12289,
    users: [
      'tgay@orangecyberdefense.com',
      'thierry'
    ]
  },
  mfetp: {
    dateAnalyseLast: 1652788580844,
    engine: '6200.9189',
    error: false,
    update: '08/05/22',
    virus: []
  },
  sophos: {
    dateAnalyseLast: 1652788580844,
    engine: '3.85.1',
    error: false,
    update: '08/05/22',
    virus: []
  },
  trend: {
    dateAnalyseLast: 1652788580844,
    engine: '17.551.00',
    error: false,
    update: '08/05/22',
    virus: []
  },
  yara: {
    dateAnalyseLast: 1652790153579,
    engine: '4.0.2',
    error: false,
    update: '08/05/22',
    virus: []
  },
  yara_45283: {
    dateAnalyseLast: 1652792612837,
    engine: '4.0.2',
    error: false,
    update: '08/05/22',
    virus: []
  },
  history: [
    {
      _key: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
      _id: 'File/9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
      _rev: '_eLGb1FG---',
      meta: {
        dateAnalyseLast: 1652792612837,
        dateSubmissionFirst: 1652448388457,
        dateSubmissionLast: 1652788580844,
        filenames: [
          'test-yara.txt'
        ],
        hash: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        password: '',
        size: 12289
      },
      fileinfo: {
        MD5: '098f6bcd4621d373cade4e832627b4f6',
        SHA1: 'a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
        SHA256: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        dateAnalyseLast: 1652788580844,
        exiftool: {},
        'mime-encoding': 'us-ascii',
        'mime-type': 'text/plain',
        size: 1289,
        ssdeep: '3:Hn:Hn',
        trid: [],
        virus: []
      },
      drweb: {
        dateAnalyseLast: 1652788580844,
        engine: '10.1.0.0.1503311845',
        error: false,
        update: '08/05/22',
        virus: []
      },
      comodo: {
        dateAnalyseLast: 1652788580844,
        engine: '5.0.163652.1142',
        error: false,
        update: '08/05/22',
        virus: []
      },
      fsecure: {
        dateAnalyseLast: 1652788580844,
        engine: '1.0  build 0069',
        error: false,
        update: '07/05/22',
        virus: []
      },
      kaspersky: {
        dateAnalyseLast: 1652788580844,
        engine: '10.1.1.6421',
        error: false,
        update: '01/01/70',
        virus: []
      },
      mfetp: {
        dateAnalyseLast: 1652788580844,
        engine: '6200.9189',
        error: false,
        update: '08/05/22',
        virus: []
      },
      mdatp: {
        dateAnalyseLast: 1652788580844,
        engine: '101.65.77',
        error: false,
        update: '08/05/22',
        virus: []
      },
      sophos: {
        dateAnalyseLast: 1652788580844,
        engine: '3.85.1',
        error: false,
        update: '08/05/22',
        virus: []
      },
      eset: {
        dateAnalyseLast: 1652788580844,
        engine: '7.1.558.0',
        error: false,
        update: '08/05/22',
        virus: []
      },
      yara: {
        dateAnalyseLast: 1652790153579,
        engine: '4.0.2',
        error: false,
        update: '08/05/22',
        virus: []
      },
      clamav: {
        dateAnalyseLast: 1652788580844,
        engine: '0.103.5',
        error: false,
        update: '08/05/22',
        virus: []
      },
      trend: {
        dateAnalyseLast: 1652788580844,
        engine: '17.551.00',
        error: false,
        update: '08/05/22',
        virus: []
      },
      yara_45283: {
        dateAnalyseLast: 1652792612837,
        engine: '4.0.2',
        error: false,
        update: '08/05/22',
        virus: []
      }
    },
    {
      _key: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08-1652457887661',
      _id: 'FileBackup/9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08-1652457887661',
      _rev: '_eLFeSdK---',
      clamav: {
        dateAnalyseLast: 1652452212323,
        engine: '0.103.5',
        error: false,
        update: '08/05/22',
        virus: []
      },
      comodo: {
        dateAnalyseLast: 1652452212323,
        engine: '5.0.163652.1142',
        error: false,
        update: '08/05/22',
        virus: []
      },
      drweb: {
        dateAnalyseLast: 1652452212323,
        engine: '10.1.0.0.1503311845',
        error: false,
        update: '08/05/22',
        virus: []
      },
      eset: {
        dateAnalyseLast: 1652452212323,
        engine: '7.1.558.0',
        error: false,
        update: '08/05/22',
        virus: []
      },
      fileinfo: {
        MD5: '098f6bcd4621d373cade4e832627b4f6',
        SHA1: 'a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
        SHA256: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        dateAnalyseLast: 1652452212323,
        exiftool: {},
        'mime-encoding': 'us-ascii',
        'mime-type': 'text/plain',
        size: 12289,
        ssdeep: '3:Hn:Hn',
        trid: [],
        virus: []
      },
      fsecure: {
        dateAnalyseLast: 1652452212323,
        engine: '1.0  build 0069',
        error: false,
        update: '07/05/22',
        virus: []
      },
      kaspersky: {
        dateAnalyseLast: 1652452212323,
        engine: '10.1.1.6421',
        error: false,
        update: '01/01/70',
        virus: []
      },
      mdatp: {
        dateAnalyseLast: 1652452212323,
        engine: '101.65.77',
        error: false,
        update: '08/05/22',
        virus: []
      },
      meta: {
        dateAnalyseLast: 1652457887661,
        dateSubmissionFirst: 1652448388457,
        dateSubmissionLast: 1652448388457,
        filenames: [
          'test-yara.txt'
        ],
        hash: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        password: '',
        size: 12289
      },
      mfetp: {
        dateAnalyseLast: 1652452212323,
        engine: '6200.9189',
        error: false,
        update: '08/05/22',
        virus: []
      },
      sophos: {
        dateAnalyseLast: 1652452212323,
        engine: '3.85.1',
        error: false,
        update: '08/05/22',
        virus: []
      },
      trend: {
        dateAnalyseLast: 1652452212323,
        engine: '17.551.00',
        error: false,
        update: '08/05/22',
        virus: []
      },
      yara: {
        dateAnalyseLast: 1652457887661,
        engine: '4.0.2',
        error: false,
        update: '08/05/22',
        virus: [
          'vital_test'
        ]
      }
    },
    {
      _key: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08-1652452052056',
      _id: 'FileBackup/9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08-1652452052056',
      _rev: '_eJ1RtkC---',
      clamav: {
        dateAnalyseLast: 1652452052056,
        engine: '0.103.5',
        error: false,
        update: '08/05/22',
        virus: []
      },
      comodo: {
        dateAnalyseLast: 1652452052056,
        engine: '5.0.163652.1142',
        error: false,
        update: '08/05/22',
        virus: []
      },
      drweb: {
        dateAnalyseLast: 1652452052056,
        engine: '10.1.0.0.1503311845',
        error: false,
        update: '08/05/22',
        virus: []
      },
      eset: {
        dateAnalyseLast: 1652452052056,
        engine: '7.1.558.0',
        error: false,
        update: '08/05/22',
        virus: []
      },
      fileinfo: {
        MD5: '098f6bcd4621d373cade4e832627b4f6',
        SHA1: 'a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
        SHA256: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        dateAnalyseLast: 1652452052056,
        exiftool: {},
        'mime-encoding': 'us-ascii',
        'mime-type': 'text/plain',
        size: 12289,
        ssdeep: '3:Hn:Hn',
        trid: [],
        virus: []
      },
      fsecure: {
        dateAnalyseLast: 1652452052056,
        engine: '1.0  build 0069',
        error: false,
        update: '07/05/22',
        virus: []
      },
      kaspersky: {
        dateAnalyseLast: 1652452052056,
        engine: '10.1.1.6421',
        error: false,
        update: '01/01/70',
        virus: []
      },
      mdatp: {
        dateAnalyseLast: 1652452052056,
        engine: '101.65.77',
        error: false,
        update: '08/05/22',
        virus: []
      },
      meta: {
        dateAnalyseLast: 1652452052056,
        dateSubmissionFirst: 1652448388457,
        dateSubmissionLast: 1652448388457,
        filenames: [
          'test-yara.txt'
        ],
        hash: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        password: '',
        size: 12289
      },
      mfetp: {
        dateAnalyseLast: 1652452052056,
        engine: '6200.9189',
        error: false,
        update: '08/05/22',
        virus: []
      },
      sophos: {
        dateAnalyseLast: 1652452052056,
        engine: '3.85.1',
        error: false,
        update: '08/05/22',
        virus: []
      },
      trend: {
        dateAnalyseLast: 1652452052056,
        engine: '17.551.00',
        error: false,
        update: '08/05/22',
        virus: []
      },
      yara: {
        dateAnalyseLast: 1652452052056,
        engine: '4.0.2',
        error: false,
        update: '08/05/22',
        virus: []
      }
    },
    {
      _key: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08-1652451736575',
      _id: 'FileBackup/9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08-1652451736575',
      _rev: '_eJ1PRDa---',
      clamav: {
        dateAnalyseLast: 1652451470804,
        engine: '0.103.5',
        error: false,
        update: '08/05/22',
        virus: []
      },
      comodo: {
        dateAnalyseLast: 1652451470804,
        engine: '5.0.163652.1142',
        error: false,
        update: '08/05/22',
        virus: []
      },
      drweb: {
        dateAnalyseLast: 1652451470804,
        engine: '10.1.0.0.1503311845',
        error: false,
        update: '08/05/22',
        virus: []
      },
      eset: {
        dateAnalyseLast: 1652451470804,
        engine: '7.1.558.0',
        error: false,
        update: '08/05/22',
        virus: []
      },
      fileinfo: {
        MD5: '098f6bcd4621d373cade4e832627b4f6',
        SHA1: 'a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
        SHA256: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        dateAnalyseLast: 1652451470804,
        exiftool: {},
        'mime-encoding': 'us-ascii',
        'mime-type': 'text/plain',
        size: 12289,
        ssdeep: '3:Hn:Hn',
        trid: [],
        virus: []
      },
      fsecure: {
        dateAnalyseLast: 1652451470804,
        engine: '1.0  build 0069',
        error: false,
        update: '07/05/22',
        virus: []
      },
      kaspersky: {
        dateAnalyseLast: 1652451470804,
        engine: '10.1.1.6421',
        error: false,
        update: '01/01/70',
        virus: []
      },
      mdatp: {
        dateAnalyseLast: 1652451470804,
        engine: '101.65.77',
        error: false,
        update: '08/05/22',
        virus: []
      },
      meta: {
        dateAnalyseLast: 1652451736575,
        dateSubmissionFirst: 1652448388457,
        dateSubmissionLast: 1652448388457,
        filenames: [
          'test-yara.txt'
        ],
        hash: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        password: '',
        size: 4
      },
      mfetp: {
        dateAnalyseLast: 1652451470804,
        engine: '6200.9189',
        error: false,
        update: '08/05/22',
        virus: []
      },
      sophos: {
        dateAnalyseLast: 1652451470804,
        engine: '3.85.1',
        error: false,
        update: '08/05/22',
        virus: []
      },
      trend: {
        dateAnalyseLast: 1652451470804,
        engine: '17.551.00',
        error: false,
        update: '08/05/22',
        virus: []
      },
      yara: {
        dateAnalyseLast: 1652451736575,
        engine: '4.0.2',
        error: false,
        update: '08/05/22',
        virus: []
      }
    },
    {
      _key: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08-1652451272475',
      _id: 'FileBackup/9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08-1652451272475',
      _rev: '_eJ1GZbG---',
      clamav: {
        dateAnalyseLast: 1652451272475,
        engine: '0.103.5',
        error: false,
        update: '08/05/22',
        virus: []
      },
      comodo: {
        dateAnalyseLast: 1652451272475,
        engine: '5.0.163652.1142',
        error: false,
        update: '08/05/22',
        virus: []
      },
      drweb: {
        dateAnalyseLast: 1652451272475,
        engine: '10.1.0.0.1503311845',
        error: false,
        update: '08/05/22',
        virus: []
      },
      eset: {
        dateAnalyseLast: 1652451272475,
        engine: '7.1.558.0',
        error: false,
        update: '08/05/22',
        virus: []
      },
      fileinfo: {
        MD5: '098f6bcd4621d373cade4e832627b4f6',
        SHA1: 'a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
        SHA256: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        dateAnalyseLast: 1652451272475,
        exiftool: {},
        'mime-encoding': 'us-ascii',
        'mime-type': 'text/plain',
        size: 12289,
        ssdeep: '3:Hn:Hn',
        trid: [],
        virus: []
      },
      fsecure: {
        dateAnalyseLast: 1652451272475,
        engine: '1.0  build 0069',
        error: false,
        update: '07/05/22',
        virus: []
      },
      kaspersky: {
        dateAnalyseLast: 1652451272475,
        engine: '10.1.1.6421',
        error: false,
        update: '01/01/70',
        virus: []
      },
      mdatp: {
        dateAnalyseLast: 1652451272475,
        engine: '101.65.77',
        error: false,
        update: '08/05/22',
        virus: []
      },
      meta: {
        dateAnalyseLast: 1652451272475,
        dateSubmissionFirst: 1652448388457,
        dateSubmissionLast: 1652448388457,
        filenames: [
          'test-yara.txt'
        ],
        hash: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        password: '',
        size: 4
      },
      mfetp: {
        dateAnalyseLast: 1652451272475,
        engine: '6200.9189',
        error: false,
        update: '08/05/22',
        virus: []
      },
      sophos: {
        dateAnalyseLast: 1652451272475,
        engine: '3.85.1',
        error: false,
        update: '08/05/22',
        virus: []
      },
      trend: {
        dateAnalyseLast: 1652451272475,
        engine: '17.551.00',
        error: false,
        update: '08/05/22',
        virus: []
      },
      yara: {
        dateAnalyseLast: 1652451272475,
        engine: '4.0.2',
        error: false,
        update: '08/05/22',
        virus: []
      }
    },

    {
      _key: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08-1652451194222',
      _id: 'FileBackup/9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08-1652451194222',
      _rev: '_eJ1DXvK---',
      clamav: {
        dateAnalyseLast: 1652448388457,
        engine: '0.103.5',
        error: false,
        update: '08/05/22',
        virus: []
      },
      comodo: {
        dateAnalyseLast: 1652448388457,
        engine: '5.0.163652.1142',
        error: false,
        update: '08/05/22',
        virus: []
      },
      drweb: {
        dateAnalyseLast: 1652448388457,
        engine: '10.1.0.0.1503311845',
        error: false,
        update: '08/05/22',
        virus: []
      },
      eset: {
        dateAnalyseLast: 1652448388457,
        engine: '7.1.558.0',
        error: false,
        update: '08/05/22',
        virus: []
      },
      fileinfo: {
        MD5: '098f6bcd4621d373cade4e832627b4f6',
        SHA1: 'a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
        SHA256: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        dateAnalyseLast: 1652448388457,
        exiftool: {},
        'mime-encoding': 'us-ascii',
        'mime-type': 'text/plain',
        size: 12298,
        ssdeep: '3:Hn:Hn',
        trid: [],
        virus: []
      },
      fsecure: {
        dateAnalyseLast: 1652448388457,
        engine: '1.0  build 0069',
        error: false,
        update: '07/05/22',
        virus: []
      },
      kaspersky: {
        dateAnalyseLast: 1652448388457,
        engine: '10.1.1.6421',
        error: false,
        update: '01/01/70',
        virus: []
      },
      mdatp: {
        dateAnalyseLast: 1652448388457,
        engine: '101.65.77',
        error: false,
        update: '08/05/22',
        virus: []
      },
      meta: {
        dateAnalyseLast: 1652451194222,
        dateSubmissionFirst: 1652448388457,
        dateSubmissionLast: 1652448388457,
        filenames: [
          'test-yara.txt'
        ],
        hash: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        password: '',
        size: 4
      },
      mfetp: {
        dateAnalyseLast: 1652448388457,
        engine: '6200.9189',
        error: false,
        update: '08/05/22',
        virus: []
      },
      sophos: {
        dateAnalyseLast: 1652448388457,
        engine: '3.85.1',
        error: false,
        update: '08/05/22',
        virus: []
      },
      trend: {
        dateAnalyseLast: 1652448388457,
        engine: '17.551.00',
        error: false,
        update: '08/05/22',
        virus: []
      },
      yara: {
        dateAnalyseLast: 1652451181862,
        engine: '4.0.2',
        error: false,
        update: '08/05/22',
        virus: []
      }
    },
    {
      _key: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08-1652451181862',
      _id: 'FileBackup/9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08-1652451181862',
      _rev: '_eJ1CLUu---',
      clamav: {
        dateAnalyseLast: 1652448388457,
        engine: '0.103.5',
        error: false,
        update: '08/05/22',
        virus: []
      },
      comodo: {
        dateAnalyseLast: 1652448388457,
        engine: '5.0.163652.1142',
        error: false,
        update: '08/05/22',
        virus: []
      },
      drweb: {
        dateAnalyseLast: 1652448388457,
        engine: '10.1.0.0.1503311845',
        error: false,
        update: '08/05/22',
        virus: []
      },
      eset: {
        dateAnalyseLast: 1652448388457,
        engine: '7.1.558.0',
        error: false,
        update: '08/05/22',
        virus: []
      },
      fileinfo: {
        MD5: '098f6bcd4621d373cade4e832627b4f6',
        SHA1: 'a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
        SHA256: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        dateAnalyseLast: 1652448388457,
        exiftool: {},
        'mime-encoding': 'us-ascii',
        'mime-type': 'text/plain',
        size: 4,
        ssdeep: '3:Hn:Hn',
        trid: [],
        virus: []
      },
      fsecure: {
        dateAnalyseLast: 1652448388457,
        engine: '1.0  build 0069',
        error: false,
        update: '07/05/22',
        virus: []
      },
      kaspersky: {
        dateAnalyseLast: 1652448388457,
        engine: '10.1.1.6421',
        error: false,
        update: '01/01/70',
        virus: []
      },
      mdatp: {
        dateAnalyseLast: 1652448388457,
        engine: '101.65.77',
        error: false,
        update: '08/05/22',
        virus: []
      },
      meta: {
        dateAnalyseLast: 1652451181862,
        dateSubmissionFirst: 1652448388457,
        dateSubmissionLast: 1652448388457,
        filenames: [
          'test-yara.txt'
        ],
        hash: '9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08',
        password: '',
        size: 4
      },
      mfetp: {
        dateAnalyseLast: 1652448388457,
        engine: '6200.9189',
        error: false,
        update: '08/05/22',
        virus: []
      },
      sophos: {
        dateAnalyseLast: 1652448388457,
        engine: '3.85.1',
        error: false,
        update: '08/05/22',
        virus: []
      },
      trend: {
        dateAnalyseLast: 1652448388457,
        engine: '17.551.00',
        error: false,
        update: '08/05/22',
        virus: []
      },
      yara: {
        dateAnalyseLast: 1652451181862,
        engine: '4.0.2',
        error: false,
        update: '08/05/22',
        virus: []
      }
    }
  ],

  analysing: 0
};

/** example vital exifTools response */
const exampleMfdExifTools = [
  {name: 'Creation date', value: '2022:07:12 16:38:47'},
  {name: 'Date', value: '2022:07:19 16:56:34'},
  {name: 'Document-statistic character-count', value: '1243'},
  {name: 'Document-statistic image-count', value: '0'},
  {name: 'Document statistic non-whitespace-character-count', value: '1023'},
  {name: 'Document statistic Object-count', value: '1023'},
  {name: 'Document statistic Page-count', value: '2'},
  {name: 'Document statistic Paragraph-count', value: '38'},
  {name: 'Document statistic Table-count', value: '0'},
  {name: 'Document statistic Word-count', value: '133'},
  {name: 'Editing-cycles', value: '4'},
  {name: 'Editing-duration', value: 'PT1M23S'},
  {name: 'Generator', value: 'LibreOffice/6.0.7$Linux_x_86_64'},
  {name: 'Preview PNG', value: '(Binary Data 3107 Bytes, use -b option to extract'}
];

