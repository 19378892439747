import { XtendedFacet, XtendedLocalFacet } from "../shared/model/cybersocxdr/facets";
import {
  ClientTicketCreated,
  ClosureVerdict,
  Severity,
  SLANotification,
  Status,
  WaitingFor
} from "../shared/model/cybersocxdr/facetsValues";

export interface IncidentFilterOptionsDTO {
  value: string;
  label: string;
}

export const localFilterOptions: { [key in XtendedLocalFacet]: IncidentFilterOptionsDTO[] } = {
  [XtendedFacet.CLOSURE_VERDICT]: [
    {value: ClosureVerdict.UNCATEGORIZED, label: "Uncategorized"},
    {value: ClosureVerdict.DUPLICATE, label: "Duplicate"},
    {value: ClosureVerdict.TRUE_POSITIVE, label: "True Positive"},
    {value: ClosureVerdict.FALSE_POSITIVE, label: "False Positive"},
    {value: ClosureVerdict.LINKED_INCIDENT, label: "Linked Incident"},
  ],
  [XtendedFacet.WAITING_FOR]: [
    {value: WaitingFor.OCD, label: "OCD"},
    {value: WaitingFor.CLIENT, label: "Client"},
    {value: WaitingFor.NO_ONE, label: "No One"},
  ],
  [XtendedFacet.SEVERITY]: [
    {value: Severity.CRITICAL, label: "Critical"},
    {value: Severity.HIGH, label: "High"},
    {value: Severity.MEDIUM, label: "Medium"},
    {value: Severity.LOW, label: "Low"},
    {value: Severity.INFORMATIONAL, label: "Informational"},
  ],
  [XtendedFacet.STATUS]: [
    {value: Status.NEW, label: "New"},
    {value: Status.INVESTIGATION, label: "Investigation"},
    {value: Status.REMEDIATION, label: "Remediation"},
    {value: Status.CLOSED, label: "Closed"},
  ],
  [XtendedFacet.SLA_NOTIFICATION]: [
    {value: SLANotification.BREAK, label: "Break"},
    {value: SLANotification.AT_RISK, label: "At Risk"},
    {value: SLANotification.IN, label: "In"},
    {value: SLANotification.NONE, label: "None"},
  ],
  [XtendedFacet.CLIENT_TICKET_CREATED]: [
    {value: ClientTicketCreated.CREATED, label: 'pages.cybersocxdr.filters.created'},
    {value: ClientTicketCreated.NOT_CREATED, label: 'pages.cybersocxdr.filters.notCreated'}
  ]
};
