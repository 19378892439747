import { SentinelOneCardType } from './sentinel-one-models';

export const S1_COLORS = {
  lighter: ['#FFD200', '#50BE87', '#4BB4E6', '#A885D8', '#FF7900', '#CD3C14'],
  darker: ['#FFB400', '#0A6E31', '#085EBD', '#492191', '#F16E00', '#CD3C14'],
};

export const TABLE_ACTIONS = {
  VIEW: 'view',
  INVESTIGATE: 'investigate',
  CLOSE: 'close',
  PROCESS: 'in-process',
};

// MES CARDS AND DATA STRUCTURES (with data samples to override)
export const S1_DATA = {
  overview: {
    threatsCard: {
      type: SentinelOneCardType.SIMPLE,
      titleKey: 'pages.updates.sentinelOne.threats',
      data: null,
      textKey: 'pages.updates.sentinelOne.threatsDesc',
    },
    supportCasesCard: {
      type: SentinelOneCardType.SIMPLE,
      titleKey: 'pages.updates.sentinelOne.supportCases',
      data: null,
      textKey: 'pages.updates.sentinelOne.supportCasesDesc',
    },
    endpointsCard: {
      type: SentinelOneCardType.SIMPLE,
      titleKey: 'pages.updates.sentinelOne.endpoints',
      data: null,
      textKey: 'pages.updates.sentinelOne.endpointsDesc',
    },
    investigationCreditsCard: {
      type: SentinelOneCardType.SIMPLE,
      titleKey: 'pages.updates.sentinelOne.investigationCredits',
      data: null,
      textKey: 'pages.updates.sentinelOne.investigationCreditsDesc',
    },
    threatFunnelCard: {
      type: SentinelOneCardType.THREAT_FUNNEL,
      titleKey: 'pages.updates.sentinelOne.threatFunnel',
      data: [],
      textKey: 'pages.updates.sentinelOne.threatFunnelDesc',
    },
    endpointsMonitoredCard: {
      type: SentinelOneCardType.ENDPOINTS_MONITORED,
      titleKey: 'pages.updates.sentinelOne.endpointsMonitored',
      data: [],
      textKey: 'pages.updates.sentinelOne.endpointsMonitoredDesc',
    },
    endpointsMonitoredData: [
      [
        {
          name: 'monitoredEndpoints',
          textKey: 'pages.updates.sentinelOne.endpointsAreCurrentlyMonitored',
          value: null,
        },
      ],
      [
        {
          name: 'workstations',
          textKey: 'pages.updates.sentinelOne.workstations',
          value: null,
        },
        {
          name: 'servers',
          textKey: 'pages.updates.sentinelOne.servers',
          value: null,
        },
        {
          name: 'unknownDevices',
          textKey: 'pages.updates.sentinelOne.unknownDevices',
          value: null,
        },
      ],
      [
        {
          name: 'criticalEndpoints',
          textKey: 'pages.updates.sentinelOne.criticalEndpoints',
          value: null,
        },
      ],
      [
        {
          name: 'endPointsWithThreats',
          textKey: 'pages.updates.sentinelOne.endpointsWithThreats',
          value: null,
        },
        {
          name: 'maliciousThreats',
          textKey: 'pages.updates.sentinelOne.maliciousThreats',
          value: null,
        },
        {
          name: 'suspiciousThreats',
          textKey: 'pages.updates.sentinelOne.suspiciousThreats',
          value: null,
        },
      ],
    ],
    threatFunnelData: [
      {
        name: 'threatsDetected',
        textKey: 'pages.updates.sentinelOne.threatsDetected',
        value: null,
      },
      {
        name: 'threatsRemediated',
        textKey: 'pages.updates.sentinelOne.threatsRemediated',
        value: null,
      },
      {
        name: 'threatsEscalatedByYou',
        textKey: 'pages.updates.sentinelOne.threatsEscalatedByYou',
        value: null,
      },
      {
        name: 'currentlyOpenCases',
        textKey: 'pages.updates.sentinelOne.currentlyOpenCases',
        value: null,
      },
    ],
  },
  endpointManagement: {
    topRiskyEndpointsCard: {
      type: SentinelOneCardType.TABLE,
      titleKey: 'pages.updates.sentinelOne.topFiveRiskyEndpoints',
      data: undefined,
      textKey: '',
    },
    endpointsWithOpenThreatsCard: {
      type: SentinelOneCardType.TABLE,
      titleKey: 'pages.updates.sentinelOne.endpointsWithOpenThreats',
      data: undefined,
      textKey: '',
    },
    endpointsOfflineCard: {
      type: SentinelOneCardType.TABLE,
      titleKey: 'pages.updates.sentinelOne.endpointsOfflineForMoreThan30Days',
      data: undefined,
      textKey: '',
    },
    allEndpointsCard: {
      type: SentinelOneCardType.TABLE,
      titleKey: 'pages.updates.sentinelOne.allEndpoints',
      data: undefined,
      textKey: '',
    },
    topRiskyEndpointsData: {
      columnKeys: ['pages.updates.sentinelOne.endpoint', 'pages.updates.sentinelOne.threatsInThePast30Days'],
      data: [],
    },
    endpointsWithOpenThreatsData: {
      columnKeys: ['pages.updates.sentinelOne.endpoint', 'pages.updates.sentinelOne.openThreats'],
      data: [],
    },
    endpointsOfflineData: {
      columnKeys: ['pages.updates.sentinelOne.endpoint', 'pages.updates.sentinelOne.lastSeen'],
      data: [],
    },
    allEndpointsData: {
      columnKeys: [
        'pages.updates.sentinelOne.endpoint',
        'pages.updates.sentinelOne.type',
        'pages.updates.sentinelOne.operatingSystem',
        'pages.updates.sentinelOne.lastSeen',
        'pages.updates.sentinelOne.criticalEndpoint',
        'pages.updates.sentinelOne.policy',
      ],
      data: [],
    },
  },
  threatManagement: {
    openSecurityIncidentsCard: {
      title: 'openSecurityIncidents',
      type: SentinelOneCardType.SIMPLE,
      titleKey: 'pages.updates.sentinelOne.openSecurityIncidents',
      data: [],
      textKey: '',
      color: '#F16E00',
    },
    incidentsByConfidenceCard: {
      title: 'incidentsByConfidence',
      type: SentinelOneCardType.GRAPH,
      titleKey: 'pages.updates.sentinelOne.incidentsByConfidence',
      data: undefined,
      textKey: '',
      graphOptions: null,
    },
    incidentsByPriorityCard: {
      title: 'incidentsByPriority',
      type: SentinelOneCardType.GRAPH,
      titleKey: 'pages.updates.sentinelOne.incidentsByPriority',
      data: undefined,
      textKey: '',
      graphOptions: null,
    },
    incidentsRequiringAttentionCard: {
      title: 'incidentsRequiringAttention',
      type: SentinelOneCardType.TABLE,
      titleKey: 'pages.updates.sentinelOne.incidentsRequiringAttention',
      data: undefined,
      textKey: '',
    },
    allSecurityIncidentsCard: {
      title: 'recentSecurityIncidents',
      type: SentinelOneCardType.TABLE,
      titleKey: 'pages.updates.sentinelOne.recentSecurityIncidents',
      data: undefined,
      textKey: '',
      graphOptions: null,
      buttonLink: {
        textKey: 'View all',
        link: '',
      },
    },
    openSecurityIncidentsData: [
      {
        name: 'openSecurityIncidents',
        textKey: 'pages.updates.sentinelOne.openSecurityIncidents',
        value: 2,
      },
      {
        name: 'criticalSecurityIncidents',
        textKey: 'pages.updates.sentinelOne.critical',
        value: 1,
      },
      {
        name: 'highSecurityIncidents',
        textKey: 'pages.updates.sentinelOne.high',
        value: 1,
      },
      {
        name: 'mediumSecurityIncidents',
        textKey: 'pages.updates.sentinelOne.medium',
        value: 1,
      },
      {
        name: 'lowSecurityIncidents',
        textKey: 'pages.updates.sentinelOne.low',
        value: 1,
      },
      {
        name: 'planningSecurityIncidents',
        textKey: 'pages.updates.sentinelOne.planning',
        value: 1,
      },
    ],
    incidentsByConfidenceData: {
      titleKey: 'pages.updates.sentinelOne.securityIncidents',
      data: [
        {
          name: 'malicious',
          textKey: 'pages.updates.sentinelOne.malicious',
          value: 35,
        },
        {
          name: 'suspicious',
          textKey: 'pages.updates.sentinelOne.suspicious',
          value: 15,
        },
      ],
    },
    incidentsByPriorityData: {
      titleKey: 'pages.updates.sentinelOne.securityIncidents',
      data: [
        {
          name: 'critical',
          textKey: 'pages.updates.sentinelOne.critical',
          value: 30,
        },
        {
          name: 'high',
          textKey: 'pages.updates.sentinelOne.high',
          value: 13,
        },
        {
          name: 'medium',
          textKey: 'pages.updates.sentinelOne.medium',
          value: 4,
        },
        {
          name: 'low',
          textKey: 'pages.updates.sentinelOne.low',
          value: 3,
        },
        {
          name: 'planning',
          textKey: 'pages.updates.sentinelOne.planning',
          value: 3,
        },
      ],
    },
    incidentsRequiringAttentionData: {
      columnKeys: ['pages.updates.sentinelOne.caseNumber', 'pages.updates.sentinelOne.priority'],
      data: [],
    },
    allSecurityIncidentsData: {
      columnKeys: [
        'pages.updates.sentinelOne.caseNumber',
        'pages.updates.sentinelOne.priority',
        /* 'pages.updates.sentinelOne.confidence',
        'pages.updates.sentinelOne.classification', */
        'pages.updates.sentinelOne.status',
        'pages.updates.sentinelOne.created',
        TABLE_ACTIONS.VIEW, // view icon column
        // TABLE_ACTIONS.INVESTIGATE, // investigate icon column
        // TABLE_ACTIONS.CLOSE // close icon column
      ],
      data: [],
    },
  },
};

export const sentinelOneGraphOptions = {
  title: {
    text: '',
    align: 'center',
    verticalAlign: 'middle',
    y: -15,
  },
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: 0,
    plotShadow: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  legend: {
    enabled: true,
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'horizontal',
  },
  tooltip: {
    headerFormat: '',
    pointFormat: `<span style="color:{point.color}">\u25CF</span>
      {point.name}: <b>{point.y}</b><br/>`,
  },
  accessibility: {
    description: `The chart shows the number of security incidents by status.`,
    point: {
      descriptionFormat: '{name}: {y} incidents.',
    },
  },
  credits: {
    enabled: false,
  },
  series: [],
};
