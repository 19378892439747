import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientContact } from 'src/app/shared/model/cybersocxdr/clientContact';

@Component({
  selector: 'app-clientcontactstable',
  templateUrl: './clientcontactstable.component.html',
  styleUrl: './clientcontactstable.component.scss',
})
export class ClientcontactstableComponent {
  @Input() clientContacts: ClientContact[];
  @Output() onSubmitContacts = new EventEmitter<ClientContact[]>();
  contactFormGroup: FormGroup;
  isEditing = false;

  constructor(private readonly formBuilder: FormBuilder) {
    this.contactFormGroup = this.formBuilder.group({
      contacts: this.formBuilder.array([]),
    });
  }

  enableEditMode() {
    this.contactFormGroup = this.formBuilder.group({
      contacts: this.formBuilder.array([]),
    });

    const controls = <FormArray>this.contactFormGroup.get('contacts');
    for (const client of this.clientContacts) {
      controls.push(this.initiateInlineFormGroup(client));
    }

    this.isEditing = true;
  }

  getFormArray(): FormArray {
    return <FormArray>this.contactFormGroup.get('contacts');
  }

  addNewContact() {
    this.getFormArray().push(this.initiateEmptyInlineFormGroup());
  }

  removeContact(event: Event, index: number) {
    // Keyboard enter key
    if (event instanceof PointerEvent && event.pointerId == -1) return;

    this.getFormArray().removeAt(index);
  }

  save() {
    this.onSubmitContacts.emit(this.contactFormGroup.value.contacts);
    this.isEditing = false;
  }

  cancel() {
    this.isEditing = false;
  }

  /** Initialise a form group for a single line */
  initiateInlineFormGroup(contact: ClientContact) {
    const emptyGroup = this.initiateEmptyInlineFormGroup();
    emptyGroup.patchValue({
      name: contact.name,
      mail: contact.mail,
      role: contact.role,
      number: contact.number,
    });
    return emptyGroup;
  }

  initiateEmptyInlineFormGroup() {
    return this.formBuilder.group({
      name: ['', [Validators.required]],
      mail: ['', [Validators.required, Validators.email]],
      role: ['', [Validators.required]],
      // There is no prebuilt validator for phone numbers. Somehow
      number: ['', [Validators.required, Validators.pattern('\\+[- ()0-9]{6,}')]],
    });
  }
}
