<h3>{{ parentItem.title }}</h3>
<div class="grid-container" *ngIf="items.length > 0">

  @if(!showEmbedded) {

    @if(parentItem) {
      <div class="presentation">
        <img class="card-img-top" src="{{ parentItem.imagePath }}" alt="{{ parentItem.title }} illustration">
      </div>
    }

    <div class="launcher">
      @for (item of items; track item.title) {
        <div>
          <div class="card main mb-3">
            <div class="header m-2">
              <h3 class="title" data-toggle="tooltip" data-placement="top">{{ item?.title}}</h3>
            </div>
            <div class="card-body">
              <p class="card-text" [innerHTML]="item?.description"></p>
            </div>
            <div class="card-footer">
              <button class="btn btn-primary m-1 btn-launch"
                (click)="openCourse(item?.id);">
                {{  'pages.updates.training.startCourse' | translate }}
              </button>
            </div>
          </div>
        </div>
      }
  
      <button class="btn btn-primary m-1 btn-back"
        (click)="goToTrainingDashboard();">
        {{  'pages.updates.training.returnToTrainings' | translate }}
      </button>
  
    </div>
  }
  
  @if(showEmbedded) {
    <div #embedSection id="embedSection" class="embed-section">
      <div #iframeDiv>
        <iframe title="{{ selectedItem.title }} training" class="easy-generator-embed" [src]="url" allowfullscreen></iframe>
      </div>
      <button #endCourseBtn
        [hidden]="!showEndOfCourseBtn"
        type="button"
        class="btn btn-primary btn-overlap glowing"
        title="{{ 'pages.updates.training.returnDesc' | translate }}"
        (click)="goToTrainingDashboard()">
        {{  'pages.updates.training.return' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        title="{{ 'pages.updates.training.closeDesc' | translate }}"
        (click)="closeCourse()">
        {{  'buttons.action.close' | translate }}
      </button>
    </div>
  }
  
</div>
