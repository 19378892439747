import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { SeriesPieOptions } from 'highcharts';
import { AppSharedModule } from 'src/app/app-shared.module';
import { CardComponent } from 'src/app/shared/components/xtended/card/card.component';
import { InsightData } from 'src/app/shared/model/cybersocxdr/insight';
import { SelectOption } from 'src/app/shared/model/cybersocxdr/selectOptions';
import {
  AssessmentTableItemsDto,
  VulnerabilitiesCountDto,
} from 'src/app/shared/services/ethicalhacking/model/ethicalHackingDtos';
import { VulnerabilitiesEthicalHackingService } from 'src/app/shared/services/ethicalhacking/vulnerabilities.service';
import { FindingStatus, FindingStatusMap } from 'src/app/shared/utils/xtended/constants.utils';
import { AssessmentTableComponent } from '../../components/assessments-table/assessment-table.component';
@Component({
  selector: 'app-ethicalhacking-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: '../../../layout/xtended/ethical-hacking-layout/styles/ethicalhacking.scss',
  standalone: true,
  imports: [TranslateModule, AppSharedModule, CardComponent, AssessmentTableComponent],
})
export class EthicalhackingDashboardComponent implements OnInit {
  private readonly vulnerabilitiesService: VulnerabilitiesEthicalHackingService = inject(VulnerabilitiesEthicalHackingService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  protected readonly ASSESSMENTS_LIMIT = 5;
  protected readonly INSIGHTS_LIMIT = 5;
  protected readonly INSIGHTS_INTERVAL_TYPE = 'month';
  protected groupedVulnerabilities: SeriesPieOptions[];
  protected top5LatestAssessments: AssessmentTableItemsDto[] = [];
  protected ethicalHackingInsights: InsightData[] = [];

  protected readonly groupByOptions: SelectOption[] = [
    { value: 'open', label: 'pages.ethicalhacking.dashboard.reportedVulnerabilitiesChart.groupBy.open' },
    { value: 'closed', label: 'pages.ethicalhacking.dashboard.reportedVulnerabilitiesChart.groupBy.closed' },
    { value: 'all', label: 'pages.ethicalhacking.dashboard.reportedVulnerabilitiesChart.groupBy.all' },
  ];
  protected groupBy: SelectOption = this.groupByOptions[0];


  public async ngOnInit(): Promise<void> {
    this.getGroupedVulnerabilities(this.getFindingStatuses(this.groupBy.value));
    this.vulnerabilitiesService.getAllVulnerabilityInsights(this.INSIGHTS_INTERVAL_TYPE, this.INSIGHTS_LIMIT)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((insights) => {
        this.ethicalHackingInsights = this.formatInsightsData(insights);
      });
  }

  // ---- PIE CHART DATA FORMAT ----

  private getGroupedVulnerabilities(findingsStatus: FindingStatus[]): void {
    this.vulnerabilitiesService.getReportedVulnerabilities(findingsStatus)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((vulnerabilities) => {
        this.groupedVulnerabilities = this.formatVulnerabilitiesPie(vulnerabilities);
      });
  }

  private formatVulnerabilitiesPie(vulnerabilities: VulnerabilitiesCountDto[]): SeriesPieOptions[] {
    const series = vulnerabilities.map((vulnerability) => ({
      name: `pages.ethicalhacking.dashboard.severity.${vulnerability.name}`,
      y: vulnerability.count,
      color: `var(--severity-${vulnerability.name}-bg)`,
    }));

    return [
      {
        type: 'pie',
        data: series,
      },
    ];
  }

  private getFindingStatuses(value: string): FindingStatus[] {
    return FindingStatusMap[value] || FindingStatusMap['all'];
  }

  public updateGroupBy(selectedValue: SelectOption): void {
    if (selectedValue) {
      const findingStatuses = this.getFindingStatuses(selectedValue.value);

      this.groupBy = { value: selectedValue.value, label: selectedValue.label };
      this.getGroupedVulnerabilities(findingStatuses);
    }
  }

  // ----------------- INSIGHTS DATA FORMAT --------------

  private formatInsightsData(insights: InsightData[]): InsightData[] {
    return insights.map((insight) => ({
      ...insight,
      title: insight.title ? `pages.ethicalhacking.dashboard.insights.${insight.title}` : '',
    }));
  }

}
