import { Component, Input } from '@angular/core';
import { EXPORT_COLUMNS, EXPORT_TYPES, PDF_ACCESS_LEVELS } from 'src/app/shared/model/shared-items';
import { ExportParameters, GenerateReportParameters} from '../../modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss']
})
export class ExportModalComponent {

  @Input() params: GenerateReportParameters;

  public fileName = '';
  protected defaultExportColumns = EXPORT_COLUMNS.selectedColumns.value;
  public defaultReportOption = EXPORT_TYPES.csv.value;
  public defaultPDFAccessLevel = PDF_ACCESS_LEVELS.internal.value;
  public scheduleDate = '';
  public reportIsScheduled = false;

  protected exportColumnsOptions = EXPORT_COLUMNS; 
  public pdfAccessLevels = PDF_ACCESS_LEVELS;
  public exportTypes = EXPORT_TYPES;

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  public success() {
    this.activeModal.close();
    if(this.reportIsScheduled) {
      this.params.successCallback(
        new ExportParameters(this.fileName, this.defaultExportColumns, this.defaultReportOption, this.defaultPDFAccessLevel, this.reportIsScheduled, this.scheduleDate)
      );
    } else {
      this.params.successCallback(
        new ExportParameters(this.fileName, this.defaultExportColumns, this.defaultReportOption, this.defaultPDFAccessLevel)
      );
    }
  }

  public close() {
    this.activeModal.close();
  }

}
