<div class="incident-title-container">
  <div class="status-circle" [ngClass]="{
        'new': xdrCase?.socXdrStatus?.toLowerCase() === 'new',
        'investigation': xdrCase?.socXdrStatus?.toLowerCase() === 'investigation',
        'remediation': xdrCase?.socXdrStatus?.toLowerCase() === 'remediation',
        'closed': xdrCase?.socXdrStatus?.toLowerCase() === 'closed'
      }">
    <div class="wave-container">
      <svg class="wave wave1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 28" preserveAspectRatio="none">
        <path d="M0 25 Q30 10, 60 20 T120 15 V30 H0 Z" fill="rgba(255,255,255,0.6)" />
      </svg>
      <svg class="wave wave2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 28" preserveAspectRatio="none">
        <path d="M0 25 Q30 20, 60 10 T120 18 V30 H0 Z" fill="rgba(255,255,255,0.4)" />
      </svg>
    </div>
  </div>

  <span class="closure-verdict-badge me-2"
  [ngClass]="{
    'uncategorized': !xdrCase?.closureVerdict || xdrCase?.closureVerdict?.toLowerCase() === 'uncategorized',
    'duplicate': xdrCase?.closureVerdict?.toLowerCase() === 'duplicate',
    'linked-incident': xdrCase?.closureVerdict?.toLowerCase() === 'linked incident',
    'false-positive': xdrCase?.closureVerdict?.toLowerCase() === 'false positive',
    'true-positive': xdrCase?.closureVerdict?.toLowerCase() === 'true positive'
  }">
  {{ xdrCase?.closureVerdict || ('pages.cybersocxdr.dashboard.noClosureVerdict' | translate) }}
  </span>


  <h1 class="incident-title">{{ xdrCase.subject | anonymise }}</h1>
  </div>
  <div class="title-separator"></div>

  <div class="container bg-body-tertiary p-3" style="max-width: 1800px">

  <div class="row mb-3">
    <div class="col-12 col-lg-6">
      <app-card
        class="mw-100"
        cardTitle="pages.cybersocxdr.dashboard.fieldTitles.incidentInformation"
      >
        <div class="row mb-3">
          <div class="col-6">

            <h4 class="mb-2">
              <fa-icon [icon]="['fas', 'link']"></fa-icon>
              {{ 'pages.cybersocxdr.dashboard.fieldTitles.incidentUrl' | translate }}
            </h4>
            <a [href]="xdrCase.incidentUrl" class="col-6">
              {{ xdrCase.incidentUrl | shortUrl | anonymise }}
            </a>
          </div>
          <div class="col-6">
            <h4 class="mb-2">
              <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
              {{ 'pages.cybersocxdr.dashboard.fieldTitles.occured' | translate }}
            </h4>
            <p>{{ xdrCase.createdDate | date : 'short' }}</p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-6">
            <h4 class="mb-3">
              <fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
              {{ 'pages.cybersocxdr.dashboard.fieldTitles.severity' | alternate | translate }}
            </h4>
            <span
              class="py-1 px-2 rounded-pill fw-bold"
              [ngClass]="'P' + xdrCase.severity | lowercase | removeWhiteSpace"
            >
              {{ xdrCase.severity | severity | alternate | translate }}
            </span>

            <h4 class="mt-3 mb-2">
              <fa-icon [icon]="['fas', 'list-alt']"></fa-icon>
              {{ 'pages.cybersocxdr.dashboard.fieldTitles.status' | translate }}
            </h4>
            <p>{{ xdrCase.socXdrStatus }}</p>
          </div>

          <div class="col-6">
            <h4 class="mb-2">
              <fa-icon [icon]="['fas', 'user']"></fa-icon>
              {{ 'pages.cybersocxdr.dashboard.fieldTitles.incidentOwner' | translate }}
            </h4>
            <p>{{ (xdrCase.owner | anonymise) || 'None' }}</p>
            <h4 class="mt-3 mb-2">
              <fa-icon [icon]="['fas', 'hashtag']"></fa-icon>
              {{ 'pages.cybersocxdr.dashboard.fieldTitles.incidentId' | translate }}
            </h4>
            <p>{{ incidentId || 'N/A' }}</p>
          </div>
        </div>
        <div class="row mb-2 incident-description-container">
          <div class="col-12">
            <h4 class="mb-2">
              <fa-icon [icon]="['fas', 'file-alt']"></fa-icon>
              {{ 'pages.cybersocxdr.dashboard.fieldTitles.description' | translate }}
            </h4>
            <p class="incident-description">{{ xdrCase.subject | anonymise }}</p>
          </div>
        </div>
      </app-card>

      <app-card
      class="mw-100 mt-3"
      cardTitle="pages.cybersocxdr.dashboard.fieldTitles.closureInformation">
        <div class="row">
          <div class="col-6">
            <h4 class="mb-2">
              {{ 'pages.cybersocxdr.dashboard.fieldTitles.closureDate' | translate }}
            </h4>
            <p>{{ xdrCase?.closedDate | date: 'medium' }}</p>
          </div>
          <div class="col-6 text-end">
            <h4 class="mb-2">
              {{ 'pages.cybersocxdr.dashboard.fieldTitles.closureVerdict' | translate }}
            </h4>
            <span class="closure-verdict-badge"
            [ngClass]="{
              'uncategorized': !xdrCase?.closureVerdict ||xdrCase?.closureVerdict?.toLowerCase() === 'uncategorized',
              'duplicate': xdrCase?.closureVerdict?.toLowerCase() === 'duplicate',
              'linked-incident': xdrCase?.closureVerdict?.toLowerCase() === 'linked incident',
              'false-positive': xdrCase?.closureVerdict?.toLowerCase() === 'false positive',
              'true-positive': xdrCase?.closureVerdict?.toLowerCase() === 'true positive'
            }">
          {{ xdrCase?.closureVerdict || ('pages.cybersocxdr.dashboard.noClosureVerdict' | translate) }}
          </span>
      
          </div>
        </div>

        <hr />

        <h4 class="mt-3 mb-2">
          {{ 'pages.cybersocxdr.dashboard.closureComment' | translate }}
        </h4>
        <p>{{ xdrCase?.socXdrClosure || ('pages.cybersocxdr.dashboard.noComment' | translate) }}</p>
      </app-card>


      <app-card
        class="mw-100 mt-3"
        cardTitle="pages.cybersocxdr.incidentDetail.incidentStateOvertime"
      >
        <app-incident-timeline [data]="xdrCase.timeline" />
      </app-card>
    </div>

    <div class="col-12 col-lg-6">
      <app-card
        class="h-100 mw-100"
        cardTitle="pages.cybersocxdr.dashboard.investigation"
      >
      <div id="investigationContent" *ngIf="investigationContent$ | async as content; else noInvest"
      [innerHTML]="content">
      </div>
    
      <ng-template #noInvest>
        <p>{{ 'pages.cybersocxdr.dashboard.noInvestigation' | translate }}</p>
      </ng-template>
      </app-card>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12 col-lg-6">
      <app-card
        class="mw-100"
        cardTitle="pages.cybersocxdr.dashboard.fieldTitles.slaFull"
      >
        <div class="row">
          <div
            *ngIf="xdrCase.notificationSla && xdrCase.investigationSla; else noSla"
            class="row"
          >
            <div class="col-6 text-center">
              <h4 class="font-weight-bold">
                {{ 'pages.cybersocxdr.dashboard.fieldTitles.slaNotificationTime' | translate }}
              </h4>
              <p
                class="display-3"
                [ngClass]="{
                  'text-danger': xdrCase.notificationSla > xdrCase.allowedNotificationSla,
                  'text-success': xdrCase.notificationSla <= xdrCase.allowedNotificationSla
                }"
              >
                {{ parseTimeLeft(xdrCase.notificationSla) }}
              </p>
            </div>
            <div class="col-6 text-center">
              <h4 class="font-weight-bold">
                {{ 'pages.cybersocxdr.dashboard.fieldTitles.slaInvestigationTime' | translate }}
              </h4>
              <p
                class="display-3"
                [ngClass]="{
                  'text-danger': xdrCase.investigationSla > xdrCase.allowedInvestigationSla,
                  'text-success': xdrCase.investigationSla <= xdrCase.allowedInvestigationSla
                }"
              >
                {{ parseTimeLeft(xdrCase.investigationSla) }}
              </p>
            </div>
          </div>
          <ng-template #noSla>
            <p>{{ 'pages.cybersocxdr.dashboard.noSla' | translate }}</p>
          </ng-template>
        </div>
      </app-card>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <app-card class="mw-100">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <div>
            <ng-container *ngIf="xdrCase?.ticketUrl; else noUrl">
              <a [href]="xdrCase.ticketUrl" target="_blank" class="text-primary fw-bold">
                {{ xdrCase.clientTicketNumber || xdrCase.clientTicketId }}
              </a>
            </ng-container>
            <ng-template #noUrl>
              <strong>{{ xdrCase.clientTicketNumber || xdrCase.clientTicketId || 'N/A' }}</strong>
            </ng-template>
          </div>
    
          <div class="flex-grow-1 text-center">
            <h3 class="m-0">{{ 'pages.cybersocxdr.dashboard.fieldTitles.itsm' | translate }}</h3>
          </div>
          <div class="spacer"></div>
        </div>
      
        <table class="table table-striped">
          <caption class="d-none">{{ 'pages.cybersocxdr.dashboard.fieldTitles.itsm' | translate }}</caption>
          <thead>
            <tr>
              <th>{{ 'pages.cybersocxdr.dashboard.fieldTitles.date' | translate }}</th>
              <th>{{ 'pages.cybersocxdr.dashboard.fieldTitles.user' | translate }}</th>
              <th>{{ 'pages.cybersocxdr.dashboard.fieldTitles.comment' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            @for (exchange of itsmExchanges; track exchange) {
              <tr>
              <td>{{ exchange.date | date:'dd/MM/yyyy HH:mm' }}</td>
              <td>{{ exchange.user }}</td>
              <td [innerHTML]="exchange.comment"></td>
              </tr> 
            }
          </tbody>
        </table>
      </app-card>      
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12 col-lg-6">
      <app-card
        class="mw-100 mb-lg-0"
        cardTitle="{{ 'pages.cybersocxdr.charts.preciseCategoryOvertime' | translate : { category: xdrCase.category } }}"
      >
        <app-barovertime
          [data]="categoryHighchartsData"
          [createdFrom]="oldDate"
          [createdTo]="currentDate"
        />
      </app-card>
    </div>
    <div class="col-12 col-lg-6">
      <app-card
        class="mw-100 h-100"
        cardTitle="pages.cybersocxdr.charts.similarOvertime"
      >
        <app-barovertime
          [data]="similarHighchartsData"
          [createdFrom]="oldDate"
          [createdTo]="currentDate"
        />
      </app-card>
    </div>
  </div>
  @if (xdrCase) {
  <div class="row">
    <app-card>
      <app-xtended-incident-list
        title="{{ 'pages.cybersocxdr.menu.similarIncident' | translate }}"
        [sortable]="false"
        [loaded]="true"
        [cases]="similarXdrCases"
        [displayFlag]="false"
      />
    </app-card>
  </div>
}
</div>
