  <div
    class="openclosedcards border border-{{ colorClass }} bg-{{ colorClass }}"
  >
    <div class="title">
      <span>{{ title }}</span>
    </div>
    <div class="count">
      <span>{{ count }}</span>
    </div>
  </div>
