export enum AssessmentSortableFields {
  COMPLETED_DATE = 'completed_date',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  NEXT_DEADLINE = 'next_deadline',
}

export enum AssessmentStatus {
  PUBLISHED = 3,
}
