import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CardComponent } from 'src/app/shared/components/xtended/card/card.component';
import { AppSharedModule } from '../app-shared.module';
import { EthicalhackingRoutingModule } from './ethicalhacking-routing.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
@NgModule({
  declarations: [],
  providers: [],
  imports: [CommonModule, EthicalhackingRoutingModule, AppSharedModule, FormsModule, CardComponent, BreadcrumbComponent],
})
export class EthicalhackingModule {}
