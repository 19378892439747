import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientEntityDto, XtendedClientService } from 'src/app/shared/services/xtendedsoc/xtended-client.service';

@Component({
  selector: 'app-xtended-entities',
  templateUrl: './entities.component.html',
})
export class XtendedSOCEntitiesComponent implements OnInit {
  public entities: ClientEntityDto[] = [];

  constructor(private readonly xtendedClientService: XtendedClientService, private readonly router: Router) {}

  ngOnInit(): void {
    this.loadEntities();
  }

  private async loadEntities(): Promise<void> {
    try {
      this.entities = await this.xtendedClientService.getEntities();
    } catch (error) {
      this.entities = [];
    }
  }

  onEntityClick(entityId: number): void {
    this.router.navigate(['/xtended-soc/entities', entityId]);
  }
}
