@if(loaded) {
<div>
    <!-- header -->
    <div class="header card">
        <!-- survey selection -->
        <div class="input-group">
            <label class="me-2" for="searchInput">{{"pages.feedbacks.surveySummary.selectedSurvey" | translate}} : </label>
            <select class="form-select" [(ngModel)]="selectedSurvey" (change)="updateSelectedSurvey()"
                aria-label="select survey list">Search
                @for(survey of surveys; track survey) {
                <option [ngValue]="survey">{{survey.title}}</option>
                }
            </select>
        </div>
        
        <!-- count -->
        <div class="input-group">
            <div class="sticker sticker-fs-l me-2">{{surveySummaryService.surveyResult().details.count}}</div>
            <label>{{"pages.feedbacks.surveySummary.collectedSurvey" | translate : {date: this.surveySummaryService.selectedSurvey().timestamp_created | timeZoneDatetimePipe} }}</label>
        </div>

         <!-- description -->
         <div class="input-group">
            <label class="me-2" for="searchInput">{{"pages.shared.description" | translate }} : </label>
            <label for="searchInput">{{this.surveySummaryService.selectedSurvey().desc ? this.surveySummaryService.selectedSurvey().desc : "pages.shared.none" | translate}}</label>
        </div>
    </div>

    <!-- answers -->
    <div class="response">
        @for(answer of surveySummaryService.surveyResult().answers; track answer; let i = $index) {
            <div class="card widgets">
                <label class="tag no-border active m-2"><span class="visually-hidden">Filter by</span>Q.{{i+1}} - {{answer.question.title}}</label>
                
                @if(answer.question.component === 'list-order') {
                    <app-list-order-answer [data]="answer.data"></app-list-order-answer>
                }@else if (answer.question.component === 'multi-select') {
                    <app-multi-select-answer [data]="answer.data"></app-multi-select-answer>
                }@else if (answer.question.component === 'single-select') {
                    <app-single-select-answer [data]="answer.data"></app-single-select-answer>
                }@else if (answer.question.component === 'closed-choice') {
                    <app-closed-choice-answer [data]="answer.data"></app-closed-choice-answer>
                }@else if (answer.question.component === 'rating') {
                    <app-rating-answer [data]="answer.data"></app-rating-answer>
                }
            </div>
        }
    </div>

</div>
} @else {
<div class="row justify-content-center p-5">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
</div>
}