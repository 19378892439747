<div class="table-responsive">
  <table class="table table-striped table-hover">
    <caption class="d-none">
      {{
        'pages.ethicalhacking.assessmentsTable.caption' | translate
      }}
    </caption>
    <thead>
      <tr>
        <th scope="col">
          {{ 'pages.ethicalhacking.assessmentsTable.columns.date' | translate }}
        </th>
        <th scope="col">
          {{
            'pages.ethicalhacking.assessmentsTable.columns.perimeter'
              | translate
          }}
        </th>
        <th scope="col">
          {{
            'pages.ethicalhacking.assessmentsTable.columns.owner' | translate
          }}
        </th>
        <th scope="col">
          {{
            'pages.ethicalhacking.assessmentsTable.columns.vulnerabilities'
              | translate
          }}
        </th>
      </tr>
    </thead>
    <tbody>
      @for(item of assessments?.items ?? []; track item) {
      <tr
        (click)="navigateToAssessmentDetail(item.id)"
        (keydown.enter)="navigateToAssessmentDetail(item.id)"
        tabindex="0"
      >
        <td>
          {{ item.date | date : 'shortDate' : '' : translate.currentLang }}
        </td>
        <td>{{ item.name }}</td>
        <td>{{ item.owner }}</td>
        <td>
          <div class="vulnerabilities-container">
            @for(severity of severitiesTypes; track severity) {
            <div
              [ngbTooltip]="
                'pages.ethicalhacking.dashboard.severity.' + severity
                  | translate
              "
              class="severity-{{ severity }}"
            >
              {{ item.status[severity] }}
            </div>
            }
          </div>
        </td>
      </tr>
      }
    </tbody>
  </table>
</div>

@if(isPaginated) {
<div style="display: flex; justify-content: center">
  @if(assessments?.page != 1) {
  <button
    class="btn"
    type="button"
    (click)="loadPage((assessments?.page ?? 2) - 1)"
  >
    {{ 'pages.ethicalhacking.assessmentsTable.previousPage' | translate }}
  </button>
  } @for(page of generatePageArray(assessments?.page ?? 1); track $index) {
  <button
    class="btn"
    type="button"
    (click)="loadPage(page)"
    [disabled]="page === (assessments?.page ?? 1)"
  >
    {{ page }}
  </button>
  } @if(assessments?.page != (assessments?.total ?? 0) / pageSize) {
  <button class="btn" (click)="loadPage((assessments?.page ?? 1) + 1)">
    {{ 'pages.ethicalhacking.assessmentsTable.nextPage' | translate }}
  </button>
  }

  <div class="d-flex align-items-center ms-auto">
    <label for="pageSize" class="me-3 pageSizeLabel">{{
      'pages.ethicalhacking.assessmentsTable.itemsPerPage' | translate
    }}</label>
    <select
      id="pageSize"
      class="form-select"
      [(ngModel)]="pageSize"
      (ngModelChange)="onPageSizeChange($event)"
    >
      @for(size of pageSizeOptions; track size) {
      <option [value]="size">
        {{ size }}
      </option>
      }
    </select>
  </div>
</div>
}
