import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-openclosedcards',
    templateUrl: './openclosedcards.component.html',
    styleUrls: ['./openclosedcards.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule]
})
export class OpenClosedCardsComponent {
    @Input() type: string = '';
    @Input() count: number = 0;
    @Input() title: string = '';
    @Input() colorClass: string = 'primary';
}