import { EventEmitter, Injectable } from '@angular/core';
import { CybersocXDRDashboardSearchCriteria } from '../shared/model/searchCriteria';
import { XtendedFacet } from '../shared/model/cybersocxdr/facets';
import { FilterPreset } from '../shared/model/cybersocxdr/filterPreset';
import { XtendedAnalyticsService } from './xtended-analytics.service';
import { DynamicDate, Severity } from '../shared/model/cybersocxdr/facetsValues';
import { ContractService, IncidentDataSource } from '../shared/model/cybersocxdr/incidentDataSourceEnum';
import { ClientInfos, Contract } from '../shared/model/cybersocxdr/clientInfo';

@Injectable({
  providedIn: 'root',
})
export class XtendedFiltersService {
  public searchCriteria: CybersocXDRDashboardSearchCriteria;

  contractChangeEvent = new EventEmitter<Contract | null>();
  private _selectedContractId: number | null = null;
  public get selectedContract(): Contract | null {
    if (this._selectedContractId && this.clientInfo?.contracts?.some((c) => c.id === this._selectedContractId)) {
      return this.clientInfo.contracts.find((c) => c.id === this._selectedContractId) ?? null;
    } else if (this.clientInfo?.contracts?.length > 0) {
      return this.clientInfo.contracts[0];
    }
    return null;
  }

  public set selectedContract(contractId: number | null) {
    if (contractId !== this._selectedContractId) {
      this._selectedContractId = contractId;
      localStorage.setItem('selectedContractId', contractId?.toString() ?? '');
      if (this.selectedContract?.incidentDataSource !== this.searchCriteria.incidentDataSource)
        this.onIncidentDataSourceChange(this.selectedContract?.incidentDataSource ?? IncidentDataSource.ELASTIC);
      this.contractChangeEvent.emit(this.selectedContract);
    }
  }

  private _clientInfo: ClientInfos | null = null;
  public set clientInfo(clientInfo: ClientInfos | null) {
    if (!this._clientInfo) {
      this._clientInfo = clientInfo;

      const storedContractId = localStorage.getItem('selectedContractId');
      const storedContract = storedContractId
        ? this._clientInfo?.contracts.find((c) => c.id === +storedContractId)
        : null;

      if (storedContract) {
        this.selectedContract = storedContract.id;
      } else {
        const xtendedContract = this._clientInfo?.contracts.find((c) => c.service === ContractService.XTENDED);
        if (xtendedContract) {
          this.selectedContract = xtendedContract.id;
        } else {
          this.selectedContract = this._clientInfo?.contracts?.length > 0 ? this._clientInfo?.contracts[0].id : null;
        }
      }
    } else {
      this._clientInfo = clientInfo;
    }
  }
  public get clientInfo(): ClientInfos | null {
    return this._clientInfo;
  }

  filterEvent = new EventEmitter<CybersocXDRDashboardSearchCriteria>();

  public currentPreset: string = 'Custom filters';
  public filtersPresets: FilterPreset[] = [
    {
      name: 'High & Critical, last 6 months',
      search: {
        ...new CybersocXDRDashboardSearchCriteria(),
        createdFrom: DynamicDate.LAST_180D,
        severitys: [Severity.HIGH, Severity.CRITICAL],
      },
    },
    {
      name: 'High & Critical, last month',
      search: {
        ...new CybersocXDRDashboardSearchCriteria(),
        createdFrom: DynamicDate.LAST_MONTH,
        severitys: [Severity.HIGH, Severity.CRITICAL],
      },
    },
  ];

  facetToFilter = new Map<XtendedFacet, keyof CybersocXDRDashboardSearchCriteria>([
    [XtendedFacet.CATEGORY, 'category'],
    [XtendedFacet.OWNER, 'owner'],
    [XtendedFacet.WAITING_FOR, 'waitingFor'],
    [XtendedFacet.CLOSURE_VERDICT, 'closureVerdict'],
    [XtendedFacet.SEVERITY, 'severitys'],
    [XtendedFacet.STATUS, 'status'],
    [XtendedFacet.SLA_NOTIFICATION, 'slaNotification'],
    [XtendedFacet.GROUPLEVEL1, 'groupLevel1'],
    [XtendedFacet.GROUPLEVEL2, 'groupLevel2'],
    [XtendedFacet.ACTION_LEVEL_1, 'actionLevel1'],
    [XtendedFacet.USERNAME, 'username'],
    [XtendedFacet.RULE_NAME, 'ruleName'],
    [XtendedFacet.HOSTNAME, 'hostname'],
    [XtendedFacet.CMD_LINE, 'cmdLine'],
    [XtendedFacet.TYPE, 'type'],
    [XtendedFacet.CLIENT_TICKET_ID, 'clientTicketId'],
    [XtendedFacet.PROCESS_PATH, 'processPath'],
    [XtendedFacet.ID, 'id'],
    [XtendedFacet.CLIENT_TICKET_CREATED, 'clientTicketCreated'],
    [XtendedFacet.MITRE_TACTIC, 'mitreTactic'],
    [XtendedFacet.MITRE_TECHNIQUE, 'mitreTechnique'],
    [XtendedFacet.SUBJECT, 'subject'],
    [XtendedFacet.ACTOR_LEVEL_1, 'actorLevel1'],
    [XtendedFacet.ACTOR_LEVEL_2, 'actorLevel2'],
    [XtendedFacet.ACTION_LEVEL_2, 'actionLevel2'],
    [XtendedFacet.WHY_LEVEL_1, 'whyLevel1'],
    [XtendedFacet.WHY_LEVEL_2, 'whyLevel2'],
    [XtendedFacet.WHAT_LEVEL_1, 'whatLevel1'],
    [XtendedFacet.WHO_LEVEL_1, 'whoLevel1'],
    [XtendedFacet.ASSET_LEVEL_1, 'assetLevel1'],
    [XtendedFacet.ATTRIBUTES_LEVEL_1, 'attributesLevel1'],
  ]);

  filterTimestamps: { [key: string]: number } = {};

  private readonly fieldMappings = {
    severity: 'severitys',
    category: 'category',
    status: 'status',
    owner: 'owner',
    waiting_for: 'waitingFor',
    closure_verdict: 'closureVerdict',
    sla_notification: 'slaNotification',
    hostname: 'hostname',
    username: 'username',
    rule_name: 'ruleName',
    type: 'type',
    mitre_tactic: 'mitreTactic',
    mitre_technique: 'mitreTechnique',
    client_ticket_created: 'clientTicketCreated',
    process_path: 'processPath',
    cmd_line: 'cmdLine',
    id: 'id',
    subject: 'subject',
    client_ticket_id: 'clientTicketId',
    client_ticket_status: 'clientTicketStatus',
    actionLevel1: 'actionLevel1',
    groupLevel1: 'groupLevel1',
    groupLevel2: 'groupLevel2',
    actorLevel1: 'actorLevel1',
    actorLevel2: 'actorLevel2',
    assetLevel1: 'assetLevel1',
    attributesLevel1: 'attributesLevel1',
    whyLevel1: 'whyLevel1',
    whyLevel2: 'whyLevel2',
    whatLevel1: 'whatLevel1',
    whoLevel1: 'whoLevel1',
    actionLevel2: 'actionLevel2',
  } as const;

  constructor(private readonly xtendedAnalyticsService: XtendedAnalyticsService) {
    //Get the search criteria from the session storage
    this.deserializeSearchCriteria();

    if (!this.searchCriteria) {
      //Default preset
      this.searchCriteria = { ...this.filtersPresets[0].search };
    }
    this.checkCurrentPreset();
  }

  checkCurrentPreset(): void {
    const preset = this.filtersPresets.find(
      (p) =>
        JSON.stringify({
          ...p.search,
          limit: undefined,
          skip: undefined,
          sortBy: undefined,
          incidentDataSource: undefined,
        }) ===
        JSON.stringify({
          ...this.searchCriteria,
          limit: undefined,
          skip: undefined,
          sortBy: undefined,
          incidentDataSource: undefined,
        })
    );
    this.currentPreset = preset ? preset.name : 'Custom filters';
  }

  // called after search is performed
  serializeSeachCriteria(): void {
    this.checkCurrentPreset();
    sessionStorage.setItem('xtended_soc_incident_form', JSON.stringify(this.searchCriteria));
  }

  /** Deserialize the search criteria data into form data */
  private deserializeSearchCriteria(): void {
    const storedItem = sessionStorage.getItem('xtended_soc_incident_form');
    if (storedItem) {
      this.searchCriteria = Object.assign(new CybersocXDRDashboardSearchCriteria(), JSON.parse(storedItem));
    }
  }

  applyPreset(preset: FilterPreset): void {
    this.searchCriteria = { ...preset.search, incidentDataSource: this.searchCriteria.incidentDataSource };
    this.serializeSeachCriteria();
    this.filterEvent.emit(this.searchCriteria);
    this.xtendedAnalyticsService.trackEvent('apply preset', { preset: preset.name });
  }

  resetFilters(): void {
    const resetValues = Array.from(this.facetToFilter.values()).reduce((accumulator, key) => {
      const currentValue = this.searchCriteria[key];
      const resetValue = Array.isArray(currentValue) ? [] : '';

      return {
        ...accumulator,
        [key]: resetValue,
      };
    }, {});

    this.searchCriteria = {
      ...this.searchCriteria,
      ...resetValues,
    };

    this.serializeSeachCriteria();
    this.xtendedAnalyticsService.trackEvent('reset filters');
  }

  filterIn(facet: XtendedFacet, value: string): void {
    const field = this.facetToFilter.get(facet);
    if (!field) {
      console.error(`Facet ${facet} is not supported for filtering`);
      return;
    }

    (this.searchCriteria[field] as string[]) = [value];
    this.serializeSeachCriteria();
    this.filterEvent.emit(this.searchCriteria);
  }

  filterOut(facet: XtendedFacet, value: string): void {
    const field = this.facetToFilter.get(facet);
    if (!field) {
      console.error(`Facet ${facet} is not supported for filtering`);
      return;
    }

    const index = (this.searchCriteria[field] as string[])?.indexOf(value);
    if (index !== -1) {
      (this.searchCriteria[field] as string[])?.splice(index, 1);
      this.serializeSeachCriteria();
      this.filterEvent.emit(this.searchCriteria);
    }
  }

  onIncidentDataSourceChange(dataSource: IncidentDataSource): void {
    this.searchCriteria.incidentDataSource = dataSource;
    this.serializeSeachCriteria();
    this.filterEvent.emit(this.searchCriteria);
    this.xtendedAnalyticsService.trackEvent('incident data source change', { source: dataSource });
  }

  /**
   * Maps filter type to search criteria field name
   */
  public mapTypeToField(type: string): keyof CybersocXDRDashboardSearchCriteria {
    return (this.fieldMappings[type] || type) as keyof CybersocXDRDashboardSearchCriteria;
  }

  public mapFieldToType(field: string): string {
    const reverseMappings = Object.entries(this.fieldMappings).reduce(
      (acc, [key, value]) => ({ ...acc, [value]: key }),
      {}
    );
    return reverseMappings[field] || field;
  }
}
