import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppSharedModule } from 'src/app/app-shared.module';
import { Asset, Location } from 'src/app/shared/model/itsm';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';

@Component({
  selector: 'app-location-view',
  standalone: true,
  imports: [TranslateModule, AppSharedModule, NgbPaginationModule],
  templateUrl: './location-view.component.html',
  styleUrl: './location-view.component.scss'
})
export class LocationViewComponent implements OnInit {

  private locationRef: string;
  public location: Location;
  public locationAssets: Asset[] = [];
  public subsetAssets: Asset[] = [];
  public locationAssetsLoaded: boolean;
  public currentPage: number = 1;
  public perPage: number = 10;
  private defaultSortOrder = 'DESC';
  private sortableColumns: Map<string, 'ASC' | 'DESC'>;

  constructor(
      private route: ActivatedRoute,
      private itsmService: ItsmService,
      private alertService: AlertService,
      private translateService: TranslateService
    ) { }

  
  ngOnInit(): void {

    this.sortableColumns = this.getSortableColumnsMap();


    this.route.params.subscribe(params => this.locationRef = params.locationRef);
    this.itsmService.getAssetsLocationById(this.locationRef).subscribe({
      next:(response: Location[]) => {
        if (response.length === 1) {
          this.location = response[0];
        } else {
          this.alertService.addError(this.translateService.instant('pages.assets.dashboard.assetLocationsError'));
        }
      },
      error: () => this.alertService.addError(this.translateService.instant('pages.assets.dashboard.assetLocationsError'))
    });
    this.itsmService.getAssetsByLocationId(this.locationRef).subscribe({
      next:(response: Asset[]) => {
        if (response.length > 0) {
          this.locationAssets = response;
          this.subsetAssets = this.locationAssets.slice(0, this.perPage);
        }
        this.locationAssetsLoaded = true;
      },
      error: () => {
        this.locationAssetsLoaded = true;
        this.alertService.addError("pages.assets.dashboard.locationAssetsError");
      }
    });
  }

  /**
   * Generate a Map of the columns which allow sorting 
   * Each element contains the columnName and a 'ASC' or 'DESC' string 
   * which are to be stored to recall to be able to sort at the invert next time
   * @returns map of column names and booleans
   */
  private getSortableColumnsMap() {
    const map = new Map();
    map.set("name", this.defaultSortOrder);
    map.set("serialNumber", this.defaultSortOrder);
    map.set("vendor", this.defaultSortOrder);
    return map;
  }

  public sortResults(event: string) {
    const sortOrder = this.sortableColumns.get(event) === 'ASC' ? 'DESC' : 'ASC';
    this.sortableColumns.set(event, sortOrder);
    this.subsetAssets.sort( (a, b) => {
      if (sortOrder === 'ASC') {
        return b[event] > a[event] ? -1 : 1;
      } else {
        return b[event] > a[event] ? 1 : -1;
      }
    });
  }

  public onPageChange($event){
    this.locationAssetsLoaded = false;
    this.subsetAssets = this.locationAssets.slice(this.perPage*(this.currentPage-1), this.perPage*(this.currentPage-1)+this.perPage);
    this.locationAssetsLoaded = true;
  }

}
