import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CVE } from '../../model/cve';

@Component({
  selector: 'app-vuln-list',
  templateUrl: './vuln-list.component.html',
  styleUrls: ['./vuln-list.component.scss']
})
export class VulnListComponent {

  @Input()
  cves: CVE[];

  @Input()
  flagRefs: any[];

  @Input()
  flaggingRef: string;

  @Input()
  loaded: boolean;

  @Input()
  scrollLoading: boolean;

  @Output()
  flagEmitter = new EventEmitter<any>();

  @Output()
  sortEmitter = new EventEmitter<any>();

  flagSignal(event, cveRef: string): void {
    event.stopPropagation();
    this.flagEmitter.emit(cveRef);
  }

  sortCVEs(event, field: string): void {
    event.stopPropagation();
    this.sortEmitter.emit(field);
  }

}
