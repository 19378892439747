import { Component, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-http-error',
  standalone: true,
  imports: [],
  templateUrl: './http-error.component.html'
})
export class HttpErrorComponent {

  public code: WritableSignal<number> = signal(undefined);
  public description: WritableSignal<string> = signal(undefined);
  public contact: WritableSignal<string> = signal(undefined);

  constructor(
    private readonly translateService: TranslateService,
    private readonly route: ActivatedRoute
  ) { }

  public ngOnInit() {
    this.route.url.subscribe( url => {
      const path = url[0].path
      this.selectErrorContent(path);
    })
  }

  private selectErrorContent(path: string) {
    switch (path) {
      case HttpErrorPath.child_restricted_access:
        this.setErrorMessage(403, 'error.childAccessDenied', 'pages.403.contactIncentive');
        break;
      case HttpErrorPath.bad_gateway:
        this.setErrorMessage(502, 'pages.502.description', 'pages.502.contactIncentive');
        break;
        // The following acases re responding same message for now. Setted here to fill with other content if needed
      case HttpErrorPath.not_authorized:
        this.setDefaultError();
        break;
      case HttpErrorPath.access_denied:
        this.setDefaultError();
        break;
      default: this.setDefaultError();
    }
  }

  private setErrorMessage(code: number, descriptionKey: string, contactKey: string) {
    this.code.set(code);
    this.description.set(this.translateService.instant(descriptionKey));
    this.contact.set(this.translateService.instant(contactKey));
  }

  private setDefaultError() {
    this.code.set(403);
    this.description.set(this.translateService.instant('pages.403.description'));
    this.contact.set(this.translateService.instant('pages.403.contactIncentive'));
  }
}
  
  
export enum HttpErrorPath {
  child_restricted_access = "child-restricted-access",
  bad_gateway = "bad-gateway",
  not_authorized = "not-authorized",
  access_denied = "access-denied",
}
