<app-card class="mb-2">
  @if (preset) {

    <div class="preset">

      <div class="preset-header">
        <input type="text"
               class="title-field form-control rounded"
               [(ngModel)]="preset().name"
               [readonly]="!isEditMode()"/>

        <div class="actions-container">
          @if (!isEditMode()) {
            <button type="button"
                    class="btn btn-icon btn-secondary rounded"
                    (click)="toggleEditMode()"
                    ngbTooltip="Edit the preset title">
              <fa-icon [icon]="['fas', 'edit']"/>
            </button>
          } @else {
            <button type="button"
                    class="btn btn-icon btn-secondary rounded"
                    (click)="update()">
              <fa-icon [icon]="['fas', 'save']"/>
            </button>
          }

          <button type="button"
                  class="btn btn-icon btn-danger rounded ms-1"
                  [dialog]="confirmDialogTarget()"
                  (click)="delete()">
            <fa-icon [icon]="['fas', 'trash']"/>
          </button>
        </div>
      </div>

      <div class="filter-tag-container">
        @for (filter of filters(); track filter) {
          <div class="filter-tag rounded"
               [ngbTooltip]="filter.values.join(', ')"
               placement="top">

            <span class="filter-tag-name">
              {{ 'pages.cybersocxdr.filters.' + filter.name | alternate:"severity" | translate }} :
            </span>
            <span class="filter-tag-values">
              {{ filter.values.join(', ') }}
            </span>

          </div>
        }
      </div>

    </div>

  }
</app-card>
