import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { VulnerabilitiesCountDto } from 'src/app/shared/services/ethicalhacking/model/ethicalHackingDtos';

@Component({
  selector: 'severity',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './severity.component.html',
  styleUrls: ['./severity.component.scss'],
})
export class SeverityComponent {
  @Input({ required: true })
  items: VulnerabilitiesCountDto[] = [];
}
