export const enum FindingStatus {
    UNRESOLVED = 0,
    RESOLVED = 1,
    RETEST_PENDING = 2,
    ACCEPTED_RISK = 3,
}

export const FindingStatusMap: Record<string, FindingStatus[]> = {
    'all': [FindingStatus.UNRESOLVED, FindingStatus.RETEST_PENDING, FindingStatus.RESOLVED, FindingStatus.ACCEPTED_RISK],
    'open': [FindingStatus.UNRESOLVED, FindingStatus.RETEST_PENDING],
    'closed': [FindingStatus.RESOLVED, FindingStatus.ACCEPTED_RISK]
};