<header class="header">
  <nav
    class="navbar navbar-expand-xxl"
    [ngClass]="navBarTheme"
    aria-label="Service navigation"
  >
    <div class="container-fluid">
      <!-- Burger menu (visible on small screens) -->
      <div class="burger-menu">
        <button
          class="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target=".service-header-1"
          aria-controls="service-header-1.1"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <a
        class="logo"
        attr.aria-label="{{ 'pages.shared.returnToDashboard' | translate }}"
        [routerLink]="['/dashboard']"
      ></a>

      <!-- Navbar with links -->
      <div
        id="service-header-1.1"
        class="navbar-collapse collapse me-lg-auto service-header-1"
      >
        <ul class="navbar-nav me-2">
          <!-- dashboard -->
          <li class="nav-item">
            <a
              class="nav-link"
              routerLinkActive="active"
              #navbarRouterLink="routerLinkActive"
              [attr.aria-current]="
                navbarRouterLink.isActive ? 'page' : undefined
              "
              routerLink="/dashboard"
              data-placement="top"
              >{{ 'menu.dashboard' | translate }}</a
            >
          </li>

          <!-- support -->
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="DropdownSupport"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              title="{{ 'pages.dashboard.supportTicketDesc' | translate }}"
              >{{ 'menu.support.title' | translate }}
              <fa-icon
                [icon]="['fas', 'chevron-down']"
                size="1x"
                class="ps-1 down"
              ></fa-icon>
            </a>
            <ul class="dropdown-menu" aria-labelledby="DropdownSupport">
              <li>
                <a
                  class="dropdown-item"
                  routerLink="/support/dashboard"
                  (click)="trackSupportAccess(pages.supportOverview)"
                  >{{ 'menu.support.overview' | translate }}</a
                >
              </li>
              <!-- <li><a class="dropdown-item" routerLink="/support/contacts">{{ 'menu.support.contact' | translate }}</a>
              </li> -->
              <li>
                <a
                  class="dropdown-item"
                  routerLink="/support/log"
                  (click)="trackSupportAccess(pages.logCase)"
                  >{{ 'menu.support.log' | translate }}</a
                >
              </li>
            </ul>
          </li>

          <!-- activity -->
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="dropdownActivity"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ 'menu.activity.title' | translate }}
              <fa-icon
                [icon]="['fas', 'chevron-down']"
                size="1x"
                class="ps-1 down"
              ></fa-icon>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownActivity">
              <li
                title="{{ 'pages.dashboard.activitySummaryDesc' | translate }}"
                (click)="trackActivityAccess(pages.activityDashboard)"
              >
                <a class="dropdown-item" routerLink="/activity/dashboard"
                  >{{ 'menu.activity.overview' | translate }}
                </a>
              </li>
              <li
                title="{{ 'pages.dashboard.timelineDesc' | translate }}"
                (click)="trackActivityAccess(pages.securityIncidentTimeline)"
              >
                <a
                  class="dropdown-item"
                  routerLink="/activity/securityincident"
                  >{{ 'menu.activity.securityincidentTimeline' | translate }}</a
                >
              </li>
            </ul>
          </li>

          <!-- asset management -->
          <li
            class="nav-item"
            (click)="trackAssetManagementAccess(pages.assetDashboard)"
          >
            <a
              class="nav-link"
              routerLinkActive="active"
              #navbarRouterLink="routerLinkActive"
              [attr.aria-current]="
                navbarRouterLink.isActive ? 'page' : undefined
              "
              (click)="clearAssetsRetention()"
              routerLink="/asset/dashboard"
              data-placement="top"
              title="{{ 'pages.dashboard.listAssetDesc' | translate }}"
              >{{ 'menu.assets.title' | translate }}</a
            >
          </li>

          <!-- report -->
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="dropdownReport"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              title="{{ 'pages.dashboard.scheduleReportDesc' | translate }}"
              >{{ 'menu.reports.title' | translate }}
              <fa-icon
                [icon]="['fas', 'chevron-down']"
                size="1x"
                class="ps-1 down"
              ></fa-icon>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownReport">
              <li
                title="{{ 'pages.dashboard.reportsDocumentsDesc' | translate }}"
                (click)="trackReportAccess(pages.reportsAndDocuments)"
              >
                <a class="dropdown-item" routerLink="/reports/standard"
                  >{{ 'menu.reports.standard' | translate }}
                </a>
              </li>

              <!-- internal file transfert -->
              <li
                title="{{ 'pages.dashboard.internalFileDesc' | translate }}"
                (click)="trackReportAccess(pages.sharedFiles)"
              >
                <a class="dropdown-item" routerLink="/reports/sharedFiles"
                  >{{ 'menu.reports.internalFile' | translate }}
                </a>
              </li>

              <!-- security incident -->
              <li
                title="{{
                  'pages.dashboard.securityIncidentsDesc' | translate
                }}"
                (click)="trackReportAccess(pages.securityIncidentsReport)"
              >
                <a class="dropdown-item" routerLink="/reports/live/securitycase"
                  >{{ 'menu.reports.securityCase' | translate }}
                </a>
              </li>

              <!-- Threat Detection and Response -->
              <li
                title="{{
                  'pages.dashboard.securityIncidentsDesc' | translate
                }}"
                (click)="trackReportAccess(pages.threatDetectionAndResponse)"
              >
                <a
                  class="dropdown-item"
                  routerLink="/reports/live/threatDetectionAndResponse"
                  >{{ 'menu.reports.threatDetectionAndResponse' | translate }}
                </a>
              </li>

              <!-- security role resolution MTTR -->
              @if (userAuthService.accesses.SLA) {
              <li
                title="{{ 'pages.dashboard.securityResolDesc' | translate }}"
                (click)="trackReportAccess(pages.supportResolutionReport)"
              >
                <a class="dropdown-item" routerLink="/reports/live/resolution"
                  >{{ 'menu.reports.resolution' | translate }}
                </a>
              </li>
              }

              <!-- incident benchmarking -->
              <li
                title="{{
                  'pages.dashboard.incidentBenchmarkingDesc' | translate
                }}"
                (click)="trackReportAccess(pages.securityIncidentsBenchmarking)"
              >
                <a class="dropdown-item" routerLink="/reports/live/benchmarking"
                  >{{ 'menu.reports.benchmarking' | translate }}
                </a>
              </li>
            </ul>
          </li>

          <!-- ressources -->
          <li
            class="nav-item dropdown"
            title="{{ 'pages.dashboard.selectBlogsDesc' | translate }}"
          >
            <a
              class="nav-link dropdown-toggle"
              id="dropdownResources"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              >{{ 'menu.updates.title' | translate }}
              <fa-icon
                [icon]="['fas', 'chevron-down']"
                size="1x"
                class="ps-1 down"
              ></fa-icon
            ></a>

            <!-- ressources dropdown -->
            <ul class="dropdown-menu" aria-labelledby="dropdownResources">
              <!-- Worldwatch -->
              <li
                title="{{ 'pages.dashboard.worldwatchDesc' | translate }}"
                (click)="trackRessourceAccess(pages.worldwatch)"
              >
                <a
                  class="dropdown-item"
                  routerLink="/updates/worldwatch/dashboard"
                  >{{ 'menu.updates.worldwatch' | translate }}</a
                >
              </li>

              <!-- Vulnerabilities -->
              <li
                title="{{ 'pages.dashboard.vulnDesc' | translate }}"
                (click)="trackRessourceAccess(pages.vulnerabilities)"
              >
                <a
                  class="dropdown-item"
                  routerLink="/updates/vulnerabilities/dashboard"
                  >{{ 'menu.updates.vulnerabilities' | translate }}</a
                >
              </li>

              <!-- Training -->
              <li
                title="{{ 'pages.dashboard.trainingDesc' | translate }}"
                (click)="trackRessourceAccess(pages.training)"
              >
                <a class="dropdown-item" routerLink="/updates/training">{{
                  'menu.updates.training' | translate
                }}</a>
              </li>

              <!-- Blogs -->
              <li
                title="{{ 'pages.dashboard.blogsDesc' | translate }}"
                (click)="trackRessourceAccess(pages.blogs)"
              >
                <a class="dropdown-item" routerLink="/updates/blogs">{{
                  'menu.updates.rss.blog' | translate
                }}</a>
              </li>

              <!-- Events -->
              <li
                title="{{ 'pages.dashboard.eventsDesc' | translate }}"
                (click)="trackRessourceAccess(pages.events)"
              >
                <a class="dropdown-item" routerLink="/updates/events">{{
                  'menu.updates.rss.events' | translate
                }}</a>
              </li>

              <!-- News -->
              <li
                title="{{ 'pages.dashboard.newsDesc' | translate }}"
                (click)="trackRessourceAccess(pages.news)"
              >
                <a a class="dropdown-item" routerLink="/updates/news">{{
                  'menu.updates.rss.news' | translate
                }}</a>
              </li>

              <!-- Whitepapers -->
              <li
                title="{{ 'pages.dashboard.whitePapersDesc' | translate }}"
                (click)="trackRessourceAccess(pages.whitepapers)"
              >
                <a class="dropdown-item" routerLink="/updates/whitepapers">{{
                  'menu.updates.rss.whitepapers' | translate
                }}</a>
              </li>

              <!-- -->
              <!-- <li title="{{ 'pages.dashboard.integrationsDesc' | translate }}"><a class="dropdown-item"
                  routerLink="/updates/integrations">{{ 'menu.updates.integrations.title' | translate }}</a></li> -->
            </ul>
          </li>

          <!-- services -->
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="dropdownServices"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              title="{{ 'pages.dashboard.listServDesc' | translate }}"
              >{{ 'pages.dashboard.services' | translate }}
              <fa-icon
                [icon]="['fas', 'chevron-down']"
                size="1x"
                class="ps-1 down"
              ></fa-icon
            ></a>

            <!-- services dropdown  -->
            <ul class="dropdown-menu" aria-labelledby="dropdownServices">
              <!-- micro SOC XDR -->
              @if(userAuthService.accesses.xtendedsoc.hasAccess) {
              <li>
                <a class="dropdown-item" routerLink="/xtended-soc"
                  >{{
                    'pages.cybersocxdr.service.' +
                      userAuthService.accesses.xtendedsoc.serviceName
                      | translate
                  }}
                </a>
              </li>
              }

              <!-- Ethical Hacking -->
              @if(userAuthService.accesses.ethicalhacking) {
              <li>
                <a class="dropdown-item" routerLink="/ethical-hacking">
                  {{ 'menu.ethicalhacking.title' | translate }}
                </a>
              </li>
              }

              <!-- Thread Defence Center -->
              <li (click)="trackServiceAccess(pages.threadDefenseCenter)">
                <a
                  class="dropdown-item"
                  target="_new"
                  href="https://cert.orangecyberdefense.com/"
                  rel="noopener noreferrer"
                  >{{ 'pages.dashboard.threatDefenseCenter' | translate }}</a
                >
              </li>

              <!-- Orange Cyberdefense Services -->
              <li
                (click)="trackServiceAccess(pages.orangeCyberdefenseServices)"
              >
                <a class="dropdown-item" routerLink="/updates/services">{{
                  'menu.updates.ourServices' | translate
                }}</a>
              </li>

              <!-- Malicious File Detection -->
              <li (click)="trackServiceAccess(pages.maliciousFileDetection)">
                <a
                  class="dropdown-item"
                  routerLink="/updates/services/certtools"
                  >{{ 'menu.updates.certToolbox' | translate }}</a
                >
              </li>

              <!-- My Security Report -->
              @if(userAuthService.accesses.MSR) {
              <li (click)="trackServiceAccess(pages.mySecurityReports)">
                <a
                  class="dropdown-item"
                  href="{{ mySecurityReportUrl }}"
                  target="_blank"
                  rel="noopener"
                  >{{ 'menu.updates.mySecurityReport' | translate }}</a
                >
              </li>
              }

              <!-- Managed Vulnerability Intelligence [identify]" -->
              @if(nucleusUrl) {
              <li
                (click)="
                  trackServiceAccess(pages.managedVulnerabilityIntelligence)
                "
              >
                <a
                  class="dropdown-item"
                  href="{{ nucleusUrl }}"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ 'menu.updates.managedVulnerability' | translate }}</a
                >
              </li>
              } @else {
              <li>
                <a class="dropdown-item disabled">{{
                  'menu.updates.managedVulnerability' | translate
                }}</a>
              </li>
              }

              <!-- Netskope Admin Console -->
              <!-- <li><a class="dropdown-item" target="_blank" href="https://ocd-soc-uk.eu.goskope.com">Netskope Admin Console</a></li> -->

              <!-- sentinelOne -->
              @if (userAuthService.accesses?.sentinelOne) {
              <li title="{{ 'menu.updates.sentinelOne' | translate }}">
                <a
                  class="dropdown-item"
                  routerLink="/updates/services/managed-endpoint-security"
                  >{{ 'menu.updates.sentinelOne' | translate }}</a
                >
              </li>
              }
            </ul>
          </li>

          <!-- admin dashboard-->
          <li class="nav-item dropdown" *ngIf="userAuthService.userAdmin">
            <a
              class="nav-link dropdown-toggle"
              id="dropdownAdmin"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              title="{{ 'pages.dashboard.interfaceManDesc' | translate }}"
              >{{ 'pages.dashboard.admin' | translate }}
              <fa-icon
                [icon]="['fas', 'chevron-down']"
                size="1x"
                class="ps-1 down"
              ></fa-icon>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownAdmin">
              <li (click)="trackAdminAccess(pages.users)">
                <a class="dropdown-item" routerLink="/user/management">{{
                  'menu.admin.users' | translate
                }}</a>
              </li>
            </ul>
          </li>
        </ul>

        <!-- log case -->
        <ul
          class="log-case-btn-navbar navbar-nav align-items-center ms-auto my-1"
          data-placement="top"
          title="{{ 'pages.dashboard.clickToLogCaseDesc' | translate }}"
        >
          <li class="nav-item">
            <a
              href="#"
              class="btn btn-inverse btn-primary"
              routerLink="/support/log"
              (click)="trackSupportAccess(pages.logCase)"
              >{{ 'buttons.logticket' | translate }}</a
            >
          </li>
        </ul>
      </div>
      <!-- log case -->
      <div
        class="log-case-btn navbar-nav my-1 me-2"
        data-placement="top"
        title="{{ 'pages.dashboard.clickToLogCaseDesc' | translate }}"
      >
        <span class="nav-item">
          <a
            href="#"
            class="btn btn-inverse btn-primary"
            routerLink="/support/log"
            (click)="trackSupportAccess(pages.logCase)"
            >{{ 'buttons.logticket' | translate }}</a
          >
        </span>
      </div>
    </div>
  </nav>
</header>
