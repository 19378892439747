<div class="container-fluid p-4 h-100">
  <app-card>
    <h3 id="entitiesTableDescription">
      {{ 'pages.cybersocxdr.menu.entities' | translate }}
    </h3>
    <div class="table-responsive">
      <table class="table table-hover">
        <caption class="d-none">
          desc
        </caption>
        <thead>
          <tr>
            <th scope="col">
              {{ 'pages.cybersocxdr.entities.fieldTitles.name' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          @for (entity of entities; track entity.id) {
          <tr
            (click)="onEntityClick(entity.id)"
            (keydown.enter)="onEntityClick(entity.id)"
            (keydown.space)="onEntityClick(entity.id); $event.preventDefault()"
            tabindex="0"
            class="cursor-pointer"
          >
            <td>{{ entity.name }}</td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </app-card>
</div>
