import { HttpClient } from '@angular/common/http';
import { Component, inject, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { IconsUtils } from 'src/app/shared/utils/xtended/icons.utils';

@Component({
  selector: 'app-ethicalhacking-perimeters-box',
  templateUrl: './perimeter-box.component.html',
  styleUrls: ['./perimeter-box.component.scss'],
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule],
})
export class EthicalHackingPerimeterBoxComponent implements OnInit {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly sanitizer: DomSanitizer = inject(DomSanitizer);

  @Input() textKey: string;
  @Input() icons?: string[];
  @Input() class: string;

  @Input() hasDescription: boolean = false;
  @Input() hasIcon: boolean = false;
  @Input() isExpertiseContainer: boolean = false;
  @Input() isFaIcon: boolean = false;

  protected svgContent: SafeHtml[] = [];

  public ngOnInit(): void {
    this.initIcons();
  }

  private initIcons(): void {
    if (!this.isFaIcon && this.icons && this.icons.length > 0) {
      this.icons.forEach((icon) => {
        IconsUtils.initIcons(icon, this.http)
          .subscribe((content) => {
            this.svgContent.push(
              this.sanitizer.bypassSecurityTrustHtml(content)
            );
          });
      });
    }
  }
}
