import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconsModule } from "src/app/shared/modules/icons/icons.module";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'dev-switch-theme-button',
  standalone: true,
  imports: [
    IconsModule,
    TranslateModule
  ],
  styleUrl: './switch-theme-button.component.scss',
  templateUrl: './switch-theme-button.component.html',
})
export class SwitchThemeButtonComponent {
  @Input({required: true}) darkModeState: boolean;
  @Output() onStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * @protected
   * @desc Toggles the dark mode state and emits the updated state.
   */
  protected toggle(): void {
    this.darkModeState = !this.darkModeState;
    this.onStateChange.emit(this.darkModeState);
  }
}
