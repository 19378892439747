import { CommonModule, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { XtendedFacet } from 'src/app/shared/model/cybersocxdr/facets';
import { AlternateDataSourcePipe } from 'src/app/shared/pipes/alternate-data-source.pipe';
import { RemoveWhiteSpacePipe } from 'src/app/shared/pipes/removewhitespace.pipe';
import { CaseXDR } from '../../../shared/model/cybersocxdr/microsocticket';
import { SeverityPipe } from '../../../shared/pipes/severity.pipe';
import { AnonymisePipe } from '../../pipes/anonymise.pipe';

type IncidentColumns =
  | XtendedFacet.SEVERITY
  | XtendedFacet.CLIENT_TICKET_ID
  | XtendedFacet.SUBJECT
  | XtendedFacet.OWNER
  | XtendedFacet.CATEGORY
  | XtendedFacet.ID
  | XtendedFacet.START_TIME;

interface ColumnDetails {
  name: string;
  visible: boolean;
  sortable: boolean;
  size: number;
}

@Component({
  selector: 'app-xtended-incident-list',
  templateUrl: './xtended-incident-list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    RemoveWhiteSpacePipe,
    RouterModule,
    NgClass,
    FormsModule,
    AnonymisePipe,
    SeverityPipe,
    AlternateDataSourcePipe,
  ],
  styleUrls: ['./xtended-incident-list.component.scss'],
})
export class XtendedIncidentListComponent {
  @Input()
  cases: CaseXDR[];

  @Input()
  title: string;

  @Input()
  badgeAnnotation: string | number;

  @Output()
  newFlagEmitter: EventEmitter<CaseXDR> = new EventEmitter<CaseXDR>();

  @Output()
  sortEmitter = new EventEmitter<{ field: string; direction: 'ASC' | 'DESC' }>();

  @Input()
  loaded: boolean;

  @Input()
  displayFlag: boolean = true;

  @Input()
  scrollLoading: boolean;

  @Input()
  exportable: boolean = false;

  @Input()
  exportLoading: boolean = false;

  @Output()
  exportEmitter = new EventEmitter<void>();

  @Input()
  sortable: boolean = true;

  @Input()
  selectableColumns: boolean = false;

  public currentSort: string = '';
  public currentSortDirection: 'ASC' | 'DESC' = 'ASC';
  public XtendedFacet = XtendedFacet;

  //Order of columns is important for the table
  public columns: { [key in IncidentColumns]: ColumnDetails } = {
    [XtendedFacet.SEVERITY]: {
      name: 'pages.cybersocxdr.incidents.list.columns.severity',
      sortable: true,
      visible: true,
      size: 1,
    },
    [XtendedFacet.CLIENT_TICKET_ID]: {
      name: 'pages.cybersocxdr.incidents.list.columns.clientTicketNumber',
      sortable: false,
      visible: true,
      size: 1,
    },
    [XtendedFacet.SUBJECT]: {
      name: 'pages.cybersocxdr.incidents.list.columns.subject',
      sortable: false,
      visible: true,
      size: 4,
    },
    [XtendedFacet.OWNER]: {
      name: 'pages.cybersocxdr.incidents.list.columns.owner',
      sortable: false,
      visible: true,
      size: 1,
    },
    [XtendedFacet.CATEGORY]: {
      name: 'pages.cybersocxdr.incidents.list.columns.type',
      sortable: false,
      visible: true,
      size: 1,
    },
    [XtendedFacet.ID]: {
      name: 'pages.cybersocxdr.incidents.list.columns.incidentId',
      sortable: true,
      visible: true,
      size: 2,
    },
    [XtendedFacet.START_TIME]: {
      name: 'pages.cybersocxdr.incidents.list.columns.createdDate',
      sortable: true,
      visible: true,
      size: 2,
    },
  };

  public getVisibleColumns(): (ColumnDetails & { id: IncidentColumns })[] {
    return Object.entries(this.columns)
      .filter(([_, column]) => column.visible)
      .map(([id, column]) => ({ ...column, id: id as IncidentColumns }));
  }

  public getAllColumns(): (ColumnDetails & { id: IncidentColumns })[] {
    return Object.entries(this.columns).map(([id, column]) => ({
      ...column,
      id: id as IncidentColumns,
    }));
  }

  constructor() {}

  flagCase(event: Event, incident: CaseXDR): boolean {
    event.stopPropagation();
    this.newFlagEmitter.emit(incident);
    return false;
  }

  sortResults(event: Event, field: string): void {
    event.stopPropagation();
    if (this.currentSort === field) {
      if (this.currentSortDirection === 'ASC') {
        this.currentSortDirection = 'DESC';
      } else {
        // Remove sort if already DESC
        this.currentSort = '';
        this.currentSortDirection = null;
        this.sortEmitter.emit(null);
        return;
      }
    } else {
      this.currentSortDirection = 'ASC';
    }
    this.currentSort = field;
    this.sortEmitter.emit({ field, direction: this.currentSortDirection });
  }

  exportCSV(event: Event): void {
    event.stopPropagation();
    this.exportEmitter.emit();
  }

  toggleColumnVisibility(event: Event, columnId: IncidentColumns): void {
    event.stopPropagation();
    this.columns[columnId].visible = !this.columns[columnId].visible;
  }
}
