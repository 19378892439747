import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { Vulnerability, VulnerabilityRow } from 'src/app/shared/services/ethicalhacking/model/vulnerabilities-model';
import { PaginationConfig, TableColumn } from 'src/app/shared/services/ethicalhacking/model/paginated-model';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-generic-table',
  standalone: true,
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    TranslateModule,
    FontAwesomeModule
  ],
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss']
})
export class GenericTableComponent {

  @Input() data: Vulnerability[] = [];
  @Input() columns: TableColumn[] = [];
  @Input() sortable: boolean = false;
  @Input() pagination: PaginationConfig = {
    enabled: false,
    pageSize: 10,
    currentPage: 1,
    total: 0
  };
  @Input() selectable = false;
  @Input() loading = false;
  @Input() emptyMessage = 'noData';
  @Input() loadedActivities: boolean = true;

  @Output() rowClick = new EventEmitter<VulnerabilityRow>();
  @Output() sort = new EventEmitter<{ column: string, direction: 'asc' | 'desc' }>();
  @Output() pageChange = new EventEmitter<number>();
  @Output() selectionChange = new EventEmitter<Vulnerability[]>();
  @Output() pageSizeChange = new EventEmitter<number>();

  public selectedRows: Vulnerability[] = [];

  public currentSortColumn: string | null = null;
  public currentSortDirection: 'asc' | 'desc' = 'asc';

  protected Math = Math;

  get paginatedData(): Vulnerability[] {
    if (!this.pagination.enabled) {
      return this.data;
    }
    return this.data;
  }

  public toggleSelection(row: Vulnerability): void {
    const index = this.selectedRows.indexOf(row);
    if (index === -1) {
      this.selectedRows.push(row);
    } else {
      this.selectedRows.splice(index, 1);
    }
    this.selectionChange.emit(this.selectedRows);
  }

  public onPageChange(page: number | { pageIndex: number, pageSize: number }): void {
    let newPage: number;

    if (typeof page === 'number') {
      newPage = page + 1;
    } else {
      newPage = page.pageIndex;
    }

    this.pagination.currentPage = newPage;
    this.pageChange.emit(newPage);
  }

  public generatePageArray(): number[] {
    const totalPages = Math.ceil(this.pagination.total / this.pagination.pageSize);
    return Array.from({ length: totalPages }, (_, i) => i);
  }

  public onPageSizeChange(event: Event): void {
    const newSize = parseInt((event.target as HTMLSelectElement).value);
    this.pagination.pageSize = newSize;
    this.pagination.currentPage = 1;
    this.pageSizeChange.emit(newSize);
    this.onPageChange({ pageIndex: 0, pageSize: newSize });
  }

  public onSort(column: string): void {
    if (!this.sortable) return;

    if (this.currentSortColumn === column) {
      this.currentSortDirection = this.currentSortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.currentSortColumn = column;
      this.currentSortDirection = 'asc';
    }

    this.sort.emit({
      column: column,
      direction: this.currentSortDirection
    });
  }
}

