<main
  class="container-fluid g-0 {{ isDarkThemeEnabled ? 'dark' : 'light' }}-theme"
>
  <div class="side-bar">
    <div class="sticky-top">
      <app-sidemenu
        [menuLinks]="menuLinks"
        [menuTitle]="
          'pages.cybersocxdr.service.' +
          userAuthService.accesses.xtendedsoc.serviceName
        "
        [titleRoute]="'/xtended-soc'"
      />
    </div>

    <div class="sticky-bottom d-lg-block d-none">
      <anonymous-button (onClick)="onAnonymousClick()"/>

      @if (isDevMode()) {
        <dev-switch-theme-button
          [darkModeState]="isDarkThemeEnabled"
          (onStateChange)="isDarkThemeEnabled = $event"/>
      }
    </div>
  </div>

  <div class="w-100 h-100">
    <app-xtended-notification-carousel [notifications]="notifications"/>

    <div class="sticky-top header">
      @if (getCurrentPage() && !getCurrentPage()?.hideHeader) {
        <xtended-header
          [outlet]="outlet"
          [isContractTypeSelectable]="getCurrentPage()?.sourceSelectable"
        />
      }
    </div>

    <router-outlet (activate)="onActivate()"/>
  </div>

  <div class="position-fixed bottom-0 end-0 mb-4 me-4" style="z-index: 1000">
    <button
      class="btn btn-primary rounded-circle"
      style="width: 60px; height: 60px"
      (click)="toggleHelp()"
      aria-label="Open help"
    >
      <fa-icon
        [icon]="['fas', isHelpOpen ? 'chevron-down' : 'question']"
        size="lg"
      />
    </button>

    <app-xtended-help [isHelpOpen]="isHelpOpen"/>
  </div>
</main>
