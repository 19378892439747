import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

type QueryOptions = {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  params?: HttpParams | { [param: string]: string | string[] };
  withCredentials?: boolean;
  body?: any;
};

const ROOT_URL = environment.endpoints.ethicalhacking;
const CLIENT_URL = ROOT_URL + 'clients';

@Injectable({
  providedIn: 'root',
})
export class EthicalHackingService {
  private readonly http: HttpClient = inject(HttpClient);

  public isEthicalHackingClient(): Observable<boolean> {
    return this.http.get<boolean>(CLIENT_URL + '/isethicalhackingclient').pipe(catchError(() => of(false)));
  }
}
