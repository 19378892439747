<div class="endpoint-management-section">
  @if(!loading) {
    <div class="cards-row" [ngStyle]="getStyles(cardsRow)" *ngFor="let cardsRow of cards">
      <div class="card" *ngFor="let card of cardsRow">
        <app-sentinel-one-table [card]="card"></app-sentinel-one-table>
      </div>
    </div>
  } @else {
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
  }
</div>
