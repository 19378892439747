<div class="container-fluid pb-2">
  <div class="row">
    @if(category) {
    <div class="col-12">
      <h3>{{ category }}</h3>
    </div>
    }
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <caption class="d-none">
          {{
            'pages.ethicalhacking.contract.contactsTable.caption' | translate
          }}
        </caption>
        <thead>
          <tr>
            <th scope="col">
              {{
                'pages.ethicalhacking.contract.contactsTable.columns.name'
                  | translate
              }}
            </th>
            <th scope="col">
              {{
                'pages.ethicalhacking.contract.contactsTable.columns.role'
                  | translate
              }}
            </th>
            <th scope="col">
              {{
                'pages.ethicalhacking.contract.contactsTable.columns.email'
                  | translate
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          @for(item of contacts; track item) {
          <tr>
            <td [class.highlighted]="item.highlighted">
              <b>{{ item.name }}</b>
            </td>
            <td [class.highlighted]="item.highlighted">
              {{
                'pages.ethicalhacking.contract.contactsTable.' + item.role
                  | translate
              }}
            </td>
            <td>
              <a [href]="'mailto:' + item.email">
                {{ item.email }}
              </a>
            </td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
