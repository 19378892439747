import { Component, OnInit } from '@angular/core';
import { SentinelOneTab, SentinelOneData, ThreatsCount } from '../sentinel-one-models';
import { S1_COLORS } from '../sentinel-one-constants';
import { ContextService } from 'src/app/shared/services/context-service';
import { UserTheme } from 'src/app/shared/model/shared-items';

@Component({
  selector: 'app-sentinel-one-dashboard',
  templateUrl: './sentinel-one-dashboard.component.html',
  styleUrl: './sentinel-one-dashboard.component.scss'
})
export class SentinelOneDashboardComponent implements OnInit {

  public tabs = SentinelOneTab;
  public activeTab: SentinelOneTab;
  public customColors = S1_COLORS.lighter;
  public errorMessage = null;
  public sentinelOneData: {
    endpoints: SentinelOneData,
    threats: SentinelOneData,
    threatsCount: ThreatsCount
  };
  public snowData: {
    casesPendingCustomer: number,
    casesEscalatedToYou: number,
    threatsDetected: number,
    openCases: number
  };
  
  private defaultTab = this.tabs.OVERVIEW;

  constructor(public contextService: ContextService) {}

  ngOnInit(): void {
    this.customColors = this.contextService.userSelectedTheme === UserTheme.dark
      ? S1_COLORS.lighter
      : S1_COLORS.darker;
    this.loadComponent(this.defaultTab);
  }

  public loadComponent(tab: SentinelOneTab) {
    this.errorMessage = null;
    this.activeTab = tab;
  }

  public displayError(error: string) {
    this.errorMessage = error;
  }

}
