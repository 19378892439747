import { QueryStatus } from "src/app/support/supportlog/filters/asset-and-service/asset-and-service.component";

export class RatingAnswerQueryStatus extends QueryStatus {
    rate_search : [number, number];
    survey_id: string;

    constructor(survey_id: string, limit: number) {
      super(limit)
      this.rate_search = [0, 10];
      this.survey_id = survey_id;
    }
}