import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CaseXDR } from '../../../shared/model/cybersocxdr/microsocticket';
import { PaginatedList } from 'src/app/shared/model/cybersocxdr/paginatedList';

@Component({
  selector: 'app-pagination-incident-list',
  templateUrl: './paginationIncidentList.component.html',
})
export class PaginationIncidentListComponent {
  @Input()
  set currentPage(value: PaginatedList<CaseXDR>) {
    if (!value) return;

    //si pas de data sur la page fetch la page 0
    if (value.items.length === 0 && value.page > 0) this.fetchPage.emit(0);

    this._currentPage = value;
    if (value.page === 0) {
      this.cases = [...value.items];
    } else {
      this.cases = [...this.cases, ...value.items];
    }
    this.scrollLoading = false;
  }
  _currentPage: PaginatedList<CaseXDR>;

  @Input()
  title: string;

  @Input()
  badgeAnnotation: string | number;

  @Input()
  flags: number[] = [];

  @Input()
  selectableColumns: boolean = false;

  @Input()
  exportable: boolean = false;

  @Input()
  exportLoading: boolean = false;

  cases: CaseXDR[] = [];

  @Output()
  fetchPage: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  newFlagEmitter = new EventEmitter<CaseXDR>();

  @Output()
  sortEmitter = new EventEmitter<{ field: string; direction: 'ASC' | 'DESC' }>();

  @Output()
  exportEmitter = new EventEmitter<void>();

  scrollLoading: boolean = false;

  get totalPages(): number {
    if (!this._currentPage) {
      console.error('currentPage is undefined or pageSize is zero', this._currentPage.total);
      return 0;
    }
    return Math.ceil(this._currentPage.total / this._currentPage.pageSize);
  }

  flagCase(incident: CaseXDR) {
    this.newFlagEmitter.emit(incident);
  }

  exportCSV(): void {
    this.exportEmitter.emit();
  }

  sortCases(sort: { field: string; direction: 'ASC' | 'DESC' }) {
    this.sortEmitter.emit(sort);
  }

  public loadPage(pageNumber: number) {
    console.log(
      'Page demandée :',
      this._currentPage.page,
      'sur',
      this.totalPages,
      'pages au total',
      'et le nombre incident',
      this._currentPage.total
    );

    if (pageNumber < 0 || pageNumber >= this.totalPages) {
      return;
    }
    this.scrollLoading = true;
    this.fetchPage.emit(pageNumber);
  }

  /**
   * Generates an array of page numbers for pagination.
   *
   * @param currentPage The number of the current page. This is a zero-indexed integer, so the first page is 0, the second page is 1, etc.
   * @return An array of page numbers. The array starts from two pages before the current page (or from the first page if the current page is one of the first two pages),
   * and ends five pages after the start page, or at the last page if less than five pages remain.
   */
  generatePageArray(currentPage: number): number[] {
    let startPage = Math.max(currentPage - 3, 0);
    let endPage = Math.min(startPage + 7, this.totalPages);
    return Array.from({ length: endPage - startPage }, (_, i) => startPage + i);
  }
}
