import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from 'src/app/shared/components/xtended/card/card.component';
import { AssessmentTableComponent } from '../../components/assessments-table/assessment-table.component';
@Component({
  selector: 'app-ethicalhacking-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['../../../layout/xtended/ethical-hacking-layout/styles/ethicalhacking.scss'],
  standalone: true,
  imports: [TranslateModule, CardComponent, AssessmentTableComponent],
})
export class EthicalhackingAssessmentsComponent {}
