@if (vulnerability && !loading) {
  <div class="container mt-4">
    <app-card>


      <div class="mb-4 bg-orange-100 text-center p-3">
        <h4 class="text-primary fw-bold mb-2">{{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.perimeter' | translate }}</h4>
        <h2 class="text-secondary mb-0">
          <fa-icon [icon]="faEarthEurope" class="me-2"></fa-icon>
          {{ vulnerability.targets[0].name }}
        </h2>
      </div>
   

   
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-12">
            <div class="d-flex align-items-center gap-4">
              <div class="severity-{{vulnerability.severity.toLowerCase()}} p-2 rounded text-center" style="min-width: 150px;">
                <h3 class="mb-1">{{ 'pages.ethicalhacking.dashboard.vulnerabilities.severity.' + vulnerability.severity.toLowerCase() | translate }}</h3>
                <small>({{ vulnerability.severity_metrics.scoring_system }})</small>
              </div>
              <div class="flex-grow-1 title rounded-3 p-3">
                <h3 class="mb-0 text-white">{{ vulnerability.title }}</h3>
              </div>
              <div class="text-end">
                <h5 class="mb-0 label text-white rounded-3 p-3">
                  <span>{{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.ref' | translate }}:</span>
                  <span class="ms-1 fw-bold" [innerHTML]="referencesHtml"></span>
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex align-items-stretch">
                <div class="label text-center text-white rounded p-3 d-flex align-items-center">
                  <span>{{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.perimeter' | translate }}</span>
                </div>
                <div class="ps-3 d-flex align-items-center">
                  <h4 class="mb-0">
                    @if (vulnerability.perimeter) {
                      {{ vulnerability.perimeter }}
                    } @else {
                      {{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.noData' | translate }}
                    }
                  </h4>
                </div>
              </div>
            </div>
          </div>

      

        

        <div class="row">
          <div class="col-12">
            <table class="table">
              <caption class="caption-top text-center text-muted fst-italic title-table w-100 py-2">
                {{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.recommendation' | translate }}
              </caption>
              <thead>
                <tr>
                  <th scope="col" class="bg-light text-center" style="width: 50%">{{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.long_term' | translate }}</th>
                  <th scope="col" class="bg-light text-center" style="width: 50%">{{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.difficulty' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">
                    @if (vulnerability.action) {
                      {{ vulnerability.action }}
                    } @else {
                      {{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.noData' | translate }}
                    }
                  </td>
                  <td class="text-center">
                    <span class="text-nowrap">
                      @for (item of [1,2,3,4]; track $index) {
                        <fa-icon 
                          [icon]="faTools" 
                          [class.text-primary]="item <= vulnerability.complexity"
                          [class.text-muted]="item > vulnerability.complexity"
                          class="me-2"
                        ></fa-icon>
                      }
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>      
      </div>

      <div class="row mt-3 g-2">
        <div class="col-12">
          <div class="p-3 rounded-3">
            <h4 class="title-color mb-2 fs-3">{{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.description' | translate }}</h4>
            <p class="mb-0 fs-5">
              @if (descriptionHtml) {
                <div [innerHTML]="descriptionHtml"></div>
              } @else {
                {{'pages.ethicalhacking.dashboard.vulnerabilities.details.noData' | translate}}
              }
            </p>
          </div>
        </div>

        <div class="col-12">
          <div class="p-3 rounded-3">
            <h4 class="title-color mb-2 fs-3">{{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.state' | translate }}</h4>
            <p class="mb-0 fs-5">
              @if (riskHtml) {
                <div [innerHTML]="riskHtml"></div>
              } @else {
                {{'pages.ethicalhacking.dashboard.vulnerabilities.details.noData' | translate}}
              }
            </p>
          </div>
        </div>

        <div class="col-12">
          <div class="p-3 rounded-3">
            <h4 class="title-color mb-2 fs-3">{{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.risk' | translate }}</h4>
            <p class="mb-0 fs-5">
              @if (riskHtml) {
                <div [innerHTML]="riskHtml"></div>
              } @else {
                {{'pages.ethicalhacking.dashboard.vulnerabilities.details.noData' | translate}}
              }
            </p>
          </div>
        </div>

        <div class="col-12">
          <div class="p-3 rounded-3">
            <h4 class="title-color mb-2 fs-3">{{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.recommendation' | translate }}</h4>
            <p class="mb-0 fs-5">
              @if (recommendationHtml) {
                <div [innerHTML]="recommendationHtml"></div>
              } @else {
                {{'pages.ethicalhacking.dashboard.vulnerabilities.details.noData' | translate}}
              }
            </p>
          </div>
        </div>

        <div class="col-12">
          <div class="p-3 rounded-3">
            <h4 class="title-color mb-2 fs-3">{{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.references' | translate }}</h4>
            <p class="mb-0 fs-5">
              @if (referencesHtml) {
                <div [innerHTML]="referencesHtml"></div>
              } @else {
                {{'pages.ethicalhacking.dashboard.vulnerabilities.details.noData' | translate}}
              }
            </p>
          </div>
        </div>
      </div>

    </app-card>
    </div>


    
}

@if (!vulnerability || loading) {
  <div class="container mt-4 text-center">
    <div class="spinner-border text-primary">
      <span class="visually-hidden">{{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.loading' | translate }}</span>
    </div>
    <p class="mt-2">{{ 'pages.ethicalhacking.dashboard.vulnerabilities.details.loading' | translate }}</p>
  </div>
}