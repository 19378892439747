import { Component, Input, OnInit } from '@angular/core';
import { SentinelOneCardTable, SentinelOneTableData } from '../sentinel-one-models';
import { TABLE_ACTIONS } from '../sentinel-one-constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sentinel-one-table',
  templateUrl: './sentinel-one-table.component.html',
  styleUrl: './sentinel-one-table.component.scss'
})
export class SentinelOneTableComponent implements OnInit {
  
  @Input() card: SentinelOneCardTable;
  
  public cardData: SentinelOneTableData;
  public tableActions = TABLE_ACTIONS;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.cardData = this.card.data as SentinelOneTableData
  }

  public getDataType(data: any) {
    return typeof data;
  }

  public getCellDisplayFormat(dataEntry: any) {
    let result: string;
    if (typeof dataEntry === 'boolean') {
      result = 'boolean';
    } else if (dataEntry.action && dataEntry.action === this.tableActions.VIEW) {
      result = this.tableActions.VIEW;
    } else if (dataEntry.action && dataEntry.action === this.tableActions.INVESTIGATE) {
      result = this.tableActions.INVESTIGATE;
    } else if (dataEntry.action && dataEntry.action === this.tableActions.CLOSE) {
      result = this.tableActions.CLOSE;
    } else if (dataEntry.action === this.tableActions.PROCESS) {
      result = this.tableActions.PROCESS;
    } else {
      result = 'text';
    }
    return result;
  }

  
  public gotoCases(url: string) {
    this.router.navigateByUrl(url);
  }

}
