import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from 'src/app/shared/components/xtended/card/card.component';
import { SeverityComponent } from 'src/app/shared/components/xtended/severity/severity.component';
import { AssessmentService } from 'src/app/shared/services/ethicalhacking/assessment.service';
import { VulnerabilitiesCountDto } from 'src/app/shared/services/ethicalhacking/model/ethicalHackingDtos';
import { SreporterMdService } from 'src/app/shared/services/ethicalhacking/sreporter-md.service';
import { AppSharedModule } from '../../../../app-shared.module';
import {
  AssessmentDto,
  AssessmentSectionDto,
  AssessmentStatusValues,
} from '../../../../shared/services/ethicalhacking/model/assessment.dto';

@Component({
  selector: 'app-assessment-detail',
  standalone: true,
  imports: [CommonModule, TranslateModule, FontAwesomeModule, AppSharedModule, CardComponent, SeverityComponent],
  templateUrl: './assessment-detail.component.html',
  styleUrls: ['./assessment-detail.component.scss'],
})
export class AssessmentDetailComponent implements OnInit {
  public assessment: AssessmentDto | null = null;
  public loading: boolean = false;

  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly assessmentService: AssessmentService = inject(AssessmentService);
  private readonly mdService: SreporterMdService = inject(SreporterMdService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  protected openVulnerabilitiesBySeverity: VulnerabilitiesCountDto[] = [];

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      let assessmentId = params.get('id');
      if (assessmentId) {
        this.loadAssessmentDetails(assessmentId);
      }
    });
  }

  private async loadAssessmentDetails(id: string): Promise<void> {
    if (id) {
      this.loading = true;
      this.assessmentService
        .getAssessmentById(id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(async (res) => {
          this.openVulnerabilitiesBySeverity = Object.entries(res.vulnerabilities_repartition).map(
            ([severity, count]) => ({
              name: severity,
              count: count,
            })
          );

          this.assessment = {
            ...res,
            sections: await Promise.all(
              res.sections.map(async (section) => ({
                ...section,
                description: await this.mdService.convertToHtml(section.description),
              }))
            ),
          };
          this.loading = false;
        });
    }
  }

  public getRootSections(sections: AssessmentSectionDto[]): AssessmentSectionDto[] {
    if (!sections) return [];
    const sectionIds = sections.map((s) => s.id);
    return sections.filter((section) => !section.parent_id || !sectionIds.includes(section.parent_id));
  }

  public getChildSections(sections: AssessmentSectionDto[], parentId: string): AssessmentSectionDto[] {
    if (!sections || !parentId) return [];
    return sections.filter((section) => section.parent_id === parentId);
  }

  public getAssessentStatusClass(status: AssessmentStatusValues): string {
    switch (status) {
      case AssessmentStatusValues.PENDING:
      case AssessmentStatusValues.SCHEDULED:
      case AssessmentStatusValues.RETEST_SCHEDULED:
        return 'warning';
      case AssessmentStatusValues.UNDER_REVIEW:
      case AssessmentStatusValues.RETEST_UNDER_REVIEW:
      case AssessmentStatusValues.RETEST_REQUESTED:
      case AssessmentStatusValues.CONTINUOUS_MONITORING:
        return 'info';
      case AssessmentStatusValues.REVIEW_COMPLETED:
      case AssessmentStatusValues.RETEST_COMPLETED:
      case AssessmentStatusValues.RETEST_REVIEW_COMPLETED:
      case AssessmentStatusValues.ACTIVE:
      case AssessmentStatusValues.RETEST_ACTIVE:
      case AssessmentStatusValues.COMPLETED:
        return 'success';
    }
  }
}
