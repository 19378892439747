<ol class="breadcrumb mb-0 py-2">
  @for (crumb of breadcrumbs$ | async; track crumb; let last = $last) {
    <li class="breadcrumb-item fs-6">
      @if (!last) {
        <a [routerLink]="crumb.url">{{ crumb.label }}</a>
      } @else {
        <span>{{ crumb.label }}</span>
      }
    </li>
  }
</ol>
