import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { PortalSavedFilterService } from 'src/app/shared/services/xtendedsoc/portalSavedFilter.service';
import { PortalSavedFilterDTO } from 'src/app/shared/model/cybersocxdr/portalSavedFilter';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { XtendedFacet } from 'src/app/shared/model/cybersocxdr/facets';
import { take } from 'rxjs/operators';
import { XtendedFiltersService } from 'src/app/cybersocxdr/xtendedfilters.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent implements OnInit {
  protected readonly confirmTarget: string = 'confirmDialog';

  private readonly notifications: AlertService = inject(AlertService);
  private readonly translate: TranslateService = inject(TranslateService);
  private readonly globalFilters: XtendedFiltersService = inject(XtendedFiltersService);
  private readonly portalSavedFiltersService: PortalSavedFilterService = inject(PortalSavedFilterService);

  /**
   * @protected
   * @desc A signal holding an array of `PortalSavedFilterDTO` objects, representing the user's saved filter presets.
   */
  protected presets: WritableSignal<PortalSavedFilterDTO[]> = signal<PortalSavedFilterDTO[]>([]);

  private deletePresetId?: number | null = null;

  public ngOnInit(): void {
    this.loadSavedPresets();
  }

  /**
   * @protected
   * @desc Loads the user's saved filter presets from the back-end.
   * Subscribes to the service response and handles the result.
   */
  protected loadSavedPresets(): void {
    this.portalSavedFiltersService
      .findByMail()
      .pipe(take(1))
      .subscribe({
        next: (filters: PortalSavedFilterDTO[]): void => this.handleLoadedPreset(filters),
        error: (): void => this.notifications.addError(
          this.translate.instant('pages.cybersocxdr.mySpace.settings.loadError')
        )
      });
  }

  /**
   * @private
   * @desc Handles the result of the saved presets loading process.
   *
   * @param filters - The loaded presets.
   */
  private handleLoadedPreset(filters: PortalSavedFilterDTO[]): void {
    this.presets.set(filters.map(this.processSingleFilter, this));
  }

  /**
   * @private
   * @desc Processes a single filter by transforming the facet object.
   *
   * @param filter - The filter to process.
   *
   * @returns The processed filter.
   */
  private processSingleFilter(filter: PortalSavedFilterDTO): PortalSavedFilterDTO {
    try {
      return {
        ...filter,
        facet: this.transformFacet(JSON.parse(filter.facet))
      };
    } catch (error) {
      this.notifications.addError(
        this.translate.instant('pages.cybersocxdr.mySpace.settings.loadError')
      );
    }
  }

  /**
   * @private
   * @desc Transforms a facet object by mapping field names to their types.
   *
   * @param parsedFacet - The facet object to transform.
   *
   * @returns The transformed facet.
   */
  private transformFacet(parsedFacet: XtendedFacet): XtendedFacet {
    return Object.fromEntries(
      Object.entries(parsedFacet)
        .map(([key, value]: [string, string]): string[] =>
          [
            this.globalFilters.mapFieldToType(key),
            Array.isArray(value)
              ? value.join(', ')
              : value
          ]
        )
        .filter(([key, value]: [string, string]): boolean =>
          value && Object.values(XtendedFacet).includes(key as XtendedFacet)
        )
    );
  }

  /**
   * @protected
   * @desc Sets the ID of the preset to be deleted.
   *
   * @param id - The ID of the preset to delete.
   */
  protected setDeletePresetId(id: number): void {
    this.deletePresetId = id;
  }

  /**
   * @protected
   * @desc Deletes a saved filter by calling the corresponding service.
   * Emits an event after the deletion process is completed.
   */
  protected onDeletePreset(): void {
    this.portalSavedFiltersService
      .delete(this.deletePresetId)
      .pipe(take(1))
      .subscribe((): void => {
        this.loadSavedPresets();
        this.deletePresetId = null;
      });
  }
}
