import { Component, OnInit } from '@angular/core';
import FileSaver from 'file-saver';
import { XtendedClientService } from 'src/app/shared/services/xtendedsoc/xtended-client.service';
import { ClientFileDto } from './client-file.dto';

@Component({
  selector: 'app-xtended-my-files-page',
  templateUrl: './my-files.component.html',
  styleUrl: './my-files.component.scss',
})
export class XtendedSOCMyFilesComponent implements OnInit {
  clientFiles: ClientFileDto[] = [];

  constructor(private readonly clientService: XtendedClientService) {}

  // # Initialization
  ngOnInit() {
    this.clientService.getClientFiles().then((clientFiles) => (this.clientFiles = clientFiles));
  }

  async onDownload(file: ClientFileDto) {
    const blob = await this.clientService.getClientFile(file.id);
    FileSaver.saveAs(blob, file.name);
  }
}
