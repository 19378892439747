@if (location) {
  <div>
    <div class="p-3" data-toggle="tooltip" data-placement="top"
      title="{{ 'pages.assets.assetView.assetContractDesc' | translate }}">
      <div class="row">
        <div class="col-8 col-xl-9">
          <h2>{{ location.fullName }}</h2>
        </div>
      </div>
  
      <!-- full_name -->
      <div class="row p-1 banding">
        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <h5>{{ 'pages.assets.dashboard.fieldTitles.locations.fullName' | translate }}</h5>
        </div>
        <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">
          {{location.fullName}}
        </div>
      </div>
  
      <!-- u_site_code -->
      <div class="row p-1 banding">
        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <h5>{{ 'pages.assets.dashboard.fieldTitles.locations.siteCode' | translate }}</h5>
        </div>
        <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">
          {{location.siteCode ? location.siteCode : '--'}}
        </div>
      </div>
  
      <!-- street -->
      <div class="row p-1 banding">
        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <h5>{{ 'pages.assets.dashboard.fieldTitles.locations.street' | translate }}</h5>
        </div>
        <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">
          {{location.street ? location.street : '--'}}
        </div>
      </div>
  
      <!-- city -->
      <div class="row p-1 banding">
        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <h5>{{ 'pages.assets.dashboard.fieldTitles.locations.city' | translate }}</h5>
        </div>
        <div class="col-xl-9 col-lg-8 col-md-8 col-sm-6 col-xs-12">
          {{location.city ? location.city : '--'}}
        </div>
      </div>
  
      <!-- country -->
      <div class="row p-1 banding">
        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
          <h5>{{ 'pages.assets.dashboard.fieldTitles.locations.country' | translate }}</h5>
        </div>
        <div class="col-xl-6 col-lg-4 col-md-4 col-sm-4 col-xs-12">
          {{location.country ? location.country : '--'}}
        </div>
      </div>
      
    </div>
  </div>

  <div class="card border-none">
    <div class="card-title">{{ 'pages.assets.dashboard.fieldTitles.locations.relatedAssets' | translate }}</div>
    <div class="card-body">
      <app-asset-list
        [assets]="subsetAssets"
        [flagRefs]="[]"
        [flaggingRef]=""
        [loading]="!locationAssetsLoaded"
        (sortEmitter)="sortResults($event);"></app-asset-list>
    </div>
  
    @if (locationAssetsLoaded) {
      <div class="card-body justify-content-center p-t-1 p-3">
        <ngb-pagination
          [collectionSize]="locationAssets.length"
          pageSize="{{perPage}}"
          page="currentPage"
          [(page)]="currentPage"
          [maxSize]="10"
          aria-label="Pagination"
          (pageChange)="onPageChange($event)">
          <ng-template ngbPaginationPrevious></ng-template>
          <ng-template ngbPaginationNext></ng-template>
        </ngb-pagination>
      </div>
    }
    
  </div>
}
