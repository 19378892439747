<ul class="documents-container tree">
  <li>
    <details open>
      <summary>{{'pages.shared.reports' | translate }}</summary>
      <ul>
        <ng-template #recursiveList let-viewedFileStructureArray>
          <li *ngFor="let item of viewedFileStructureArray">

            <details *ngIf="item.value !== null">
              <summary>
                <img src="assets/images/icons8-file-folder-48.png" alt="folder icon" width="20px">
                {{item.name}}
              </summary>
              <ul>
                <ng-container *ngTemplateOutlet="recursiveList; context:{
                  $implicit: item.value
                }"></ng-container>
              </ul>
            </details>

            <div class="file-line" *ngIf="item.value === null">
              <div
                title="{{ 'pages.reports.document.downloadFile' | translate }}"
                class="cursorpointer"
                (click)="clickFile(item.folderPath, item.name, fileAction.download)">
                {{item.name}}
              </div>
              <span class="action">
                <div class="date" *ngIf="item.created">
                  Created {{ item.created | date }}
                </div>
                <div class="action-icons">
                  <span title="{{ 'pages.reports.document.downloadFile' | translate }}">
                    <fa-icon
                      [icon]="['fas', 'download']"
                      size="lg"
                      class="cursorpointer action-icon" 
                      (click)="clickFile(item.folderPath, item.name, fileAction.download)">
                    </fa-icon>
                  </span>
                  <span title="{{ 'pages.reports.document.viewFile' | translate  }}">
                    <fa-icon [hidden]="!viewableTypes.includes(getContentType(item.name))"
                      [icon]="['far', 'eye']"
                      size="lg"
                      class="cursorpointer action-icon"
                      (click)="clickFile(item.folderPath, item.name, fileAction.view)">
                    </fa-icon>
                  </span>
                </div>
              </span>
            </div>

          </li>
        </ng-template>

        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: viewedFileStructureArray }"></ng-container>
      </ul>
    </details>
  </li>
</ul>
