<div class="container-fluid p-3 h-100">
  <div class="row justify-content-between">
    <div class="col-md-5 col-12">
      <app-card
        [cardTitle]="
          'pages.ethicalhacking.dashboard.reportedVulnerabilitiesChart.title'
            | translate
        "
        [selectOptions]="groupByOptions"
        [selectAction]="updateGroupBy.bind(this)"
      >
        <app-pie
          [data]="groupedVulnerabilities"
          [showTotal]="true"
          [totalLabel]="
            'pages.ethicalhacking.dashboard.reportedVulnerabilitiesChart.totalLabel'
              | translate
          "
          suffix=""
          innerSize="0"
        />
      </app-card>
    </div>
    <div class="col-md-7 col-12">
      <app-card
        [cardTitle]="'pages.ethicalhacking.assessmentsTable.title' | translate"
        [actionRoute]="'/ethical-hacking/assessments'"
        [actionTitle]="
          'pages.ethicalhacking.assessmentsTable.action' | translate
        "
      >
        <app-ethicalhacking-assessment-table [pageSize]="ASSESSMENTS_LIMIT" />
      </app-card>
    </div>
    <div class="col-12">
      <app-insight-carrousel
        [insights]="ethicalHackingInsights"
        [displayHeader]="false"
      />
    </div>
  </div>
</div>
