import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, take } from "rxjs/operators";

export class IconsUtils {
    private static readonly assetsPath = 'assets/images/icons/';

    public static initIcons(iconPath: string, http: HttpClient): Observable<string> {
        return IconsUtils.getSvgContent(iconPath, http)
            .pipe(
                take(1),
                map((content) => content)
            );
    }

    private static getSvgContent(path: string, http: HttpClient): Observable<string> {
        const svgPath: string = IconsUtils.assetsPath + path;
        return http.get(svgPath, { responseType: 'text' }).pipe(
            catchError(() => of(`cannot load svg ${path}`))
        );
    }
}    