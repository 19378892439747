<div class="side-menu">
  <!-- HEADER -->
  <div class="side-menu-header p-3">
    <a class="fw-semibold text-decoration-none" [routerLink]="titleRoute">
      {{ menuTitle | translate}}
    </a>
  </div>

  <!-- ITEMS -->
  <ul class="list-unstyled mb-0">
    @for (item of menuLinks; track item.route) {
      @if (isVisible(item)) {
        <li class="side-menu-item py-lg-1" [class.active]="isActive(item.route)">

          <a [routerLink]="item.route"
             class="px-3 py-2 fw-semibold text-decoration-none">
            {{ item.name | translate }}
          </a>

        </li>
      }
    }
  </ul>
</div>
