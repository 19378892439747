<div class="filter-bar">

  <div class="filters-container rounded-start">

    <!-- Tags des filtres regroupés par type -->
    <div class="filter-tags-container">
      <ng-container *ngTemplateOutlet="filterTags"/>
    </div>

    <div #inputContainer
         class="{{ CssSelector.INPUT_CONTAINER }}">
      <ng-container *ngTemplateOutlet="searchInputTpl"/>
      <ng-container *ngTemplateOutlet="filterDropdownTpl"/>
    </div>

    <button type="button"
            class="filters-remove-btn btn btn-icon rounded"
            (click)="xtendedFiltersService.resetFilters()"
            title="{{ 'pages.cybersocxdr.filters.clear_all' | translate }}">
      <fa-icon [icon]="['fas', 'xmark']"/>
    </button>
  </div>

  <!-- === User saved filter dropdown === -->
  <ng-container *ngTemplateOutlet="userFiltersDropdownTpl"/>

  <!-- === Search button === -->
  <ng-container *ngTemplateOutlet="searchButtonTpl"/>
</div>


<!-- === Filter Tags === -->
<ng-template #filterTags>
  @for (facetGroup of getGroupedFilters(); track facetGroup.type) {
    <span class="{{ CssSelector.FILTER_TAG }} rounded"
          (keydown)="$event.key === 'Enter' && onFilterTagClick(facetGroup.type, $event)"
          (click)="onFilterTagClick(facetGroup.type, $event)"
          [ngbTooltip]="facetGroup.values.join(', ')"
          [disableTooltip]="showFiltersDropdown"
          placement="bottom">

            <span class="filter-tag-name">
              {{ 'pages.cybersocxdr.filters.' + facetGroup.type | alternate:"severity" | translate }} :
            </span>

            <span class="filter-tag-values">
              {{ facetGroup.values.join(', ') }}
            </span>

            <button type="button"
                    class="filter-tag-remove btn btn-icon rounded"
                    (click)="onRemoveFilterTagClick(facetGroup.type, $event);">
              <fa-icon [icon]="['fas', 'xmark']"/>
            </button>
    </span>
  }
</ng-template>

<!-- === Facet Input === -->
<ng-template #searchInputTpl>
  <input class="filter-search-input"
         type="text"
         [(ngModel)]="searchQuery"
         (focus)="onFilterSearchInputFocus()"
         (keyup.enter)="onSearchClick()"
         (ngModelChange)="onSearchChange(false)"
         placeholder="{{'pages.cybersocxdr.filters.searchBar.filter' | translate}}"/>
</ng-template>

<!-- === Facet dropdown === -->
<ng-template #filterDropdownTpl>
  <div #filterDropdown
       class="{{ CssSelector.FILTER_DROPDOWN }} dropdown-menu rounded"
       [class.show]="showFiltersDropdown"
       [ngStyle]="dropdownPosition">

    <div class="{{ CssSelector.FILTER_DROPDOWN }}-content">
      <ng-container *ngTemplateOutlet="!selectedFacet
                                        ? facetListTpl
                                        : filterOptionsTpl"/>
    </div>
  </div>
</ng-template>

<ng-template #facetListTpl>
  <div class="filter-items-dropdown">
    @for (facet of getFilteredFacets(); track $index) {
      <div class="{{ CssSelector.FILTER_ITEM }} dropdown-item rounded"
           (keydown)="$event.key === 'Enter' && selectFacet(facet)"
           (click)="selectFacet(facet)">
        {{ 'pages.cybersocxdr.filters.' + facet | alternate:"severity" | translate }}
      </div>
    }
  </div>
</ng-template>

<ng-template #filterOptionsTpl>
  <div class="filter-options-dropdown">

    <div class="filter-option-header sticky-top">
      <div class="header-title">
        {{ 'pages.cybersocxdr.filters.' + selectedFacet | alternate:"severity" | translate }}
      </div>

      <div class="header-input-container">
        <input class="form-control rounded"
               type="text"
               [(ngModel)]="valueSearchQuery"
               (ngModelChange)="onSearchChange(true)"
               placeholder="{{'pages.cybersocxdr.filters.searchBar.search_value' | translate}}"/>
      </div>

      @if (filteredOptions.length > 0) {
        <div class="dropdown-divider m-2 mt-0"></div>
      }
    </div>

    <div class="filter-option-container">
      @for (option of filteredOptions; track option.value) {
        <div class="filter-option dropdown-item rounded"
             (keydown)="$event.key === 'Enter' && selectValue(option)"
             (click)="selectValue(option)">

          <div class="form-check">
            <input class="form-check-input rounded"
                   type="checkbox"
                   id="checkBox_{{ $index }}"
                   [checked]="isOptionSelected(option)">

            <label class="form-check-label"
                   for="checkBox_{{ $index }}"
                   (keydown)="$event.stopPropagation()"
                   (click)="$event.stopPropagation()">
              {{ option.label | translate }}
            </label>
          </div>

        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #userFiltersDropdownTpl>
  <div ngbDropdown
       class="preset-dropdown dropdown">

    <button ngbDropdownToggle
            class="dropdown-toggle btn btn-icon btn-secondary">
      <fa-icon [icon]="['fas', 'filter']"/>
    </button>

    <div ngbDropdownMenu
         class="preset-dropdown-menu dropdown-menu rounded">

      <div class="m-2 mb-0">
        @for (preset of xtendedFiltersService.filtersPresets; track preset) {
          <button type="button"
                  class="preset-item dropdown-item rounded"
                  [class.active]="xtendedFiltersService.currentPreset === preset.name"
                  (click)="xtendedFiltersService.applyPreset(preset)">
            {{ preset.name }}
          </button>
        }
      </div>

      <div class="mx-2">
        <div class="dropdown-divider"></div>

        <span class="dropdown-section-title">
          Save actual filters
        </span>

        <div class="input-group input-group-sm p-2">

          <input type="text"
                 class="rounded-start border-end-0 form-control"
                 [ngModel]="newUserPresetName()"
                 (ngModelChange)="newUserPresetName.set($event);"
                 [disabled]="!hasActiveFilters()"
                 placeholder="{{ 'pages.cybersocxdr.filters.nameFilter' | translate }}"/>

          <button type="button"
                  class="btn btn-sm btn-secondary rounded-end"
                  (click)="onSaveUserPreset()"
                  [disabled]="!(newUserPresetName().length > 0) || !hasActiveFilters()">
            <fa-icon [icon]="['fas', 'save']"/>
          </button>

        </div>
      </div>

      @if (savedFilters.length > 0) {
        <div class="user-presets mx-2 mb-2">
          <div class="dropdown-divider"></div>

          <span class="dropdown-section-title">
            {{ 'pages.cybersocxdr.filters.savedFilters' | translate }}
          </span>

          <div class="user-preset-container">
            @for (filter of savedFilters; track filter.name) {
              <button type="button"
                      class="dropdown-item rounded"
                      (click)="applySavedFilter(filter)">
                {{ filter.name }}
              </button>
            }
          </div>
        </div>
      }
    </div>

  </div>
</ng-template>

<ng-template #searchButtonTpl>
  <button type="button"
          class="search-button btn btn-icon btn-secondary rounded-end"
          (click)="onSearchClick()">
    <fa-icon [icon]="['fas', 'search']"/>
  </button>
</ng-template>
