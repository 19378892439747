<div class="modal-header">
    <h5 class="modal-title text-primary" id="exampleModalLabel">{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.reviews" | translate}}</h5>
</div>
<div class="modal-body">
    <form class="filter" [formGroup]="filterForm">
        <div class="mb-3">
            <label for="userInput" class="form-label">{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.user" | translate}}</label>
            <input type="text" class="form-control" formControlName="userControl" id="userInput" placeholder="{{ 'pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.placeholderSearchUser' | translate}}">
        </div>
        <div class="slider-container mb-3">
            <label for="ratingSLider" class="form-label">{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.rating" | translate}}</label>
            <ngx-slider [options]="options" formControlName="sliderControl" id="ratingSlider"></ngx-slider>
        </div>
    </form>
    <button class="reset-button btn btn-primary" (click)="resetFilter()">
        {{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.resetFilter" | translate}}    
    </button>

    <div class="list-users col">
        <div class="header list-group-item">
            <span class="users-label">{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.usersLabel" | translate}}</span>
            <span class="rating-label">{{"pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.ratingsLabel" | translate}}</span>
        </div>
        <ul class="list-group scrollable-content" infiniteScroll
        [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
        (scrolled)="loadReviews(true)">
            @if(!(this.reviews.ratings.length > 0)){
                <li class="user-line list-group-item d-flex justify-content-between align-items-center">
                    <span class="username">{{"pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.noUser" | translate}}</span>
                    <span class="sticker sticker-xs mx-1" style="background-color: #6699ff;">i</span>
                </li>
            }
            @for (rating of this.reviews.ratings; track $index) {
                <li class="users-line list-group-item d-flex justify-content-between align-items-center" [class.active] = '$index===selectedIndex' (click)="onSelect($index)">
                    <span class="username">{{rating.login}}</span>
                    <span class="sticker sticker-xs mx-1">{{rating.answers[0].answer}}</span>
                </li>
            }
            @if (this.queryStatus.loading) {
                <li class="row mt-1 justify-content-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
                    </div>
                </li> 
            }         
        </ul>
        
        
    </div>
    
    <!-- feedbacks -->
    <div class="feedback col border border-3">
        @if(this.reviews.ratings.length > 0 && this.reviews.ratings[selectedIndex].answers[0].complement){
            <div class="user">
                <strong>{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.from" | translate}}</strong> {{this.reviews.ratings[selectedIndex].login}}
            </div>
            <strong>{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.feedback" | translate}}</strong>
            <div class="text-feedback">{{this.reviews.ratings[selectedIndex].answers[0].complement}}</div>
        }@else{
            <div class="alert alert-info" role="alert">
                <span class="alert-icon"><span class="visually-hidden">Info</span></span>
                <p>{{ "pages.feedbacks.surveyComponents.ratingAnswerComponent.ratingModal.noReview" | translate}}</p> 
            </div>
        }
    </div>
    
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">{{ 'pages.generic.close' | translate }}</button>
</div>
