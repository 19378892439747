@if (contractList.length > 1) {
  <select class="form-select rounded h-100"
          (change)="onChange($event)"
          [disabled]="unselectable">

    @for (contract of contractList; track $index) {
      <option [value]="contract.id"
              [selected]="contract.id === selectedContractId">
        {{ contract.service }}
      </option>
    }
  </select>
}
