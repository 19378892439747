import { Pipe, PipeTransform } from '@angular/core';
import { Anonymous } from "src/app/cybersocxdr/utils";

/**
 * Replace any text with dots, useful for demo purposes
 */
@Pipe({ name: 'anonymise', standalone: true })
export class AnonymisePipe implements PipeTransform {
  anonymousEnabled: boolean;

  constructor() {
    this.anonymousEnabled = Anonymous.isEnabled();
  }

  transform(value: string, ...args: any[]): string {
    if (this.anonymousEnabled) {
      return value.replace(/[^\n| ].?/gimu, '•');
    }
    return value;
  }
}
