<app-tabbar [currentTab]="selectedTab" [tabs]="tabs" />

<div class="container-fluid p-3 h-100">
  @if(selectedTab === 'overview') {
  <app-my-it-overview-tab />
  } @else {
  <app-techno-tab
    [hostsData]="hostsData[selectedTab] || null"
    [installedAgentSeries]="installedAgentSeries[selectedTab] || []"
    [osRepartitionSeries]="osRepartitionSeries[selectedTab] || []"
    [agentsVersionByProductTypeSeries]="
      agentsVersionByProductTypeSeries[selectedTab] || []
    "
    [agentsVersionOptions]="agentsVersionOptions[selectedTab] || []"
    [policiesRepartitionByProductTypeSeries]="
      policiesRepartitionByProductTypeSeries[selectedTab] || []
    "
    [policiesRepartitionByProductTypeOptions]="
      policiesRepartitionByProductTypeOptions[selectedTab] || []
    "
    [agentsCards]="agentsCards"
  />
  }
</div>
