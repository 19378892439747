import { Component, inject, Input, OnInit } from '@angular/core';
import { XtendedFiltersService } from "src/app/cybersocxdr/xtendedfilters.service";
import { Contract } from "src/app/shared/model/cybersocxdr/clientInfo";
import { ContractService } from "src/app/shared/model/cybersocxdr/incidentDataSourceEnum";

@Component({
  selector: 'contract-type-selector',
  standalone: true,
  templateUrl: './contract-type-selector.component.html',
})
export class ContractTypeSelectorComponent implements OnInit {
  private filterService: XtendedFiltersService = inject(XtendedFiltersService);

  /**
   * @public
   * @defaultValue false
   * @desc If `true`, the component will automatically select the first contract found in the list that is an **Xtended contract**.
   * If `false`, the component will not auto-select any contract.
   */
  @Input() public unselectable: boolean = false;

  protected contractList: Contract[];
  protected selectedContractId: number | null = null;

  public ngOnInit(): void {
    this.contractList = this.filterService.clientInfo?.contracts ?? [];

    this.initSelectedItem();
  }

  /**
   * @private
   * @desc Initialize the selected item.
   */
  private initSelectedItem(): void {
    if (!this.unselectable) {
      this.selectedContractId = this.filterService.selectedContract?.id ?? null;
    } else {
      this.setupUnselectable();
    }
  }

  /**
   * @private
   * @desc Setup the unselectable mode.
   */
  private setupUnselectable(): void {
    const xtendedContract: Contract = this.contractList
      .find((c: Contract): boolean => c.service === ContractService.XTENDED);

    if (xtendedContract) {
      this.setSelectedContract(xtendedContract.id);
    }
  }

  /**
   * @private
   * @desc Set the selected contract.
   *
   * @param contractId The ID of the contract to select.
   */
  private setSelectedContract(contractId: number): void {
    this.selectedContractId = contractId;
    this.filterService.selectedContract = contractId;
  }

  /**
   * @public
   * @desc Event handler for the change event.
   *
   * @param event The change event.
   */
  protected onChange(event: Event): void {
    const id: number = +(event.target as HTMLInputElement).value;
    this.setSelectedContract(id);
  }
}
