import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { XtendedHighchartService } from 'src/app/cybersocxdr/xtended-highchart.service';
import { XtendedFiltersService } from 'src/app/cybersocxdr/xtendedfilters.service';
import { RepartitionChartItem } from 'src/app/shared/model/cybersocxdr/charts/common';
import { XtendedFacet } from 'src/app/shared/model/cybersocxdr/facets';
import { CybersocxdrService } from 'src/app/shared/services/xtendedsoc/cybersocxdr.service';

@Component({
  selector: 'app-my-it-overview-tab',
  templateUrl: './overview-tab.component.html',
})
export class MyItOverviewTabComponent implements OnInit, OnDestroy {
  private readonly cybersocxdrService: CybersocxdrService = inject(CybersocxdrService);
  private readonly globalFilters: XtendedFiltersService = inject(XtendedFiltersService);
  private readonly chartService: XtendedHighchartService = inject(XtendedHighchartService);

  private filterSubscription: Subscription;

  public topHostsData: RepartitionChartItem[] = [];
  public hostsRepartitionData: Highcharts.SeriesPieOptions[] = [];

  private async getData(): Promise<void> {
    this.cybersocxdrService.getCasesGroupBy(this.globalFilters.searchCriteria, XtendedFacet.HOSTNAME).then((data) => {
      const sortedData = [...data].sort((a, b) => b.volume - a.volume);
      this.topHostsData = sortedData.slice(0, 10);
      this.hostsRepartitionData = [this.chartService.pieChartSerie(this.topHostsData, XtendedFacet.HOSTNAME)];
    });
  }

  ngOnInit(): void {
    this.getData();
    this.filterSubscription = this.globalFilters.filterEvent.subscribe(() => this.getData());
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }
}
