<div class="row">
  @for (item of items; track $index) {
  <div class="col-12 col-sm-6 col-lg mt-lg-0 mt-2">
    <div class="rounded h-100 p-3 position-relative severity-{{ item.name }}">
      <div class="justify-content-between text-center">
        <span class="fs-1 fw-bold mb-2 d-block">
          {{ item.count }}
        </span>
        <span class="d-block">
          {{
            'pages.ethicalhacking.dashboard.severity.' + item.name | translate
          }}
        </span>
      </div>
    </div>
  </div>
  }
</div>
