import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { oktaAuthGuard } from '../app.guard';
import { LayoutComponent } from '../layout/layout/layout.component';
import { EthicalHackingLayoutComponent } from '../layout/xtended/ethical-hacking-layout/ethical-hacking-layout.component';
import { AssessmentService } from '../shared/services/ethicalhacking/assessment.service';
import { VulnerabilitiesEthicalHackingService } from '../shared/services/ethicalhacking/vulnerabilities.service';
import { EthicalhackingGuard } from './ethicalhacking.guard';
import { BreadcrumbGenericResolver } from './framework/breadcrumb/breadcrumb-generic.resolver';
import { AssessmentDetailComponent } from './pages/assessments/assessment-detail/assessment-detail.component';
import { EthicalhackingAssessmentsComponent } from './pages/assessments/assessments.component';
import { EthicalhackingContractComponent } from './pages/contract/contract.component';
import { EthicalhackingDashboardComponent } from './pages/dashboard/dashboard.component';
import { VulnerabilitieDetailComponent } from './pages/vulnerabilities/vulnerabilitie-detail/vulnerabilitie-detail.component';
import { EthicalhackingVulnerabilitiesComponent } from './pages/vulnerabilities/vulnerabilities.component';

const routes: Routes = [
  {
    path: 'ethical-hacking',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard, EthicalhackingGuard],
    data: {
      displayBackToTop: false,
    },
    children: [
      {
        path: '',
        component: EthicalHackingLayoutComponent,
        data: {
          fullWidth: true,
        },
        children: [
          { path: 'ethical-hacking', redirectTo: '', pathMatch: 'full', },
          {
            path: '',
            component: EthicalhackingDashboardComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
          {
            path: 'vulnerabilities',
            component: EthicalhackingVulnerabilitiesComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
              breadcrumb: `pages.ethicalhacking.menu.vulnerabilities`
            },
          },
          {
            path: 'vulnerabilities/:id',
            component: VulnerabilitieDetailComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
              breadcrumb: {
                key: '@vulnerabilities.title@',
                resolvers: {
                  vulnerabilities: {
                    service: VulnerabilitiesEthicalHackingService,
                    method: 'getVulnerabilitiesById',
                    params: {
                      id: (route: ActivatedRouteSnapshot) => route.params.id
                    }
                  }
                }
              }
            },
            resolve: {
              breadcrumbRes: BreadcrumbGenericResolver
            }
          },
          {
            path: 'assessments',
            component: EthicalhackingAssessmentsComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
              breadcrumb: `pages.ethicalhacking.menu.assessments`
            },
          },
          {
            path: 'assessments/:id',
            component: AssessmentDetailComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
              breadcrumb: {
                key: '@assessments.title@',
                resolvers: {
                  assessments: {
                    service: AssessmentService,
                    method: 'getAssessmentById',
                    params: {
                      id: (route: ActivatedRouteSnapshot) => route.params.id
                    }
                  }
                }
              }
            },
            resolve: {
              breadcrumbRes: BreadcrumbGenericResolver
            }
          },
          {
            path: 'contract',
            component: EthicalhackingContractComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
              breadcrumb: `pages.ethicalhacking.menu.contract`
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EthicalhackingRoutingModule { }
