import { Component, EventEmitter, Output } from '@angular/core';
import { BaseChartComponent } from '../basechart/basechart.component';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-sunburst',
  templateUrl: '../basechart/basechart.component.html',
})
export class SunburstComponent extends BaseChartComponent<Highcharts.SeriesSunburstOptions[]> {
  @Output() newRootEvent = new EventEmitter<string>();

  options: Highcharts.Options = {
    chart: {
      type: 'sunburst',
      backgroundColor: '#FFFFFF00',
    },
    title: {
      text: this.title,
      style: {
        fontSize: '1.2em',
      },
    },
    tooltip: {
      useHTML: true,
      pointFormat: '<b>{point.name}</b>: {point.value}',
    },
    plotOptions: {
      series: {
        borderWidth: 2,
        borderColor: '#FFFFFF00',
      },
      sunburst: {
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none',
            fontSize: '0.9em',
          },
          rotationMode: 'circular',
        },
        levelIsConstant: false,
        levels: [
          {
            level: 1,
            colorByPoint: true,
            dataLabels: {
              rotationMode: 'parallel',
            },
          },
          {
            level: 2,
            colorVariation: {
              key: 'brightness',
              to: -0.3,
            },
          },
        ],
        allowTraversingTree: true,
      },
    },
  };

  draw(renderingId: string) {
    this.options.series = this.data;
  }
}
