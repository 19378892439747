import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BaseChartComponent } from '../basechart/basechart.component';
import { getDefaultHighchartOptions } from '../../utils';

@Component({
  selector: 'app-treemap',
  templateUrl: '../basechart/basechart.component.html',
})
export class TreemapComponent extends BaseChartComponent<Array<Highcharts.PointOptionsObject>> {
  options: Highcharts.Options = {
    chart: {
      type: 'treemap',
    },
    title: {
      text: undefined,
    },
    tooltip: {
      useHTML: true,
      pointFormat: '<b><span style="color:{point.color}">{point.name}</span>:</b> {point.value}',
    },
    legend: {
      layout: 'proximate',
      align: 'left',
    },
    series: undefined,
  };

  colors = getDefaultHighchartOptions().colors;

  draw(renderingid: string) {
    this.options.title.text = this.title;
    this.options.series = [
      {
        type: 'treemap',
        layoutAlgorithm: 'stripes',
        alternateStartingDirection: true,
        borderColor: 'transparent',
        borderRadius: 6,
        borderWidth: 0,
        dataLabels: {
          style: {
            textOutline: 'none',
          },
        },
        levels: [
          {
            level: 1,
            layoutAlgorithm: 'sliceAndDice',
            dataLabels: {
              enabled: true,
              align: 'left',
              verticalAlign: 'top',
              style: {
                fontSize: '15px',
                fontWeight: 'bold',
              },
            },
          },
        ],
        colors: this.colors,
        data: this.data,
      },
    ];
  }
}
