import { Component, DestroyRef, EventEmitter, inject, isDevMode, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CybersocXDRDashboardSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { XtendedFiltersService } from 'src/app/cybersocxdr/xtendedfilters.service';
import { XtendedAnalyticsService } from 'src/app/cybersocxdr/xtended-analytics.service';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { ContextService } from 'src/app/shared/services/context-service';
import { CybersocxdrService } from 'src/app/shared/services/xtendedsoc/cybersocxdr.service';
import { IncidentDataSource } from 'src/app/shared/model/cybersocxdr/incidentDataSourceEnum';
import { XtendedNotificationDTO } from 'src/app/shared/model/cybersocxdr/xtended-notification';
import { XtendedNotificationService } from 'src/app/shared/services/xtendedsoc/xtended-notification.service';
import { MenuLink, SidemenuComponent } from 'src/app/shared/components/xtended/sidemenu/sidemenu.component';
import { Anonymous } from 'src/app/cybersocxdr/utils';
import { SwitchThemeButtonComponent } from 'src/app/shared/components/xtended/dev/switch-theme-button/switch-theme-button.component';
import { AnonymousButtonComponent } from 'src/app/cybersocxdr/components/anonymous-button/anonymous-button.component';
import { XtendedNotificationCarouselComponent } from 'src/app/cybersocxdr/components/xtended-notification-carousel/xtended-notification-carousel.component';
import { HeaderComponent } from 'src/app/cybersocxdr/header/header.component';
import { XtendedHelpComponent } from 'src/app/cybersocxdr/components/xtended-help/xtended-help.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from 'src/app/shared/modules/icons/icons.module';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DynamicDate } from 'src/app/shared/model/cybersocxdr/facetsValues';
import { ClientInfos } from 'src/app/shared/model/cybersocxdr/clientInfo';

declare global {
  interface Window {
    umami: any;
  }
}

@Component({
  selector: 'app-xtended-layout',
  standalone: true,
  imports: [
    TranslateModule,
    SwitchThemeButtonComponent,
    AnonymousButtonComponent,
    SidemenuComponent,
    XtendedNotificationCarouselComponent,
    HeaderComponent,
    RouterOutlet,
    XtendedHelpComponent,
    IconsModule,
  ],
  templateUrl: './xtended-layout.component.html',
  styleUrls: [
    'styles/xtended.scss',
    '../../default-layout/default-layout.component.scss'
  ],
})
export class XtendedLayoutComponent implements OnInit {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly router: Router = inject(Router);
  private readonly filterService: XtendedFiltersService = inject(XtendedFiltersService);
  private readonly xtendedAnalyticsService: XtendedAnalyticsService = inject(XtendedAnalyticsService);
  public readonly userAuthService: UserAuthService = inject(UserAuthService);
  private readonly cybersocxdrService: CybersocxdrService = inject(CybersocxdrService);
  private readonly xtendedNotificationService: XtendedNotificationService = inject(XtendedNotificationService);
  private readonly contextService: ContextService = inject(ContextService);

  @ViewChild(RouterOutlet)
  outlet: RouterOutlet;

  @Output()
  searchEmitter: EventEmitter<CybersocXDRDashboardSearchCriteria> = new EventEmitter<CybersocXDRDashboardSearchCriteria>(true);

  anonymousEnabled: boolean;
  isDarkThemeEnabled: boolean;
  notifications: XtendedNotificationDTO[] = [];
  isHelpOpen: boolean = false;
  menuLinks: MenuLink[] = [];
  periodOptions = [
    DynamicDate.TODAY,
    DynamicDate.LAST_24H,
    DynamicDate.THIS_WEEK,
    DynamicDate.LAST_7D,
    DynamicDate.THIS_MONTH,
    DynamicDate.LAST_30D,
    DynamicDate.LAST_MONTH,
    DynamicDate.LAST_90D,
    DynamicDate.LAST_180D,
    DynamicDate.THIS_YEAR
  ];

  displayCreated: string;

  public ngOnInit(): void {
    this.anonymousEnabled = Anonymous.isEnabled();
    this.isDarkThemeEnabled = this.contextService.isDarkTheme();

    this.initUmami();

    //Fetch client info
    this.cybersocxdrService.getClientInfo().then((clientInfo: ClientInfos) => {
      this.filterService.clientInfo = clientInfo;
      this.initMenuLinks();
    });

    this.xtendedNotificationService.getActiveNotifications().then((notifications: XtendedNotificationDTO[]) => {
      this.notifications = notifications;
    });
  }

  async initMenuLinks() {
    this.menuLinks = [
      {
        name: 'pages.cybersocxdr.menu.home',
        route: '/xtended-soc',
        saveSearchDisplay: true,
        sourceDisplay: true,
        sourceSelectable: true,
      },
      {
        name: 'pages.cybersocxdr.menu.incidents',
        route: '/xtended-soc/incidents',
        saveSearchDisplay: true,
        sourceDisplay: true,
        sourceSelectable: true,
      },
      {
        name: 'pages.cybersocxdr.menu.dashboard',
        route: '/xtended-soc/dashboard',
        saveSearchDisplay: true,
        sourceDisplay: true,
        sourceSelectable: true,
      },
      {
        name: 'pages.cybersocxdr.menu.4A3W',
        route: '/xtended-soc/4A3W',
        saveSearchDisplay: true,
        sourceDisplay: true,
        sourceSelectable: true,
      },
      {
        name: 'pages.cybersocxdr.menu.sla',
        route: '/xtended-soc/sla',
        saveSearchDisplay: true,
        sourceDisplay: true,
        sourceSelectable: false,
        onlyForDataSources: [IncidentDataSource.ELASTIC],
      },
      {
        name: 'pages.cybersocxdr.menu.myIT',
        route: '/xtended-soc/my-it',
        saveSearchDisplay: false,
        sourceDisplay: false,
        sourceSelectable: false,
      },
      {
        name: 'pages.cybersocxdr.menu.mySpace',
        route: '/xtended-soc/my-space',
        saveSearchDisplay: false,
        hideHeader: true,
        sourceDisplay: true,
        sourceSelectable: true,
      },
      ...(this.filterService.clientInfo?.haveEntities && (await this.userAuthService.isUserAdminOnCurrentTenant())
        ? [
          {
            name: 'pages.cybersocxdr.menu.entities',
            route: '/xtended-soc/entities',
            saveSearchDisplay: false,
            sourceDisplay: false,
            sourceSelectable: false,
          },
        ]
        : []),
    ];
  }

  private initUmami(): void {
    const userEmail: string = this.userAuthService.userDetails?.email;
    const isOcdUser: boolean = ['@orangecyberdefense.com', '@orange.com'].some((domain: string): boolean =>
      userEmail?.endsWith(domain)
    );
    this.filterService.filterEvent.subscribe((searchCriteria) => {
      this.displayCreated = this.periodOptions.includes(this.filterService.searchCriteria.createdFrom as DynamicDate)
        ? this.filterService.searchCriteria.createdFrom
        : 'custom';
    });

    this.displayCreated = this.displayCreated || DynamicDate.LAST_24H;
    if (!this.filterService.searchCriteria.createdFrom) {
      this.filterService.searchCriteria.createdFrom = DynamicDate.LAST_24H;
      this.filterService.serializeSeachCriteria();
    }

    const umamiScript: HTMLElement = document.getElementById('xtended-umami-tracking-script');

    if (!umamiScript) {
      let script = document.createElement('script');
      script.setAttribute('id', 'xtended-umami-tracking-script');
      script.setAttribute('src', 'https://umami-admin-portal.cybersocxdr.fr/script.js');
      script.setAttribute('data-website-id', environment?.xtended_analytic_website_id ?? '');
      script.setAttribute('data-auto-track', 'false');

      script.onload = () => {
        window.umami?.track((props) => ({
          ...props,
          url: this.router.url,
          referrer: `https://${this.userAuthService.selectedTenant}${isOcdUser ? '-ocd' : ''}`,
        }));

        this.router.events
          .pipe(
            filter((event) => event instanceof NavigationEnd),
            filter((event: NavigationEnd) => event.urlAfterRedirects.startsWith('/xtended-soc')),
            takeUntilDestroyed(this.destroyRef)
          )
          .subscribe((event) => {
            window.umami?.track((props) => ({
              ...props,
              url: event.urlAfterRedirects,
              referrer: `https://${this.userAuthService.selectedTenant}${isOcdUser ? '-ocd' : ''}`,
            }));
          });
      };

      document.head.appendChild(script);
    }
  }

  public getCurrentPage(): MenuLink {
    return (
      this.menuLinks.find(
        (menu: MenuLink): boolean =>
          menu.route === this.router.url ||
          (this.router.url.includes('#') && menu.route === this.router.url.split('#')[0]) ||
          this.router.url.startsWith(menu.route)
      ) ?? null
    );
  }

  public onActivate(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  public onAnonymousClick(): void {
    Anonymous.setState(!this.anonymousEnabled);
    this.xtendedAnalyticsService.trackEvent('Toggle Anonymous mode', {
      anonymousEnabled: !this.anonymousEnabled,
    });
  }

  public toggleHelp(): void {
    this.isHelpOpen = !this.isHelpOpen;
  }

  protected readonly isDevMode = isDevMode;
}
