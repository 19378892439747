import { Component, Input } from '@angular/core';
import { InsightData } from 'src/app/shared/model/cybersocxdr/insight';


enum InsightState {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  EQUAL = 'equal',
}

@Component({
  selector: 'app-insight',
  templateUrl: './insight.component.html',
  styleUrls: ['./insight.component.scss'],
})
export class InsightComponent {
  @Input()
  insight: InsightData;

  protected getState(): InsightState {
    if (this.insight.periodTotal === this.insight.previousPeriodTotal) {
      return InsightState.EQUAL;
    }

    return this.insight.lowerIsBetter === this.isPositive()
      ? InsightState.INCREASE
      : InsightState.DECREASE;
  }

  protected isPositive(): boolean {
    if (this.insight.lowerIsBetter) {
      return this.insight.periodTotal - this.insight.previousPeriodTotal < 0;
    } else {
      return this.insight.periodTotal - this.insight.previousPeriodTotal > 0;
    }
  }

  protected getVariationPercent(): number {
    if (this.insight.periodTotal === 0) return 0;
    return Math.floor(
      ((this.insight.periodTotal - this.insight.previousPeriodTotal) * 100) / this.insight.previousPeriodTotal
    );
  }

  protected getStateClass(): string {
    const state: InsightState = this.getState();
    const isPositiveChange: boolean = (state === InsightState.INCREASE && this.insight.lowerIsBetter) ||
      (state === InsightState.DECREASE && !this.insight.lowerIsBetter);

    return isPositiveChange ? 'text-success' : 'text-danger';
  }
}
