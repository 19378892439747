import { Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { XtendedFiltersService } from 'src/app/cybersocxdr/xtendedfilters.service';
import { IncidentDataSource } from 'src/app/shared/model/cybersocxdr/incidentDataSourceEnum';
import { Contract } from 'src/app/shared/model/cybersocxdr/clientInfo';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

export interface MenuLink {
  name: string;
  route: string;
  saveSearchDisplay: boolean;
  sourceDisplay: boolean;
  sourceSelectable: boolean;
  shouldDisplayMailButton?: boolean;
  onlyForDataSources?: IncidentDataSource[];
  hideHeader?: boolean;
}

@Component({
  standalone: true,
  selector: 'app-sidemenu',
  imports: [RouterLink, TranslateModule],
  templateUrl: './sidemenu.component.html',
  styleUrl: './sidemenu.component.scss',
})
export class SidemenuComponent implements OnInit, OnChanges {
  private readonly router: Router = inject(Router);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly xtendedFiltersService: XtendedFiltersService = inject(XtendedFiltersService);

  private activeLink: string = '';

  /**
   * @public
   * @desc List of menu links
   */
  @Input({ required: true })
  public menuLinks: MenuLink[] = [];

  /**
   * @public
   * @desc Title of the menu
   */
  @Input({ required: true })
  public menuTitle: string = '';

  /**
   * @public
   * @desc Route of the menu title link
   */
  @Input({ required: true })
  public titleRoute: string = '';

  ngOnInit(): void {
    this.initRouterEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuLinks && this.menuLinks.length) {
      this.updateActiveLink(this.router.url);
    }
  }

  /**
   * @private
   * @desc Initializes the event listener for the router events.
   */
  private initRouterEvents(): void {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ urlAfterRedirects }: NavigationEnd): void => {
        this.updateActiveLink(urlAfterRedirects);
      });
  }

  /**
   * @private
   * @desc Checks if the given link matches the current url.
   *
   * @param currentUrl - Current url.
   * @param link - Link to check.
   *
   * @returns `true` if the link matches the current url.
   */
  private isMatch(currentUrl: string, link: string): boolean {
    return currentUrl === link || currentUrl.startsWith(`${link}/`);
  }

  /**
   * @private
   * @desc Updates the active link based on the current url.
   *
   * @param currentUrl - Current url.
   */
  private updateActiveLink(currentUrl: string): void {
    this.activeLink = this.menuLinks.reduce((deepest: string, { route }: MenuLink): string => {
      return this.isMatch(currentUrl, route) && route.length > deepest.length ? route : deepest;
    }, '');
  }

  /**
   * @protected
   * @desc Returns true if the link is active.
   *
   * @param link - Link to check.
   *
   * @returns `true` if the link is active.
   */
  protected isActive(link: string): boolean {
    return link === this.activeLink;
  }

  /**
   * @protected
   * @desc Returns true if the menu is visible.
   *
   * @param menu - Menu to check.
   *
   * @returns `true` if the menu is visible
   */
  protected isVisible(menu: MenuLink): boolean {
    if (!menu.onlyForDataSources) {
      return true;
    }

    return this.xtendedFiltersService.clientInfo?.contracts.some(({ incidentDataSource }: Contract): boolean =>
      menu.onlyForDataSources?.includes(incidentDataSource)
    );
  }
}
