<div class="d-flex faketableheaderrow w-100">
  <div class="col-2 d-none d-lg-block faketableheader click" (click)="sortLocations('siteCode');">
    {{ 'pages.assets.dashboard.fieldTitles.locations.siteCode' | translate }}
    <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
  </div>
  <div class="col-2 d-none d-lg-block faketableheader click" (click)="sortLocations('fullName');">
    {{'pages.assets.dashboard.fieldTitles.locations.fullName' | translate}}
    <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
  </div>
  <div class="col-2 d-none d-lg-block faketableheader click">
    {{'pages.assets.dashboard.fieldTitles.locations.street' | translate}}
  </div>
  <div class="col-2 d-none d-lg-block faketableheader click" (click)="sortLocations('city');">
    {{'pages.assets.dashboard.fieldTitles.locations.city' | translate}}
    <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
  </div>
  <div class="col-2 d-none d-lg-block faketableheader click" (click)="sortLocations('country');">
    {{'pages.assets.dashboard.fieldTitles.locations.country' | translate}}
    <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
  </div>
</div>

  @for(loc of locations; track loc.u_site_code) {
    <div class="d-flex faketablerow" 
      routerLinkActive="activeLink" [routerLink]="['/asset/viewLocation/' + loc.id]">
      <div class="col-2 faketablecell">{{loc.siteCode ? loc.siteCode : '--'}}</div>
      <div class="col-2 d-none d-lg-block faketablecell">{{loc.fullName ? loc.fullName : '--'}}</div>
      <div class="col-2 d-none d-lg-block faketablecell">{{loc.street ? loc.street : '--'}}</div>
      <div class="col-2 d-none d-lg-block faketablecell">{{loc.city ? loc.city : '--'}}</div>
      <div class="col-2 d-none d-md-block faketablecell">{{loc.country ? loc.country : '--'}}
      </div>
    </div>
  }

  @if(loading) {
    <div class="d-flex faketablerow justify-content-center">
      <div class="row justify-content-center p-5">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
        </div>
      </div>
    </div>
  } @else if (locations && locations.length === 0) {
    <div class="row p-3 justify-content-center redborder-sides">
      <div class="col-md-12 table-border justify-content-center">
        {{ 'pages.assets.dashboard.noResults' | translate }}
      </div>
    </div>
  }
