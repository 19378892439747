<div
  class="rounded-3 h-100 p-3 border border-light bg-light agent-card"
  [ngClass]="type"
>
  <div class="card-body">
    <div class="d-flex align-items-center mb-2">
      <h5 class="card-title mb-0">{{ cardTitle | translate }}</h5>
      @if (description) {
      <fa-icon
        [icon]="['fas', 'info-circle']"
        size="md"
        class="ms-2 text-info"
        [ngbTooltip]="description | translate"
        placement="top"
      ></fa-icon>
      }
    </div>
    <p class="card-text fs-4">{{ value }}</p>
  </div>
</div>
