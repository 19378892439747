import { DestroyRef, Injectable, OnDestroy, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { marked } from 'marked';
import { AssessmentService } from './assessment.service';

@Injectable({
  providedIn: 'root',
})
export class SreporterMdService implements OnDestroy {
  private documentCache: Record<string, string> = {};
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly assessmentService: AssessmentService = inject(AssessmentService);

  constructor() {
    this.initializeMarked();
  }

  private initializeMarked(): void {
    marked.use({
      async: true,
      renderer: {
        image: (token) => {
          const { isDocument, documentId } = this.parseDocumentPath(token.href);

          if (isDocument && documentId && this.documentCache[documentId]) {
            return this.createImageHtml(this.documentCache[documentId], token.text);
          }

          return this.createImageHtml(token.href, token.text);
        },
      },
    });

    marked.setOptions({
      breaks: true,
      gfm: true,
    });
  }

  /**
   * Converts markdown content to HTML and ensures all documents are pre-fetched
   * @param markdownContent The markdown content to convert
   * @returns Promise containing the HTML string
   */
  public async convertToHtml(markdownContent: string): Promise<string> {
    if (!markdownContent) {
      return '';
    }

    // Ensure all documents are fetched before converting
    await this.prefetchDocumentReferences(markdownContent);
    return await marked.parse(markdownContent);
  }

  /**
   * Pre-fetches and caches all document references found in the content
   * @param markdownContent The content to scan for document references
   */
  public async prefetchDocumentReferences(markdownContent: string): Promise<void> {
    const documentIds = this.extractDocumentIds(markdownContent);
    await this.fetchAndCacheDocuments(documentIds);
  }

  /**
   * Fetches and caches multiple documents
   * @param documentIds Set of document IDs to fetch
   */
  private async fetchAndCacheDocuments(documentIds: Set<string>): Promise<void> {
    const fetchPromises = Array.from(documentIds)
      .filter((docId) => !this.documentCache[docId])
      .map((docId) => this.fetchAndCacheDocument(docId));

    if (fetchPromises.length > 0) {
      await Promise.all(fetchPromises);
    }
  }

  /**
   * Fetches and caches a single document
   * @param docId Document ID to fetch
   */
  private async fetchAndCacheDocument(docId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.assessmentService
        .getAssessmentDocument(docId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response) => {
            const objectUrl = URL.createObjectURL(response);
            this.documentCache[docId] = objectUrl;
            resolve();
          },
          error: (error) => {
            console.error(`Failed to fetch document ${docId}:`, error);
            reject(new Error(`Failed to fetch document ${docId}: ${error}`));
          },
        });
    });
  }

  /**
   * Extracts document IDs from markdown content
   * @param markdownContent The markdown content to scan
   * @returns Set of document IDs found in the content
   */
  public extractDocumentIds(markdownContent: string): Set<string> {
    const imageRegex = /!\[([^\]]+)\]\((?:\/?)(?:documents?)\/([^)]+)\)/g;
    const documentIds = new Set<string>();
    let match: RegExpExecArray | null;

    while ((match = imageRegex.exec(markdownContent)) !== null) {
      const path = match[2];
      const docId = path.split('.')[0];
      documentIds.add(docId);
    }

    return documentIds;
  }

  /**
   * Checks if a path is a document reference
   * @param path The path to check
   * @returns Object containing isDocument and documentId if matched
   */
  public parseDocumentPath(path: string): {
    isDocument: boolean;
    documentId: string | null;
  } {
    const docRegex = /^\/?documents?\/([^/]+)(?:\.\w+)?$/;
    const docMatch = docRegex.exec(path);

    return {
      isDocument: !!docMatch,
      documentId: docMatch ? docMatch[1].split('.')[0] : null,
    };
  }

  private createImageHtml(src: string, altText: string): string {
    return `
      <img alt="${altText}" 
           title="${altText}" 
           src="${src}" 
           class="document-image" 
           style="max-width: 100%;" />`;
  }

  ngOnDestroy(): void {
    Object.values(this.documentCache).forEach((url) => {
      if (url.startsWith('blob:')) {
        URL.revokeObjectURL(url);
      }
    });
  }
}
