<div class="table-container">
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <caption class="d-none">
        {{
          'pages.ethicalhacking.dashboard.tables.generic.tableDescription'
            | translate
        }}
      </caption>
      <thead>
        <tr>
          @if (selectable) {
          <th scope="col" class="col-1"></th>
          } @for (col of columns; track col.key) {
          <th scope="col" class="col-{{ col.size || 'auto' }}">
            <button
              class="btn btn-link p-0 text-decoration-none"
              [class.cursor-pointer]="sortable && col.sortable"
              (click)="col.sortable && sortable ? onSort(col.key) : null"
              [class.disabled-sort]="!(sortable && col.sortable)"
              [disabled]="!(sortable && col.sortable)"
              [attr.aria-label]="
                'Sort by ' +
                ('pages.ethicalhacking.dashboard.vulnerabilities.columns.' +
                  col.label | translate)
              "
            >
              <div class="d-flex align-items-center">
                <span>{{
                  'pages.ethicalhacking.dashboard.vulnerabilities.columns.' +
                    col.label | translate
                }}</span>
                @if (sortable && col.sortable) { @if (currentSortColumn ===
                col.key) {
                <fa-icon
                  [icon]="
                    currentSortDirection === 'asc'
                      ? ['fas', 'sort-up']
                      : ['fas', 'sort-down']
                  "
                >
                </fa-icon>
                } @else {
                <fa-icon [icon]="['fas', 'sort']"></fa-icon>
                } }
              </div>
            </button>
          </th>
          }
        </tr>
      </thead>

      <tbody>
        @for (row of paginatedData; track row) {
        <tr>
          @if (selectable) {
          <td class="align-middle">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                (change)="toggleSelection(row)"
                [checked]="selectedRows.includes(row)"
              />
            </div>
          </td>
          } @for (col of columns; track col) {
          <td class="{{ col.colorClass ? col.colorClass(row[col.key]) : '' }}">
            <button
              class="btn btn-link p-0 text-start text-decoration-none fw-normal"
              (click)="rowClick.emit({ row: row, column: col })"
              [attr.aria-label]="'View details for ' + row[col.key]"
              [innerHTML]="
                col.html
                  ? col.html(row[col.key])
                  : col.format
                  ? col.format(row[col.key])
                  : row[col.key]
              "
            ></button>
          </td>
          }
        </tr>
        } @if (data.length === 0) {
        <tr>
          <td
            [colSpan]="columns.length + (selectable ? 1 : 0)"
            class="text-center"
          >
            {{
              'pages.ethicalhacking.dashboard.tables.generic.' + emptyMessage
                | translate
            }}
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>

  @if (pagination.enabled) {
  <div class="d-flex flex-column align-items-center mt-3">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="d-flex justify-content-center gap-2">
        @if (pagination.currentPage > 1) {
        <button
          class="btn btn-outline-primary rounded"
          [disabled]="!loadedActivities"
          (click)="onPageChange(pagination.currentPage - 2)"
        >
          {{
            'pages.ethicalhacking.dashboard.tables.generic.previousPage'
              | translate
          }}
        </button>
        } @for (page of generatePageArray(); track $index) {
        <button
          class="btn btn-outline-primary rounded"
          [class.active]="page === pagination.currentPage - 1"
          [disabled]="!loadedActivities"
          (click)="onPageChange(page)"
        >
          {{ page + 1 }}
        </button>
        } @if (pagination.currentPage < Math.ceil(pagination.total /
        pagination.pageSize)) {
        <button
          class="btn btn-outline-primary rounded"
          [disabled]="!loadedActivities"
          (click)="onPageChange(pagination.currentPage)"
        >
          {{
            'pages.ethicalhacking.dashboard.tables.generic.nextPage' | translate
          }}
        </button>
        }
      </div>

      <div class="d-flex align-items-center gap-2">
        <span>{{
          'pages.ethicalhacking.dashboard.tables.generic.elementsPerPage'
            | translate
        }}</span>
        <select
          class="form-select rounded form-select-sm w-auto"
          [disabled]="!loadedActivities"
          [(ngModel)]="pagination.pageSize"
          (change)="onPageSizeChange($event)"
        >
          <option [value]="5">5</option>
          <option [value]="10">10</option>
          <option [value]="25">25</option>
        </select>
      </div>
    </div>
  </div>
  }
</div>
