import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from '../layout/layout/layout.component';
import { AssetDashboardComponent } from './assetdashboard/assetdashboard.component';
import { AssetViewComponent } from './assetview/assetview.component';
import { childTenantGuard, oktaAuthGuard } from '../app.guard';
import { LocationViewComponent } from './location-view/location-view.component';

const routes: Routes = [
  {
    path: 'asset',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard, childTenantGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'viewAsset/:assetRef',
        component: AssetViewComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.assets.view',
          urls: [{ title: 'menu.assets.title',  url: '/asset/dashboard/'}, { title: 'menu.assets.view' }],
        }
      }
    ]
  },
  {
    path: 'asset',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard, childTenantGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: AssetDashboardComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.assets.overview',
          urls: [{ title: 'menu.assets.title',  url: '/asset/dashboard/' }, { title: 'menu.assets.overview' }],
        }
      }
    ]
  },
  {
    path: 'asset',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard, childTenantGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'viewLocation/:locationRef',
        component: LocationViewComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.assets.viewLocation',
          urls: [{ title: 'menu.assets.title',  url: '/asset/dashboard/'}, { title: 'menu.assets.viewLocation' }],
        }
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AssetManagementRoutingModule { }
