import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Route, Router, Routes } from '@angular/router';
import { firstValueFrom, take } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
import {
  BreadcrumbConfig,
  BreadcrumbObjectConfig,
  BreadcrumbParamFunction,
  Crumb,
  DynamicPlaceholdersReplacement,
  ResolverConfig
} from "../breadcrumb/breadcrumb.types";
import { BreadcrumbUtils } from "../breadcrumb/utils/breadcrumb.utils";
import { filter } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  private readonly router: Router = inject(Router);
  private readonly translate: TranslateService = inject(TranslateService);

  /**
   * Regular expression to match service placeholders.
   *
   * This regex supports two formats:
   * - `@objectKey@`: For simple string replacements.
   * - `@objectKey.propKey@`: For object property replacements.
   */
  private readonly serviceResolveRegex: RegExp = /@(\w+)((?:\.\w+)*)@/;

  /**
   * @public
   * @desc Retrieves the list of breadcrumbs for the current route.
   *
   * @return Promise resolving to an array of Crumb objects.
   */
  public async getBreadcrumbs(): Promise<Crumb[]> {
    const currentUrl: string = this.router.url;
    if (!currentUrl || currentUrl === '/') return [];

    const segments: string[] = BreadcrumbUtils.splitUrlIntoSegments(currentUrl);
    const partialPaths: string[] = BreadcrumbUtils.generatePartialPaths(segments);

    const crumbs: (Crumb | null)[] = await Promise.all(
      partialPaths.map(async (path: string): Promise<Crumb> =>
        this.createBreadcrumbForPartialPath(path)
      )
    );

    return crumbs.filter((crumb: Crumb): crumb is Crumb => !!crumb);
  }

  /**
   * @private
   * @desc Processes a partial path to generate a breadcrumb, if applicable.
   *
   * @param partialPath - Partial path to process.
   *
   * @returns Promise resolving to a breadcrumb or null if no breadcrumb is applicable.
   */
  private async createBreadcrumbForPartialPath(partialPath: string): Promise<Crumb | null> {
    const route: Route | undefined = this.findMatchingRoute(partialPath, this.router.config);
    return route
      ? this.createBreadcrumb(route, partialPath)
      : null;
  }

  /**
   * @private
   * @desc Creates a breadcrumb object for the matched route and partial path.
   *
   * @param route - The matched route.
   * @param partialPath - The partial path corresponding to the route.
   *
   * @returns Promise resolving to a breadcrumb or `null`.
   */
  private async createBreadcrumb(route: Route, partialPath: string): Promise<Crumb | null> {
    const breadcrumb: BreadcrumbConfig | undefined = route.data?.breadcrumb as BreadcrumbConfig;
    if (!breadcrumb) return null

    let label: string;
    if (typeof breadcrumb === 'string') {
      // ? When breadcrumb is a string, use it as a translation key. Value if `breadcrumb` if no key found.
      label = await firstValueFrom(this.translate.get(breadcrumb));

    } else if (typeof breadcrumb === 'function') {
      // ? When breadcrumb is a function, call it to get the label.
      const params: Record<string, string> = BreadcrumbUtils.extractRouteParameters(partialPath, route.path || '');
      label = breadcrumb({ params } as ActivatedRouteSnapshot);

    } else if (typeof breadcrumb === 'object') {
      // ? When breadcrumb is an object, resolve dynamic placeholders.
      label = await this.resolveDynamicBreadcrumbLabel(breadcrumb, partialPath, route.path || '');
    } else {
      return null;
    }

    return { label, url: partialPath };
  }

  /**
   * @private
   * @desc Finds the route that matches the given partial path from the available routes.
   *
   * @param partialPath - The partial path to match.
   * @param routes - Available routes to search within.
   *
   * @returns The matching Route or undefined if no match is found.
   */
  private findMatchingRoute(partialPath: string, routes: Routes): Route | undefined {
    return routes
      .filter((route: Route): boolean => route.path === 'ethical-hacking')
      .flatMap((route: Route): Route[] => {
        return route.children[0].children
      })
      .find((route: Route): boolean => {
        return route.path !== undefined
          && BreadcrumbUtils.isRouteMatch(BreadcrumbUtils.normalizeUrl(partialPath).slice(1), route.path)
      });
  }

  /**
   * @private
   * @desc Resolves a dynamic breadcrumb label by processing both the translation key and any additional parameters.
   * It replaces dynamic placeholders in the key and custom params, merges them with default route parameters,
   * and then uses the translation service to produce the final label.
   *
   * @param breadcrumb - The breadcrumb configuration object containing the key, optional params, and resolvers.
   * @param partialPath - The partial URL path associated with the breadcrumb.
   * @param routePath - The route's path pattern used to extract default route parameters.
   *
   * @returns Promise resolving to the fully translated breadcrumb label.
   */
  private async resolveDynamicBreadcrumbLabel(
    breadcrumb: BreadcrumbObjectConfig,
    partialPath: string,
    routePath: string
  ): Promise<string> {
    const routeSnapshot: ActivatedRouteSnapshot = await this.getCurrentActivatedRouteSnapshot();

    const defaultParams: Record<string, string> = BreadcrumbUtils.extractRouteParameters(partialPath, routePath);
    const resolvers: Record<string, ResolverConfig> = breadcrumb.resolvers ?? {};

    const resolvedKey: string = await this.replaceDynamicPlaceholders(breadcrumb.key, resolvers);

    const customParamsEntries: Awaited<[string, string]>[] = await Promise.all(
      Object.entries(breadcrumb.params ?? {}).map(async ([key, value]: [string, BreadcrumbParamFunction]): Promise<[string, string]> => {
        // ? If the parameter is a function, execute it with the current route snapshot.
        if (typeof value === 'function') {
          return [key, value(routeSnapshot)];
        }

        // ? If the parameter is a string, resolve any dynamic placeholders it contains.
        if (typeof value === 'string') {
          return [key, await this.replaceDynamicPlaceholders(value, resolvers)];
        }

        // ? For any other type, convert the value to a string.
        return [key, String(value)];
      })
    );
    const customParams: { [p: string]: string } = Object.fromEntries(customParamsEntries);

    const mergedParams = { ...defaultParams, ...customParams };
    return firstValueFrom(this.translate.get(resolvedKey, mergedParams));
  }

  /**
   * @private
   * @desc Replaces dynamic placeholders in a translation key with values from pre-resolved data (provided by BreadcrumbGenericResolver).
   *
   * @param key - Translation key containing dynamic placeholders.
   * @param resolvers - Resolver configurations mapped by placeholder keys.
   *
   * @returns Promise resolving to the translation key with all dynamic placeholders replaced.
   */
  private async replaceDynamicPlaceholders(
    key: string,
    resolvers: Record<string, ResolverConfig>
  ): Promise<string> {
    const placeholderRegex = new RegExp(this.serviceResolveRegex.source, 'g');
    const matches: RegExpMatchArray[] = Array.from(key.matchAll(placeholderRegex));

    for (const match of matches) {
      const { placeholder, replacement } = await this.getReplacementForMatch(match, resolvers);
      key = key.replace(placeholder, replacement);
    }

    return key;
  }

  /**
   * @private
   * @desc Retrieves a replacement value for a dynamic placeholder match.
   *
   * @param match - The dynamic placeholder match.
   * @param resolvers - Resolver configurations mapped by placeholder keys.
   *
   * @returns Promise resolving to the replacement value.
   */
  private async getReplacementForMatch(
    match: RegExpMatchArray,
    resolvers: Record<string, ResolverConfig>
  ): Promise<DynamicPlaceholdersReplacement> {
    const [placeholder, objectKey, subProperties] = match;
    const resolver: ResolverConfig = resolvers[objectKey];

    if (!resolver) {
      return { placeholder, replacement: placeholder };
    }

    const resolvedValue: Record<string, unknown> = await this.resolveServicePlaceholder(objectKey);
    const finalValue: unknown = this.resolveNestedProperties(resolvedValue, subProperties);

    const replacement: string = typeof finalValue === 'string'
      ? finalValue
      : placeholder;

    return { placeholder, replacement };
  }

  /**
   * @private
   * @desc Resolves nested properties in an object.
   *
   * @param value - The value to resolve nested properties from.
   * @param subProperties - A dot-separated string of property names to resolve.
   *
   * @returns The resolved value or undefined if any part of the property chain is undefined.
   */
  private resolveNestedProperties(value: unknown, subProperties: string): unknown {
    if (!subProperties) return value;
    return subProperties
      .split('.')
      .filter(Boolean)
      .reduce((acc: unknown, key: string): unknown =>
      (acc && typeof acc === 'object'
        ? (acc as Record<string, unknown>)[key]
        : undefined)
        , value
      );
  }

  /**
   * @private
   * @desc Retrieves pre-resolved data for a dynamic placeholder
   * as provided by the BreadcrumbGenericResolver in the route configuration.
   *
   * @param resolverKey - Key identifying the resolver (e.g., "entity").
   *
   * @returns Promise resolving to the object containing dynamic values.
   */
  private async resolveServicePlaceholder(resolverKey: string): Promise<Record<string, unknown>> {
    const currentSnapshot: ActivatedRouteSnapshot = await this.getCurrentActivatedRouteSnapshot();
    const resolvedData: unknown = currentSnapshot.data['breadcrumbRes'] || {};

    return resolvedData[resolverKey] || {};
  }

  /**
   * @private
   * @desc Returns the latest ActivatedRouteSnapshot recorded via NavigationEnd.
   * If no snapshot has been recorded yet, it is extracted directly from routerState.
   *
   * @returns The current ActivatedRouteSnapshot.
   */
  private async getCurrentActivatedRouteSnapshot(): Promise<ActivatedRouteSnapshot> {
    const snapshot: ActivatedRouteSnapshot = this.getDeepestChild(this.router.routerState.snapshot.root);
    if (snapshot && snapshot.routeConfig) {
      return snapshot;
    }

    await firstValueFrom(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          take(1)
        )
    );
    return this.getDeepestChild(this.router.routerState.snapshot.root);
  }

  /**
   * @private
   * @desc Returns the deepest child ActivatedRouteSnapshot starting from the provided route.
   *
   * @param route - Starting ActivatedRouteSnapshot.
   *
   * @returns The deepest ActivatedRouteSnapshot.
   */
  private getDeepestChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    return route.firstChild
      ? this.getDeepestChild(route.firstChild)
      : route;
  }
}
