import { Injectable, signal, WritableSignal } from '@angular/core';
import { Survey, SurveyReport } from '../model/feedback';

@Injectable({
  providedIn: 'root'
})
export class SurveySummaryService {
  
  public selectedSurvey: WritableSignal<Survey> = signal(undefined);
  public surveyResult: WritableSignal<SurveyReport> = signal(undefined);
}
