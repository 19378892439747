import { ActivatedRouteSnapshot } from "@angular/router";
import { BreadcrumbParamFunction } from "../../breadcrumb/breadcrumb.types";

export class BreadcrumbResolverUtils {
  /**
   * Resolves parameters based on a given config. If no config is provided,
   * the parameters of the route (or similar object with a `params` property) are returned.
   *
   * @param paramsConfig - A map of parameter names to functions.
   * @param routeLike - An object containing at least a `params` property.
   * @returns The resolved parameters.
   */
  public static resolveParameters(
    paramsConfig: Record<string, BreadcrumbParamFunction> | undefined,
    routeLike: { params: Record<string, unknown> } & Partial<ActivatedRouteSnapshot>
  ): Record<string, unknown> {
    if (!paramsConfig) {
      return routeLike.params;
    }

    return Object.entries(paramsConfig).reduce(
      (acc: Record<string, unknown>, [key, paramFn]: [string, BreadcrumbParamFunction]): Record<string, unknown> => {
        acc[key] = paramFn(routeLike as ActivatedRouteSnapshot);
        return acc;
      },
      {} as Record<string, unknown>
    );
  }
}

