<app-tabbar [currentTab]="tab" [tabs]="tabs"/>

<div class="container-fluid p-4 h-100">
  <div class="row">
    <div class="col-lg-7 col-12 mb-3 mb-lg-0">
      <app-card>
        <div class="mb-4">
          <app-customchart
            [isStacking]="true"
            [height]="450"
            [title]="
                (tab === 'notification'
                  ? 'pages.cybersocxdr.dashboard.fieldTitles.SLANotificationStatusRateOvertime'
                  : 'pages.cybersocxdr.dashboard.fieldTitles.SLAInvestigationStatusRateOvertime'
                ) | translate
              "
            [data]="
                tab === 'notification'
                  ? slaNotifStatusRateData
                  : slaInvestRateData
              "
            titleY="Valeurs en %"
          />
        </div>
      </app-card>
    </div>
    <div class="col-lg-5 col-12">
      <div class="row px-2">
        <app-card
          [cardTitle]="'pages.cybersocxdr.dashboard.fieldTitles.' + (tab === 'notification' ? 'MTTDAverage' : 'MTTIAverage')">
          <div class="row">
            <div class="container py-1 d-flex gap-2">
              <div
                class="rounded-3 h-100 p-3 border border-primary bg-primary col-4"
                style="--bs-bg-opacity: 0.2"
              >
                <div class="mb-2">
                  <span class="fs-5 fw-bold">Global time</span>
                </div>
                <div class="d-flex align-items-end gap-2">
                  @if (tab === 'notification') {
                    <div class="fs-1 fw-bold">{{ slaGlobalMttD }}</div>
                  } @else if (tab === 'investigation') {
                    <div class="fs-1 fw-bold">{{ slaGlobalMttI }}</div>
                  }
                  <div class="mb-1">minutes</div>
                </div>
              </div>
              <div
                class="rounded-3 text-white h-100 p-3 position-relative col-4"
                style="background-color: #c00000"
              >
                <div class="fw-bold mb-2">{{ 'pages.cybersocxdr.dashboard.severity_critical' | translate }}</div>
                <div class="d-flex align-items-end gap-2">
                  <div class="fs-1 fw-bold">
                    @if (tab === 'notification') {
                      {{ severityValuesmttdCritical }}
                    } @else if (tab === 'investigation') {
                      {{ severityValuesmttiCritical }}
                    }
                  </div>
                  <div class="mb-1">minutes</div>
                </div>
              </div>
              <div
                class="rounded-3 h-100 p-3 col-4"
                style="background-color: #ff0000"
              >
                <div class="fw-bold mb-2">{{ 'pages.cybersocxdr.dashboard.severity_high' | translate }}</div>
                <div class="d-flex align-items-end gap-2">
                  <div class="fs-1 fw-bold">
                    @if (tab === 'notification') {
                      {{ severityValuesmttdHigh }}
                    } @else if (tab === 'investigation') {
                      {{ severityValuesmttiHigh }}
                    }
                  </div>
                  <div class="mb-1">minutes</div>
                </div>
              </div>
            </div>
          </div>
        </app-card>
      </div>
      <div class="row px-2">
        <app-card>
          <div
            class="justify-content-center"
            style="font: bold 16px 'Trebuchet MS', Verdana, sans-serif"
          >
            <p class="highcharts-title">
              {{
                tab === 'notification'
                  ? ('pages.cybersocxdr.dashboard.fieldTitles.SLANotificationGauge'
                    | translate)
                  : ('pages.cybersocxdr.dashboard.fieldTitles.SLAInvestigationGauge'
                    | translate)
              }}
            </p>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <app-slagauge
              [data]="
                  tab === 'notification'
                    ? slaNotifGaugeData
                    : slaInvestGaugeData
                "
            />
          </div>
        </app-card>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-7 col-12 mt-3 m-md-0">
      <div class="w-100 h-100">
        <app-card>
          <div class="justify-content-between align-items-center mb-4">
            <app-areaovertime
              [title]="
                  tab === 'notification'
                    ? ('pages.cybersocxdr.dashboard.fieldTitles.MTTDOvertime'
                      | translate)
                    : ('pages.cybersocxdr.dashboard.fieldTitles.MTTIOvertime'
                      | translate)
                "
              [data]="tab === 'notification' ? mttdData : mttiData"
              [isStacking]="false"
              [average]="true"
              titleY="Time in minutes"
            ></app-areaovertime>
          </div>
          <div class="row pt-3">
            <div id="incidents-over-time-mttd"></div>
          </div>
        </app-card>
      </div>
    </div>
    <div class="col-md-5 col-12 mt-3 m-md-0">
      <div class="w-100">
        <app-filtermenu>
          <app-card>
            <div class="justify-content-between align-items-center mb-4">
              <app-barchart
                [isStacking]="true"
                [xAxisCategories]="['<30min', '<2h', '>2h']"
                [title]="
                    'pages.cybersocxdr.dashboard.fieldTitles.SLABreakByClosureVerdictAndTimeBreack'
                      | translate
                  "
                [data]="
                    tab === 'notification'
                      ? slaBreakClosureVerdictOverTimeNotification
                      : slaBreakClosureVerdictOverTimeInvestigation
                  "
                titleY="Number of incidents"
              ></app-barchart>
            </div>
            <div class="row">
              <div id="sla-notification"></div>
            </div>
          </app-card>
        </app-filtermenu>
      </div>
    </div>
  </div>


  <div class="row">
    <app-card>
      <app-pagination-incident-list
        [currentPage]="
          tab === 'notification'
            ? notificationIncidentsCurrentPage
            : investigationIncidentsCurrentPage
        "
        (fetchPage)="
          tab === 'notification'
            ? loadNotificationIncidentPage($event)
            : loadInvestigationIncidentPage($event)
        "
        [title]="
          tab === 'notification'
            ? ('pages.cybersocxdr.sla.SLANotification' | translate)
            : ('pages.cybersocxdr.sla.SLAInvestigation' | translate)
        "
        [badgeAnnotation]="
          tab === 'notification'
            ? brokenNotificationCases?.length ?? 0
            : brokenInvestigationCases?.length ?? 0
        "
        (newFlagEmitter)="onFlag($event)"/>
    </app-card>
  </div>
</div>
