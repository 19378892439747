import { VulnerabilitiesCountDto } from './ethicalHackingDtos';
import { TableColumn } from './paginated-model';

export enum VulnerabilitySeverity {
  INFO = 'info',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical',
}

export interface VulnerabilityTableItemDto {
    id: string;
    assessment_id: string;
    created_at: string;
    perimeter: string;
    priority: string;
    severity: string;
    assessment_name: string;
    vulnerabilities: string;
}

export interface VulnerabilityRow {
    row: VulnerabilityTableItemDto;
    column: TableColumn;
}

export interface Vulnerability {
  id: string;
  assessment_id: string;
  user_id: string;
  assessment_section_id: string;
  is_vulnerability: boolean;
  classifications: Record<string, any>;
  classification_categories: Record<string, any>;
  severity: string;
  severity_score: number;
  severity_metrics: {
    cvss_string: string;
    scoring_system: string;
  };
  status: string;
  review_status: string;
  finding_template_id: string | null;
  has_caution: boolean;
  original_is_vulnerability: boolean;
  original_severity: number;
  original_severity_metrics: {
    cvss_string: string;
    scoring_system: string;
  };
  original_severity_score: number;
  number: number;
  short_id: string | null;
  title: string;
  priority: string;
  complexity: number;
  action: string | null;
  description: string;
  risk: string | null;
  recommendation: string;
  proof: string | null;
  references: string | null;
  found_at: string;
  published_at: string;
  reviewed_at: string | null;
  reviewed_by_user_id: string | null;
  created_at: string;
  resolved_at: string;
  updated_at: string;
  is_published: boolean;
  targets: {
    id: string;
    assessment_id: string;
    target_type: number;
    business_impact: string | null;
    name: string;
    name_url: string | null;
    description: string | null;
    description_url: string | null;
    ip_information: string | null;
    details: string | null;
    credentials: string | null;
    order: number;
    created_at: string;
    updated_at: string;
  }[];
  perimeter: string;
}

export type VulnerabilityStatus = 'open' | 'closed';

export interface VulnerabilitySearch {
  total: number;
  items: VulnerabilitiesCountDto[];
}

export enum VulnerabilitySortBy {
  SEVERITY = 'severity',
  CREATED_AT = 'created_at',
  PRIORITY = 'priority',
  COMPLEXITY = 'complexity',
  STATUS = 'status',
  TITLE = 'title',
}

export class VulnerabilityListItemDTO {
  id: string;
  severity: VulnerabilitySeverity;
  date_found: string;
  perimeter: string;
  reference: string;
  title: string;
  remediation: string;
  status: VulnerabilityStatus;
  priority: string;
}

export const SORTABLE_COLUMNS = Object.values(VulnerabilitySortBy);
