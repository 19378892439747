<div class="container w-75">
  <div class="col-12">

    @if (presets().length > 0) {
      @for (preset of presets(); track preset) {
        <user-preset-item [preset]="preset"
                          [confirmDialogTarget]="confirmTarget"
                          (onDelete)="setDeletePresetId($event)"/>
      }
    } @else {
      <div>
        {{ 'pages.cybersocxdr.mySpace.settings.noFilters' | translate }}
      </div>
    }
  </div>
</div>

<confirm [target]="confirmTarget"
         title="pages.cybersocxdr.mySpace.settings.dialog.title"
         message="pages.cybersocxdr.mySpace.settings.dialog.message"
         (onConfirm)="onDeletePreset()"/>
