<div class="app-card rounded {{getCleanedClasses()}}">

  @if ((actionRoute() && actionTitle()) || cardTitle()) {
    <div class="d-flex justify-content-between flex-wrap align-items-center mb-2 mb-lg-4">
      @if (cardTitle()) {
        <h3 class="m-0">
          {{ cardTitle() | translate }}
        </h3>
      }

      @if (actionRoute() && actionTitle()) {
        <a class="link-chevron" [routerLink]="actionRoute()">
          {{ actionTitle() | translate }}
        </a>
      }

      @if (selectOptions() && selectAction) {
        <select class="form-select rounded"
                style="max-width: fit-content"
                [formControl]="selectControl">

          @for (option of selectOptions(); track option.label) {
            <option [ngValue]="option">
              {{ option.label | translate }}
            </option>
          }

        </select>
      }

      <!-- Optional children for those who need actions on the top bar -->
      <ng-content select="[action]"/>
    </div>
  }

  <ng-content/>
</div>
