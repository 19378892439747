@if(loadingPage) {
<div class="justify-content-center mt-4">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
</div>
} @else {
<section [ngClass]="{'my-shared-files': selectedTab === 'mySharedFiles'}">
    <div>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link active" [ngClass]="{'active': selectedTab === 'sharedFiles'}" id="nav-tab-shared-files"
                    data-bs-toggle="tab" href="#tab-shared-files-content" data-bs-target="#tab-shared-files-content" role="tab"
                    aria-controls="tab-shared-files-content" aria-selected="true"
                    (click)="loadSharedFiles()">{{'pages.reports.internalFileTransfer.sharedFiles' | translate }}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': selectedTab === 'mySharedFiles'}" id="nav-tab-my-shared-files"
                    data-bs-toggle="tab" href="#tab-my-shared-files-content" data-bs-target="#tab-my-shared-files-content" role="tab"
                    aria-controls="tab-my-shared-files-content" aria-selected="false"
                    (click)="loadMySharedFiles()">{{'pages.reports.internalFileTransfer.mySharedFiles' | translate
                    }}</a>
            </li>
        </ul>

        <div class="tab-content" id="nav-tabs-content-my-files">

            <div class="info-and-refresh">
                <!-- informations -->
                <div class="alert alert-info alert-sm" style="width: 50%;" role="alert">
                    <span class="alert-icon"><span class="visually-hidden">Info</span></span>
                    @if(selectedTab === 'sharedFiles') {
                    <p>{{'pages.reports.internalFileTransfer.sharedFilesDesc' | translate }}</p>
                    } @else {
                    <p>{{'pages.reports.internalFileTransfer.mySharedFilesDesc' | translate }}</p>
                    }
                </div>

                <button type="button" class="btn btn-primary refresh" (click)="refresh()">
                    <div id="textRefreshButton">
                        {{'pages.reports.internalFileTransfer.refreshFiles' | translate }}
                    </div>
                    <!-- To be displayed in place of initial button text on small screens -->
                    <fa-icon id="iconRefreshButton" [icon]="['fas', 'arrows-rotate']" class="icon refresh" size="1.5x"></fa-icon>
                </button>  
            </div>
            

            <!-- shared files tab -->
            <div class="tab-pane fade show active" id="tab-shared-files-content" role="tabpanel" aria-labelledby="nav-tab-shared-files">

                <!-- shared file list -->
                <div class="list">
                    @if(loadingSharedFiles) {
                    <div class="justify-content-center mt-4">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
                        </div>
                    </div>
                    } @else if(sharedFiles && sharedFiles.length === 0){
                    <div class="alert alert-info mt-3" role="alert">
                        <span class="alert-icon"><span class="visually-hidden">Info</span></span>
                        <p>{{'pages.reports.internalFileTransfer.noSharedFile' | translate }}</p>
                    </div>
                    } @else {
                    <table class="table table-hover">
                        <caption></caption>
                        <thead>
                            <tr class="shared-files-headers">
                                <th id="filename-header" class="shared-files-filename">{{'pages.reports.internalFileTransfer.filename' | translate }}
                                </th>
                                <th id="date-header" class="sm-screen-hidden">{{'pages.reports.internalFileTransfer.date' | translate }}</th>
                                <th id="depositary-header" class="shared-files-depositary">{{'pages.reports.internalFileTransfer.depositary' | translate }}
                                </th>
                                <th id="actions-header" class="shared-files-actions">{{'pages.reports.internalFileTransfer.actions' | translate }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            @for(file of sharedFiles; track $index) {
                            <tr class="shared-files-row">
                                <th [id]="'filename_' + $index" class="break-words">{{file.filename}}</th>
                                <th [id]="'date_' + $index" class="sm-screen-hidden" >{{file.created / 1000 | date: 'medium'}}</th>
                                <th [id]="'depositary_' + $index" class="break-words" >{{file.depositary}}</th>
                                <th [id]="'actions_' + $index">
                                    <div class="actions">
                                        <fa-icon class="icon center" [icon]="['fas', 'download']" size="lg"
                                            (click)="downloadFile(file)"></fa-icon>
                                    </div>
                                </th>
                            </tr>
                            }
                        </tbody>
                    </table>
                    }
                </div>
            </div>

            <!-- my files -->
            <div class="tab-pane" id="tab-my-shared-files-content" role="tabpanel" aria-labelledby="nav-tab-my-shared-files">
                <!-- shared file list -->
                <div class="list">
                    @if(loadingUserSharedFiles) {
                    <div class="justify-content-center mt-4">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
                        </div>
                    </div>
                    } @else if(sharedFiles && mySharedFiles.length === 0) {
                    <div class="alert alert-info mt-3" role="alert">
                        <span class="alert-icon"><span class="visually-hidden">Info</span></span>
                        <p>{{'pages.reports.internalFileTransfer.noSharedFile' | translate }}</p>
                    </div>
                    } @else {
                    <table class="table table-hover">
                        <caption></caption>
                        <thead>
                            <tr class="shared-files-headers">
                                <th id="my-shared-files-filename-header" class="my-shared-files-filename">{{'pages.reports.internalFileTransfer.filename' | translate }}
                                </th>
                                <th id="my-shared-files-date-header" class="sm-screen-hidden">{{'pages.reports.internalFileTransfer.date' | translate }}</th>
                                <th id="my-shared-files-recipients-header" class="my-shared-files-recipients">{{'pages.reports.internalFileTransfer.recipients' | translate }}
                                </th>
                                <th id="my-shared-files-actions-header" class="my-shared-files-actions">{{'pages.reports.internalFileTransfer.actions' | translate }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            @for(mySharedFile of mySharedFiles; track $index) {
                            <tr class="shared-files-row">
                                <th [id]="'my-shared-files-filename_' + $index" class="break-words">{{mySharedFile.filename}}</th>
                                <th [id]="'my-shared-files-date_' + $index" class="sm-screen-hidden">{{mySharedFile.created / 1000 | date: 'medium'}}</th>
                                <th [id]="'my-shared-files-recipients_' + $index" class="recipients break-words">
                                    @for(recipient of mySharedFile.recipients; track recipient) {
                                        <p class="overflow-ellipsis mb-1">{{recipient}}</p>
                                    }
                                </th>
                                <th [id]="'my-shared-files-actions_' + $index">
                                    <div class="actions">
                                        <fa-icon class="icon" [icon]="['fas', 'download']" size="lg"
                                            (click)="downloadFile(mySharedFile)"></fa-icon>
                                        <fa-icon class="icon ms-3" [icon]="['fas', 'trash']" size="lg"
                                            (click)="deleteFile(mySharedFile)"></fa-icon>
                                    </div>
                                </th>
                            </tr>
                            }
                        </tbody>
                    </table>
                    }
                </div>
            </div>
        </div>
    </div>

    <!-- upload new file-->
    @if(selectedTab === 'mySharedFiles') {
    <div class="upload">
        @if(loading) {
        <div class="justify-content-center mt-4">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
            </div>
        </div>
        } @else {
        <div class="file-import-container">
            <app-file-import (onAddFile)="addFile($event)"></app-file-import>
        </div>

        <form class="recipient-form" [formGroup]="recipientForm">
            <div formArrayName="recipients">
                @for (recipient of recipients.controls; track recipient) {
                <!-- recipient-->
                <div class="recipient mt-2" [ngClass]="{'invalid': !recipients.controls[$index].valid && uploadedFile}">
                    <input type="text" class="form-control" [id]="'recipient'+ $index"
                        placeholder="{{ 'pages.reports.internalFileTransfer.typeEmail' | translate }}"
                        [formControlName]="$index">
                    @if($index > 0) {
                    <fa-icon class="icon" [icon]="['fas', 'trash']" size="lg"
                        (click)="removeRecipient($index)"></fa-icon>
                    }
                </div>
                }
            </div>
        </form>

        <!-- add recipient -->
        <button id="addRecipientButton" type="button" class="btn btn-secondary mt-2" (click)="addRecipient()"
            [disabled]="recipients.invalid || recipients.controls.length === 5 ">{{'pages.reports.internalFileTransfer.addRecipient'
            | translate }}<fa-icon class="ms-2 primary" [icon]="['fas', 'plus']" size="lg" data-toggle="tooltip"
                data-placement="top" aria-labelledby="caseIconDesc"></fa-icon> <label for="addRecipientButton"
                class="ms-3">({{recipients.controls.length}}/5)</label></button>
        <!-- submit -->
        <button id="submitFileButton" type="button" class="btn btn-primary mt-3" [disabled]="recipients.invalid || !uploadedFile"
            (click)="submitFile()">{{ 'buttons.action.submit' | translate }}</button>

        }
    </div>
    }

</section>
}