@if (pageLoading) {
  <div>
    <div class="row justify-content-center p-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
      </div>
    </div>
  </div>
} @else {
  <div>

    <h3 class="visually-hidden">{{ 'pages.dashboard.user.accountSettings' | translate }}</h3>
  
    <!-- profile -->
    <h4>{{ 'pages.dashboard.user.profile' | translate }}</h4>
    @if (!updating) {
      <div class="profile">
  
        <!-- user informations -->
        <form class="informations" [formGroup]="userForm" (ngSubmit)="submit()">
    
          <!-- email -->
          <div>
            <label for="formControlEmail" class="form-label">
              <span class="align-middle">{{ 'pages.dashboard.user.emailAddress' | translate }}</span>
            </label>
            <input type="email" formControlName="email" name="email" class="form-control" id="formControlEmail"
              name="email" />
          </div>
    
          <!-- verified email -->
          <div>
            <label for="formControlVerifiedEmail" class="form-label">
              {{ 'pages.dashboard.user.emailVerified' | translate }}
            </label>
            <input type="checkbox" class="form-check-input" id="formControlVerifiedEmail" formControlName="verified"
              name="emailIsVerified">
          </div>
    
          <!-- first name -->
          <div>
            <label for="formControlFirstName" class="form-label">
              {{ 'pages.generic.firstName' | translate }}
            </label>
            <div>
              <input type="text" class="form-control is-required" id="formControlFirstName" placeholder=""
                formControlName="firstName" name="firstName">
              @if (firstName.invalid && firstName.errors?.['required']) {
                <div class="alert alert-warning alert-sm text-danger" role="alert">
                  <p>{{ 'pages.generic.firstNameReq' | translate }}</p>
                </div>
              }
            </div>
          </div>
    
          <!-- last name -->
          <div>
            <label for="formControlLastName" class="form-label">
              {{ 'pages.generic.lastName' | translate }}
            </label>
            <div>
              <input type="text" class="form-control is-required" id="formControlLastName" placeholder=""
                formControlName="lastName" name="lastName">
              @if (lastName.invalid && lastName.errors?.['required']) {
                <div class="alert alert-warning alert-sm" role="alert">
                  <p>{{ 'pages.generic.lastNameReq' | translate }}</p>
                </div>
              }
            </div>
          </div>
    
          <div class="mb-3 px-0">
            <button type="submit" [disabled]="(!userForm.valid || userForm.pristine) && !profilePictureAction"
              class="btn btn-primary">{{ 'pages.generic.update' |
              translate
              }}</button>
          </div>
        </form>
    
        <!-- user's picture-->
        <div class="picture">
          <div class="d-flex justify-content-center">
            <div class="dragZone" appDnd (fileDropped)="onFileDropped($event)">
              <div class="action edit">
                <label>
                  <fa-icon [icon]="['fas', 'pencil']" size="1x"></fa-icon>
                  <label class="visually-hidden" for="fileDropRef">{{ 'pages.dashboard.user.changeProfilePicture' | translate }}</label>
                  <input type="file" name="myfile" #fileDropRef id="fileDropRef"
                    (change)="fileBrowseHandler($event.target.files)" style="display:none">
                </label>
              </div>
              <div class="action delete">
                <div (click)="deleteFile()">
                  <fa-icon [icon]="['fas', 'trash']" size="1.5x"></fa-icon>
                </div>
              </div>
              @if (!url) {
                <div class="user-icon">
                  <fa-icon [icon]="['fas', 'user-large']" size="6x"></fa-icon>
                </div>
              } @else {
                <figure>
                  <img [src]="url" alt="user's picture">
                </figure>
              }
            </div>
          </div>
          @if (url && errorFile) {
            <div class="alert alert-danger alert-sm" role="alert">
              <span class="alert-icon"><span class="visually-hidden">Error</span></span>
              <p>{{ errorFile }}</p>
            </div>
          }
          
        </div>
    
      </div>
    } @else {
      <div class="row mt-5 justify-content-center p-5">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
        </div>
      </div>
    }
    
    <hr class="mt-4 mb-4" />
    <app-service-settings></app-service-settings>
  
    <hr class="mt-4 mb-4" />
  
    <div class="privileges">
      <div>
        <!-- privileges -->
        <h4>{{ 'pages.dashboard.user.privileges' | translate }}</h4>
  
        @if (tenants.length > 1) {
          <table class="table table-striped-columns text-center">
            <caption class="visually-hidden">user and admin roles in tenants</caption>
            <thead>
              <tr>
                <td></td>
                <th id="user-header">{{ 'pages.generic.user' | translate }}</th>
                <th id="admin-header">{{ 'pages.dashboard.admin' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              @for (tenant of tenants; track tenant) {
                <tr>
                  <th scope="row" class="text-start">{{ tenant }}</th>
                  <td>
                    @if (userTenants.includes(tenant)) {
                      <fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon>
                    }
                  </td>
                  <td>
                    @if (adminTenants.includes(tenant)) {
                      <fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon>
                    }
                  </td>
                </tr>
              }
            </tbody>
          </table>
        } @else if (tenants.length === 1) {
          <ul>
            @if (userTenants.length === 1) {
              <li>{{ 'pages.dashboard.user.inUserGroup' | translate }}</li>
            }
            @if (adminTenants.length === 1) {
              <li>{{ 'pages.dashboard.user.inAdminGroup' | translate }}</li>
            }
          </ul>
        }
      </div>
    </div>
  
    <hr class="mt-4 mb-4" />
  
    <h4>{{ 'pages.user.roles' | translate }}</h4>
  
    <div class="roles">
      <!-- SOC user-->
      <table class="table table-striped-columns text-center">
        <caption class="visually-hidden">{{ 'pages.dashboard.user.socUser' | translate }}</caption>
        <tbody>
          @if (userRoles.SOC_User.length > 0) {
            <tr>
              <th colspan="2" class="text-start role-title">{{ 'pages.dashboard.user.socUser' | translate }}</th>
            </tr>
          }
          @if (userRoles.SOC_User.includes('Access_SOC_Role')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.accessSocRole' | translate }}
                <app-help message="{{ 'pages.user.accessSOCDesc' | translate }}"></app-help>
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
          @if (userRoles.SOC_User.includes('Access_SOC_Reports')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.accessSocReports' | translate }}
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
        </tbody>
      </table>
  
      <!-- CSOC user -->
      <table class="table table-striped-columns text-center">
        <caption class="visually-hidden">{{ 'pages.dashboard.user.csocUser' | translate }}</caption>
        <tbody>
          @if (userRoles.CyberSOC_User.length > 0) {
            <tr>
              <th colspan="2" class="text-start role-title">{{ 'pages.dashboard.user.csocUser' | translate }}</th>
            </tr>
          }
          @if (userRoles.CyberSOC_User.includes('Access_CSOC_Role')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.accessCsocRole' | translate }}
                <app-help message="{{ 'pages.user.accessCSOCDesc' | translate }}"></app-help>
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
          @if (userRoles.CyberSOC_User.includes('Access_CSOC_Reports')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.accessCsocReports' | translate }}
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
        </tbody>
      </table>
  
      <!-- Report Viewing Permissions -->
      <table class="table table-striped-columns text-center">
        <caption class="visually-hidden">{{ 'pages.user.reportViewingPermissions' | translate }}</caption>
        <tbody>
          @if (userRoles.Report_Viewing_Permissions.length > 0) {
            <tr>
              <th colspan="2" class="text-start role-title">{{ 'pages.user.reportViewingPermissions' | translate }}</th>
            </tr>
          }
          @if (userRoles.Report_Viewing_Permissions.includes('Access_Internal_Reports')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.accessInternalReports' | translate }}
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
          @if (userRoles.Report_Viewing_Permissions.includes('Access_Confidential_Reports')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.accessConfidentialReports' | translate }}
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
          @if (userRoles.Report_Viewing_Permissions.includes('Access_Restricted_Reports')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.accessRestrictedReports' | translate }}
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
        </tbody>
      </table>
  
      <!-- Permitted Actions -->
      <table class="table table-striped-columns text-center">
        <caption class="visually-hidden">{{ 'pages.user.permittedActions' | translate }}</caption>
        <tbody>
          @if (userRoles.Permitted_Actions.length > 0) {
            <tr>
              <th colspan="2" class="text-start role-title">{{ 'pages.user.permittedActions' | translate }}</th>
            </tr>
          }
          @if (userRoles.Permitted_Actions.includes('Access_Change_Request')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.accessChangeRequest' | translate }}
                <app-help message="{{ 'pages.user.accessCRDesc' | translate }}"></app-help>
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
          @if (userRoles.Permitted_Actions.includes('Create_Change_Request')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.createChangeRequest' | translate }}
                <app-help message="{{ 'pages.user.createCRDesc' | translate }}"></app-help>
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
          @if (userRoles.Permitted_Actions.includes('Access_Service_Request')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.accessServiceRequest' | translate }}
                <app-help message="{{ 'pages.user.accessSRDesc' | translate }}"></app-help>
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
          @if (userRoles.Permitted_Actions.includes('Create_Service_Request')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.createServiceRequest' | translate }}
                <app-help message="{{ 'pages.user.createSRDesc' | translate }}"></app-help>
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
          @if (userRoles.Permitted_Actions.includes('Access_Incident')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.accessIncident' | translate }}
                <app-help message="{{ 'pages.user.accessIncDesc' | translate }}"></app-help>
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
          @if (userRoles.Permitted_Actions.includes('Create_Incident')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.createIncident' | translate }}
                <app-help message="{{ 'pages.user.createIncDesc' | translate }}"></app-help>
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
        </tbody>
      </table>
  
      <!-- Additionnal Permissions -->
      <table class="table table-striped-columns text-center">
        <caption class="visually-hidden">{{ 'pages.dashboard.user.additionalPermissions' | translate }}</caption>
        <tbody>
          @if (userRoles.Report_Viewing_Permissions.length > 0) {
            <tr>
              <th colspan="2" class="text-start role-title">{{ 'pages.dashboard.user.additionalPermissions' | translate }}</th>
            </tr>
          }
          @if (userRoles.Additional_Permissions.includes('WorldWatch')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.worldwatchPermission' | translate }}
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
          @if (userRoles.Additional_Permissions.includes('MicroSOCXDR')) {
            <tr>
              <th scope="row" class="text-start fw-normal">
                {{ 'pages.dashboard.user.microSocXdrPermission' | translate }}
              </th>
              <td><fa-icon [icon]="['fas', 'check']" class="p-1"></fa-icon></td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  
  </div>
}

<br />
<br />
