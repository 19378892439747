import { Component, ElementRef, EventEmitter, input, InputSignal, Output, ViewChild } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'confirm',
  standalone: true,
  imports: [
    TranslateModule,
  ],
  templateUrl: './confirm.component.html',
  styleUrls: ['../_styles/dialog.component.scss']
})
export class ConfirmDialogComponent {
  @ViewChild('dismissBtn')
  private readonly dismissBtn: ElementRef;

  /**
   * @public
   * @desc The target element to be closed when the dialog is closed.
   */
  public readonly target: InputSignal<string> = input.required<string>();

  /**
   * @public
   * @desc The message to be displayed in the confirmation dialog.
   */
  public readonly message: InputSignal<string> = input.required<string>();

  /**
   * @public
   * @desc The title of the confirmation dialog.
   */
  public readonly title: InputSignal<string> = input<string>(
    'pages.cybersocxdr.dialog.confirm.title'
  );

  /**
   * @public
   * @desc The label of the dismiss button.
   */
  public readonly dismissLabel: InputSignal<string> = input<string>(
    'pages.cybersocxdr.dialog.confirm.dismiss'
  );

  /**
   * @public
   * @desc The label of the confirm button.
   */
  public readonly confirmLabel: InputSignal<string> = input<string>(
    'pages.cybersocxdr.dialog.confirm.confirm'
  );

  /**
   * @public
   * @desc Emits when the confirm button is clicked.
   */
  @Output()
  public readonly onConfirm: EventEmitter<void> = new EventEmitter<void>();

  /**
   * @protected
   * @desc Handles the confirm button click event.
   */
  protected onConfirmClick(): void {
    // ? Close the dialog by clicking on the 'Dismiss Button'
    this.dismissBtn.nativeElement.click();
    this.onConfirm.emit();
  }
}
