import { Component } from '@angular/core';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { LocalStorageItem } from '../shared/model/shared-items';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-not-assigned',
  templateUrl: './not-assigned.component.html',
  styleUrls: ['./not-assigned.component.scss']
})
export class NotAssignedComponent {

  public removeTenant = true;

  constructor(
    private userAuthService: UserAuthService,
    private activateRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activateRoute.queryParams.subscribe(params => {
      if(params['refresh'] && params['refresh'] === 'disabled') {
        this.removeTenant = false;
      }
    });
   }

  logout() {
    this.userAuthService.logout();
  }

  /**
   * sometime selectedTenant can be desynchronized when selected tenant has been removed
   * remove seletectedTenant in local storage then reload app
   */
  refreshSession() {
    if(this.removeTenant) {
      localStorage.removeItem(LocalStorageItem.oktaSelectedTenant);
    }
    
    this.router.navigateByUrl('/dashboard').then(() => {
      window.location.reload();
    });
    
  }
}
