import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EthicalHackingContactDto } from 'src/app/shared/services/ethicalhacking/model/ethicalHackingDtos';

@Component({
  selector: 'app-ethicalhacking-contacts-table',
  templateUrl: './contacts-table.component.html',
  styleUrls: ['./contacts-table.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class ContactsTableComponent {
  @Input() category: string;
  @Input() contacts: EthicalHackingContactDto[] = [];
}
