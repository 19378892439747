import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AssetManagementRoutingModule } from './assetmanagement-routing.module';
import { AppSharedModule } from '../app-shared.module';
import { AssetDashboardComponent } from './assetdashboard/assetdashboard.component';
import { AssetViewComponent } from './assetview/assetview.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LocationViewComponent } from './location-view/location-view.component';

@NgModule({
  declarations: [
    AssetDashboardComponent,
    AssetViewComponent
  ],
  imports: [
    CommonModule,
    AssetManagementRoutingModule,
    AppSharedModule,
    FormsModule,
    NgbModule,
    NgMultiSelectDropDownModule,
    InfiniteScrollModule,
    LocationViewComponent
  ],
  exports: [
    AssetDashboardComponent, AssetViewComponent, LocationViewComponent
  ],
  bootstrap: [
    AssetDashboardComponent, AssetViewComponent
  ]
})
export class AssetManagementModule { }
