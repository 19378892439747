<div class="threat-management-section">

  @if (!loading) {
    @for (cardsRow of cards; track cardsRow) {
      <div class="cards-row" [ngStyle]="getStyles(cardsRow)">
        @for (card of cardsRow; track card) {
          <div class="card">
            @switch (card.type) {
              @case (mesCardType.SIMPLE) {
                <!-- SIMPLE DATA -->
                <div class="simple-card">
                  <div class="card-header simple-content">
                    {{ card.titleKey | translate }}
                  </div>
                  <div class="card-body">
                    <div *ngFor="let item of card.data; let i = index">
                      <div *ngIf="i === 0; else inlineData" class="exergue mb-4">
                        <span class="exergue-value primary">
                            {{ item.value }}
                        </span>
                        <span>{{ item.textKey | translate }}</span>
                      </div>
                      <ng-template #inlineData>
                        <div class="exergue exergue-inline" >
                          <span
                            class="exergue-value secondary"
                            [ngStyle]="{'color': shiftedColors[i-1]}">
                              {{ item.value }}
                          </span>
                          <span>{{ item.textKey | translate }}</span>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              }
              @case (mesCardType.GRAPH) {
                <!-- GRAPHS -->
                <div class="graph-card" *ngIf="card.type === mesCardType.GRAPH">
                  <div class="card-header multi-content">
                    {{ card.titleKey | translate }}
                    <select class="filter form-select"
                      name="filter-period"
                      id="filter-period"
                      aria-label="Filter by period"
                      [(ngModel)]="incidentsByPriorityDateSelected"
                      (change)="loadIncidentsByPriority()">
                      @for (dateOption of incidentsByPriorityDateOptions; track dateOption.value;) {
                        <option value="{{dateOption.value}}">{{ dateOption.textKey | translate }}</option>
                      }
                    </select>
                  </div>
                  <div class="card-body">
                    <highcharts-chart class="chart"
                      [Highcharts]="highcharts"
                      [options]="card.graphOptions"
                      [style.width]="width"
                      [style.height]="height">
                    </highcharts-chart>
                  </div>
                </div>    
              }
              @case (mesCardType.TABLE) {
                <!-- TABLES -->
                <app-sentinel-one-table [card]="card"></app-sentinel-one-table>
              }
            }
          </div>
        }
      </div>
    }
  } @else {
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
  }
  
</div>
