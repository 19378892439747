import { Component, inject } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Tab } from '../../components/tabbar/tabbar.component';
import { XtendedFiltersService } from '../../xtendedfilters.service';

@Component({
  selector: 'app-xtended-my-space-page',
  templateUrl: './my-space.component.html',
  styleUrl: './my-space.component.scss'
})
export class XtendedSOCMySpaceComponent {
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly router: Router = inject(Router)
  private readonly xtendedFiltersService: XtendedFiltersService = inject(XtendedFiltersService);

  public readonly tabs: Tab[] = [
    {
      titleKey: 'pages.cybersocxdr.mySpace.contract',
      value: 'contract',
      url: '/xtended-soc/my-space/contract'
    },
    {
      titleKey: 'pages.cybersocxdr.mySpace.settings.title',
      value: 'settings',
      url: '/xtended-soc/my-space/settings'
    }
  ];

  public tab: string;
  private readonly availableTabs: string[] = ['contract', 'settings'];

  public async ngOnInit(): Promise<void> {
    this.manageEntitiesTabs();
    this.manageTabRedirection();
  }

  /**
   * @private
   * @desc Check if the user has entities or not.
   */
  private manageEntitiesTabs(): void {
    const haveEntities: boolean = this.xtendedFiltersService.clientInfo.haveEntities;

    if (!haveEntities) {
      this.availableTabs.push('my-files');
      this.tabs.push(
        {
          titleKey: 'pages.cybersocxdr.mySpace.myFiles',
          value: 'my-files',
          url: '/xtended-soc/my-space/my-files',
        }
      );
    }
  }

  /**
   * @private
   * @desc Manage the redirection to the right tab if the user is not in the right one.
   */
  private manageTabRedirection(): void {
    this.route.paramMap
      .subscribe((params: ParamMap): void => {
        const tab: string = params.get('tab');

        if (this.availableTabs.includes(tab)) {
          this.tab = tab;
        } else {
          this.router.navigateByUrl(this.tabs[0].url);
        }
      });
  }
}
