import { Component } from '@angular/core';
import { Perimeter } from './model/PerimeterModels';
import { TranslateModule } from '@ngx-translate/core';
import { EthicalHackingPerimeterBoxComponent } from './perimeter-box/perimeter-box.component';
import { EthicalHackingPerimeterTitleBoxComponent } from './perimeter-title-box/perimeter-title-box.component';

@Component({
  selector: 'app-ethicalhacking-perimeters',
  templateUrl: './ethical-hacking-perimeters.component.html',
  styleUrls: ['./ethical-hacking-perimeters.component.scss'],
  standalone: true,
  imports: [TranslateModule, EthicalHackingPerimeterBoxComponent, EthicalHackingPerimeterTitleBoxComponent],
})
export class EthicalHackingPerimetersComponent {
  private readonly PERIMETER_BOX_CLASS = 'perimeter-box-';
  protected boxesNumber = [1, 2, 3, 4];
  perimeters: Perimeter[] = [
    {
      title: 'external',
      class: 'perimeter-external',
      boxes: [
        {
          textKey: 'external.infrastructure',
          class: `${this.PERIMETER_BOX_CLASS}external`,
          hasDescription: true,
          hasIcon: true,
          isFaIcon: false,
          icons: ['server-storage-array.svg'],
        },
        {
          textKey: 'external.application',
          class: `${this.PERIMETER_BOX_CLASS}external`,
          hasDescription: true,
          hasIcon: true,
          isFaIcon: false,
          icons: ['desktop-web.svg', 'mobile-applications.svg'],
        },
        {
          textKey: 'external.discovery',
          class: `${this.PERIMETER_BOX_CLASS}external`,
          hasDescription: true,
          hasIcon: true,
          isFaIcon: false,
          icons: ['radar.svg'],
        },
        {
          textKey: 'external.intrusion-test',
          class: `${this.PERIMETER_BOX_CLASS}external`,
          hasDescription: false,
          hasIcon: true,
          isFaIcon: false,
          icons: ['office365.svg'],
        },
      ],
    },
    {
      title: 'internal',
      class: 'perimeter-internal',
      boxes: [
        {
          textKey: 'internal.network',
          class: `${this.PERIMETER_BOX_CLASS}internal`,
          hasDescription: true,
          hasIcon: true,
          isFaIcon: false,
          icons: ['data-centre.svg', 'laptop-connected.svg'],
        },
        {
          textKey: 'internal.password-assessments',
          class: `${this.PERIMETER_BOX_CLASS}internal`,
          hasDescription: true,
          hasIcon: true,
          isFaIcon: false,
          icons: ['internet-security-password.svg'],
        },
        {
          textKey: 'internal.wifi',
          class: `${this.PERIMETER_BOX_CLASS}internal`,
          hasDescription: false,
          hasIcon: true,
          isFaIcon: true,
          icons: ['wifi'],
        },
      ],
    },
    {
      title: 'behavioral',
      class: 'perimeter-behavioral',
      boxes: [
        {
          textKey: 'behavioral.phishing',
          class: `${this.PERIMETER_BOX_CLASS}behavioral`,
          hasDescription: false,
          hasIcon: true,
          isFaIcon: true,
          icons: ['envelope'],
        },
        {
          textKey: 'behavioral.physical-access',
          class: `${this.PERIMETER_BOX_CLASS}behavioral`,
          hasDescription: false,
          hasIcon: true,
          isFaIcon: true,
          icons: ['hand-point-right', 'key'],
        },
        {
          textKey: 'behavioral.social-engineering',
          class: `${this.PERIMETER_BOX_CLASS}behavioral`,
          hasDescription: false,
          hasIcon: true,
          isFaIcon: false,
          icons: ['call.svg', 'usb-key.svg', 'my-skills.svg'],
        },
      ],
    },
    {
      title: 'expertise',
      class: 'perimeter-expertise',
      boxes: [
        {
          textKey: ['expertise.passi', 'expertise.red-team'],
          isExpertiseContainer: true,
          class: `${this.PERIMETER_BOX_CLASS}expertise`,
        },
        {
          textKey: ['expertise.purple-team', 'expertise.cloud-security'],
          isExpertiseContainer: true,
          class: `${this.PERIMETER_BOX_CLASS}expertise`,
        },
        {
          textKey: ['expertise.ot', 'expertise.iot'],
          isExpertiseContainer: true,
          class: `${this.PERIMETER_BOX_CLASS}expertise`,
        },
        {
          textKey: ['expertise.banking'],
          class: `${this.PERIMETER_BOX_CLASS}expertise`,
        },
      ],
    },
  ];
}
