export class BreadcrumbUtils {

  // ==== URL ====

  /**
   * @public
   * @desc Removes the leading slash from a URL, if present.
   *
   * @param url - The URL to normalize.
   *
   * @returns The URL without a leading slash.
   */
  public static normalizeUrl(url: string): string {
    return url.startsWith('/') ? url.slice(1) : url;
  }

  /**
   * @public
   * @desc Splits the URL into segments by '/' and filters out empty segments.
   *
   * @param url - The URL to split into segments.
   *
   * @returns Array of URL segments.
   */
  public static splitUrlIntoSegments(url: string): string[] {
    return url.split('/').filter(Boolean);
  }

  /**
   * @public
   * @desc Generates an array of partial paths from URL segments.
   *
   * @param segments - Array of URL segments.
   *
   * @returns Array of partial paths constructed from the segments.
   */
  public static generatePartialPaths(segments: string[]): string[] {
    return segments.map((_, index: number): string => `/${segments.slice(0, index + 1).join('/')}`);
  }

  // ====  ROUTE ====

  /**
   * @public
   * @desc Determines if the full path matches the route path definition.
   *
   * @param fullPath - The complete URL path.
   * @param routePath - The route path which may include parameter tokens.
   *
   * @returns True if the full path matches the route definition, false otherwise.
   */
  public static isRouteMatch(fullPath: string, routePath: string): boolean {
    const fullSegments: string[] = fullPath.split('/').slice(1);
    const routeSegments: string[] = routePath.split('/').filter(Boolean);

    return (
      fullSegments.length === routeSegments.length &&
      routeSegments.every((segment: string, index: number): boolean =>
        segment.startsWith(':') || segment === fullSegments[index]
      )
    );
  }

  /**
   * @public
   * @desc Extracts route parameters from a full URL path based on the parameter definitions in the route path.
   *
   * @param fullPath - The full URL path.
   * @param routePath - The route path containing parameter tokens (e.g., ':id').
   *
   * @returns An object mapping route parameter names to their corresponding values.
   */
  public static extractRouteParameters(fullPath: string, routePath: string): Record<string, string> {
    const fullSegments: string[] = fullPath.split('/').filter(Boolean);
    const routeSegments: string[] = routePath.split('/').filter(Boolean);

    return routeSegments.reduce<Record<string, string>>(
      (params: Record<string, string>, segment: string, index: number): Record<string, string> => {
        if (segment.startsWith(':')) {
          params[segment.slice(1) as string] = fullSegments[index] as string;
        }
        return params;
      },
      {} as Record<string, string>
    );
  }
}
