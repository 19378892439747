<button class="generate-report btn rounded h-100"
        (click)="generate()"
        [disabled]="isGenerationInProgress">

  @if (isGenerationInProgress) {
    <span class="spinner-border spinner-border-sm me-2"></span>
  }

  {{ 'pages.cybersocxdr.header.generateReport' | translate }}
</button>
