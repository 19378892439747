import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY } from 'rxjs';
import { AppSharedModule } from 'src/app/app-shared.module';
import { GenericTableComponent } from 'src/app/ethicalhacking/components/generic-table/generic-table.component';
import { CardComponent } from "src/app/shared/components/xtended/card/card.component";
import { OpenClosedCardsComponent } from 'src/app/shared/components/xtended/openclosedcards/openclosedcards.component';
import { SeverityComponent } from "src/app/shared/components/xtended/severity/severity.component";
import { InsightData } from 'src/app/shared/model/cybersocxdr/insight';
import { VulnerabilitiesCountDto } from 'src/app/shared/services/ethicalhacking/model/ethicalHackingDtos';
import { SearchCriteria, TableColumn } from 'src/app/shared/services/ethicalhacking/model/paginated-model';
import { SORTABLE_COLUMNS, VulnerabilityRow, VulnerabilitySortBy, VulnerabilityStatus, VulnerabilityTableItemDto } from 'src/app/shared/services/ethicalhacking/model/vulnerabilities-model';
import { VulnerabilitiesEthicalHackingService } from 'src/app/shared/services/ethicalhacking/vulnerabilities.service';
import { FindingStatus } from 'src/app/shared/utils/xtended/constants.utils';
@Component({
  selector: 'app-vulnerabilities',
  standalone: true,
  imports: [
    GenericTableComponent,
    CommonModule,
    SeverityComponent,
    CardComponent,
    OpenClosedCardsComponent,
    AppSharedModule,
    TranslateModule
  ],
  templateUrl: './vulnerabilities.component.html',
  styleUrl: './vulnerabilities.component.scss'
})
export class EthicalhackingVulnerabilitiesComponent implements OnInit {
  private readonly router: Router = inject(Router);
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly vulnerabilitiesService: VulnerabilitiesEthicalHackingService = inject(VulnerabilitiesEthicalHackingService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public vulnerabilities: VulnerabilityTableItemDto[] = [];
  public openVulnerabilitiesCount: number = 0;
  public closedVulnerabilitiesCount: number = 0;
  public tableColumns: TableColumn[] = [];
  public activeTab: VulnerabilityStatus = 'open';
  private readonly CURRENT_PAGE: number = 1;
  private readonly PAGE_SIZE: number = 10;
  public total: number = 0;
  public searchCriteria: SearchCriteria = {
    page: this.CURRENT_PAGE,
    pageSize: this.PAGE_SIZE,
    sortColumn: '',
    sortDirection: '',
    status: 'open'
  };

  private readonly OPEN_FINDINGS_STATUS: FindingStatus[] = [FindingStatus.UNRESOLVED, FindingStatus.RETEST_PENDING];
  private readonly CLOSED_FINDINGS_STATUS: FindingStatus[] = [FindingStatus.RESOLVED, FindingStatus.ACCEPTED_RISK];

  protected readonly INSIGHTS_INTERVAL_TYPE = 'month';
  protected readonly INSIGHTS_LIMIT = 5;

  protected openVulnerabilitiesBySeverity: VulnerabilitiesCountDto[] = [];

  public ethicalHackingInsights: InsightData[] = [];
  public closedEthicalHackingInsights: InsightData[] = [];
  public async ngOnInit(): Promise<void> {
    await Promise.all([
      this.loadVulnerabilities(),
      this.loadCounters(),
      this.loadInsights(),
      this.loadClosedInsights()
    ]);
  }

  private async loadCounters(): Promise<void> {
    this.vulnerabilitiesService.getReportedVulnerabilities(this.OPEN_FINDINGS_STATUS)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((vulnerabilities) => {
        this.openVulnerabilitiesBySeverity = vulnerabilities;
        this.openVulnerabilitiesCount = this.calculateOpenClosedCount(vulnerabilities);
      });

    this.vulnerabilitiesService.getReportedVulnerabilities(this.CLOSED_FINDINGS_STATUS)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((vulnerabilities) => {
        this.closedVulnerabilitiesCount = this.calculateOpenClosedCount(vulnerabilities);
      });
  }

  private async loadInsights(): Promise<void> {
    this.vulnerabilitiesService.getOpenVulnerabilitiesInsights(this.INSIGHTS_INTERVAL_TYPE, this.INSIGHTS_LIMIT)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((insights) => {
        this.ethicalHackingInsights = this.formatInsightsData(insights);
      });
  }

  private async loadClosedInsights(): Promise<void> {
    this.vulnerabilitiesService.getClosedVulnerabilitiesInsights(this.INSIGHTS_INTERVAL_TYPE, this.INSIGHTS_LIMIT)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((insights) => {
        this.closedEthicalHackingInsights = this.formatInsightsData(insights);
      });
  }

  private calculateOpenClosedCount(totalSeverities: VulnerabilitiesCountDto[]): number {
    const sumVulnerabilityCount = (acc: number, curr: VulnerabilitiesCountDto) => acc + curr.count;
    return totalSeverities.reduce(sumVulnerabilityCount, 0);
  }

  private async loadVulnerabilities(): Promise<void> {
    this.vulnerabilitiesService['getVulnerabilities' + (this.activeTab === 'open' ? 'Open' : 'Closed')]({
      page: this.searchCriteria.page,
      pageSize: this.searchCriteria.pageSize,
      sortBy: this.searchCriteria.sortColumn,
      sortDir: this.searchCriteria.sortDirection
    }).pipe(
      catchError(error => {
        console.error('Error loading vulnerabilities:', error);
        return EMPTY;
      })
    ).subscribe(response => {
      this.vulnerabilities = Array.isArray(response.items)
        ? response.items.map(vuln => {
          const { remediation, ...rest } = vuln;
          return rest;
        })
        : [];

      this.initializeColumns();
      this.total = response.total;
    });

  }

  public async onPageChange(page: number): Promise<void> {
    this.searchCriteria.page = page;
    await this.loadVulnerabilities();
  }

  public onSort(event: { column: string, direction: 'asc' | 'desc' }): void {
    this.searchCriteria.sortColumn = event.column;
    this.searchCriteria.sortDirection = event.direction;
    this.loadVulnerabilities();
  }

  public async switchTab(tab: VulnerabilityStatus): Promise<void> {
    this.activeTab = tab;
    this.searchCriteria.status = tab;
    this.searchCriteria.page = 1;
    this.loadVulnerabilities();
  }

  private initializeColumns(): void {
    this.tableColumns = [];
    if (this.vulnerabilities.length > 0) {
      const firstItem = this.vulnerabilities[0];
      this.tableColumns = Object.keys(firstItem)
        .filter(key => key !== 'id' && key !== 'assessment_id')
        .map(key => this.createColumnDefinition(key));
    }
  }

  public isSortableColumn(column: string): boolean {
    return SORTABLE_COLUMNS.includes(column as VulnerabilitySortBy);
  }

  private createColumnDefinition(key: string): TableColumn {
    const baseColumn: TableColumn = {
      key: key,
      label: key,
      sortable: this.isSortableColumn(key),
      size: '2'
    };

    return {
      ...baseColumn,
      ...this.getSpecialColumnProperties(key)
    };
  }

  private getSpecialColumnProperties(key: string): Partial<TableColumn> {
    const specialColumns: { [key: string]: Partial<TableColumn> } = {
      created_at: {
        format: (value: string) => new Date(value).toLocaleDateString(this.translateService.currentLang)
      },
      severity: {
        html: (value: string) => {
          const translationKey = `pages.ethicalhacking.dashboard.vulnerabilities.severity.${value.toLowerCase()}`;
          return `<span class="severity-pill severity-${value.toLowerCase()} px-3 py-1 rounded-pill d-inline-block">${this.translateService.instant(translationKey)}</span>`;
        }
      },
      priority: {
        html: (value: string) => {
          const priorityMap: { [key: string]: number } = {
            'low': 1,
            'medium': 2,
            'high': 3,
            'urgent': 4
          };
          const starCount = priorityMap[value.toLowerCase()] || 0;
          return `<span class="d-flex gap-1 fw-bold">${Array(starCount).fill('+').join('')}</span>`;
        }
      },
      status: {
        html: (value: string) => {
          return `<span class="status-pill status-${value.toLowerCase()} px-3 py-1 rounded-pill d-inline-block">${value}</span>`;
        }
      },
      remediation_validated: {

        html: (value: string) => {
          return `<span class="review-status-pill review-status-${value.toLowerCase()} px-3 py-1 rounded-pill d-inline-block">${value}</span>`;
        }
      },
      remediation_date: {
        format: (value: string) => new Date(value).toLocaleDateString(this.translateService.currentLang)
      },
      assessment_name: {
        html: (value: string) => {
          return `<a class="text-decoration-none">${value}</a>`;
        }
      }
    };
    return specialColumns[key] || {};
  }

  public onRowClick(vulnerability: VulnerabilityRow): void {
    if (vulnerability.row?.assessment_id && vulnerability.column.key === 'assessment_name') {
      this.router.navigate(['/ethical-hacking/assessments', vulnerability.row.assessment_id]);
    } else if (vulnerability.row?.id) {
      this.router.navigate(['../vulnerabilities', vulnerability.row.id], {
        relativeTo: this.route
      });
    }
  }

  public async onPageSizeChange(newSize: number): Promise<void> {
    this.searchCriteria.pageSize = newSize;
    await this.loadVulnerabilities();
  }

  // ----------------- INSIGHTS DATA FORMAT --------------

  private formatInsightsData(insights: InsightData[]): InsightData[] {
    return insights.map((insight) => ({
      ...insight,
      title: insight.title ? `pages.ethicalhacking.dashboard.insights.${insight.title}` : '',
    }));
  }
}
