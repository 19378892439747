import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { oktaAuthGuard } from '../app.guard';
import { LayoutComponent } from '../layout/layout/layout.component';
import { XtendedLayoutComponent } from '../layout/xtended/xtended-layout/xtended-layout.component';
import { CybersocxdrGuard } from './cybersocxdr.guard';
import { XtendedSOC4A3WComponent } from './pages/4A3W/4A3W.component';
import { XtendedSOCDashboardComponent } from './pages/dashboard/dashboard.component';
import { XtendedSOCEntityDetailComponent } from './pages/entities/detail/entity-detail.component';
import { XtendedSOCEntitiesComponent } from './pages/entities/entities.component';
import { XtendedSOCHomeComponent } from './pages/home/home.component';
import { XtendedSOCIncidentDetailsComponent } from './pages/incident-details/incident-details.component';
import { XtendedSOCIncidentsComponent } from './pages/incidents/incidents.component';
import { XtendedSOCMySpaceComponent } from './pages/my-space/my-space.component';
import { PortailReleaseNoteComponent } from './pages/portail-release-note/portail-release-note.component';
import { XtendedSOCSLAComponent } from './pages/sla/sla.component';
import { MyItComponent } from './pages/my-it/my-it.component';

const routes: Routes = [
  {
    path: 'xtended-soc',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard, CybersocxdrGuard],
    data: {
      displayBackToTop: false,
    },
    children: [
      {
        path: '',
        component: XtendedLayoutComponent,
        children: [
          { path: 'xtended-soc', redirectTo: '', pathMatch: 'full' },
          {
            path: 'dashboard/:tab',
            component: XtendedSOCDashboardComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
          {
            path: 'dashboard',
            redirectTo: 'dashboard/overview',
            pathMatch: 'full',
          },
          {
            path: 'sla/:tab',
            component: XtendedSOCSLAComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
          {
            path: 'sla',
            redirectTo: 'sla/notification',
            pathMatch: 'full',
          },
          {
            path: 'incidents',
            component: XtendedSOCIncidentsComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
          {
            path: '4A3W/:tab',
            component: XtendedSOC4A3WComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
          {
            path: '4A3W',
            redirectTo: '4A3W/4A',
            pathMatch: 'full',
          },
          {
            path: 'entities',
            component: XtendedSOCEntitiesComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
          {
            path: 'entities/:entityId',
            component: XtendedSOCEntityDetailComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
          {
            path: 'my-it/:tab',
            component: MyItComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
          {
            path: 'my-it',
            component: MyItComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
          {
            path: 'my-space',
            redirectTo: 'my-space/contract',
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
          {
            path: 'my-space/:tab',
            component: XtendedSOCMySpaceComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
          {
            path: 'incidents/:incidentId',
            component: XtendedSOCIncidentDetailsComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
          {
            path: 'release-notes',
            component: PortailReleaseNoteComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
          {
            path: '',
            component: XtendedSOCHomeComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
              displayBackToTop: false,
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CybersocxdrRoutingModule {}
