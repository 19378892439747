@if (loadingSignal) {
    <div class="row justify-content-center p-5">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
        </div>
    </div>
}

@if (!isWorldWatchUser && !loadingSignal) {
    <div class="row p-2">
        <div class="col-xl-1 col-lg-1 hidden-md"></div>
        <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12">
            <div style="padding: 1rem; border-color: #ff0000 !important; border: 0.125rem solid transparent;">
                <div>
                    <h3>{{ 'pages.worldwatch.noAccess.heading' | translate }}</h3>
                </div>
                <div>
                    <p [innerHTML]="'pages.worldwatch.noAccess.body' | translate"></p>
                </div>
            </div>
        </div>
    </div>
}

@if(advisory) {
    <div>
        <div class="head">
            <span class="title" [ngClass]="{'light-theme': !isDarkTheme}">{{createBlock.title}}</span>
        </div>
        <div class="signal-content">    
    
            <!-- TABS -->
            <div class="tab-content" id="nav-tabs-content">
                <ul role="tablist" aria-owns="analysis-tab appendices-tab comments-tab nav-tab4" class="nav nav-tabs"
                    id="nav-tab-with-nested-tabs">
                    <!-- analysis-->
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" [ngClass]="{'active': selectedTab === 'analysis'}" aria-current="page"
                            id="analysis-tab" href="#analysis-content" data-bs-toggle="tab"
                            data-bs-target="#analysis-content" role="tab" aria-controls="analysis-content"
                            aria-selected="true"
                            (click)="changeTab('analysis')">{{'pages.updates.signal.fieldTitles.analysisTab' | translate }}
                        </a>
                    </li>
                    <!-- updates -->
                    @if(advisory && advisory.contentBlocks.length > 1) {
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" [ngClass]="{'active': selectedTab === 'updates'}" id="comments-tab"
                                data-bs-toggle="tab" href="#comments-content" data-bs-target="#comments-content" role="tab"
                                aria-controls="comments-content" aria-selected="false" (click)="changeTab('updates')">{{
                                'pages.updates.signal.fieldTitles.commentsTab' | translate }}
                                <span class="tag no-border text-bg-primary ms-2" style="padding:0rem 0.5rem;"
                                    *ngIf="updateBlocks.length > 0">{{updateBlocks.length}}</span></a>
                        </li>
                    }
                    <!-- appendices -->
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" [ngClass]="{'active': selectedTab === 'appendices'}" id="appendices-tab"
                            data-bs-toggle="tab" href="#appendices-content" data-bs-target="#appendices-content" role="tab"
                            aria-controls="appendices-content" aria-selected="false" (click)="changeTab('appendices')">{{
                            'pages.updates.signal.fieldTitles.appendicesTab' | translate }}</a>
                    </li>
                </ul>
    
                <!-- ANALYSIS -->
                <div class="tab-pane fade show" [ngClass]="{'active': selectedTab === 'analysis'}" id="analysis-content"
                    role="tabpanel" aria-label="analysis-tab">
                    <div class="preserve-whitespace">
    
                        <!-- executiveSummary -->
                        @if(createBlock.executiveSummary) {
                            <div class="block">
                                <div class="header">
                                    <label class="title">{{ 'pages.updates.signal.fieldTitles.executiveSummary' | translate
                                        }}</label>
                                </div>
                                <div class="preserve-whitespace" [innerHTML]="createBlock.executiveSummary | formatHtml"></div>
                            </div>
                        }
    
                        <!-- whatYouWillHear -->
                        @if(createBlock.whatYouWillHear) {
                            <div class="block">
                                <div class="header">
                                    <label class="title">{{ 'pages.updates.signal.fieldTitles.whatYouWillHear' | translate
                                        }}</label>
                                </div>
                                <div class="preserve-whitespace" [innerHTML]="createBlock.whatYouWillHear | formatHtml"></div>
                            </div>
                        }
    
                        <!-- whatItMeans -->
                        @if(createBlock.whatItMeans) {
                            <div class="block">
                                <div class="header">
                                    <label class="title">{{ 'pages.updates.signal.fieldTitles.whatItMeans' | translate
                                        }}</label>
                                </div>
                                <div class="preserve-whitespace" [innerHTML]="createBlock.whatItMeans | formatHtml">
                                </div>
                            </div>
                        }
    
                        <!-- whatWeAreDoing -->
                        @if(createBlock.whatWeAreDoing) {
                            <div class="block">
                                <div class="header">
                                    <label class="title">{{ 'pages.updates.signal.fieldTitles.whatWeAreDoing' | translate
                                        }}</label>
                                </div>
                                <div class="preserve-whitespace" [innerHTML]="createBlock.whatWeAreDoing | formatHtml">
                                </div>
                            </div>
                        }
    
                        <!-- whatYouShouldDo -->
                        @if(createBlock.whatYouShouldDo) {
                            <div class="block">
                                <div class="header">
                                    <label class="title">{{ 'pages.updates.signal.fieldTitles.whatYouShouldDo' | translate
                                        }}</label>
                                </div>
                                <div class="preserve-whitespace" [innerHTML]="createBlock.whatYouShouldDo | formatHtml">
                                </div>
                            </div>
                        }
                    </div>
                    <button class="btn btn-primary mt-3 ms-auto d-flex" (click)="viewAppendice(0)">
                        {{ 'pages.updates.signal.fieldTitles.goToRelatedAppendices' | translate}}</button>
                </div>
    
                <!-- UPDATES -->
                <div class="tab-pane" [ngClass]="{'active': selectedTab === 'updates'}" id="comments-content"
                    role="tabpanel" aria-label="comments-tab">
    
                    <!-- header table -->
                    <table class="header">
                        <caption class="d-none">
                            {{ 'pages.updates.signal.fieldTitles.updates' | translate }}
                        </caption>
                        <thead>
                            <tr>
                                <th id="header-title" class="title">{{ 'pages.updates.signal.fieldTitles.title' | translate }}</th>
                                <th id="header-date" class="date" (click)="revertBlocks()">{{ 'pages.updates.signal.fieldTitles.release' |
                                    translate }}
                                    <fa-icon id="order" class="ms-2"
                                        [icon]="['fas', order === 'DESC' ? 'arrow-down-wide-short' : 'arrow-up-wide-short']"
                                        size="lg" data-toggle="tooltip" data-placement="top">
                                    </fa-icon>
                                </th>
                                <th id="header-related" class="related">{{ 'pages.updates.signal.fieldTitles.appendix' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody class="mt-2">
                            @for(block of updateBlocks; track block.index) {
                                <tr class="hover">
                                    <th id="body-title" class="title" [title]="block.title" (click)="scrollTo('update_' + block.index)">
                                        {{block.title}}</th>
                                    <th id="body-date" class="date">{{ block.timestampUpdated | offsetDatetimePipe }} GMT</th>
                                    <th id="body-related" class="related click">
                                        <fa-icon id="glass" [icon]="['fas', 'circle-right']" size="lg"
                                            ngbTooltip="{{ 'pages.updates.signal.fieldTitles.viewRelatedAppendice' | translate }}"
                                            (click)="viewAppendice(block.index)">
                                        </fa-icon>
                                    </th>
                                </tr>
                            }
                        </tbody>
                    </table>
    
                    <!-- updates -->
                    <div class="preserve-whitespace ">
                        @for(block of updateBlocks; track block.index) {
                            <div class="block separator">
                                <div class="header" [id]="'update_' + block.index">
                                    <div class="title">{{block.title}}</div>
                                    <div class="details"> {{ block.timestampUpdated | offsetDatetimePipe }} GMT</div>
                                </div>
                                <div class="ms-2">
                                    <div class="content" [innerHTML]="block.executiveSummary"></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
    
                <!-- APPENDICES -->
                <div class="tab-pane" [ngClass]="{'active': selectedTab === 'appendices'}" id="appendices-content"
                    role="tabpanel" aria-label="appendices-tab">
                    <div class="preserve-whitespace">
                        @for(block of advisory.contentBlocks; track block.index) {
                            <div class="block separator">
                                <div class="header" [id]="'appendice_' + block.index">
                                    <div class="title">{{block.title}}</div>
                                    <div class="details">{{ block.timestampUpdated | offsetDatetimePipe }} GMT</div>
                                </div>
        
                                <!-- External links -->
                                @if(getInternalLink(block).length > 0) {
                                    <div class="sources">
                                        <label>Internal Links</label>
                                        @for(source of block.sources; track source.id) {
                                            @if(source.type === internal) {
                                                <a [href]="source.url">{{source.url}}</a>
                                            }
                                        }
                                    </div>
                                }
        
                                <!-- External links -->
                                @if(getExternalLink(block).length > 0) {
                                    <div class="sources">
                                        <label>External Links</label>
                                        @for(source of block.sources; track source.id) {
                                            @if(source.type === external) {
                                                <a [href]="source.url">{{source.url}}</a>
                                            }
                                        }
                                    </div>
                                }
        
                                <!-- External links -->
                                @if(block.datalakeUrl) {
                                    <div class="sources">
                                        <label>Datalake</label>
                                        <a [href]="block.datalakeUrl.url">{{block.datalakeUrl.url}}</a>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
    
            </div>
            <div class="summary-table">
                <div class="summary-actions">
    
                    <!-- view tickets linked to this advisory -->
                    <button [disabled]="!haveTicket" class="btn btn-primary me-4" (click)="viewTickets()">{{
                        'pages.generic.viewTickets' | translate
                        }}</button>
    
                    <!-- log ticket linked to this advisory -->
                    <button class="btn btn-primary me-4" [routerLink]="['/support/log/']"
                        [queryParams]="{signalRef: advisory.id}">{{ 'pages.generic.logTicket' | translate
                        }}</button>
    
                    <!-- download advisory -->
                    <button class="btn btn-icon btn-primary" id="DropdownSupport" role="button" data-bs-toggle="dropdown"
                        aria-labelledby="closeBtnLabel" ngbTooltip="{{ 'pages.updates.signal.fieldTitles.downloadPDF' | translate }}">
                        @if (this.downloadingPDF()) {
                            <span class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
                            </span>                        
                        }@else {
                            <fa-icon [icon]="['fas', 'download']" size="lg" placement="top" data-toggle="tooltip"
                            data-placement="top"></fa-icon> 
                        }                 
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="DropdownSupport">
                        <li>
                            <a class="dropdown-item" (click)="downloadPDF('summary')">{{ 'pages.generic.downloadSummary' | translate }}</a>
                        </li>
                        <li>
                            <a class="dropdown-item" (click)="downloadPDF('full_details')">{{ 'pages.generic.downloadFullDetail' | translate }}</a>
                        </li>
                    </ul>
                </div>
    
                <!-- informations -->
                <div class="summary-informations">
                    <table class="signal table table-responsive">
                        <caption class="d-none">
                            {{ 'pages.updates.signal.fieldTitles.details' | translate }}
                        </caption>
                        <thead>
                            <tr>
                                <th style="width: 40%;">
                                    <p class="visually-hidden">{{ 'pages.generic.title' | translate }}</p>
                                </th>
                                <th style="width: 60%;">
                                    <p class="visually-hidden">{{ 'pages.generic.content' | translate }}</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- id -->
                            <tr>
                                <td>{{ 'pages.updates.signal.fieldTitles.id' | translate }}</td>
                                <td>{{advisory.id}}</td>
                            </tr>
    
                            <!-- urgency -->
                            <tr>
                                <td>{{ 'pages.updates.signal.fieldTitles.severity' | translate }}</td>
                                <td>
                                    <div placement="top">
                                        <div class="sticker" [ngClass]="severityStyle">
                                            <p class="mb-0">
                                                <span class="sticker-fs-xl">{{createBlock.severity}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
    
                            <!-- threatCategory -->
                            @if(advisory.threatCategory) {
                                <tr>
                                    <td>{{ 'pages.updates.signal.fieldTitles.threatCategory' | translate }}</td>
                                    <td>{{advisory.threatCategory}}</td>
                                </tr>
                            }
    
                            <!-- created -->
                            @if(advisory.timestampCreated) {
                                <tr>
                                    <td>{{ 'pages.updates.signal.fieldTitles.created' | translate }}</td>
                                    <td>{{advisory.timestampCreated | offsetDatetimePipe}} GMT</td>
                                </tr>
                            }
    
                            <!-- updated -->
                            @if(advisory.timestampUpdated) {
                                <tr>
                                    <td>{{ 'pages.updates.signal.fieldTitles.updated' | translate }}</td>
                                    <td>{{advisory.timestampUpdated | offsetDatetimePipe}} GMT</td>
                                </tr>
                            }
    
                        </tbody>
                    </table>
                </div>
                <!-- tags -->
                @if(allTags && allTags.length > 0) {
                    <div class="tags-section">
                        <div class="header">
                            <h3 class="title">{{ 'pages.updates.signal.fieldTitles.tags' | translate }}</h3>
                        </div>
        
                        <ul class="list-unstyled m-0 d-flex gap-2 flex-wrap m-0">
                            @for(tag of allTags; track tag) {
                                <li (click)="searchAdvisoriesByTag(tag.key)"><span class="tag">{{tag.label}}</span></li>
                            }
                        </ul>
                    </div>
                }
            </div>
        </div>
    </div>
}