<app-card>
  <div class="d-flex justify-content-between align-items-center mb-2">
    <h3 class="m-0">
      {{
        'pages.cybersocxdr.dashboard.fieldTitles.clientSideContacts' | translate
      }}
    </h3>
    @if(!isEditing){
    <button type="button" class="btn" (click)="enableEditMode()">
      Modify my contacts
    </button>
    } @else {
    <button type="button" class="btn" (click)="addNewContact()">
      {{ 'pages.cybersocxdr.dashboard.fieldTitles.addNew' | translate }}
    </button>
    <div>
      <button type="button" class="btn" (click)="cancel()">
        {{ 'pages.cybersocxdr.dashboard.fieldTitles.cancel' | translate }}
      </button>
      <button
        type="button"
        class="btn"
        (click)="save()"
        [disabled]="!contactFormGroup.valid"
      >
        {{ 'pages.cybersocxdr.dashboard.fieldTitles.save' | translate }}
      </button>
    </div>
    }
  </div>
  <div class="row px-2">
    <form [formGroup]="contactFormGroup">
      <table class="table">
        <caption class="d-none">
          {{
            'pages.cybersocxdr.dashboard.fieldTitles.clientSideContacts'
              | translate
          }}
        </caption>
        <thead>
          <tr>
            <th scope="col">
              {{ 'pages.cybersocxdr.dashboard.fieldTitles.name' | translate }}
            </th>
            <th scope="col">
              {{ 'pages.cybersocxdr.dashboard.fieldTitles.role' | translate }}
            </th>
            <th scope="col">
              {{
                'pages.cybersocxdr.dashboard.fieldTitles.phoneNumber'
                  | translate
              }}
            </th>
            <th scope="col">
              {{ 'pages.cybersocxdr.dashboard.fieldTitles.mail' | translate }}
            </th>
          </tr>
        </thead>
        @if (!isEditing) {
        <tbody>
          @for (clientContact of clientContacts; track clientContact.name) {
          <tr>
            <th scope="row">{{ clientContact.name | anonymise }}</th>
            <td>{{ clientContact.role | anonymise }}</td>
            <td>
              <a [href]="'tel:' + clientContact.number">{{
                clientContact.number | anonymise
              }}</a>
            </td>
            <td>
              <a [href]="'mailto:' + clientContact.mail">{{
                clientContact.mail | anonymise
              }}</a>
            </td>
          </tr>
          }
        </tbody>
        } @else { @for (formGroup of getFormArray().controls; track $index) {
        <tbody formArrayName="contacts">
          <tr [formGroupName]="$index">
            <td>
              <input type="text" class="form-control" formControlName="name" />
            </td>
            <td>
              <input type="text" class="form-control" formControlName="role" />
            </td>
            <td>
              <input
                type="text"
                placeholder="+33606060606"
                class="form-control"
                formControlName="number"
              />
            </td>
            <td>
              <input type="email" class="form-control" formControlName="mail" />
            </td>
            <td>
              <button
                class="btn btn-icon"
                (click)="removeContact($event, $index)"
              >
                <fa-icon [icon]="['fas', 'trash']" class="svg-margin" />
                <span class="visually-hidden">Secondary</span>
              </button>
            </td>
          </tr>
        </tbody>
        } }
      </table>
    </form>
  </div>
</app-card>
