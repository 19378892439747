import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { CybersocxdrService } from '../shared/services/xtendedsoc/cybersocxdr.service';
import { XtendedFiltersService } from "src/app/cybersocxdr/xtendedfilters.service";

@Injectable({
  providedIn: 'root',
})
export class CybersocxdrGuard implements CanActivate {
  private readonly router: Router = inject(Router);
  private readonly authService: UserAuthService = inject(UserAuthService);
  private readonly socService: CybersocxdrService = inject(CybersocxdrService);
  private readonly xtendedFiltersService: XtendedFiltersService = inject(XtendedFiltersService);

  /**
   * @public
   * @desc Determines if a route can be activated.
   */
  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return (await this.isAuthenticated()) || this.router.parseUrl('/not-authorized');
  }

  /**
   * @private
   * @desc Checks if the user is authenticated.
   *
   * @returns `true` if the user is authenticated, `false` otherwise.
   */
  private async isAuthenticated(): Promise<boolean> {
    const userClaims = await this.authService.loadUserDetails();
    if (!userClaims) {
      // ? User not authenticated
      return false;
    }

    return this.hasRequiredAccess(userClaims.groups as Array<string>);
  }

  /**
   * @private
   * @desc Checks if the user has the required access.
   *
   * @param groups The groups of the user.
   *
   * @returns `true` if the user has the required access, `false` otherwise.
   */
  private async hasRequiredAccess(groups: string[]): Promise<boolean> {
    for (const group of groups) {
      if (this.isMicroSOCXDRGroup(group) && (await this.hasXtendedSocAccess())) {
        return await this.ensureClientInfo();
      }
    }
    return true;
  }

  /**
   * @private
   * @desc Checks if a group is a MicroSOCXDR group.
   *
   * @param group The group to check.
   *
   * @returns `true` if the group is a MicroSOCXDR group, `false` otherwise.
   */
  private isMicroSOCXDRGroup(group: string): boolean {
    return group.startsWith('COMPROLE_') && group.split('_')[2] === 'MicroSOCXDR';
  }

  /**
   * @private
   * @desc Checks if the user has XtendedSoc access.
   *
   * @returns `true` if the user has XtendedSoc access, `false` otherwise.
   */
  private async hasXtendedSocAccess(): Promise<boolean> {
    return (
      this.authService.accesses.xtendedsoc.hasAccess ||
      Boolean(await this.socService.getServiceName().catch(() => false))
    );
  }

  /**
   * @private
   * @desc Ensures that the client info is loaded.
   *
   * @returns `true` if the client info is loaded, `false` otherwise.
   */
  private async ensureClientInfo(): Promise<boolean> {
    if (!this.xtendedFiltersService.clientInfo) {
      this.xtendedFiltersService.clientInfo = await this.socService.getClientInfo();
      if (!this.xtendedFiltersService.clientInfo) {
        return false;
      }
    }

    return true;
  }
}
