<div>
  <h3>
    <span class="ethical-hacking-title">{{
      'pages.ethicalhacking.contract.perimeters.ethicalHacking' | translate
    }}</span>
    - {{ 'pages.ethicalhacking.contract.perimeters.title' | translate }}
  </h3>
</div>

<div class="perimeters-grid">
  @for(perimeter of perimeters; track perimeter.title) {
  <div class="perimeter-column">
    <app-ethicalhacking-perimeters-title-box
      [title]="perimeter.title"
      [class]="perimeter.class"
    />

    @for(box of perimeter.boxes; track box.textKey) {
    <app-ethicalhacking-perimeters-box
      [textKey]="box.textKey"
      [class]="box.class"
      [hasDescription]="box?.hasDescription"
      [hasIcon]="box?.hasIcon"
      [isFaIcon]="box?.isFaIcon"
      [icons]="box?.icons"
      [isExpertiseContainer]="box?.isExpertiseContainer"
    />
    } @for(i of boxesNumber.slice(perimeter.boxes.length); track i) {
    <div class="perimeter-box-empty"></div>
    }
  </div>
  }
</div>
