<app-tabbar [currentTab]="tab" [tabs]="tabs"/>

<div class="container-fluid p-4 h-100">
  @if (tab === 'contract') {
    <app-xtended-contract-page/>
  } @else if (tab === 'my-files') {
    <app-xtended-my-files-page/>
  } @else if (tab === 'settings') {
    <app-settings/>
  }
</div>
