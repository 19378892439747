import { Injectable } from '@angular/core';
import { CssTheme, UserTheme } from '../model/shared-items';
import { AlertService } from './alert.service';
import { UserPreferenceService } from './userpreferences.service';
import { constants } from '../constants';
import { GPAlert } from '../model/alert';
import { Survey } from '../model/feedback';

@Injectable({
  providedIn: 'root'
})
export class ContextService {

  /**
  * LOADING
  */
  private _contextLoaded = false;
  public get contextLoaded() { return this._contextLoaded; }
  public set contextLoaded(value) { this._contextLoaded = value; }

  private _gpNotifications: GPAlert[];
  public get gpNotifications() { return this._gpNotifications; }
  public set gpNotifications(value) { this._gpNotifications = value; }

  /**
  * PORTAIL CONTEXT
  */
  private _surveys: Survey[];
  public get surveys(): Survey[] { return this._surveys; }
  public set surveys(value: Survey[]) { this._surveys = value; }

  /**
  * ROUTE DATAS
  */
  private _title;
  public get title() { return this._title; }
  public set title(value) { this._title = value; }

  private _subtitle;
  public get subtitle() { return this._subtitle; }
  public set subtitle(value) { this._subtitle = value; }

  private _fullWidth;
  public get fullWidth() { return this._fullWidth; }
  public set fullWidth(value) { this._fullWidth = value; }

  private _displayBackToTop;
  public get displayBackToTop() { return this._displayBackToTop; }
  public set displayBackToTop(value) { this._displayBackToTop = value; }

  private _alt;
  public get alt() { return this._alt; }
  public set alt(value) { this._alt = value; }

  private _isDashboard;
  public get isDashboard() { return this._isDashboard; }
  public set isDashboard(value) { this._isDashboard = value; }

  private _helpTip;
  public get helpTip() { return this._helpTip; }
  public set helpTip(value) { this._helpTip = value; }

  /**
  * USER THEME SETTINGS
  */
  private _theme;
  public get theme() { return this._theme; }
  public set theme(value) { this._theme = value; }

  private _userSelectedTheme;
  public get userSelectedTheme() { return this._userSelectedTheme; }
  public set userSelectedTheme(value) { this._userSelectedTheme = value; }

  public bsTheme = UserTheme.light;

  private _bgTheme = CssTheme.white;
  public get bgTheme(): CssTheme { return this._bgTheme; }
  public set bgTheme(value: CssTheme) { this._bgTheme = value; }

  private _navBarTheme = 'navbar-dark bg-black';
  public get navBarTheme(): string { return this._navBarTheme; }
  public set navBarTheme(value: string) { this._navBarTheme = value; }

  constructor(
    //private userAuthService: UserAuthService,
    private userPreferenceService: UserPreferenceService,
    private alertService: AlertService,
  ) {}

  /**
  * load all needed datas from the backend before display portal
  */
  async loadContext() {

    try {
      const [widgets] = await Promise.allSettled([
        this.userPreferenceService.loadPreferences(),
        this.userPreferenceService.initialize(),
        this.userPreferenceService.loadTenantAccesses(),
        this.userPreferenceService.loadChildTenantAccesses(),
        this.userPreferenceService.loadTenantInfo(),
        this.loadGPNotifications()
      ]);

      try {
        this.userPreferenceService.initializePreferences(widgets);
      } catch (error) {
        this.alertService.handlerError(error);
      }

      this.initTheme();
    } catch (error) {
      this.alertService.handlerError(error);
    } finally {
      this.contextLoaded = true;
    }
  }

  async loadGPNotifications() {
    this.gpNotifications = await this.userPreferenceService.getAllGPNotification();
  }

  public initTheme() {
    if (!this.userPreferenceService?.userPreferences?.theme) {
      // if no favorite theme initialize new one
      this.userSelectedTheme = UserTheme.light;
      this.userPreferenceService.userPreferencesUpdateSingle(constants.local.userPreferenceTheme, this.userSelectedTheme)
        .catch(err => {
          this.alertService.handlerError(err);
        });
    } else {
      this.userSelectedTheme = this.userPreferenceService.userPreferences.theme;
    }
    
    const isLightTheme = this.userSelectedTheme === UserTheme.light;
    this.bsTheme = isLightTheme ? UserTheme.light : UserTheme.dark;
    this._bgTheme = isLightTheme ? CssTheme.white : CssTheme.black;
    this._navBarTheme = 'navbar-dark bg-black';
    
  }

  /**
  * USER THEME FUNCTIONS
  */

  public isDarkTheme(): boolean {
    return this.userSelectedTheme !== UserTheme.light;
  }

  public getWidgetTheme(rank): UserTheme {
    const bool = rank % 2;
    return bool ? UserTheme.light : UserTheme.dark;
  }

}

