<div ngbDropdown class="filter-date-range dropdown">
  <button ngbDropdownToggle class="dropdown-toggle btn py-0 rounded">
    <div class="d-flex align-items-center gap-2">
      <fa-icon [icon]="['fas', 'calendar']" size="lg" class="icon-calendar" />

      <div class="d-flex flex-column align-items-start">
        <small class="text-muted mb-1">
          {{ 'pages.cybersocxdr.filters.dateRange' | translate }}
        </small>
        <div class="d-flex align-items-center gap-2">
          @if (displayCreated === 'custom' && criteria.createdFrom) {
          {{
            criteria.createdFrom
              | date : 'shortDate' : '' : translate.currentLang
          }}
          -
          {{
            criteria.createdTo | date : 'shortDate' : '' : translate.currentLang
          }}
          } @else if (displayCreated) {
          {{ 'pages.cybersocxdr.filters.' + displayCreated | translate }}
          }

          <fa-icon
            [icon]="['fas', 'caret-down']"
            size="lg"
            class="icon-arrow"
          />
        </div>
      </div>
    </div>
  </button>

  <div class="dropdown-menu rounded p-2" ngbDropdownMenu>
    @for (period of periodOptions; track period) {
    <button
      class="dropdown-item rounded"
      (click)="onChangePeriod(period)"
      [class.active]="displayCreated === period"
    >
      {{ 'pages.cybersocxdr.filters.' + period | translate }}
    </button>
    }
    <div class="dropdown-divider mb-2"></div>

    <div class="mb-2">
      <label class="form-label" for="startDate">
        {{ 'pages.cybersocxdr.filters.startDate' | translate }}
      </label>
      <input
        type="date"
        id="startDate"
        name="startDate"
        class="form-control rounded"
        [(ngModel)]="criteria.createdFrom"
        (change)="onDateChange()"
      />
    </div>

    <div class="mb-2">
      <label class="form-label" for="endDate">
        {{ 'pages.cybersocxdr.filters.endDate' | translate }}
      </label>
      <input
        type="date"
        id="endDate"
        name="endDate"
        class="form-control rounded"
        [lang]="translate.currentLang"
        [(ngModel)]="criteria.createdTo"
        (change)="onDateChange()"
      />
    </div>

    <button class="btn btn-primary w-100 rounded" (click)="onSearch()">
      {{ 'pages.cybersocxdr.filters.apply' | translate }}
    </button>
  </div>
</div>
