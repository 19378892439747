<div class="row">
  <div class="files-wrapper">
    <h3 class="files-title">
      {{ 'pages.cybersocxdr.mySpace.files.files' | translate }}
    </h3>
    @if (clientFiles.length) {
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <caption class="d-none">
            {{
              'pages.cybersocxdr.mySpace.files.caption' | translate
            }}
          </caption>
          <thead>
            <tr>
              <th class="ps-4">
                {{ 'pages.cybersocxdr.mySpace.files.nameHeader' | translate }}
              </th>
              <th class="text-end pe-4">
                {{
                  'pages.cybersocxdr.mySpace.files.actionsHeader' | translate
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            @for (file of clientFiles; track file.id) {
              <tr>
                <td class="files-name">{{ file.name }}</td>
                <td class="text-end download-button">
                  <button
                    class="btn btn-link text-primary"
                    (click)="onDownload(file)"
                  >
                    <fa-icon
                      [icon]="['fas', 'file-arrow-down']"
                      size="lg"
                    ></fa-icon>
                  </button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    } @else {
      <div class="card shadow-sm text-center py-4 rounded">
        <div class="card-body">
          <h3 class="mt-3 mb-4">
            {{ 'pages.cybersocxdr.mySpace.files.noData' | translate }}
          </h3>
        </div>
      </div>
    }
  </div>
</div>
