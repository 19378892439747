import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PortalSavedFilterDTO } from '../../model/cybersocxdr/portalSavedFilter';

const ROOT_URL = environment.endpoints.cybersocxdr;
const PORTAL_SAVED_FILTER_URL = ROOT_URL + 'portal-saved-filter';

@Injectable({
  providedIn: 'root',
})
export class PortalSavedFilterService {
  constructor(private readonly http: HttpClient) {}

  findByMail(): Observable<PortalSavedFilterDTO[]> {
    return this.http.get<PortalSavedFilterDTO[]>(`${PORTAL_SAVED_FILTER_URL}/mail`);
  }

  create(filter: PortalSavedFilterDTO): Observable<PortalSavedFilterDTO> {
    return this.http.post<PortalSavedFilterDTO>(PORTAL_SAVED_FILTER_URL, filter);
  }

  update(id: number, filter: PortalSavedFilterDTO): Observable<PortalSavedFilterDTO> {
    return this.http.put<PortalSavedFilterDTO>(`${PORTAL_SAVED_FILTER_URL}/${id}`, filter);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${PORTAL_SAVED_FILTER_URL}/${id}`);
  }
}
