import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { Case, Contact, Comment, CaseType, MESUpdate, MESActions } from 'src/app/shared/model/itsm';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import FileSaver from 'file-saver';
import { convertDatesFormat } from '../supportdashboard/supportdashboard.component';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Utilities } from 'src/app/shared/utilities';
import { TranslateService } from '@ngx-translate/core';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { environment } from 'src/environments/environment';
import { ACTIONS, PAGES, TYPES, constants } from 'src/app/shared/constants';
import { ContextService } from 'src/app/shared/services/context-service';
import { WorldWatchService } from 'src/app/shared/services/worldwatch.service';
import { AdvisoryFilter, AdvisoryListResponse } from 'src/app/shared/model/worldwatch';
import { ModalParameters } from 'src/app/shared/modals/modal';
import { ModalService } from 'src/app/shared/modals/modal.service';


@Component({
  selector: 'app-supportview',
  templateUrl: './supportview.component.html',
  styleUrls: ['./supportview.component.scss'],
})
export class SupportViewComponent implements OnInit {

  private casePropsToDisplay: string[] = [];
  private cases: Case[];
  private displayableCaseProps: string[];
  private salesForceTicket = false;

  public activeModal: NgbModalRef;
  public approvalAllowed = false;
  public assetDisplay: string;
  public attachments;
  public attachmentsLoading: boolean;
  public case: Case;
  public caseRef: string;
  public caseType = CaseType;
  public closeResult: string;
  public comment = '';
  public commentError: string;
  public commentLengthCheck = false;
  public commentLengthCheckError: string;
  public commentsMap: { [id: string]: Comment[] } = {};
  public commentSuccessful: boolean;
  public contactDisplay: string;
  public contacts: Contact[];
  public contactType: string;
  public hasWorldWatchAccess = false;
  public isClosed: boolean;
  public MESActions = {
    close: false,
    requestInvestigation: false
  };
  public linkedCases: Case[];
  public linkedCasesHeading: string;
  public loading = true;
  public mesClosure = MESActions.CLOSE_CASE;
  public mesInvestigation = MESActions.REQUEST_INVESTIGATION;
  public parentCase: Case;
  public postingComment: boolean;
  public secondContactDisplay: string;
  public startDateDisplay: Date;
  public updatedCustomerReference = '';
  public updatedWorldWatchReference = '';
  public worldWatchLoading: boolean;
  public worldWatchUpdateWarning: boolean;

  constructor(
    private alertService: AlertService,
    private itsmService: ItsmService,
    private ngbModalService: NgbModal,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private userActivity: UserActivityService,
    private userAuthService: UserAuthService,
    private worldwatchService: WorldWatchService,
    public contextService: ContextService
  ) {}

  ngOnInit(): void {

    this.route.params.subscribe((params) => {
      if (!this.caseRef || params.caseRef != this.caseRef) {
        this.caseRef = params.caseRef;
      }
    });
    this.initView();    
  }

  private initView() {

    // check user has access to world watch
    this.hasWorldWatchAccess = this.userAuthService.userDetails?.groups.indexOf(environment.world_watch_group) > -1;

    // init labels
    this.linkedCasesHeading = this.translateService.instant('pages.support.view.childCases');
    this.commentLengthCheckError = this.translateService.instant('pages.support.view.commentLength');

    this.loading = true;

      this.itsmService
        .case(this.caseRef)
        .then((res) => {
          this.initCase(res);
        })
        .catch((err) => {
          this.alertService.handlerError(err);
        })
        .finally(() => {
          this.loading = false;
        }) ;


      this.worldWatchLoading = false;
      this.userActivity.logActivity(TYPES.support, PAGES.supportView, ACTIONS.visit);
  }

  public initCase(res) {
    //Handling Parent case if it exists
    if (res.parentId) {
      this.itsmService.case(res.parentId).then((res1) => {
        this.linkedCasesHeading = this.translateService.instant('pages.support.view.parentCase');
        this.parentCase = convertDatesFormat(res1)[0];
        this.linkedCases = [this.parentCase];
      });
    }

    // update approval label if needed
    if(res.aproval === constants.itsm.approvalStates.approvalNotRequested) {
      this.case.approval = '--';
    }

    this.salesForceTicket = !res.caseNumber.includes('OCD');
    this.cases = convertDatesFormat(res);
    this.case = this.cases[0];
    const closedStatuses = [
      'Closed',
      'Closed as Duplicate',
      'Misdirected',
      'No fault found',
      'Rejected',
    ];
    this.isClosed = closedStatuses.indexOf(this.case.status) > -1;

    const isMESticket = constants.itsm.globalStates.open.includes(this.case.status) && this.case.type === CaseType.incident

    this.MESActions.close = isMESticket;
    // this.MESActions.requestInvestigation = isMESticket && !this.case.underInvestigation;

    //Handling child cases if they exist
    if (this.case.childTasks.length > 0) {
      this.linkedCases = this.case.childTasks;
      this.linkedCasesHeading = this.translateService.instant('pages.support.view.childCases');
    }

    this.handleFirstContactDisplay();
    this.handleSecondContactDisplay();
    this.handleAssetDisplay();
    this.handleStartDateDisplay();
    this.processComments();
    this.getDisplayedFields();
    this.attachments = this.case.attachments;
    if (this.case.worldWatchReference) {
      this.updatedWorldWatchReference = this.case.worldWatchReference;
    }

    this.approvalAllowed = this.case.approvalPermission && constants.itsm.approvalStates.authorizedApprovalStatus.includes(this.case.approval);
  }

  public reloadPage(id) {
    this.caseRef = id;
    this.initView();
  }

  public getDisplayedFields() {
    this.displayableCaseProps = [
      'caseNumber',
      'type',
      'priority',
      'status',
      'serviceContract',
      'asset',
      'customerReference',
      'contact',
      'secondContact',
      'worldWatchReference',
      'action',
      'action',
      'subAction',
      'attributes',
      'killchain',
      'actor',
      'whyFalsePositive',
      'whyFalsePositive',
      'whatFalsePositive',
      'whoFalsePositive',
      'description',
      'startDate',
      'createdDate',
      'openedBy',
      'lastModifiedDate',
      'updatedBy',
      'closeDate'
    ];

    this.casePropsToDisplay = this.displayableCaseProps.filter( prop => {
      if(!this.case.serviceContract && prop === 'serviceContract') {
        return false;
      }
      if(!this.case.worldWatchReference && prop === 'worldWatchReference') {
        return false;
      }
      if(prop === 'truePositive') {
        return false;
      }
      if(!this.case.action && prop ==='action') {
        return false;
      }
      if(!this.case.subAction && prop === 'subAction') {
        return false;
      }
      if(!this.case.attributes && prop === 'attributes') {
        return false;
      }
      if(!this.case.killchain && prop === 'killchain') {
        return false;
      }
      if(!this.case.actor && prop === 'actor') {
        return false;
      }
      if(!this.case.whyFalsePositive && prop === 'whyFalsePositive') {
        return false;
      }
      if(!this.case.whatFalsePositive && prop === 'whatFalsePositive') {
        return false;
      }
      if(!this.case.whoFalsePositive && prop === 'whoFalsePositive') {
        return false;
      }
      if(!this.case.startDate && prop === 'startDate') {
        return false;
      }
      if(!this.case.closedDate && prop === 'closedDate') {
        return false;
      }

      return true;
    });
  }

  isBanded(field: string) {
    return this.casePropsToDisplay.indexOf(field)%2 === 0;
  }

  getEmailString(email: string) {
    return `mailto:${email}?Subject=OCD Global Portal Solution - Case ${this.case.caseNumber} - ${this.case.subject}`;
  }

  private handleStartDateDisplay() {
    // startDate's display value
    if (this.case.startDate) {
      const dateAndTime = this.case.startDate.split(' ');
      const timeParts = dateAndTime[1].split(':');
      const  dateParts = dateAndTime[0].split('-');
      const tmpStartdate = new Date(parseInt(dateParts[0], 10), parseInt(dateParts[1], 10) -1, parseInt(dateParts[2], 10));
      tmpStartdate.setHours(parseInt(timeParts[0], 10));
      tmpStartdate.setMinutes(parseInt(timeParts[1], 10));
      tmpStartdate.setSeconds(parseInt(timeParts[2], 10));
      const timeZoneOffset = new Date(tmpStartdate).getTimezoneOffset();

      this.startDateDisplay = new Date(tmpStartdate);
      this.startDateDisplay.setMinutes(this.startDateDisplay.getMinutes() - timeZoneOffset);
    }

  }

  private handleAssetDisplay() {
    // Asset's display value:
    this.assetDisplay = 'n/a';
    if (this.case.asset) {
      if (this.case.asset.id) {
        this.itsmService.getAssetsByAssetId(this.case.asset.id).subscribe(
          {
            next:(response) => {
              if(response.length === 1 ) {
                this.assetDisplay = this.itsmService.getAssetFullname(response[0]);
              }
            },
            error:(err) => {
              this.alertService.handlerError(err);
            }
          }
        )
      }
    }
  }

  private handleFirstContactDisplay() {
    // Contact's display value:
    this.contactDisplay = 'n/a';
    if (this.case && this.case.contact) {
      this.contactDisplay = this.case.contact.fullName;
      if (this.case.contact.email) {
        this.contactDisplay =
          this.contactDisplay + ' - ' + this.case.contact.email;
      } else {
        this.itsmService.getCachedContacts().then((contacts) => {
          this.contacts = contacts;
          const contact = contacts.find(
            (c) => c.id === this.case.contact.id
          );
          if (contact) {
            this.contactDisplay = contact.fullName;
            if (contact.email) {
              this.contactDisplay =
                this.contactDisplay + ' - ' + contact.email;
            }
          }
        });
      }
    }
  }

  private handleSecondContactDisplay() {
    this.secondContactDisplay = 'n/a';
      if (this.case && this.case.secondContact) {
        this.secondContactDisplay = this.case.secondContact.fullName;
        if (this.case.secondContact.email) {
          this.secondContactDisplay =
            this.secondContactDisplay + ' - ' + this.case.secondContact.email;
        } else {
          this.itsmService.getCachedContacts().then((contacts) => {
            this.contacts = contacts;
            const secondcontact = contacts.find(
              (c) => c.id === this.case.secondContact.id
            );
            if (secondcontact) {
              this.secondContactDisplay = secondcontact.fullName;
              if (secondcontact.email) {
                this.secondContactDisplay =
                  this.secondContactDisplay + ' - ' + secondcontact.email;
              }
            }
          });
        }
      }
  }

  public postComment(silentUpdate = false) {
    this.commentLengthCheck = false;
    if (this.comment?.length > 3800 && this.salesForceTicket) {
      this.commentLengthCheck = true;
      return false;
    } else if (this.comment?.length > 0) {
      this.comment = Utilities.getStringXSSEncoded(this.comment);
      this.postingComment = true;

      const commentPostObj = {
        parentId: this.case.id,
        commentBody: this.comment,
        user: this.userAuthService.userDetails.email,
        silentUpdate,
      };
      this.itsmService.caseComment(commentPostObj).then((res) => {
        if (res?.map.success) {
          // reload case
          this.itsmService
            .case(this.caseRef)
            .then((response) => {
              this.comment = '';
              this.commentsMap = {};
              this.cases = convertDatesFormat(response);
              this.case = this.cases[0];
              this.postingComment = false;
              this.commentSuccessful = true;
              this.userActivity.logActivity(TYPES.dashboard, PAGES.supportView, ACTIONS.comment);
              setTimeout(() => {
                delete this.commentSuccessful;
              }, 3000);
              this.processComments();
            })
            .catch((err) => {
              this.postingComment = false;
              this.alertService.handlerError(err);
            });
        } else {
          this.commentError = res.map.errors[0];
          this.commentSuccessful = false;
          this.postingComment = false;
        }
      });
    }
  }

  /**
   * open confirmation modal when user select MES action
   */
  public confirmAction(type: MESActions) {
    const params = new ModalParameters();
    params.title = this.translateService.instant('modals.confirmation');
    params.bodyMessage = this.translateService.instant(type === MESActions.CLOSE_CASE ? 'pages.support.dashboard.fieldTitles.closureConfirmation' : 'pages.support.dashboard.fieldTitles.investigationConfirmation', {casenumber: this.case.caseNumber});
    params.successBtnLabel = this.translateService.instant('buttons.action.yes');
    params.successCallback = (() => this.updateMES(type));
    params.closeBtnLabel = this.translateService.instant('buttons.action.no');
    this.modalService.confirmModal(params);
  }

  /**
   * update ticket on snow for MES particular actions (closure or investigation)
   */
  public updateMES(type: MESActions) {
    this.loading = true;
    const update = new MESUpdate(this.case.id, type);
    this.itsmService.mesUpdateCase(update).subscribe({
      next:(() => {
        this.initView();
        const successlabel = type === MESActions.CLOSE_CASE ? 'pages.support.dashboard.fieldTitles.successfullyClosed' : 'pages.support.dashboard.fieldTitles.investigationInSuccess';
        this.alertService.addSuccess(this.translateService.instant(successlabel, {casenumber: this.case.caseNumber}));
      }),
      error:(() =>  {
        this.loading = true;
        const errorlabel = type === MESActions.CLOSE_CASE ? 'pages.support.dashboard.fieldTitles.failedClosed' : 'pages.support.dashboard.fieldTitles.investigationInError'
        this.alertService.addError(this.translateService.instant(errorlabel, {casenumber: this.case.caseNumber}));
      }),
    });
  }

  public downloadAttachment(
    attachmentid: string,
    fileName: string,
    caseId: string
  ) {
    this.itsmService.getAttachment(attachmentid, caseId).then((res) => {
      const file = new Blob([res], { type: res.type });
      FileSaver.saveAs(file, fileName);
      this.userActivity.logActivity(TYPES.support, PAGES.supportView, ACTIONS.attachmentDownload);
    }).catch(ex =>{
      this.alertService.addError(ex);
    });
  }

  public async validateWorldWatchUpdate() {
    this.worldWatchLoading = true;
    this.worldWatchUpdateWarning = false;
    const filter = new AdvisoryFilter();
    filter.id = parseInt(this.updatedWorldWatchReference);
    this.getSignalsByTcId(this.updatedWorldWatchReference).then((response: AdvisoryListResponse) => {
      if (response.count > 0) {
        this.alertService.addSuccess(this.translateService.instant('pages.support.view.worldWatchUpdate'));
        this.case.worldWatchReference = this.updatedWorldWatchReference;
        this.activeModal.close();
      } else {
        this.worldwatchError();
      }
    }).catch( () => this.worldwatchError());
  }

  private worldwatchError() {
    this.worldWatchUpdateWarning = true;
    this.updatedWorldWatchReference = this.case.worldWatchReference;
    this.worldWatchLoading = false;
  }

  public gotoWorldWatchSignal() {
    this.router.navigateByUrl(`/updates/worldwatch/viewSignal/${this.case.worldWatchReference}`);
  }

  private getSignalsByTcId(signalRef: string): Promise<AdvisoryListResponse> {
    return new Promise((resolve, reject) => {
      const filter = new AdvisoryFilter();
      filter.id = parseInt(signalRef);
      this.worldwatchService.getAdvisories(filter).subscribe({
          next:(response) => {
              resolve(response)
          },
          error:(err: Error) => {
            reject(err);
          }
      });
    });
  }

  public refreshAttachments(message) {
    const successMessage = this.translateService.instant('pages.shared.uploadControlOK');
    if (message === successMessage) {
      this.attachmentsLoading = true;
      this.userActivity.logActivity(TYPES.support, PAGES.supportView, ACTIONS.attachmentUpload);
    }

    this.itsmService
      .case(this.caseRef)
      .then((res) => {
        this.cases = convertDatesFormat(res);
        this.case = this.cases[0];
        this.attachments = this.case.attachments;
      })
      .catch((err) => {
        this.alertService.handlerError(err);
      })
      .finally(() => this.attachmentsLoading = false);
  }

  /**
   * called when update modal is opened
   * @param content ng-template
   */
  public open(content) {
    // reset updated customer reference
    this.updatedCustomerReference = '';
    // open the modal with the given template
    this.activeModal = this.ngbModalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    this.activeModal.result.then(
      (result: string) => this.onClose(result),
      (reason: ModalDismissReasons) => this.onDismiss(reason)
    );
  }

  /**
   * when ticket is a change request, CAB users can approve or reject the request
   * @param state
   */
  async applyChangeRequest(state) {
    this.loading = true;
    try {
      const ticket = await this.itsmService.updateCaseApproval(this.case.id, state);
      // update current case with new fields
      this.initCase(ticket);
      const label = state === 'approved' ? 'pages.support.view.successfullyApproved' : 'pages.support.view.successfullyRejected';
      this.alertService.addSuccess(this.translateService.instant(label, {caseNumber: this.case.caseNumber}));
    } catch(error) {
      this.alertService.handlerError(error);
    } finally {
      this.loading = false;
    }
  }

  /**
   * called when update modal is closed
   */
  private async onClose(result: string) {
    this.closeResult = `Closed with: ${result}`;
    if (result === 'save') {
      this.setContact();
    }
    this.worldWatchUpdateWarning = false;
    this.worldWatchLoading = false;
  }

  /**
   * called when update modal is dismissed
   */
  private onDismiss(reason: ModalDismissReasons) {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    this.worldWatchUpdateWarning = false;
    this.worldWatchLoading = false;
  }

  private processComments() {
    const originalMsgSeparator = '--------------- Original Message ---------------';
    this.case.comments.forEach((c) => {
      c.isInternal = !c.creatorName || constants.creatorPortalReferences.includes(c.creatorName);
      c.creatorName = this.getCommentCreatorName(c);
      c.isEngineer = this.isEngineerComment(c.creatorName, c.isInternal);
      if (c.commentBody.includes(originalMsgSeparator)) {
        c.commentBody = c.commentBody.substring(0, c.commentBody.indexOf(originalMsgSeparator));
      }
      c.commentBody = c.commentBody.replace(/&lt;.+?\S&gt;/gm, '');
    });

    this.case.comments.sort((c1, c2) =>
      c1.createdDate > c2.createdDate ? 1 : -1
    );

    // organize comments by date
    this.case.comments.forEach( (comment: Comment) => {
      const date = comment.createdDate.split('T')[0];
      if (!this.commentsMap[date]) {
        this.commentsMap[date] = [];
      }
      this.commentsMap[date].push(comment);
    });
  }

  private setContact() {
    if (this.contactType === 'secondContact') {
      this.case.secondContactId = this.case.contactId;
      delete this.case.contactId;
    } else if (this.contactType === 'customerReference') {
      this.case.customerReference = this.updatedCustomerReference;
      delete this.case.contactId;
    }
    this.itsmService
      .caseUpdate(this.case)
      .then(() => {
        this.ngOnInit();
      })
      .catch((err) => {
        this.alertService.handlerError(err);
      });
  }

  getCommentCreatorName(comment: Comment): string {
    let result = 'Orange Cyberdefense';
    const createdByStr = 'Comment created by ';
    if (comment.commentBody.includes(createdByStr)) {
      result = comment.commentBody.substring(
        comment.commentBody.indexOf(createdByStr) + createdByStr.length,
        comment.commentBody.indexOf(' via customer portal')
      );
    }
    return result;
  }

  isEngineerComment(creatorName: string, internal: boolean): boolean {
    const internalEngineerDomain = '@orangecyberdefense.com';
    // If comment author is external || is not the case author and have an @orangecyberdefense address, they have the 'Engineer' status
    return  !internal || (creatorName !== this.case.openedBy && creatorName.includes(internalEngineerDomain));
  }

  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
