<header class="header">
  <nav class="navbar navbar-expand-xl" [ngClass]="contextService.navBarTheme" aria-labelledby="userNavbarDesc">
    <span id="userNavbarDesc" class="visually-hidden">"{{ 'pages.dashboard.userNavDesc' | translate }}"</span>
    <div class="user-bar container-fluid">

      @if(accessRestricted) {
      <a class="navbar-brand" href="#">
        <img src="assets/images/Small_Logo_RGB.png" alt="Back to homepage" title="Back to homepage">
      </a>
      }
      <div class="ms-auto">
        <!-- Burger menu (visible on small screens) -->
        <button class="navbar-toggler collapsed"
          type="button" data-bs-toggle="collapse"
          data-bs-target=".user-header-1"
          aria-controls="user-header-1.1 user-header-1.2"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div id="user-header-1.1" class="navbar-collapse collapse ms-lg-auto user-header-1">
        <!-- search bar -->
         @if(showSearchBar) {
          <div class="search-bar">
            <app-search-bar></app-search-bar>
          </div>  
         }
        
        <ul class="navbar-nav ms-auto">
          <!-- notifications -->
          @if(userAuthService.userDetails) {
          <li class="nav-item dropdown notification-wrapper">
            <a class="nav-link notification" id="dropdownNotifications" (click)="updateUserPrefNotif()" role="button"
              data-bs-toggle="dropdown" aria-expanded="false"
              title="{{ 'pages.dashboard.notificationDesc' | translate }}">
              <div class="sticker sticker-xs mx-1">
                <span class="sticker-fs-xs notification-count">{{newNotificationsCount}}</span>
              </div>
              {{'pages.dashboard.notifications' | translate}}
            </a>

            @if(notifications?.length > 0) {
            <div>
              <ul infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                (scrolled)="loadNextNotifications()" [scrollWindow]="false"
                class="dropdown-menu notification-outer-list dropdown-menu-end" aria-labelledby="dropdownNotifications">
                <li *ngFor="let notif of notifications" (click)="openNotification(notif)">
                  <a class="dropdown-item">
                    {{notif.notification.title}}
                  </a>
                </li>
              </ul>
            </div>
            } @else if (notifications?.length === 0) {
            <div>
              <ul class="dropdown-menu" aria-labelledby="dropdownNotifications">
                <li>
                  <a class="dropdown-item empty-notifications">
                    <p>No notifications</p>
                  </a>
                </li>
              </ul>
            </div>
            }
          </li>
          }

          <!-- feedback -->
          <li class="nav-item" (click)="openGlobalFeedback()" [ngbTooltip]="'pages.generic.portalFeedbacks' | translate"
            placement="bottom">
            <a class="nav-link"><fa-icon class="me-2 fa-flip-horizontal" [icon]="['far', 'comment']"
                size="lg"></fa-icon>&nbsp;{{
              'menu.feedback' | translate }}</a>
          </li>

          <!-- user -->
          @if(userAuthService.userDetails) {
          <li class="nav-item dropdown">
            <a class="nav-link" id="dropdownUser" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <fa-icon class="me-2" icon="user"></fa-icon>{{ 'menu.myAccount' | translate }}&nbsp;
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownLanguage">
              @if(changeTenants) {
              <li>
                <a class="dropdown-item" routerLink="/tenants">{{'pages.dashboard.changeTenant' | translate}}</a>
              </li>
              }
              @if(!accessRestricted) {
              <li>
                <a class="dropdown-item" routerLink="/customizedashboard">{{ 'menu.customizeDashboard' | translate
                  }}</a>
              </li>
              <li>
                <a class="dropdown-item" routerLink="/useraccount">{{ 'menu.user' | translate }}</a>
              </li>
              }
              @if(userAuthService.accesses.analytics && !accessRestricted) {
              <li>
                <a class="dropdown-item" routerLink="/analytics">{{ 'menu.analytics' | translate }}</a>
              </li>
              }
              @if(userAuthService.accesses.analytics) {
              <li>
                <a class="dropdown-item" routerLink="/feedbacks">{{ 'menu.feedback-summary' | translate }}</a>
              </li>
              }
              <li>
                <a class="dropdown-item" (click)="logout()">{{'pages.generic.logout' | translate }}</a>
              </li>
            </ul>
          </li>
          }

          <!-- region -->
          <li class="nav-item dropdown">
            <a class="nav-link" id="dropdownRegion" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{selectRegion.name}}</a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownLanguage">
              @for(region of regions; track region) {
              <li>
                <a class="dropdown-item" [class.active]="region.current" attr.aria-label={{region.name}}
                  attr.aria-current={{region.current}} hreflang={{region.name}}
                  (click)="changeRegion(region)">{{region.name}}</a>
              </li>
              }
            </ul>
          </li>

          <!-- language -->
          <li class="nav-item dropdown" data-placement="top"
            title="{{ 'pages.dashboard.selectLanguageDesc' | translate }}">
            <a class="nav-link" id="dropdownLanguage" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{selectLanguage.name}}</a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownLanguage">
              @for(language of languages; track language) {
              <li>
                <a class="dropdown-item" [class.active]="language.current" attr.aria-label={{language.label}}
                  attr.aria-current={{language.current}} lang={{language.lang}} hreflang={{language.lang}}
                  (click)="changeLanguage(language)">{{language.name}}</a>
              </li>
              }
            </ul>
          </li>

          <!-- theme -->
          <li class="nav-item" (click)="changeTheme()" >
            <div class="nav-link" [ngbTooltip]="switchTooltip | translate">
              <fa-icon class="me-2 ms-2" [icon]="['far', icon]" size="lg"></fa-icon>  
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>