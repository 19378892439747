import {
  Component,
  EventEmitter,
  inject,
  input,
  InputSignal,
  OnInit,
  Output,
  signal,
  WritableSignal
} from '@angular/core';
import { CardComponent } from "src/app/shared/components/xtended/card/card.component";
import { IconsModule } from "src/app/shared/modules/icons/icons.module";
import { PortalSavedFilterDTO } from "src/app/shared/model/cybersocxdr/portalSavedFilter";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { AlternateDataSourcePipe } from "src/app/shared/pipes/alternate-data-source.pipe";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { finalize, take } from "rxjs/operators";
import { PortalSavedFilterService } from "src/app/shared/services/xtendedsoc/portalSavedFilter.service";
import { FormsModule } from "@angular/forms";
import { AlertService } from "src/app/shared/services/alert.service";
import { DialogOpenerDirective } from "src/app/shared/directives/xtended/dialog-opener.directive";

interface FilterItem {
  name: string;
  values: string[];
}

interface UpdateDto {
  name: string;
  facet: string;
}

@Component({
  selector: 'user-preset-item',
  standalone: true,
  imports: [
    CardComponent,
    IconsModule,
    NgbTooltip,
    AlternateDataSourcePipe,
    TranslateModule,
    FormsModule,
    DialogOpenerDirective,
  ],
  templateUrl: './user-preset-item.component.html',
  styleUrl: './user-preset-item.component.scss'
})
export class UserPresetItemComponent implements OnInit {
  private readonly notifications: AlertService = inject(AlertService);
  private readonly translate: TranslateService = inject(TranslateService);
  private readonly portalSavedFiltersService: PortalSavedFilterService = inject(PortalSavedFilterService);

  protected isEditMode: WritableSignal<boolean> = signal<boolean>(false);
  protected filters: WritableSignal<FilterItem[]> = signal<FilterItem[]>([]);

  /**
   * @public
   * @desc The saved filter preset to be displayed.
   */
  public preset: InputSignal<PortalSavedFilterDTO> = input.required<PortalSavedFilterDTO>();

  /**
   * @public
   * @desc The target string for the confirm dialog.
   */
  public confirmDialogTarget: InputSignal<string> = input.required<string>();

  /**
   * @public
   * @desc Emits the id of the preset to be deleted.
   */
  @Output()
  public onDelete: EventEmitter<number> = new EventEmitter<number>();

  public ngOnInit(): void {
    this.filters.set(this.convertPreset(this.preset()));
  }

  /**
   * @protected
   * @desc Updates a saved filter by calling the corresponding service.
   * Displays a success or error notification based on the operation's result.
   * Exits edit mode once the update process is completed.
   */
  protected update(): void {
    if (this.preset().name.length === 0) {
      return;
    }

    this.portalSavedFiltersService
      .update(this.preset().id, this.presetToUpdateDTO(this.preset()))
      .pipe(
        take(1),
        finalize((): void => {
          this.toggleEditMode();
        })
      )
      .subscribe({
        next: (): void => this.notifications.addSuccess(
          this.translate.instant('pages.cybersocxdr.mySpace.settings.updateSuccess')
        ),
        error: (error: any): void => {
          console.error(error);
          this.notifications.addError(
            this.translate.instant('pages.cybersocxdr.mySpace.settings.updateError')
          );
        }
      });
  }

  /**
   * @protected
   * @desc Emits an event to delete the current preset.
   */
  protected delete(): void {
    this.onDelete.emit(this.preset().id);
  }

  /**
   * @protected
   * @desc Toggles the edit mode on or off.
   */
  protected toggleEditMode(): void {
    this.isEditMode.set(!this.isEditMode());
  }

  /**
   * @private
   * @desc Converts a `PortalSavedFilterDTO` object into a list of `FilterItem` elements.
   *
   * @param preset - The saved filter to convert.
   *
   * @returns An array of `FilterItem` objects containing the filtered facet names and values.
   */
  private convertPreset(preset: PortalSavedFilterDTO): FilterItem[] {
    return Object.entries(preset.facet)
      .filter(([name, value]: [string, string]): boolean => !!name && !!value)
      .map(([name, value]: [string, string]): FilterItem => ({
        name,
        values: value.split(',')
      }));
  }

  /**
   * @private
   * @desc Converts a `PortalSavedFilterDTO` object into an `UpdateDto` object.
   *
   * @param preset - The saved filter to convert.
   *
   * @returns An `UpdateDto` object containing the converted filter data.
   */
  private presetToUpdateDTO(preset: PortalSavedFilterDTO): UpdateDto {
    return {
      name: preset.name,
      facet: JSON.stringify(preset.facet)
    }
  }
}
