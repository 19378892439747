import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SentinelOneData, ThreatsCount } from '../sentinel-one-models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SentinelOneHttpService {
  private agentsUrl = environment.endpoints.sentinelone + 'agents';
  private threatsUrl = environment.endpoints.sentinelone + 'threats';
  private threatsCountsUrl = environment.endpoints.sentinelone + 'threatsCounts';

  constructor(private http: HttpClient, private translateService: TranslateService) {}

  public async getAgents(): Promise<SentinelOneData> {
    return this.http
      .get(this.agentsUrl)
      .toPromise()
      .then((response) => {
        if (response['errorCode']) {
          const errorMessage = this.translateService.instant(this.errorCodes.getError(response['errorCode']));
          return Promise.reject(new Error(errorMessage));
        }
        return response as SentinelOneData;
      })
      .catch((error) => {
        return Promise.reject(new Error(error));
      });
  }

  public async getThreats(): Promise<SentinelOneData> {
    return this.http
      .get(this.threatsUrl)
      .toPromise()
      .then((response) => {
        if (response['errorCode']) {
          const errorMessage = this.translateService.instant(this.errorCodes.getError(response['errorCode']));
          return Promise.reject(new Error(errorMessage));
        }
        return response as SentinelOneData;
      })
      .catch((error) => Promise.reject(new Error(this.translateService.instant(this.errorCodes.DEFAULT_ERROR))));
  }

  public async getThreatsCount(
    fromDate: string
  ): Promise<{ totalThreats: ThreatsCount; totalMitigatedThreats: ThreatsCount }> {
    return this.http
      .get(`${this.threatsCountsUrl}/${fromDate}`)
      .toPromise()
      .then((response: { totalThreats: SentinelOneData; totalMitigatedThreats: SentinelOneData }) => {
        if (response['errorCode']) {
          const errorMessage = this.translateService.instant(this.errorCodes.getError(response['errorCode']));
          return Promise.reject(new Error(errorMessage));
        }
        return {
          totalThreats: new ThreatsCount(response.totalThreats.pagination.totalItems, response.totalThreats.data),
          totalMitigatedThreats: new ThreatsCount(
            response.totalMitigatedThreats.pagination.totalItems,
            response.totalMitigatedThreats.data
          ),
        };
      })
      .catch((error) => Promise.reject(new Error(this.translateService.instant(this.errorCodes.DEFAULT_ERROR))));
  }

  private readonly errorCodes = {
    NOT_AUTHORIZED: 'pages.updates.sentinelOne.errors.notAuthorized',
    TOKEN_FAILURE: 'pages.updates.sentinelOne.errors.invalidCredentials',
    DEFAULT_ERROR: 'pages.updates.sentinelOne.errors.unreachable',

    getError(code: number): string {
      // switch case here for further errorCodes implementation
      switch (code) {
        case 1:
          return this.NOT_AUTHORIZED;
        case 2:
          return this.TOKEN_FAILURE;
        default:
          return this.DEFAULT_ERROR;
      }
    },
  };
}
