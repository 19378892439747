import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { childTenantGuard, oktaAuthGuard, slaAuthGuard, threatDetectionAuthGuard } from '../app.guard';
import { LayoutComponent } from '../layout/layout/layout.component';
import { ReportsLiveIndexComponent } from './reports-live/reports-live-index/reports-live-index.component';
import { ReportsStaticComponent } from './reports-static/reports-static.component';
import { ReportLiveItsmComponent } from './reports-live/report-live-itsm/report-live-itsm.component';
import { ReportLiveSecurityCaseComponent } from './reports-live/report-live-securitycase/report-live-securitycase.component';
import { IntelligenceComponent } from './intelligence/intelligence.component';
import { ReportLiveBenchmarkingComponent } from './reports-live/report-live-benchmarking/report-live-benchmarking.component';
import { ReportScheduleComponent } from './report-schedule/report-schedule.component';
import { UserSharedFilesComponent } from './user-shared-files/user-shared-files.component';
import { ThreatDetectionAndResponseComponent } from './threat-detection-and-response/threat-detection-and-response.component';

const theme = 'reports';
const routes: Routes = [
  {
    path: 'reports',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard, childTenantGuard],
    children: [
      { path: '', redirectTo: 'standard', pathMatch: 'full' },
      {
        path: 'standard',
        component: ReportsStaticComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.reports.standard',
          urls: [{ title: 'menu.reports.title' }, { title: 'menu.reports.standard' }],
          theme,
        }
      },
      {
        path: 'live',
        component: ReportsLiveIndexComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.reports.live',
          urls: [{ title: 'menu.reports.title' }, { title: 'menu.reports.live' }],
          theme,
        }
      },
      {
        path: 'live/threatDetectionAndResponse',
        component: ThreatDetectionAndResponseComponent,
        canActivate: [threatDetectionAuthGuard],
        pathMatch: 'full',
        data: {
          title: 'menu.reports.threatDetectionAndResponse',
          urls: [{ title: 'menu.reports.title' }, { title: 'menu.reports.internalFile' }],
          theme,
        }
      },
      {
        path: 'live/resolution',
        component: ReportLiveItsmComponent,
        canActivate: [slaAuthGuard],
        pathMatch: 'full',
        data: {
          title: 'menu.reports.resolution',
          urls: [{ title: 'menu.reports.title' }, { title: 'menu.reports.resolution' }],
          theme,
        }
      },
      {
        path: 'live/benchmarking',
        component: ReportLiveBenchmarkingComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.reports.benchmarking',
          urls: [{ title: 'menu.reports.title' }, { title: 'menu.reports.benchmarking' }],
          theme,
        }
      },
      {
        path: 'security-intelligence',
        component: IntelligenceComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.reports.intelligence',
          urls: [{ title: 'menu.reports.title' }, { title: 'menu.reports.intelligence' }],
          theme,
        }
      },
      {
        path: 'sharedFiles',
        component: UserSharedFilesComponent,
        pathMatch: 'full',
        data: {
          title: 'Shared files',
          urls: [{ title: 'menu.reports.title' }, { title: 'menu.reports.internalFile' }],
          theme,
        }
      }
    ]
  },
  {
    path: 'reports',
    component: LayoutComponent,
    canActivate: [oktaAuthGuard, childTenantGuard],
    children: [
      {
        path: 'live/securitycase',
        component: ReportLiveSecurityCaseComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.reports.securityCase',
          urls: [{ title: 'menu.reports.title' }, { title: 'menu.reports.securityCase' }],
          theme,
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
