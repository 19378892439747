import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '../../modules/icons/icons.module';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-location-list',
  standalone: true,
  imports: [TranslateModule, IconsModule, RouterModule],
  templateUrl: './location-list.component.html',
  styleUrl: './location-list.component.scss'
})
export class LocationListComponent implements OnInit {

  @Input() locations: any[];
  @Input() loading: boolean;

  private defaultSortOrder = 'DESC';
  private sortableColumns: Map<string, 'ASC' | 'DESC'>;

  ngOnInit(): void {
    this.sortableColumns = this.getSortableColumnsMap();
  }

  sortLocations(field: string): void {
    const sortOrder = this.sortableColumns.get(field) === 'ASC' ? 'DESC' : 'ASC';
    this.sortableColumns.set(field, sortOrder);
    this.locations.sort( (a, b) => {
      if (sortOrder === 'ASC') {
        return b[field] > a[field] ? -1 : 1;
      } else {
        return b[field] > a[field] ? 1 : -1;
      }
    });
  }

   /**
   * Generate a Map of the columns which allow sorting 
   * Each element contains the columnName and a 'ASC' or 'DESC' string 
   * which are to be stored to recall to be able to sort at the invert next time
   * @returns map of column names and booleans
   */
   private getSortableColumnsMap() {
    const map = new Map();
    map.set("siteCode", this.defaultSortOrder);
    map.set("fullName", this.defaultSortOrder);
    map.set("city", this.defaultSortOrder);
    map.set("country", this.defaultSortOrder);
    return map;
  }

}
