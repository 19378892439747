@if (notificationsToDisplay.length > 0) {
<div class="notification-overlay position-fixed top-0 start-0 w-100 h-100">
  <div
    class="notification-carousel carousel slide position-absolute top-50 start-50 translate-middle bg-white rounded p-3 p-md-4 mx-2"
    [ngClass]="{
      'notification-no-link': !hasLink(),
      'notification-no-images': !hasImages(),
    }"
    id="notificationsCarousel"
    data-bs-ride="carousel"
  >
    <button
      type="button"
      class="btn btn-icon btn-close position-absolute top-0 end-0 m-2 notification-close-button rounded"
      (click)="closeNotifications()"
      aria-label="Close"
    ></button>
    <div class="carousel-inner h-100">
      @for (notification of notificationsToDisplay; track notification.id; let i
      = $index) {
      <div class="carousel-item h-100" [ngClass]="{ active: i === 0 }">
        <div
          class="d-flex flex-column gap-2 gap-md-3 h-100"
          [ngClass]="{ 'px-4': notificationsToDisplay.length > 1 }"
        >
          @if (notification.image) {
          <div class="notification-image-container">
            <img
              [src]="notification.image"
              class="d-block w-100 h-100"
              [alt]="
                translateService.currentLang === 'fr'
                  ? notification.titleFR
                  : notification.titleEN
              "
            />
          </div>
          }
          <h4 class="m-0 text-break">
            {{
              translateService.currentLang === 'fr'
                ? notification.titleFR
                : notification.titleEN
            }}
          </h4>
          <p
            class="flex-grow-1 overflow-auto m-0 text-break text-justify"
            [ngClass]="{
              'notification-description with-image': notification.image,
              'notification-description without-image': !notification.image
            }"
          >
            {{
              translateService.currentLang === 'fr'
                ? notification.descriptionFR
                : notification.descriptionEN
            }}
          </p>
          @if (notification.link) {
          <div class="d-flex justify-content-center pb-4">
            <a
              [href]="notification.link"
              target="_blank"
              class="btn btn-secondary rounded d-inline-flex"
              >{{
                translateService.currentLang === 'fr'
                  ? notification.linkTextFR
                  : notification.linkTextEN
              }}</a
            >
          </div>
          }
        </div>
      </div>
      }
    </div>
    @if (notificationsToDisplay.length > 1) {
    <button
      class="carousel-control-prev d-none d-md-flex"
      type="button"
      data-bs-target="#notificationsCarousel"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next d-none d-md-flex"
      type="button"
      data-bs-target="#notificationsCarousel"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
    <div class="carousel-indicators">
      @for (notification of notificationsToDisplay; track notification.id; let i
      = $index) {
      <button
        type="button"
        data-bs-target="#notificationsCarousel"
        [attr.data-bs-slide-to]="i"
        [class.active]="i === 0"
        [attr.aria-current]="i === 0"
        [attr.aria-label]="'Slide ' + (i + 1)"
      ></button>
      }
    </div>
    }
  </div>
</div>
}
