import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  OnInit,
  Output,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Contract } from 'src/app/shared/model/cybersocxdr/clientInfo';
import { XtendedFacet } from 'src/app/shared/model/cybersocxdr/facets';
import { CybersocXDRDashboardSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { CybersocxdrService } from 'src/app/shared/services/xtendedsoc/cybersocxdr.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PortalSavedFilterService } from 'src/app/shared/services/xtendedsoc/portalSavedFilter.service';
import { AddPortalSavedFilterDTO, PortalSavedFilterDTO } from 'src/app/shared/model/cybersocxdr/portalSavedFilter';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { NgStyle, NgTemplateOutlet } from "@angular/common";
import { NgbDropdownModule, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { AlternateDataSourcePipe } from "src/app/shared/pipes/alternate-data-source.pipe";
import { IconsModule } from "src/app/shared/modules/icons/icons.module";
import { XtendedFiltersService } from "src/app/cybersocxdr/xtendedfilters.service";
import { IncidentFilterOptionsDTO, localFilterOptions } from "src/app/cybersocxdr/local-filter-options";

export enum CssSelector {
  INPUT_CONTAINER = "filter-search-input-container",
  FILTER_TAG = "filter-tag",
  FILTER_DROPDOWN = "filter-dropdown",
  FILTER_ITEM = "filter-item",
}

@Component({
  selector: 'filter-search-bar',
  standalone: true,
  imports: [
    AlternateDataSourcePipe,
    NgbDropdownModule,
    TranslateModule,
    IconsModule,
    FormsModule,
    NgStyle,
    NgTemplateOutlet,
    NgbTooltip,
  ],
  templateUrl: './filter-search-bar.component.html',
  styleUrls: ['./filter-search-bar.component.scss']
})
export class FilterSearchBarComponent implements OnInit {
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly portalSavedFiltersService: PortalSavedFilterService = inject(PortalSavedFilterService);
  private readonly cybersocxdrService: CybersocxdrService = inject(CybersocxdrService);
  private readonly translateService: TranslateService = inject(TranslateService);
  private readonly alertService: AlertService = inject(AlertService);
  protected readonly xtendedFiltersService: XtendedFiltersService = inject(XtendedFiltersService);

  @Output()
  public searchEmitter: EventEmitter<CybersocXDRDashboardSearchCriteria> = new EventEmitter<CybersocXDRDashboardSearchCriteria>();

  @ViewChild('inputContainer')
  private readonly inputContainer!: ElementRef<HTMLElement>;

  @ViewChild('filterDropdown')
  private readonly filterDropdown!: ElementRef<HTMLElement>;

  public newUserPresetName: WritableSignal<string> = signal<string>('');

  public searchQuery: string = '';
  public showFiltersDropdown: boolean = false;
  public selectedFacet: XtendedFacet | null = null;
  public groupByFiltersName: string[];
  public availableFacets: XtendedFacet[] = [];
  public filteredOptions: IncidentFilterOptionsDTO[] = [];
  public contract: Contract | null = null;
  public dropdownPosition: { left?: string; top?: string } = {};
  public valueSearchQuery: string = '';
  public userMail: string = '';
  public savedFilters: PortalSavedFilterDTO[] = [];

  private readonly searchSubject: Subject<string> = new Subject<string>();
  private readonly filterOrder: XtendedFacet[] = [];
  private validFacets: XtendedFacet[] = [];

  private readonly ignoredFields: string[] = [
    'incidentDataSource', 'createdFrom', 'createdTo', 'limit', 'skip', 'sortBy',
    'groupbyField', 'subGroupbyField', 'repartitionGroupbyLevel', 'slaInvestigationStatusBroken',
    'slaNotificationStatusBroken', '_id'
  ];

  private readonly freeInputFields: XtendedFacet[] = [
    XtendedFacet.SUBJECT
  ];

  public ngOnInit(): void {
    this.contract = this.xtendedFiltersService.selectedContract;
    this.cybersocxdrService.getGroupByFilterTypes()
      .then((types: string[]): void => {
        this.groupByFiltersName = types;
        this.setupAvailableFacets();
        this.setupSearchObservable();
        this.loadSavedFilters();
      });
  }

  private setupAvailableFacets(): void {
    this.availableFacets = Object.values(XtendedFacet)
      .filter((facet: XtendedFacet): boolean =>
        [
          XtendedFacet.SEVERITY, XtendedFacet.OWNER, XtendedFacet.STATUS, XtendedFacet.CATEGORY,
          XtendedFacet.HOSTNAME, XtendedFacet.WAITING_FOR, XtendedFacet.CLOSURE_VERDICT,
          XtendedFacet.GROUPLEVEL1, XtendedFacet.GROUPLEVEL2,
          XtendedFacet.SLA_NOTIFICATION, XtendedFacet.USERNAME, XtendedFacet.RULE_NAME,
          XtendedFacet.PROCESS_PATH, XtendedFacet.CMD_LINE, XtendedFacet.TYPE,
          XtendedFacet.MITRE_TACTIC, XtendedFacet.MITRE_TECHNIQUE, XtendedFacet.CLIENT_TICKET_CREATED,
          XtendedFacet.CLIENT_TICKET_ID, XtendedFacet.SUBJECT, XtendedFacet.ID,
          XtendedFacet.ACTION_LEVEL_1, XtendedFacet.ACTION_LEVEL_2,
          XtendedFacet.ACTOR_LEVEL_1, XtendedFacet.ACTOR_LEVEL_2,
          XtendedFacet.ASSET_LEVEL_1, XtendedFacet.ATTRIBUTES_LEVEL_1,
          XtendedFacet.WHY_LEVEL_1, XtendedFacet.WHY_LEVEL_2,
          XtendedFacet.WHAT_LEVEL_1, XtendedFacet.WHO_LEVEL_1,
        ].includes(facet)
      );
  }

  private setupSearchObservable(): void {
    this.searchSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(async (searchTerm: string): Promise<void> => {
        if (this.selectedFacet) {
          this.filteredOptions = await this.fetchFilterOptions(this.selectedFacet, searchTerm);
        }
      });
  }

  public loadSavedFilters(): void {
    this.portalSavedFiltersService.findByMail()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (filters: PortalSavedFilterDTO[]): void => {
          this.savedFilters = ([] as PortalSavedFilterDTO[]).concat(filters || []);
        },
        error: (): void => {
          this.alertService.addError(this.translateService.instant('pages.cybersocxdr.filters.loadError'));
        }
      });
  }

  public applySavedFilter(filter: PortalSavedFilterDTO): void {
    try {
      this.xtendedFiltersService.searchCriteria = JSON.parse(filter.facet);
      this.emitSearchCriteria();
    } catch (error) {
      this.alertService.addError(this.translateService.instant('pages.cybersocxdr.filters.applyError'));
    }
  }

  public hasActiveFilters(): boolean {
    const criteria: CybersocXDRDashboardSearchCriteria = this.xtendedFiltersService.searchCriteria;

    for (const key in criteria) {
      if (this.ignoredFields.includes(key)) {
        continue;
      }

      const value: any = criteria[key];
      if (this.isActiveValue(value)) {
        return true;
      }
    }

    return false;
  }

  private async fetchFilterOptions(filterKey: string, search?: string): Promise<IncidentFilterOptionsDTO[]> {
    try {
      const options: unknown = await this.cybersocxdrService.getFilterOptions(
        filterKey,
        search,
        this.contract?.incidentDataSource,
        10
      );
      return (options as any[]).map((option: any): IncidentFilterOptionsDTO => ({
        label: option.label || option.value,
        value: option.value
      }));
    } catch (error) {
      // Optionally log error here for debugging.
      return [];
    }
  }

  public async getValidFacets(): Promise<XtendedFacet[]> {
    const validFacets: XtendedFacet[] = [];

    for (const facet of this.availableFacets) {
      if (this.freeInputFields.includes(facet) || this.hasLocalFilterOptions(facet)) {
        validFacets.push(facet);
      } else {
        const options: IncidentFilterOptionsDTO[] = await this.fetchFilterOptions(facet);
        if (options?.length > 0) {
          validFacets.push(facet);
        }
      }
    }

    return validFacets;
  }

  public getFilteredFacets(): XtendedFacet[] {
    const activeFilters: string[] = this.getGroupedFilters()
      .map(filter => filter.type);

    return this.validFacets
      .filter((facet: XtendedFacet): boolean => {
        const isNotAlreadySelected: boolean = !activeFilters.includes(facet);
        const matchesSearch: boolean = facet.toLowerCase().includes(this.searchQuery.toLowerCase());

        return isNotAlreadySelected && matchesSearch;
      });
  }

  private filterLocalOptions(facet: XtendedFacet, searchTerm: string): IncidentFilterOptionsDTO[] {
    const lower: string = searchTerm.toLowerCase();
    return localFilterOptions[facet].filter(option =>
      option.label.toLowerCase().includes(lower) ||
      option.value.toLowerCase().includes(lower)
    );
  }

  public async selectFacet(facet: XtendedFacet): Promise<void> {
    this.selectedFacet = facet;
    this.searchQuery = '';
    this.valueSearchQuery = '';
    this.showFiltersDropdown = true;

    requestAnimationFrame(() => this.updateDropdownPosition());

    this.filteredOptions = this.hasLocalFilterOptions(facet)
      ? localFilterOptions[facet]
      : await this.fetchFilterOptions(this.selectedFacet);
  }

  public selectValue(option: IncidentFilterOptionsDTO): void {
    if (!this.selectedFacet) {
      return;
    }
    const field: string | undefined = this.xtendedFiltersService.mapTypeToField(this.selectedFacet);
    if (!field) {
      return;
    }
    this.addToFilterOrderList(this.selectedFacet);

    if (this.isUserInputFilter(this.selectedFacet)) {
      (this.xtendedFiltersService.searchCriteria[field] as string) = option.value;
      this.closeFilterOptionDropdown();
      return;
    }

    this.updateFilterCriteria(field, option.value);
  }

  private addToFilterOrderList(facet: XtendedFacet): void {
    if (!this.filterOrder.includes(facet)) {
      this.filterOrder.push(facet);
    }
  }

  private isUserInputFilter(facet: XtendedFacet): boolean {
    return this.freeInputFields.includes(facet);
  }

  private updateFilterCriteria(field: string, value: string): void {
    const criteria: string | string[] | number | boolean = this.xtendedFiltersService.searchCriteria[field];

    if (Array.isArray(criteria)) {
      this.toggleArrayValue(criteria, value);
    } else {
      this.xtendedFiltersService.searchCriteria[field] = value;
      this.closeFilterOptionDropdown();
    }
  }

  private toggleArrayValue(criteria: string[], value: string): void {
    const index: number = criteria.indexOf(value);

    if (index === -1) {
      criteria.push(value);
    } else {
      criteria.splice(index, 1);
      this.cleanUpFilterOrderIfEmpty(criteria);
    }
  }

  private cleanUpFilterOrderIfEmpty(criteria: string[]): void {
    if (criteria.length === 0 && this.selectedFacet) {
      const orderIndex: number = this.filterOrder.indexOf(this.selectedFacet);
      if (orderIndex !== -1) {
        this.filterOrder.splice(orderIndex, 1);
      }
    }
  }

  private closeFilterOptionDropdown(): void {
    this.showFiltersDropdown = false;
    this.selectedFacet = null;
  }

  public isOptionSelected(option: IncidentFilterOptionsDTO): boolean {
    const field: string | undefined = this.xtendedFiltersService.mapTypeToField(this.selectedFacet || '');
    const currentValue: string | string[] | number | boolean = this.xtendedFiltersService.searchCriteria[field];

    if (Array.isArray(currentValue)) {
      return currentValue.includes(option.value);
    }
    return currentValue === option.value;
  }


  public getGroupedFilters(): { type: string; values: string[] }[] {
    const criteria = this.xtendedFiltersService.searchCriteria;
    const groupedFilters: { type: string; values: string[] }[] = [];
    const filtersToProcess: (XtendedFacet | string)[] = [...this.filterOrder];

    Object.keys(criteria).forEach((key: string): void => {
      if (this.ignoredFields.includes(key)) {
        return;
      }
      const value: any = criteria[key];
      const mappedType: string = this.xtendedFiltersService.mapFieldToType(key);
      if (mappedType && this.isActiveValue(value) && !filtersToProcess.includes(mappedType)) {
        filtersToProcess.push(mappedType);
      }
    });

    // Create filter groups in order
    filtersToProcess.forEach((type: XtendedFacet | string): void => {
      const field: string | undefined = this.xtendedFiltersService.mapTypeToField(type);
      if (!field) {
        return;
      }
      const value: any = criteria[field];
      if (this.isActiveValue(value)) {
        groupedFilters.push({
          type: type,
          values: Array.isArray(value) ? value : [value]
        });
      }
    });

    return groupedFilters;
  }

  private updateDropdownPosition(): void {
    if (!this.inputContainer || !this.filterDropdown) {
      return;
    }

    const inputRect: DOMRect = this.inputContainer.nativeElement.getBoundingClientRect();
    this.dropdownPosition = {
      left: `${inputRect.left}px`,
      top: `${inputRect.bottom}px`
    };

    const dropdownRect: DOMRect = this.filterDropdown.nativeElement.getBoundingClientRect();
    if (dropdownRect.right > window.innerWidth) {
      this.dropdownPosition.left = `${window.innerWidth - dropdownRect.width - 10}px`;
    }
  }

  private emitSearchCriteria(): void {
    this.xtendedFiltersService.serializeSeachCriteria();
    this.searchEmitter.emit(this.xtendedFiltersService.searchCriteria);
    this.xtendedFiltersService.filterEvent.emit(this.xtendedFiltersService.searchCriteria);
  }

  private isActiveValue(value: any): boolean {
    if (value === undefined || value === null) {
      return false;
    }
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    if (typeof value === 'string') {
      return value.trim() !== '';
    }
    return true;
  }

  private hasLocalFilterOptions(facet: XtendedFacet): boolean {
    return facet in localFilterOptions;
  }

  private handleEnterKey(event: KeyboardEvent): void {
    if (this.selectedFacet && this.freeInputFields.includes(this.selectedFacet)) {
      this.handleFreeInputEnter(event);
    } else if (this.selectedFacet) {
      this.handleSelectedFacetEnter(event);
    } else if (this.filteredOptions.length > 0) {
      this.onSearchClick();
    }
  }

  private handleFreeInputEnter(event: KeyboardEvent): void {
    const field: string | undefined = this.xtendedFiltersService.mapTypeToField(this.selectedFacet.toLowerCase());
    if (field && this.valueSearchQuery.trim()) {
      (this.xtendedFiltersService.searchCriteria[field] as string) = this.valueSearchQuery.trim();
      this.resetDropdownState();
      this.onSearchClick();
    }
    event.preventDefault();
  }

  private handleSelectedFacetEnter(event: KeyboardEvent): void {
    this.resetDropdownState();
    event.preventDefault();
  }

  private resetDropdownState(): void {
    this.showFiltersDropdown = false;
    this.selectedFacet = null;
    this.valueSearchQuery = '';
    this.searchQuery = '';
    this.dropdownPosition = {};
  }

  public onSearchClick(): void {
    this.emitSearchCriteria();
  }

  public onSearchChange(isValueSearch: boolean = false): void {
    if (!isValueSearch) {
      this.showFiltersDropdown = true;
      return;
    }

    if (!this.selectedFacet) {
      return;
    }

    if (this.hasLocalFilterOptions(this.selectedFacet)) {
      this.filteredOptions = this.filterLocalOptions(this.selectedFacet, this.valueSearchQuery);
    } else {
      this.searchSubject.next(this.valueSearchQuery);
    }
  }

  public onRemoveFilterTagClick(type: string, event: MouseEvent): void {
    event.stopPropagation();

    const field: string | undefined = this.xtendedFiltersService.mapTypeToField(type);
    if (field && field in this.xtendedFiltersService.searchCriteria) {
      (this.xtendedFiltersService.searchCriteria[field] as string[]) = [];

      // Retirer le type de filterOrder
      const index: number = this.filterOrder.indexOf(type as XtendedFacet);
      if (index !== -1) {
        this.filterOrder.splice(index, 1);
      }
    }
  }

  public async onFilterTagClick(facet: string, event: Event): Promise<void> {
    event.stopPropagation();

    const target: HTMLElement = event.currentTarget as HTMLElement;
    const rect: DOMRect = target.getBoundingClientRect();

    if (this.hasLocalFilterOptions(facet as XtendedFacet)) {
      this.filteredOptions = localFilterOptions[facet as XtendedFacet];
    } else {
      try {
        this.filteredOptions = await this.fetchFilterOptions(facet as XtendedFacet);
      } catch (error) {
        this.filteredOptions = [];
      }
    }

    this.selectedFacet = facet as XtendedFacet;
    this.showFiltersDropdown = true;

    this.dropdownPosition = {
      left: `${rect.left}px`,
      top: `${rect.bottom}px`
    };
  }

  public async onFilterSearchInputFocus(): Promise<void> {
    if (this.validFacets.length === 0) {
      this.validFacets = await this.getValidFacets();
    }

    this.showFiltersDropdown = true;
    this.selectedFacet = null;
    this.updateDropdownPosition();
  }

  public onSaveUserPreset(): void {
    if (this.newUserPresetName().length === 0) return;

    const filter: AddPortalSavedFilterDTO = {
      name: this.newUserPresetName(),
      facet: JSON.stringify(this.xtendedFiltersService.searchCriteria),
      mail: this.userMail
    };

    this.portalSavedFiltersService
      .create(filter)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (): void => {
          this.newUserPresetName.set('');
          this.loadSavedFilters();
        },
        error: (): void => {
          this.alertService.addError(this.translateService.instant('pages.cybersocxdr.filters.saveError'));
        }
      });
  }

  @HostListener('document:click', ['$event'])
  protected onClickOutside(event: Event): void {
    const notOutsideSelectors: string[] = [
      `.${CssSelector.FILTER_TAG}`,
      `.${CssSelector.FILTER_DROPDOWN}`,
      `.${CssSelector.INPUT_CONTAINER}`,
      `.${CssSelector.FILTER_ITEM}`
    ];

    const clickedElement: HTMLElement = event.target as HTMLElement;
    const isClickedOutside: boolean = !notOutsideSelectors.some((selector: string): Element | null =>
      clickedElement.closest(selector)
    );

    if (isClickedOutside) {
      this.resetDropdownState();
    }
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.handleEnterKey(event);
    }
  }

  @HostListener('document:scroll', ['$event'])
  public onScroll(): void {
    if (this.showFiltersDropdown) {
      this.updateDropdownPosition();
    }
  }

  protected readonly CssSelector = CssSelector;
}
