import { inject } from '@angular/core';
import { Router, CanActivateFn, CanActivateChildFn } from '@angular/router';
import { UserAuthService } from './shared/services/user-auth.service';
import { HttpUsersPreferencesService } from './shared/httpServices/http-users-preferences.service';


/**
 * Okta Auth Guard
 */
export const oktaAuthGuard: CanActivateFn = async (route, state) => {

  const userAuthService = inject(UserAuthService);

  const authenticated = await userAuthService.isAuthenticated();
  if (authenticated) { 
    return true; 
  } else {
    // Redirect to login flow.
    userAuthService.login(state.url);
    return false;
  }
}

/**
 * Analytics Guard
 */
export const analyticsGuard: CanActivateFn = () => {

  const userAuthService = inject(UserAuthService);
  const router = inject(Router);

  return userAuthService.accesses.analytics ? true : router.parseUrl('/not-authorized');
};

/**
 * SLA Auth Guard
 */
export const slaAuthGuard: CanActivateFn = async () => {

  const userPreferenceService = inject(HttpUsersPreferencesService);
  const router = inject(Router);

  let accesses = await userPreferenceService.getTenantAccesses()
  return accesses.sla ? true : router.parseUrl('/not-authorized');
}

/**
 * Child tenant guard
 */
export const childTenantGuard: CanActivateChildFn = () => {
  const userAuthService = inject(UserAuthService);
  return userAuthService.childTenantAccess;
}

/** 
 * SentinelOne Auth Guard
 */
export const sentinelOneAuthGuard: CanActivateFn = async () => {

  const userAuthService = inject(UserAuthService);
  const router = inject(Router);
  
  await userAuthService.loadUserDetails();
  const sentinelOneAccess = userAuthService.accesses.sentinelOne;
  return sentinelOneAccess ? true : router.parseUrl('/not-authorized');
}

/**
 * Threat detection Auth Guard
 */
export const threatDetectionAuthGuard: CanActivateFn = async () => {

  const userAuthService = inject(UserAuthService);
  const router = inject(Router);

  const userDetails = await userAuthService.loadUserDetails();
  const userGroups = userAuthService.getUserGroupsFromDetails(userDetails);
  if (userGroups?.includes('Access_CSOC_Reports')) {
    return true;
  }
  return router.parseUrl('/not-authorized');
}
