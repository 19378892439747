import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { YearlyAssessmentCountDto } from 'src/app/shared/services/ethicalhacking/model/ethicalHackingDtos';
import { AssessmentsByYearCircleComponent } from './assessments-by-year-circle/assessments-by-year-circle.component';

@Component({
  selector: 'app-ethicalhacking-assessments-by-year',
  templateUrl: './assessments-by-year.component.html',
  styleUrls: [
    '../../../layout/xtended/ethical-hacking-layout/styles/ethicalhacking.scss',
    './assessments-by-year.component.scss',
  ],
  standalone: true,
  imports: [CommonModule, TranslateModule, AssessmentsByYearCircleComponent],
})
export class AssessmentsByYearComponent {
  @Input() yearlyAssessmentSummary: YearlyAssessmentCountDto[];
}
