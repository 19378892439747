import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SignalSearchCriteria, SupportCaseSearchCriteria } from '../model/searchCriteria';
import { Feedback } from 'src/app/dashboard/feedbacks/add-feedback/add-feedback.component';
import { AdvisorySavedFilter } from '../model/worldwatch';


export class ModalParameters {
  title: string;
  bodyMessage: string;
  successBtnLabel: string;
  closeBtnLabel: string;
  successCallback;
  closeCallback;

  constructor() { }
}

export class EditColumnParameters extends ModalParameters {
  dropdownSettingsColumns: IDropdownSettings;
  columnsSelection: any;
  selectedColumns: any;

  constructor() { super(); }
}

export class SaveFilterParameters extends ModalParameters {
  savedSearches: (SupportCaseSearchCriteria | SignalSearchCriteria | AdvisorySavedFilter)[];

  constructor() { super(); }
}

export class GenerateReportParameters extends ModalParameters {
  public reportCanBeScheduled: boolean;

  constructor() { super(); }
}

export class NotificationParameters extends ModalParameters {
  category: number;
  datetime: string;
  link?: string;

  constructor() { super(); }
}

export class ExportParameters {
  public pdfName = '';
  public exportColumns : string;
  public reportOption;
  public pdfAccessLevel;
  public isReportScheduled = false;
  public time = '';

  constructor(pdfName, exportColumns, reportOption, pdfAccessLevel, isReportScheduled = false, time = '') {
    this.pdfName = pdfName;
    this.exportColumns = exportColumns;
    this.reportOption = reportOption;
    this.pdfAccessLevel = pdfAccessLevel;
    this.isReportScheduled = isReportScheduled;
    this.time = time;
   }

}

export class GlobalFeedbackParameters extends ModalParameters {
  page: string;
  feedback: Feedback;
  mode: string;
  constructor(page, feedback, mode) {
    super();
    this.page = page;
    this.feedback = feedback;
    this.mode = mode;
  }
}

export class RatingAnswers extends ModalParameters {
  ratings : RatingAnswer[];

  constructor(answers) {
    super();
    this.ratings = answers;

    this.ratings.sort((a, b) => {
      return parseFloat(a.answers[0].answer) - parseFloat(b.answers[0].answer);
    });
  }
}

export class RatingAnswer {
  answers:[{
    answer : string,
    complement : string,
  }];
  login : string;
  totalCount : number;
}


export class MfdDynamicAnalysisReportParameters extends ModalParameters {
  reportAnalysis: Blob;
  filename: string;
  constructor() { super(); }
}

export class SaveFilterResponse {
  filterName: string;
  selectedFilter: SupportCaseSearchCriteria | SignalSearchCriteria;
}