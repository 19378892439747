<div class="rounded p-2 border border-0 bg-light" style="--bs-bg-opacity: 0.2">
  <h4 class="ms-2 mb-1">{{ insight.title | translate }}</h4>
  <div class="d-flex">
    <div
      class="col-6 d-flex flex-column justify-content-center align-items-center gap-2"
    >
      <div>
        <div class="d-flex justify-content-between gap-2 flex-wrap">
          <div class="fs-1 fw-bold">{{ insight.periodTotal }}</div>
          <div>
            <div
              class="fs-5 d-flex justify-content-center align-items-center {{
                getStateClass()
              }}"
            >
              @if (getState() == 'increase') {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                [attr.fill]="this.insight.lowerIsBetter ? '#34c833' : '#CD3C14'"
                class="solaris-icon si-download"
                viewBox="0 0 1000 1000"
              >
                <path
                  d="M300 524V174a75.221 75.221 0 0 1 75-75h250a75.221 75.221 0 0 1 75 75v350h225L567.862 871.862A98.064 98.064 0 0 1 500 899a98.065 98.065 0 0 1-67.857-27.141L75 524z"
                  style="fill-rule: evenodd"
                ></path>
              </svg>
              } @if (getState() == 'decrease') {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                [attr.fill]="this.insight.lowerIsBetter ? '#CD3C14' : '#34c833'"
                class="solaris-icon si-upload"
                viewBox="0 0 1000 1000"
              >
                <path
                  d="M700 450v350a75.22 75.22 0 0 1-75 75H375a75.22 75.22 0 0 1-75-75V450H75l357.138-347.862A98.064 98.064 0 0 1 500 75a98.066 98.066 0 0 1 67.857 27.141L925 450z"
                  style="fill-rule: evenodd"
                ></path>
              </svg>
              }
              {{ insight.periodTotal - insight.previousPeriodTotal }}
            </div>
          </div>
        </div>
      </div>
      <!-- Comparing previous periods are only useful after some data was gathered -->
      @if (insight.previousPeriodTotal !== 0) {
      <span>
        <span class="fw-bold">{{ getVariationPercent() }}%</span>
        {{ 'pages.cybersocxdr.insight.lastPeriod' | translate }}
      </span>
      }
    </div>
    <!-- The graph is expected to be a 100px tall, irrespective of the screen size -->
    <div class="col-6 insightChartContainer">
      <app-insightchart
        [data]="insight.lastFiveValues"
        [isPositive]="isPositive()"
        [showChartIfNoData]="true"
      ></app-insightchart>
    </div>
  </div>
</div>
