// ENUM

export enum Pages {
  create = 'create',
  assets = 'assets',
  flagged = 'flagged',
  locations = 'locations',
  cases = 'cases',
  tp = 'tp',
  contacts = 'contacts',
  submit = 'submit',
  vulns = 'vulns',
  watched = 'watched',
  signals = 'signals',
  analytics = 'analytics'
}

export enum SubPages {
  data = 'data',
  actionsAnalytics = 'actionsAnalytics',
  activityAnalytics = 'activityAnalytics',
}

export enum LocalStorageItem {
  //oktaTenants = 'okta-tenants',
  oktaSelectedTenant = 'okta-selected-tenant',
  oktaMta = 'okta-mta',
  oktaMtaExpires = 'okta-mta-expires',
  vitalRecords = 'vitalRecords',
  itsmAssets = 'itsmAssets',
  selectedTheme = 'selectedTheme',
  profilePicture = 'profilePicture',
  gpAlerts = 'gp-alerts'
}

export enum SessionStorageItem {
  assetRetention = 'assetRetention',
  itsmContacts = 'itsmContacts',
  latestSignals = 'latestSignals',
  milestones = 'milestones',
  itsmServices = 'itsmServices',
  itsmCasesUpdatedLastLoginCount = 'itsmCasesUpdatedLastLoginCount',
  itsmCasesCreatedLastLoginCount = 'itsmCasesCreatedLastLoginCount',
  lastLogin = 'lastLogin',
  vulnsLastLoginCount = 'vulnsLastLoginCount',
  signalsLastLoginCount = 'signalsLastLoginCount',
  oktaAppUrl = 'okta-app-url',
  vulnAnnualSeverityPet = 'vulnAnnualSeverityPet',
  vulnAnnualSeveritySeries = 'vulnAnnualSeveritySeries',
  vulnAnnualSeverityCategories = 'vulnAnnualSeverityCategories',
  vulnTotalProducts = 'vulnTotalProducts',
  vulnTotalVendors = 'vulnTotalVendors',
  vulnTotalVulns = 'vulnTotalVulns',
  vulnLastUpdated = 'vulnLastUpdated',
  vulnWeekWord = 'vulnWeekWord',
  language = 'language',
  tenantRegion = 'tenantRegion'
}

export enum HeaderProperties {
  authorization = 'Authorization',
  bearer = 'Bearer',
  oktaOauthNonce = 'okta-oauth-nonce',
  contentEncoding = 'Content-Encoding',
  contentType = 'Content-Type',
  accept = 'Accept'
}

export enum UserPreferences {
  widgetsSelection = 'widgetsSelection',
  flags = 'flags',
  selectedWidgets = 'selectedWidgets',
  watched = 'watched'
}

export enum FileExtension {
  pdf = 'pdf',
  csv = 'csv'
}

export enum UserTheme {
  light = 'light',
  dark = 'dark'
}

export enum CssTheme {
  white = 'bg-white',
  black = 'bg-black',
}

export enum CssColor {
  white = '#fff',
  gray100= '#fafafa',
  gray200= '#f6f6f6',
  gray300 = '#eee',
  gray400 = '#ddd',
  gray500 = '#ccc',
  gray600 = '#999',
  gray700 = '#666',
  gray800 = '#595959',
  gray900 = '#333',
  black = '#000',
  primary = '#ff7900',

  info = '#527edb',
  success = '#32c832',
  warning = '#fc0',
  danger = '#cd3c14'
}

// CONSTANTS

export const PDF_ACCESS_LEVELS = {
  internal: { title: 'pages.generic.ACCESS_INTERNAL_REPORTS', label: 'pages.generic.internal' , value: '0'},
  confidential: { title: 'pages.generic.ACCESS_CONFIDENTIAL_REPORT', label: 'pages.generic.confidential', value: '1'},
  restricted: { title: 'pages.generic.ACCESS_RESTRICTED_REPORTS', label: 'pages.generic.restricted', value: '2'},
};

export const EXPORT_TYPES = {
  pdf: { label: 'PDF', value: FileExtension.pdf},
  csv: { label: 'CSV', value: FileExtension.csv},
};

export const EXPORT_COLUMNS = {
  allColumns : { label: 'modals.export.allColumns', value: 'all' },
  selectedColumns : { label: 'modals.export.selectedColumns', value: 'selected' }
}

export const dropdown_settings = {
  limitSelection: 8,
  itemsShowLimit: 2
}
