/* eslint-disable @typescript-eslint/member-ordering */
import { Component } from '@angular/core';
import { SeriesOptionsType } from 'highcharts';
import { ChartService } from 'src/app/shared/services/chart.service';
import * as Highcharts from 'highcharts';
import { CountResponse, UserActivityService } from 'src/app/shared/services/user-activity.service';
import Drilldown from 'highcharts/modules/drilldown';
import { TranslateService } from '@ngx-translate/core';
import { WidgetService } from 'src/app/shared/services/widget.service';
import { ContextService } from 'src/app/shared/services/context-service';
import { BaseAnalyticsComponent } from '../base-analytics/base-analytics.component';
import { TYPES } from 'src/app/shared/constants';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';
Drilldown(Highcharts);

@Component({
  selector: 'app-activity-analytics',
  templateUrl: '../base-analytics/base-analytics.component.html',
  styleUrls: ['../base-analytics/base-analytics.component.scss'],
})
export class ActivityAnalyticsComponent extends BaseAnalyticsComponent {

  private drilldownSeriesNames: string[];
  private labelsRotation = false;

  //language param
  private langageData: {name: string; y: number}[];

  //region param
  private regionData: {name: string; y: number}[];

  // training param
  private trainingData: {name: string; y: number}[];

  //external service params
  private partnerServicesCategories: string[];
  private partnerServicesData: {name: string; y: number}[];

  // top ten params
  private topTenCategories: string[];
  private topTenData: {name: string; y: number; page: string}[];

  private uniqueUsersData: number[][];
  private userActivityData: number[][];

  //view page params
  private viewPageData: {name: string; drilldown: string; y: number}[];
  private viewPageDrilldownSeries: SeriesOptionsType[];

  // widget params
  private widgetsCategories: string[];
  private widgetsUsageData: {name: string; y: number}[];

  constructor(
    private chartService: ChartService,
    private translateService: TranslateService,
    public widgetService: WidgetService,
    public contextService: ContextService,
    public userActivityService: UserActivityService,
    public userpref: UserPreferenceService
  ) {
    super(userActivityService, widgetService, contextService, userpref);
  }

  /**
   * Map count data to graph data
   * @param data 
   */
  public filterCallBack(data: CountResponse) {

    // Generate most visited sections data
    this.viewPageData = data.types
      .map(item => ({name:item.id, drilldown:item.id, y:item.count}))
      .sort((c1, c2) => c2.y - c1.y);

    this.drilldownSeriesNames = data.pages
      .map(item => item.type)
      .reduce(
        (previous, current)=> previous.includes(current) ? previous : previous.concat([current]),
        []
      );
    
    this.viewPageDrilldownSeries = this.drilldownSeriesNames.map((name) => ({
      name,
      id: name,
      data: data.pages.filter(item => item.type === name).map(item => [item.page, item.count]),
      type: null
    }));

    // Generate external services widget
    this.partnerServicesData = data.partnerServices
      .map(item => ({name:item.id, y:item.count}))
      .sort((w1, w2) => w2.y - w1.y)
    this.partnerServicesCategories = this.partnerServicesData.map( widget => widget.name);

    // Generate Widgets usage data
    this.widgetsUsageData = data.widgetsUsage
      .map(item => ({name:item.id, y:item.count}))
      .sort((w1, w2) => w2.y - w1.y)
    this.widgetsCategories = this.widgetsUsageData.map( widget => widget.name);

    // Generate top ten visited pages data
    this.topTenData = data.pages
      .map( (page) => ({name:`${page.page} (${page.type})`, y: page.count, page: page.page}))
      .sort((p1, p2) => p2.y - p1.y);
    this.topTenData.length = 10;
    this.topTenCategories = this.topTenData.map( page => page.page);

    // Generate training visited pages data
    this.trainingData = data.pages
      .filter( page => page.type === TYPES.training)
      .map( (page) => ({name:page.page, y: page.count, page: page.page}))
      .sort((p1, p2) => p2.y - p1.y);

    // Generate user activity data
    this.userActivityData = data.days.map(item => ([new Date(item.id).getTime(), item.count]));

    // Generate unique users data
    this.uniqueUsersData = data.users.map(item => ([new Date(item.id).getTime(), item.count]));

    // Generate user regions data
    this.regionData = data.regions
      .map(item => ({name:item.id, y:item.count}))
      .sort((c1, c2) => c2.y - c1.y);

    // Generate user languages data
    this.langageData = data.langages
      .map(item => ({name:item.id, y:item.count}))
      .sort((c1, c2) => c2.y - c1.y);

    // Generate charts
    this.loadWidgets(this.generateChartsOptions());
    this.pageLoading = false;
  }

  public generateChartsOptions(): Highcharts.Options[] {
    // Define user activities widget
    const usersActivitySeries: SeriesOptionsType[] = [
      {
        name: this.translateService.instant('pages.dashboard.analytics.dailyActivity'),
        data: this.userActivityData,
        type: 'line'
      },
    ];
    const uniqueUsersSeries: SeriesOptionsType[] = [
      {
        name: this.translateService.instant('pages.dashboard.analytics.users'),
        data: this.uniqueUsersData,
        type: 'line'
      },
    ];
    const topTenSeries: SeriesOptionsType[] = [
      {
        name: this.translateService.instant('pages.dashboard.analytics.topTenVisitedPages'),
        data: this.topTenData,
        type: 'bar'
      },
    ];
    const widgetsUsageSeries: SeriesOptionsType[] = [
      {
        name: this.translateService.instant('pages.dashboard.analytics.dashboardWidgetsUsage'),
        data: this.widgetsUsageData,
        type: 'bar'
      },
    ];
    const widgetsPartnerServicesSeries: SeriesOptionsType[] = [
      {
        data: this.partnerServicesData,
        type: 'bar'
      },
    ];
    const langagesSeries: SeriesOptionsType[] = [
      {
        name: this.translateService.instant('pages.dashboard.analytics.userLanguages'),
        data: this.langageData,
        type: 'pie'
      },
    ];
    const regionsSeries: SeriesOptionsType[] = [
      {
        name: this.translateService.instant('pages.dashboard.analytics.userRegions'),
        data: this.regionData,
        type: 'pie'
      },
    ];
    const trainingSeries: SeriesOptionsType[] = [
      {
        name: this.translateService.instant('pages.dashboard.analytics.visitedTrainingCourses'),
        data: this.trainingData,
        type: 'pie'
      },
    ];
    
    const options = [
      this.chartService.initBarChartOptions(
        this.translateService.instant('pages.dashboard.analytics.dashboardWidgetsUsage'),
        '',
        this.widgetsCategories,
        this.translateService.instant('pages.dashboard.analytics.totalActiveOnDashboards'),
        widgetsUsageSeries
      ),
      this.chartService.initBarChartOptions(
        this.translateService.instant('pages.dashboard.analytics.topTenVisitedPages'),
        '',
        this.topTenCategories,
        null,
        topTenSeries
      ),
      this.chartService.initColumnOptions(
        this.translateService.instant('pages.dashboard.analytics.mostVisitedSections'),
        this.translateService.instant('pages.dashboard.analytics.consultations'),
        this.translateService.instant('pages.dashboard.analytics.visits'),
        this.viewPageData,
        this.viewPageDrilldownSeries,
        null,
        this.labelsRotation
      ),
      this.chartService.initLineChartOptions(
        this.translateService.instant('pages.dashboard.analytics.userActivity'),
        this.translateService.instant('pages.dashboard.analytics.consultations'),
        usersActivitySeries
      ),
      this.chartService.initLineChartOptions(
        this.translateService.instant('pages.dashboard.analytics.uniqueUsers'),
        this.translateService.instant('pages.dashboard.analytics.users'),
        uniqueUsersSeries
      ),
      this.chartService.initCatPieChartOptions(
        this.translateService.instant('pages.dashboard.analytics.userLanguages'),
        langagesSeries,
        null
      ),
      this.chartService.initCatPieChartOptions(
        this.translateService.instant('pages.dashboard.analytics.userRegions'),
        regionsSeries,
        null
      ),
      this.chartService.initBarChartOptions(
        this.translateService.instant('pages.dashboard.analytics.partnerServiceWidgetTitle'),
        undefined,
        this.partnerServicesCategories,
        undefined,
        widgetsPartnerServicesSeries
      ),
      this.chartService.initCatPieChartOptions(
        this.translateService.instant('pages.dashboard.analytics.visitedTrainingCourses'),
        trainingSeries,
        null
      )
    ];
    return options;
  }
}
