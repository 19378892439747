<section class="rowfiller">
  @if(_currentPage){
  <app-xtended-incident-list
    class="app-incident"
    [title]="title"
    [cases]="_currentPage.items"
    [loaded]="true"
    [badgeAnnotation]="badgeAnnotation"
    [selectableColumns]="selectableColumns"
    [exportable]="exportable"
    [exportLoading]="exportLoading"
    (exportEmitter)="exportCSV()"
    (sortEmitter)="sortCases($event)"
    (newFlagEmitter)="flagCase($event)"
  ></app-xtended-incident-list>

  <div style="display: flex; justify-content: center">
    @if(_currentPage.page != 0){
    <button class="btn" type="button" (click)="loadPage(_currentPage.page - 1)">
      Page précédente
    </button>
    } @for(page of generatePageArray(_currentPage.page); track $index;){

    <ng-container>
      <button
        class="btn"
        type="button"
        (click)="loadPage(page)"
        [disabled]="page === _currentPage.page"
      >
        {{ page + 1 }}
      </button>
    </ng-container>
    } @if(_currentPage.page != totalPages - 1){
    <button class="btn" (click)="loadPage(_currentPage.page + 1)">
      Page suivante
    </button>
    }
  </div>
  }
</section>
