<div class="overview-section">

  @if (!loading) {
    <div>
      @for (cardsRow of cards; track cardsRow) {
        <div class="cards-row" [ngStyle]="getStyles(cardsRow)">
          @for (card of cardsRow; track card; let index = $index) {
            <div class="card">
              @switch (card.type) {
                @case (mesCardType.SIMPLE) {
                  <div class="simple-card">
                    <div class="card-header simple-content">
                      {{ card.titleKey | translate }}
                    </div>
                    <div class="card-body">
                      <p class="card-text">
                        @if (card.data) { {{ card.textKey | translate:{nb: card.data} }} } @else { Pending }
                      </p>
                    </div>
                  </div>
                }
                @case (mesCardType.THREAT_FUNNEL) {
                  <div class="card-header multi-content">
                    <span>{{ card.titleKey | translate }}</span>
                    <select class="filter form-select"
                      name="filter-period"
                      id="filter-period"
                      aria-label="Filter by period"
                      [(ngModel)]="threatFunnelDateSelected"
                      (change)="reloadThreatFunnel()">
                      @for (dateOption of threatFunnelDateOptions; track dateOption.value;) {
                        <option value="{{dateOption.value}}">{{ dateOption.textKey | translate }}</option>
                      }
                    </select>
                  </div>
                  <div class="card-body">
                    <div class="grid-layout">
                      
                      <div class="grid-item exergue" *ngFor="let item of card.data; let i = index">
                        <div
                          class="exergue-value primary"
                          [ngStyle]="{'color': threatFunnelColors[i]}">
                            {{ item.value }}
                        </div>
                        <div>{{ item.textKey | translate }}</div>
                      </div>
                      
                    </div>
                  </div>
                }
                @case (mesCardType.ENDPOINTS_MONITORED) {
                  <div class="card-header multi-content">
                    <span>{{ card.titleKey | translate }}</span>
                  </div>
                  <div class="card-body">
                    <div class="grid-layout">
                      
                      <div
                        class="grid-item card"
                        [ngStyle]="{'background-color': lighterColors[lighterColors.length-(j+2)]}"
                        *ngFor="let item of card.data; let j = index">
                        <div *ngIf="item.length === 1">
                          <div class="exergue">
                            <div class="exergue-value primary"> {{ item[0].value }} </div>
                            <div>{{ item[0].textKey | translate }}</div>
                          </div>
                        </div>
                        <div *ngIf="item.length > 1" >
                          <div *ngFor="let subItem of item; let i = index">
                            <div class="exergue exergue-inline">
                              <span class="exergue-value secondary">
                                  {{ subItem.value }}
                              </span>
                              <span>{{ subItem.textKey | translate }}</span>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                      
                    </div>
                  </div>
                }
              }
            </div>
          }
          
        </div>
      }
      
    </div>
  } @else {
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
  }
  
</div>
