<main
  class="container-fluid g-0 {{ isDarkThemeEnabled ? 'dark' : 'light' }}-theme"
>
  <div class="side-bar">
    <div class="sticky-top">
      <app-sidemenu
        [menuLinks]="menuLinks"
        [menuTitle]="'menu.ethicalhacking.title'"
        [titleRoute]="'/ethical-hacking'"
      />
    </div>

    <div class="sticky-bottom d-lg-block d-none">
      @if (isDevMode()) {
      <dev-switch-theme-button
        [darkModeState]="isDarkThemeEnabled"
        (onStateChange)="isDarkThemeEnabled = $event"
      />
      }
    </div>
  </div>

  <div class="w-100 h-100">
    @if (getCurrentPage()) {
    <div
      class="header sticky-top py-1 px-2 d-flex justify-content-between align-items-center"
    >
      <h3 class="m-2">
        <breadcrumb />
      </h3>

      @if(getCurrentPage().shouldDisplayMailButton) {
      <app-request-new-assessment-button />
      }
    </div>
    }

    <router-outlet (activate)="onActivate()" />
  </div>
</main>
