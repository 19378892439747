// ENUMS

export enum GROUPS_POLICY { DETECT = 'detect', PROTECT = 'protect' }

export enum SentinelOneTab {
  OVERVIEW = 'Overview',
  ENDPOINT_MANAGEMENT = 'Endpoint Management',
  THREAT_MANAGEMENT = 'Threat Management'
};

export enum SentinelOneCardType {
  SIMPLE, TABLE, GRAPH, THREAT_FUNNEL, ENDPOINTS_MONITORED
};

export enum ThreatConfidence {
  MALICIOUS = 'malicious',
  SUSPICIOUS = 'suspicious' 
};

export const dateOptions: {value: string, textKey: string}[] = [
  {value: '15m', textKey: 'pages.updates.sentinelOne.dates.last15Minutes'},
  {value: '30m', textKey: 'pages.updates.sentinelOne.dates.last30Minutes'},
  {value: '1h', textKey: 'pages.updates.sentinelOne.dates.last1Hour'},
  {value: '24h', textKey: 'pages.updates.sentinelOne.dates.last24Hours'},
  {value: '7d', textKey: 'pages.updates.sentinelOne.dates.last7Days'},
  {value: '30d', textKey: 'pages.updates.sentinelOne.dates.last30Days'},
];

// TYPES

export type SentinelOneCardDataItem = {name: string, textKey: string, value: number, color?: string};
export type SentinelOneGraphData = {titleKey: string, data: SentinelOneCardDataItem[]};
export type SentinelOneTableData = {columnKeys: string[], data: TableRow[]};

export class TableRow {
  value: any[];
  loading: boolean;

  constructor(array: any[]) {
    this.value = array;
  }
};

export type SentinelOneData = {
  data: any[];
  pagination: {nextCursor: any; totalItems: number };
};

// CLASSES

export abstract class SentinelOneItem {
  type: SentinelOneCardType;
  titleKey: string;
  textKey: string | string[];
  graphOptions?: any;
  buttonLink?: any;
}  

export class SentinelOneCardNumber extends SentinelOneItem {
  data: number;
}

export class SentinelOneCardList extends SentinelOneItem {
  data: SentinelOneCardDataItem[];
}

export class SentinelOneCardDoubleList extends SentinelOneItem {
  data: SentinelOneCardDataItem[][];
}

export class SentinelOneCardTable extends SentinelOneItem {
  data: SentinelOneTableData;
}

export class SentinelOneCardGraph extends SentinelOneItem {
  data: SentinelOneGraphData;
}


export type SentinelOneCard = SentinelOneCardNumber | SentinelOneCardList | SentinelOneCardDoubleList | SentinelOneCardGraph | SentinelOneCardTable

export class ThreatsCount {
  totalCount: number;
  data: any[];

  constructor(totalCount: number, data: any[]) {
    this.totalCount = totalCount;
    this.data = data;
  }
};

export class EndPoint {
  totalCount: number;
  data: any[];
  groups: {name: string, count: number}[]

  constructor(totalCount: number, data: any[]) {
    this.totalCount = totalCount;
    this.data = data;
  }
};

export class EndpointData {
  name: string;
  type: string;
  os: string;
  lastSeen: string;
  critical: boolean;
  policy: string;

  constructor(name: string, type: string, os: string, lastSeen: string, critical: boolean, policy: string) {
    this.name = name;
    this.type = type;
    this.os = os;
    this.lastSeen = lastSeen;
    this.critical = critical;
    this.policy = policy;
  }
}

export class SentinelOneSecurityIncident {
  caseNumber: string;
  priority: string;
  /* confidence: string;
  classification: string; */
  status: string;
  created: string;
  link: string;
  closable?: boolean;

  constructor(
    caseNumber: string,
    priority: string,
    confidence: string,
    classification: string,
    status: string,
    created: string,
    link: string
  ) {
    this.caseNumber = caseNumber;
    this.priority = priority;
    /* this.confidence = confidence;
    this.classification = classification; */
    this.status = status;
    this.created = created;
    this.link = link;
  }
}

export class SentinelOneGroupEnum {
  #policy: string;
  #groupName: string;
  #isCritical: boolean;

  // Hard-coded groups. To make evolve accordingly to the customer's data
  static groups = [
    new SentinelOneGroupEnum('Default Group', GROUPS_POLICY.DETECT, false),
    new SentinelOneGroupEnum('Workstations', GROUPS_POLICY.PROTECT, false),
    new SentinelOneGroupEnum('Critical Assets', GROUPS_POLICY.DETECT, true),
    new SentinelOneGroupEnum('Servers', GROUPS_POLICY.PROTECT, false),
  ];

  constructor(groupName: string, policy: GROUPS_POLICY, isCritical: boolean) {
    this.#groupName = groupName;
    this.#policy = policy;
    this.#isCritical = isCritical;
  }

  static getGroupsByPolicy(policy: GROUPS_POLICY) {
    return SentinelOneGroupEnum.groups.filter( group => group.#policy === policy );
  }

  static getGroup(groupName: string) {
    return SentinelOneGroupEnum.groups.find( group => group.#groupName === groupName );
  }

  static getGroupNames(): string[] {
    return SentinelOneGroupEnum.groups.map( group => group.#groupName);
  }

  static getPolicy(groupName: string) {
    return this.getGroupNames().includes(groupName)
      ?  this.getGroup(groupName).#policy
      : GROUPS_POLICY.DETECT; // default value
  }

  static isCritical(groupName: string): boolean {
    return this.getGroupNames().includes(groupName)
      ? this.getGroup(groupName).#isCritical
      : false; // default value
  }

};
