import { Component, inject, OnInit } from '@angular/core';
import { XtendedFiltersService } from '../../xtendedfilters.service';
import { Tab } from '../../components/tabbar/tabbar.component';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { XtendedClientService } from 'src/app/shared/services/xtendedsoc/xtended-client.service';
import {
  AgentsVersionByProductTypeDTO,
  EndpointDataDTO,
  OSRepartitionDataDTO,
  PolicyRepartitionByProductTypeDTO,
} from 'src/app/shared/model/cybersocxdr/clientHosts';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-my-it',
  templateUrl: './my-it.component.html',
})
export class MyItComponent implements OnInit {
  public tabs: Tab[] = [
    {
      titleKey: 'pages.cybersocxdr.myIT.overview',
      value: 'overview',
      url: '/xtended-soc/my-it/overview',
    },
  ];
  public selectedTab: string;

  public hostsData: Record<string, EndpointDataDTO> = {};
  private osRepartitionData: Record<string, OSRepartitionDataDTO[]> = {};
  private agentsVersionByProductTypeData: Record<string, AgentsVersionByProductTypeDTO[]> = {};
  private policiesRepartitionByProductTypeData: Record<string, PolicyRepartitionByProductTypeDTO[]> = {};

  public installedAgentSeries: Record<string, Highcharts.SeriesSplineOptions[]> = {};
  public osRepartitionSeries: Record<string, Highcharts.SeriesSunburstOptions[]> = {};
  public agentsVersionByProductTypeSeries: Record<string, Highcharts.SeriesColumnOptions[]> = {};
  public agentsVersionOptions: Record<string, string[]> = {};
  public policiesRepartitionByProductTypeSeries: Record<string, Highcharts.SeriesBarOptions[]> = {};
  public policiesRepartitionByProductTypeOptions: Record<string, string[]> = {};

  public agentsCards: { title: string; key: keyof EndpointDataDTO; description?: string; type?: string }[] = [
    {
      title: 'pages.cybersocxdr.myIT.field.connectedAgents',
      key: 'connected_agents',
      description: 'pages.cybersocxdr.myIT.field.connectedAgentsDescription',
      type: 'connected',
    },
    {
      title: 'pages.cybersocxdr.myIT.field.offlineAgents',
      key: 'offline_agents',
      description: 'pages.cybersocxdr.myIT.field.offlineAgentsDescription',
      type: 'offline',
    },
    {
      title: 'pages.cybersocxdr.myIT.field.lostAgents',
      key: 'lost_agents',
      description: 'pages.cybersocxdr.myIT.field.lostAgentsDescription',
      type: 'lost',
    },
    {
      title: 'pages.cybersocxdr.myIT.field.quarantinedAgents',
      key: 'quarantined_agents',
      type: 'quarantined',
    },
  ];

  private readonly filterService: XtendedFiltersService = inject(XtendedFiltersService);
  private readonly router: Router = inject(Router);
  private readonly translate: TranslateService = inject(TranslateService);
  private readonly xtendedClientService: XtendedClientService = inject(XtendedClientService);
  private readonly route: ActivatedRoute = inject(ActivatedRoute);

  ngOnInit() {
    this.tabs = [
      ...this.tabs,
      ...(this.filterService.clientInfo?.availableHostsTechnos?.map((techno) => ({
        titleKey: 'pages.cybersocxdr.myIT.technos.' + techno,
        value: techno,
        url: `/xtended-soc/my-it/${techno}`,
      })) || []),
    ];

    this.route.paramMap.subscribe((params: ParamMap) => {
      const tab = params.get('tab');
      if (['overview', ...(this.tabs?.map((t) => t.value) || [])].includes(tab)) this.selectedTab = tab;
      else {
        this.router.navigate(['/xtended-soc', 'my-it', 'overview']);
      }
    });

    this.selectedTab = 'overview';

    this.getHostsData();
    this.getOSRepartitionData();
    this.getAgentsVersionByProductType();
    this.getPoliciesRepartitionByProductType();
  }

  private async getHostsData(): Promise<void> {
    this.filterService.clientInfo?.availableHostsTechnos.forEach(async (techno) => {
      const hostsData = await this.xtendedClientService.getHostsData(techno);
      this.hostsData[techno] = hostsData;
      this.installedAgentSeries[techno] = this.getHostsDataByTechnoEvolution(techno);
    });
  }

  private async getOSRepartitionData(): Promise<void> {
    this.filterService.clientInfo?.availableHostsTechnos.forEach(async (techno) => {
      const osRepartitionData = await this.xtendedClientService.getOSRepartitionData(techno);
      this.osRepartitionData[techno] = osRepartitionData;
      this.osRepartitionSeries[techno] = this.getOSRepartitionDataByTechno(techno);
    });
  }

  private async getAgentsVersionByProductType(): Promise<void> {
    this.filterService.clientInfo?.availableHostsTechnos.forEach(async (techno) => {
      const agentsVersionByProductType = await this.xtendedClientService.getAgentsVersionByProductType(techno);
      this.agentsVersionByProductTypeData[techno] = agentsVersionByProductType;
      this.agentsVersionByProductTypeSeries[techno] = this.getAgentsVersionByProductTypeSeries(techno);
    });
  }

  private async getPoliciesRepartitionByProductType(): Promise<void> {
    this.filterService.clientInfo?.availableHostsTechnos.forEach(async (techno) => {
      const policiesRepartitionByProductType = await this.xtendedClientService.getPoliciesRepartitionByProductType(
        techno
      );
      this.policiesRepartitionByProductTypeData[techno] = policiesRepartitionByProductType;
      this.policiesRepartitionByProductTypeSeries[techno] = this.getPoliciesRepartitionByProductTypeSeries(techno);
    });
  }

  private getHostsDataByTechnoEvolution(techno: string): Highcharts.SeriesSplineOptions[] {
    return [
      {
        type: 'spline',
        name: this.translate.instant('pages.cybersocxdr.myIT.installedAgents'),
        data: this.hostsData[techno].agents_amount_evolution.map((evolution) => [
          DateTime.fromISO(evolution.date).toMillis(),
          evolution.amount,
        ]),
      },
      {
        type: 'spline',
        name: this.translate.instant('pages.cybersocxdr.myIT.agentAmount'),
        data: this.hostsData[techno].agents_amount_evolution.reduce((acc, evolution, index) => {
          if (index === 0) return [[DateTime.fromISO(evolution.date).toMillis(), evolution.amount]];
          return [...acc, [DateTime.fromISO(evolution.date).toMillis(), acc[index - 1][1] + evolution.amount]];
        }, [] as [number, number][]),
      },
    ];
  }

  private getOSRepartitionDataByTechno(techno: string): Highcharts.SeriesSunburstOptions[] | null {
    return this.getOSRepartitionDataByOS(this.osRepartitionData[techno] ?? []);
  }

  private getOSRepartitionDataByOS(osRepartitionData: OSRepartitionDataDTO[]): Highcharts.SeriesSunburstOptions[] {
    return [
      {
        type: 'sunburst',
        name: 'OS',
        allowTraversingTree: true,
        cursor: 'pointer',
        data: [
          ...osRepartitionData.map((product_type) => ({
            id: 'product_type-' + product_type.name,
            name: product_type.name || 'Unknown',
            value: product_type.count,
          })),
          ...osRepartitionData.flatMap((product_type) =>
            product_type.os.map((os) => ({
              id: 'product_type-' + product_type.name + '-os-' + os.name,
              parent: 'product_type-' + product_type.name,
              name: os.name,
              value: os.count,
            }))
          ),
          ...osRepartitionData.flatMap((product_type) =>
            product_type.os.flatMap((os) =>
              os.os_versions.map((osVersion) => ({
                id: 'product_type-' + product_type.name + '-os-' + os.name + '-os_version-' + osVersion.name,
                parent: 'product_type-' + product_type.name + '-os-' + os.name,
                name: osVersion.name,
                value: osVersion.count,
              }))
            )
          ),
        ],
        levels: [
          {
            level: 1,
            colorByPoint: true,
            colorVariation: {
              key: 'brightness',
            },
          },
          {
            level: 2,
            colorVariation: {
              key: 'brightness',
              to: -0.5,
            },
          },
          {
            level: 3,
            colorVariation: {
              key: 'brightness',
              to: 0.5,
            },
          },
        ],
      },
    ];
  }

  private getAgentsVersionByProductTypeSeries(techno: string): Highcharts.SeriesColumnOptions[] {
    this.agentsVersionOptions[techno] = [
      ...new Set(
        this.agentsVersionByProductTypeData[techno].flatMap((productType) =>
          productType.agent_versions.map((agentVersion) => agentVersion.version)
        )
      ),
    ];

    return this.agentsVersionByProductTypeData[techno].map((productType) => ({
      type: 'column',
      name: productType.name || 'Unknown',
      data: this.agentsVersionOptions[techno].map(
        (version) => productType.agent_versions.find((av) => av.version === version)?.count ?? 0
      ),
    }));
  }

  private getPoliciesRepartitionByProductTypeSeries(techno: string): Highcharts.SeriesBarOptions[] {
    // Use name as xAxisCategories instead of id to regroup policies by name
    this.policiesRepartitionByProductTypeOptions[techno] = [
      ...new Set(
        this.policiesRepartitionByProductTypeData[techno].flatMap((productType) =>
          productType.policies.map((policy) => policy.name)
        )
      ),
    ];

    return this.policiesRepartitionByProductTypeData[techno].map((productType) => ({
      type: 'bar',
      name: productType.name || 'Unknown',
      data: this.policiesRepartitionByProductTypeOptions[techno].map((policyName) => {
        const matches = productType.policies.filter((policy) => policy.name === policyName);
        return matches.reduce((sum, policy) => sum + (policy.count ?? 0), 0);
      }),
    }));
  }
}
