import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { dateOptions, SentinelOneCard, SentinelOneCardDataItem, SentinelOneCardGraph, SentinelOneCardList, SentinelOneCardTable, SentinelOneCardType, SentinelOneGraphData, SentinelOneItem, TableRow } from '../sentinel-one-models';
import { S1_DATA, TABLE_ACTIONS, sentinelOneGraphOptions } from '../sentinel-one-constants';
import { ContextService } from 'src/app/shared/services/context-service';
import * as Highcharts from 'highcharts';
import { DataPoint } from 'src/app/reports/reports-live/report-live-securitycase/chartModels';
import { WidgetService } from 'src/app/shared/services/widget.service';
import { TranslateService } from '@ngx-translate/core';
import { Utilities } from 'src/app/shared/utilities';
import { clone } from '@okta/okta-auth-js';
import { Case, CaseType, MESActions, MESUpdate } from 'src/app/shared/model/itsm';
import { SupportCaseSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { constants } from 'src/app/shared/constants';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { ModalParameters } from 'src/app/shared/modals/modal';
import { getDateFromDateOption } from '../sentinel-one-utilities';

@Component({
  selector: 'app-sentinel-one-threat-management',
  templateUrl: './sentinel-one-threat-management.component.html',
  styleUrl: './sentinel-one-threat-management.component.scss'
})
export class SentinelOneThreatManagementComponent implements OnInit {

  @Input() customColors: string[];
  @Output() error = new EventEmitter<string>;

  public highcharts: typeof Highcharts = Highcharts;
  public cards: SentinelOneCard[][] = [];
  public mesCardType = SentinelOneCardType;
  public width = '100%';
  public height = '100%';
  public shiftedColors: string[] = [];
  public incidentsByPriorityFromCreatedDate = '';
  public incidentsByPriorityDateOptions: {value: string, textKey: string}[] = dateOptions;
  public incidentsByPriorityDateSelected = '30d';

  public loading: boolean;

  private openSecurityIncidentsCard: SentinelOneCardList = S1_DATA.threatManagement.openSecurityIncidentsCard;
  private incidentsByConfidenceCard: SentinelOneCardGraph = S1_DATA.threatManagement.incidentsByConfidenceCard;
  private incidentsByPriorityCard: SentinelOneCardGraph = S1_DATA.threatManagement.incidentsByPriorityCard;
  private incidentsRequiringAttentionCard: SentinelOneCardTable = S1_DATA.threatManagement.incidentsRequiringAttentionCard;
  private allSecurityIncidentsCard: SentinelOneCardTable = S1_DATA.threatManagement.allSecurityIncidentsCard;

  // data
  private cases: Case[] = [];
  private searchCriteria: SupportCaseSearchCriteria;
  private openCasesByPriority = { critical: 0, high: 0, medium: 0, low: 0, planning: 0 };

  constructor(
    private contextService: ContextService,
    private widgetService: WidgetService,
    private itsmService: ItsmService,
    private alertService: AlertService,
    private router: Router,
    private translateService: TranslateService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.shiftedColors = Utilities.shiftArray(clone(this.customColors), 5);
    this.incidentsByPriorityFromCreatedDate = getDateFromDateOption(this.incidentsByPriorityDateSelected);
    Promise.all([
      this.caseSearchOpen(),
      this.caseCountOpenByPriority(),
      this.loadIncidentsByPriority()
    ]).then( () => {
      this.loadCards();
      this.loading = false;
    }).catch( err => {
      this.loading = false;
      const errorMessage = err.message.includes("Error: ")
        ? err.message.split("Error: ")[1]
        : err;
      this.error.emit(errorMessage);
    });
  }

  private loadCards() {

    // DATA STRUCTURE
    this.openSecurityIncidentsCard.data = S1_DATA.threatManagement.openSecurityIncidentsData;
    this.incidentsByConfidenceCard.data = S1_DATA.threatManagement.incidentsByConfidenceData;
    this.incidentsByPriorityCard.data = S1_DATA.threatManagement.incidentsByPriorityData;
    this.incidentsRequiringAttentionCard.data = S1_DATA.threatManagement.incidentsRequiringAttentionData;
    this.allSecurityIncidentsCard.data = S1_DATA.threatManagement.allSecurityIncidentsData;

    // DATA MAPPING
    this.mapOpenSecurityIncidents();
    this.mapIncidentsByPriority();

    this.incidentsRequiringAttentionCard.data.data = this.cases
      .filter( item => item.status === 'Pending (Customer)' )
      .map( pendingCase => new TableRow([pendingCase.caseNumber, pendingCase.priority]) );

    this.allSecurityIncidentsCard.data.data = this.cases.map( item => { return new TableRow(this.getRowValues(item))});
    this.incidentsByConfidenceCard.graphOptions = this.getChartOptions(this.incidentsByConfidenceCard);
    this.incidentsByPriorityCard.graphOptions = this.getChartOptions(this.incidentsByPriorityCard);

    this.cards.push(
      [this.openSecurityIncidentsCard/* , incidentsByConfidenceCard */, this.incidentsByPriorityCard, this.incidentsRequiringAttentionCard],
      [this.allSecurityIncidentsCard]
    );
  }

  private mapOpenSecurityIncidents() {
    const openCasesByPriorityArray = Object.values(this.openCasesByPriority);
    const openSecurityTmpData = [openCasesByPriorityArray.reduce( (sum, a) => sum + a, 0)];
    openCasesByPriorityArray.forEach( value => openSecurityTmpData.push(value));
    this.openSecurityIncidentsCard.data.forEach( (data: SentinelOneCardDataItem, index) => {
      data.value = openSecurityTmpData[index];
    });
  }

  private mapIncidentsByPriority() {
    const openCasesByPriorityArray = Object.values(this.openCasesByPriority);
    const incidentsByPriorityTmpData = [];
    openCasesByPriorityArray.forEach( value => incidentsByPriorityTmpData.push(value));
    this.incidentsByPriorityCard.data.data.forEach( (data: SentinelOneCardDataItem, index) => {
      data.value = incidentsByPriorityTmpData[index];
    });
  }

  /**
   * open confirmation modal when user select MES action
   */
  private confirmAction(incident: Case, type: MESActions) {
    const params = new ModalParameters();
    params.title = this.translateService.instant('modals.confirmation');
    params.bodyMessage = this.translateService.instant(type === MESActions.CLOSE_CASE ? 'pages.support.dashboard.fieldTitles.closureConfirmation' : 'pages.support.dashboard.fieldTitles.investigationConfirmation', {casenumber: incident.caseNumber});
    params.successBtnLabel = this.translateService.instant('buttons.action.yes');
    params.successCallback = (() => this.updateMES(incident, type));
    params.closeBtnLabel = this.translateService.instant('buttons.action.no');
    this.modalService.confirmModal(params);
  }

  /**
   * update ticket on snow for MES particular actions (closure or investigation)
   */
  private updateMES(incident: Case, type: MESActions) {
    const index = this.allSecurityIncidentsCard.data.data.findIndex(i => i.value.includes(incident.caseNumber));
    const row = this.allSecurityIncidentsCard.data.data.at(index);
    row.loading = true;
    const update = new MESUpdate(incident.id, type);
    this.itsmService.mesUpdateCase(update).subscribe({
      next:(() => {
        row.loading = false;
        if(type === MESActions.CLOSE_CASE) {
          this.allSecurityIncidentsCard.data.data.splice(index, 1);
        } else {
          incident.underInvestigation = true;
          const updatedTable = this.getUpdateRow(this.allSecurityIncidentsCard.data.data, index, incident);
          this.allSecurityIncidentsCard.data.data = updatedTable;
        }
        const successlabel = type === MESActions.CLOSE_CASE ? 'pages.support.dashboard.fieldTitles.successfullyClosed' : 'pages.support.dashboard.fieldTitles.investigationInSuccess';
        this.alertService.addSuccess(this.translateService.instant(successlabel, {casenumber: incident.caseNumber}));
      }),
      error:(() =>  {
        row.loading = false;
        const errorlabel = type === MESActions.CLOSE_CASE ? 'pages.support.dashboard.fieldTitles.failedClosed' : 'pages.support.dashboard.fieldTitles.investigationInError'
        this.alertService.addError(this.translateService.instant(errorlabel, {casenumber: incident.caseNumber}));
      }),
    });
  }

  private getRowValues(item: Case): any[] {
    // initialize action for each icon
    const viewAction = {action:  TABLE_ACTIONS.VIEW, link: `/support/view/${item.id}`};
    //const investigationAction = item.underInvestigation ?  {action:  TABLE_ACTIONS.PROCESS, clickAction: undefined} : {action:  TABLE_ACTIONS.INVESTIGATE, clickAction: () => this.confirmAction(item, MESActions.REQUEST_INVESTIGATION)};
    const closeAction = {action:  TABLE_ACTIONS.CLOSE, clickAction: () => this.confirmAction(item, MESActions.CLOSE_CASE)};

    //return [ item.caseNumber, item.priority, '?', '?', item.status, item.createdDate, viewAction, investigationAction, closeAction];
    return [ item.caseNumber, item.priority, item.status, item.createdDate, viewAction, closeAction];
  }

  private getUpdateRow(data: TableRow[], index, item: Case): TableRow[] {
    const updateRow = this.getRowValues(item);
    data[index].value = updateRow;

    return data;
  }

  public getStyles(rowItems: SentinelOneItem[]) {
    return {
      display: 'grid',
      'grid-template-columns': `repeat(${rowItems.length}, auto)`
    }
  }

  public getDataType(data: any) {
    return typeof data;
  }

  private getChartOptions(card: SentinelOneCardGraph) {
    const dataItems = [];
    const cardData = card.data as SentinelOneGraphData;
    const chartOptions = JSON.parse(JSON.stringify(sentinelOneGraphOptions));

    const pieSeries = new DataPoint();
    pieSeries.type = 'pie';
    pieSeries.innerSize = '75%';
    pieSeries.name = this.translateService.instant(card.titleKey);

    let totalCount = 0;
    cardData.data.forEach( (dataItem, index) => {
      const item = {
        name: this.translateService.instant(dataItem.textKey),
        y: dataItem.value,
        indicator: index+1 // priorities are designated by a number between 1 and 5 in supportdashboard
      };
      totalCount += dataItem.value;
      dataItems.push(item);
    });

    pieSeries.data = dataItems;
    const series = [];
    series.push(pieSeries);
    chartOptions.series = series;

    chartOptions.plotOptions.series = {
      cursor: 'pointer',
      events: {
          click: (event) => {
              this.router.navigateByUrl('/support/dashboard/' + event.point.indicator);
          }
      }
    };

    chartOptions.title.text = '<strong>' + totalCount + '<br/>' + this.translateService.instant(cardData.titleKey) + '</strong>';

    this.widgetService.updateWidgetStyle(chartOptions, this.contextService.userSelectedTheme)
    chartOptions.chart.backgroundColor = 'transparent';
    chartOptions.colors = this.shiftedColors;
    return chartOptions;
  }

  /**
    * Triggers case search for cases that are pending Ocd
    */
  private caseSearchOpen(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.searchCriteria = new SupportCaseSearchCriteria();
      this.searchCriteria.status = constants.itsm.globalStates.open;
      this.searchCriteria.type = CaseType.incident;

      // fetch the actual records
      this.searchCriteria.SNCount = false;
      this.searchCriteria.limit = 12;
      this.searchCriteria.byPassSF = true;
      this.searchCriteria.forceTMLimit = true;

      this.itsmService.caseSearch(this.searchCriteria)
        .then(res => {
          this.cases = res as Case[];
          resolve({abandoned: false});
        })
        .catch(err => {
          reject();
          this.alertService.handlerError(this.translateService.instant('pages.updates.sentinelOne.errors.SNowError'));
        });
    });
  }

  private async caseCountOpenByPriority(fromDate?: string): Promise<any> {
    const promises: Promise<any>[] = [];
    const priorities = [
      '1 - Business Critical',
      '2 - High',
      '3 - Medium',
      '4 - Low',
      '5 - planning'
    ];
    const openCasesByPriorityKeys = Object.keys(this.openCasesByPriority);
    this.searchCriteria = new SupportCaseSearchCriteria();
    this.searchCriteria.status = constants.itsm.globalStates.open;
    this.searchCriteria.SNCount = true;
    this.searchCriteria.limit = undefined;
    this.searchCriteria.byPassSF = true;
    this.searchCriteria.forceTMLimit = true;
    if (fromDate) {
      this.searchCriteria.createdFrom = fromDate;
    }
    priorities.forEach( priority => {
      promises.push(new Promise((resolve,reject) => {
        this.searchCriteria.priority = priority;
        // fetch the count of matching records
        this.itsmService.caseSearch(this.searchCriteria)
          .then( res => {
            resolve(res | 0);
          })
          .catch(err => {
            reject();
            this.alertService.handlerError(this.translateService.instant('pages.updates.sentinelOne.errors.SNowError'));
          });
      }))
    });

    return Promise.all(promises).then( (responses) => {
      responses.forEach( (res, index) => {
        this.openCasesByPriority[openCasesByPriorityKeys[index]] = res as number | 0;
      })
    });
  }

  public loadIncidentsByPriority() {
      this.incidentsByPriorityFromCreatedDate = getDateFromDateOption(this.incidentsByPriorityDateSelected);

      this.caseCountOpenByPriority(this.incidentsByPriorityFromCreatedDate).then( () => {
        this.mapIncidentsByPriority();
        this.incidentsByPriorityCard.graphOptions = this.getChartOptions(this.incidentsByPriorityCard);
      });
    }

}
