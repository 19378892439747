import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PaginatedList } from 'src/app/shared/model/cybersocxdr/paginatedList';
import { AssessmentService } from 'src/app/shared/services/ethicalhacking/assessment.service';
import { AssessmentTableItemsDto } from 'src/app/shared/services/ethicalhacking/model/ethicalHackingDtos';
import { VulnerabilitySeverity } from 'src/app/shared/services/ethicalhacking/model/vulnerabilities-model';
@Component({
  selector: 'app-ethicalhacking-assessment-table',
  templateUrl: './assessment-table.component.html',
  styleUrls: ['./assessment-table.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, NgbTooltipModule],
})
export class AssessmentTableComponent implements OnInit {
  private readonly assessmentService: AssessmentService = inject(AssessmentService);
  protected readonly translate: TranslateService = inject(TranslateService);
  private readonly router: Router = inject(Router);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  @Input() pageSize: number = 10;
  @Input() isPaginated: boolean = false;

  protected assessments: PaginatedList<AssessmentTableItemsDto[]>;

  protected pageSizeOptions: number[] = [5, 10, 25, 50];
  protected readonly severitiesTypes: VulnerabilitySeverity[] = Object.values(VulnerabilitySeverity);

  public ngOnInit(): void {
    this.loadDatas();
  }

  private loadDatas(): void {
    this.assessmentService.getLatestAssessments(this.pageSize, this.assessments?.page ?? 1)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => (this.assessments = res));
  }

  protected loadPage(pageNumber: number): void {
    if (pageNumber < 1 || pageNumber > this.assessments.total / this.pageSize) {
      return;
    }
    this.assessmentService.getLatestAssessments(this.pageSize, pageNumber)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => (this.assessments = res));
  }

  protected generatePageArray(currentPage: number): number[] {
    const startPage = Math.max(currentPage - 3, 1);
    const endPage = Math.min(startPage + 7, Math.ceil((this.assessments?.total ?? 0) / this.pageSize) + 1);
    return Array.from({ length: endPage - startPage }, (_, i) => startPage + i);
  }

  protected onPageSizeChange(newSize: number): void {
    this.pageSize = newSize;
    this.loadDatas();
  }

  protected navigateToAssessmentDetail(id: number): void {
    this.router.navigate(['/ethical-hacking/assessments', id]);
  }
}
