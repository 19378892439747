import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { ACTIONS, constants, PAGES, TYPES } from 'src/app/shared/constants';
import { SessionStorageItem } from 'src/app/shared/model/shared-items';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ContextService } from 'src/app/shared/services/context-service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';
import { QueryStatus } from 'src/app/support/supportlog/filters/asset-and-service/asset-and-service.component';
import { environment } from 'src/environments/environment';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';

@Component({
  selector: 'app-services-navbar',
  templateUrl: './services-navbar.component.html',
  styleUrls: ['./services-navbar.component.scss'],
})
export class ServicesNavbarComponent implements OnInit {

  navBarTheme = '';
  nucleusUrl = '';
  portalSatisfactionSurveyUrl = constants.portalSatisfactionSurveyUrl;
  mySecurityReportUrl = environment.endpoints.mySecurityReport;
  public pages = PAGES;

  constructor(
    public itsmService: ItsmService,
    public contextService: ContextService,
    public userAuthService: UserAuthService,
    public alertService: AlertService,
    private userpreferenceService: UserPreferenceService,
    private userActivityService: UserActivityService
    ) {}

  ngOnInit(): void {
    // initialize access and url to nucleus
    this.checkManagedVulnIntAccess();
    this.userAuthService.accesses.SLA = this.userpreferenceService.tenantAccesses?.sla;
    this.navBarTheme = this.contextService.navBarTheme;
  }

  /**
   * check the access to managed vulnerability intelligence services
   */
  public checkManagedVulnIntAccess() {
    // get vulnerabilities only if tenant selected

    const query = new QueryStatus(40, constants.nucleusServiceName);
    this.itsmService.getLazyloadedServices(query).subscribe({
      next: (response) => {
        if(response && response.length > 0) {
          const serviceCenter = Array.from(
            new Set(
              response.filter(s => s.status === constants.nucleusStatus).map(s => s.serviceCenter)
            )
          );
          const NUCLEUS_IDS = environment.nucleusIds;
          serviceCenter.forEach((serviceCenter) => {
            NUCLEUS_IDS.forEach((nucleusId) => {
              // only display for specific service center id
              if (nucleusId.id === serviceCenter) {
                this.nucleusUrl = nucleusId.url;
              }
            });
          });
        }
      },
      error: (err) => {
        this.alertService.handlerError(err);
      }
    })
  }

  public clearAssetsRetention() {
    sessionStorage.removeItem(SessionStorageItem.assetRetention);
  }

   /**
   * track access to asset management
   */
   public trackSupportAccess(page: string) {
    this.userActivityService.logActivity(TYPES.support, page, ACTIONS.visit)
  }

  /**
   * track access to asset management
   */
  public trackActivityAccess(page: string) {
    this.userActivityService.logActivity(TYPES.activity, page, ACTIONS.visit)
  }

  /**
   * track access to asset management
   */
  public trackAssetManagementAccess(page: string) {
    this.userActivityService.logActivity(TYPES.assetManagement, page, ACTIONS.visit)
  }

  /**
   * track access to reports
   */
  public trackReportAccess(page: string) {
    this.userActivityService.logActivity(TYPES.reports, page, ACTIONS.visit)
  }

  /**
   * track access to ressources
   */
  public trackRessourceAccess(page: string) {
    this.userActivityService.logActivity(TYPES.resources, page, ACTIONS.visit)
  }

  /**
   * track access to services
   */
  public trackServiceAccess(page: string) {
    this.userActivityService.logActivity(TYPES.services, page, ACTIONS.visit)
  }

  /**
   * track access to ressources
   */
  public trackAdminAccess(page: string) {
    this.userActivityService.logActivity(TYPES.admin, page, ACTIONS.visit)
  }
}
