import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEarthEurope, faTools } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CardComponent } from 'src/app/shared/components/xtended/card/card.component';
import { VulnerabilitiesEthicalHackingService } from 'src/app/shared/services/ethicalhacking/vulnerabilities.service';
import { AppSharedModule } from '../../../../app-shared.module';
import { Vulnerability } from '../../../../shared/services/ethicalhacking/model/vulnerabilities-model';
import { SreporterMdService } from '../../../../shared/services/ethicalhacking/sreporter-md.service';

@Component({
  selector: 'app-vulnerabilitie-detail',
  standalone: true,
  imports: [CommonModule, TranslateModule, FontAwesomeModule, AppSharedModule, CardComponent],
  templateUrl: './vulnerabilitie-detail.component.html',
  styleUrl: './vulnerabilitie-detail.component.scss',
})
export class VulnerabilitieDetailComponent implements OnInit {
  faTools = faTools;
  faEarthEurope = faEarthEurope;
  public vulnerability: Vulnerability | null = null;
  private vulnerabilityId: string = null;

  // Transformed content variables
  public descriptionHtml: string = '';
  public riskHtml: string = '';
  public recommendationHtml: string = '';
  public referencesHtml: string = '';
  public loading = false;

  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly vulnerabilitiesService: VulnerabilitiesEthicalHackingService = inject(
    VulnerabilitiesEthicalHackingService
  );
  private readonly sreporterMdService: SreporterMdService = inject(SreporterMdService);

  ngOnInit(): void {
    this.loadDetails();
  }

  private loadDetails(): void {
    this.vulnerabilityId = this.route.snapshot.paramMap.get('id');
    this.loadVulnerabilityDetails();
  }

  private loadVulnerabilityDetails(): void {
    if (this.vulnerabilityId) {
      this.loading = true;
      this.vulnerabilitiesService
        .getVulnerabilitiesById(this.vulnerabilityId)
        .pipe(
          catchError((error) => {
            console.error('Error loading vulnerability:', error);
            this.loading = false;
            return EMPTY;
          })
        )
        .subscribe(async (response) => {
          this.vulnerability = response;

          // Transform content only once after vulnerability is loaded
          if (this.vulnerability) {
            this.descriptionHtml = await this.transformContentToHtml(this.vulnerability.description || '');
            this.riskHtml = await this.transformContentToHtml(this.vulnerability.risk || '');
            this.recommendationHtml = await this.transformContentToHtml(this.vulnerability.recommendation || '');
            this.referencesHtml = await this.transformContentToHtml(this.vulnerability.references || '');
          }

          this.loading = false;
        });
    }
  }

  private transformCVEsToLinks(text: string): string {
    const cvePattern = /(CVE-\d{4}-\d+)/g;
    return text.replace(cvePattern, (match) => {
      return `<a href="https://www.cve.org/CVERecord?id=${match}" target="_blank" class="text-decoration-none link-light">${match}</a>`;
    });
  }

  public async transformContentToHtml(text: string): Promise<string> {
    if (!text) return '';

    // Apply CVE links transformation first
    const withCVELinks = this.transformCVEsToLinks(text);

    // Use the sreporterMdService to handle image transformations and markdown conversion
    return await this.sreporterMdService.convertToHtml(withCVELinks);
  }
}
