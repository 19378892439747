import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { InsightData } from '../../model/cybersocxdr/insight';
import { FindingStatus } from '../../utils/xtended/constants.utils';
import { VulnerabilitiesCountDto } from './model/ethicalHackingDtos';
import { Vulnerability, VulnerabilitySearch } from './model/vulnerabilities-model';

const ROOT_URL = environment.endpoints.ethicalhacking;
const VULNERABILITIES_URL = ROOT_URL + 'vulnerabilities';

interface VulnerabilitySearchParams {
  page: number;
  pageSize: number;
  sortBy?: string;
  sortDir?: string;
}

@Injectable({
  providedIn: 'root'
})
export class VulnerabilitiesEthicalHackingService {
  private readonly http: HttpClient = inject(HttpClient);

  public getReportedVulnerabilities(findingsStatus: FindingStatus[]): Observable<VulnerabilitiesCountDto[]> {
    const queryParams = new HttpParams().set('findingsStatus', findingsStatus.join(','));
    return this.http.get<VulnerabilitiesCountDto[]>(VULNERABILITIES_URL, { params: queryParams });
  }

  private getVulnerabilitiesByStatus(status: 'open' | 'closed', params: VulnerabilitySearchParams): Observable<VulnerabilitySearch> {
    const httpParams = new HttpParams()
      .set('page', params.page.toString())
      .set('pageSize', params.pageSize.toString())
      .set('sortBy', params.sortBy || '')
      .set('sortDir', params.sortDir || '');

    return this.http.get<VulnerabilitySearch>(`${VULNERABILITIES_URL}/${status}`, { params: httpParams })
      .pipe(
        catchError(error => {
          console.error(`Error fetching ${status} vulnerabilities:`, error);
          return throwError(() => error);
        })
      );
  }

  public getVulnerabilitiesOpen(params: VulnerabilitySearchParams): Observable<VulnerabilitySearch> {
    return this.getVulnerabilitiesByStatus('open', params);
  }

  public getVulnerabilitiesClosed(params: VulnerabilitySearchParams): Observable<VulnerabilitySearch> {
    return this.getVulnerabilitiesByStatus('closed', params);
  }

  public getVulnerabilitiesById(id: string): Observable<Vulnerability> {
    return this.http.get<Vulnerability>(`${VULNERABILITIES_URL}/${id}`);
  }

  public getAllVulnerabilityInsights(intervalType: 'week' | 'month', numberOfIntervals: number): Observable<InsightData[]> {
    let queryParams = new HttpParams().set('totalOnly', false);
    queryParams = queryParams.set('intervalType', intervalType);
    queryParams = queryParams.set('numberOfIntervals', numberOfIntervals.toString());
    return this.http.get<InsightData[]>(`${VULNERABILITIES_URL}/insights`, { params: queryParams });
  }

  public getOpenVulnerabilitiesInsights(intervalType: 'week' | 'month', numberOfIntervals: number): Observable<InsightData[]> {
    let queryParams = new HttpParams().set('totalOnly', true);
    queryParams = queryParams.set('intervalType', intervalType);
    queryParams = queryParams.set('numberOfIntervals', numberOfIntervals.toString());
    return this.http.get<InsightData[]>(`${VULNERABILITIES_URL}/insights`, { params: queryParams });
  }

  public getClosedVulnerabilitiesInsights(intervalType: 'week' | 'month', numberOfIntervals: number): Observable<InsightData[]> {
    let queryParams = new HttpParams().set('totalOnly', true);
    queryParams = queryParams.set('intervalType', intervalType);
    queryParams = queryParams.set('numberOfIntervals', numberOfIntervals.toString());
    return this.http.get<InsightData[]>(`${VULNERABILITIES_URL}/closed-insights`, { params: queryParams });
  }
} 