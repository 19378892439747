import { Component, Input } from '@angular/core';
import { EndpointDataDTO } from 'src/app/shared/model/cybersocxdr/clientHosts';

@Component({
  selector: 'app-techno-tab',
  templateUrl: './techno-tab.component.html',
})
export class TechnoTabComponent {
  @Input() hostsData: EndpointDataDTO | null = null;
  @Input() installedAgentSeries: Highcharts.SeriesSplineOptions[] = [];
  @Input() osRepartitionSeries: Highcharts.SeriesSunburstOptions[] = [];
  @Input() agentsVersionByProductTypeSeries: Highcharts.SeriesColumnOptions[] = [];
  @Input() agentsVersionOptions: string[] = [];
  @Input() policiesRepartitionByProductTypeSeries: Highcharts.SeriesBarOptions[] = [];
  @Input() policiesRepartitionByProductTypeOptions: string[] = [];

  @Input() agentsCards: {
    title: string;
    key: keyof EndpointDataDTO;
    description?: string;
    type?: string;
  }[] = [];
}
