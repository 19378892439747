<footer class="footer bg-black">
  <div class="container-xxl footer-terms">

    <!-- Policies -->
    <div class="footer-policies">
      <ul class="navbar-nav ps-0">
        <li><a class="nav-link" routerLink="/termsofservice">{{ 'footer.termsOfService' | translate }}</a></li>
        <li><a class="nav-link" routerLink="/privacypolicy">{{ 'footer.privacyPolicy' | translate }}</a></li>
        <li><a class="nav-link" routerLink="/cookiepolicy">{{ 'footer.cookiePolicy' | translate }}</a></li>
      </ul>
    </div>
    
    <!-- Contacts -->
    <div class="footer-contacts">
      <div class="mb-0 pb-1">
        {{ 'footer.bug' | translate }}<a class="ps-1" href="mailto:{{mailToPortalSupport}}">{{mailToPortalSupport}}</a>
      </div>
      <div class="mb-0 pt-2 pl-3">
        {{ 'footer.vuln' | translate }}<a class="ps-1" href="mailto:{{mailToInfoSec}}">{{mailToInfoSec}}</a>
      </div>
    </div>
    
    <!-- Socials -->
    <div class="footer-social">
      <h3 class="footer-heading me-3">{{ 'footer.follow' | translate }} </h3>
      <ul class="navbar-nav">
        <li><a href={{twitterUrl}} class="btn btn-icon btn-social btn-twitter btn-inverse" [ngClass]="{'btn-inverse': contextService.userSelectedTheme === 'dark'}"><span class="visually-hidden">{{ 'footer.followUsOnX' | translate }}</span></a></li>
        <li><a href={{linkedinUrl}} class="btn btn-icon btn-social btn-linkedin btn-inverse" [ngClass]="{'btn-inverse': contextService.userSelectedTheme === 'dark'}"><span class="visually-hidden">{{ 'footer.followUsOnlinkedin' | translate }}</span></a></li>
      </ul>
    </div>

    <!-- Version -->
    <div class="footer-version">
      <span  class="custom-label">{{ 'footer.version' | translate }}</span>
      <span id="version-number"> {{version}}</span>
    </div>

  </div>
  
</footer>
