import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SupportCaseSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { Utilities } from 'src/app/shared/utilities';
import {
  AxisColors, AxisType, ChartColors, ChartType, ChartZoom, Charts,
  OcdCases, PendingCases, PointStages, Priorities, Axis
} from 'src/app/shared/model/activity';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';
import { ContextService } from 'src/app/shared/services/context-service';
import { Case } from 'src/app/shared/model/itsm';

@Component({
  selector: 'app-security-incident-timeline',
  templateUrl: './security-incident-timeline.component.html',
  styleUrls: ['./security-incident-timeline.component.scss']
})
export class SecurityIncidentTimelineComponent extends Charts implements OnInit {

  public displayTimeLineChart = false;
  public isDarkTheme = false;
   //loading
  public loading = true;
  //selectedPoint
  public pointSelected: {xIndex: number, yIndex: number; seriesIndex: any} = {xIndex: 0, yIndex: null, seriesIndex: null};
  public selectionIndex: number;
  public showCases: any[] = [];
  // Cases to show in table
  public showCasesTable = false;
  public sort: string;
  public sortBy: string;
  public updateDonutFlag = false;
  
  //Highcharts
  public colors = [ChartColors.red, ChartColors.orange, ChartColors.yellow, ChartColors.blue];
  public timeLineChart = {
    chart: {
      type: ChartType.bubble,
      plotBorderWidth: 0,
      zoomType: ChartZoom.xy,
      backgroundColor: '#eee',
      borderRadius: '15px'
    },
    legend: {
      itemStyle: {
        color: '#000000',
        fontWeight: 'bold'
      }
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false
    },
    xAxis: {
      gridLineColor: AxisColors.transparent,
      labels: {
        enabled: false
      },
      min: 0,
      max: 100,
      categories: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      gridLineWidth: 0,
      lineWidth: 0
    },
    yAxis: {
      gridLineColor: AxisColors.transparent,
      title: {
        enabled: false
      },
      labels: {
        enabled: false
      },
      tickAmount: 10,
      min: 0,
      max: 100,
      startOnTick: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true
          },
          inactive: {
            opacity: 1
          },
          select: {
            enabled: true
          }
        },
        allowPointSelect: true,
        point: {
          events: {
            click: (e) => {
              this.showCases = [];
              const priority: string = e.point.options.name.toLowerCase();
              this.cases.forEach( item => {
                const itemPriority: string = item.priority.split(' - ')[1].toLowerCase();
                if (itemPriority === priority) {
                  switch (e.point.options.stage.toLowerCase()) {
                    case (PointStages.underInvestigation.toLowerCase()):
                      if (this.underInvestigation.indexOf(item.status.toLowerCase()) != -1) {
                        this.showCases.push(item);
                      }
                      break;
                    case (PointStages.pendingCases.toLowerCase()):
                      if (this.pendingCases.indexOf(item.status.toLowerCase()) != -1) {
                        this.showCases.push(item);
                      }
                      break;
                    case (PointStages.pendingOCD.toLowerCase()):
                      if (this.pendingOCD.indexOf(item.status.toLowerCase()) != -1) {
                        this.showCases.push(item);
                      }
                      break;
                    case (PointStages.closedInLastHours.toLowerCase()):
                      if (this.closedInLastHours.indexOf(item.status.toLowerCase()) != -1) {
                        this.showCases.push(item);
                      }
                      break;
                  }
                }
              });
              this.showCasesTable = true;
            },
            select: () => null,
            unselect: (_) => null
          }
        }
      },
      bubble: {
      }
    },
    tooltip: {
      useHTML: true,
      headerFormat: '',
      pointFormatter() {
        const r = `"x" cases of "x" priority are currently under investigation by Orange Cyberdefense analysts`;
        return `
    <table><tr><th colspan="2" style="text-align: center">Cases</th></tr>
    <tr><td colspan="2"> <b>${this.z} Cases</b> of <span style="color: ${this.series.color}">${this.series.name}</span> priority are <b>${this.stage}</b></td></tr>
    <tr><td colspan="2">Detained by <b>${this.pendingAt}</b></td></tr>
    <tr><td colspan="2">${r}</td></tr>
    </table>`;
      },
    },
    series: []

  };
  
  //all cases
  private cases: any[];
  //Highchart Data
  private chartData = {};
  private offset = 4;

  private underInvestigationInitial = 5;
  private escalatedInitial = 30;
  private pendingOCDInitial = 56;
  private closedInLastHoursInitial = 82;

  private underInvestigation = [OcdCases.new, OcdCases.inProgress, OcdCases.investigating];
  private pendingCases = [PendingCases.pending1, PendingCases.pending2];
  private pendingOCD = [OcdCases.open, OcdCases.onHold, OcdCases.pending, OcdCases.pendingOcd1, OcdCases.pendingOcd2,OcdCases.pendingVendor1, OcdCases.pendingVendor2];
  private closedInLastHours = [OcdCases.resolved, OcdCases.answered, OcdCases.closed];
  
  private xAxis = {} as Axis;
  private yAxis = {} as Axis;
  private yAxisValue = {
    0: {
      ocdCases: 20,
      customerCases: 75
    },
    1: {
      ocdCases: 25,
      customerCases: 80
    },
    2: {
      ocdCases: 30,
      customerCases: 85
    },
    3: {
      ocdCases: 35,
      customerCases: 90
    }
  };

  private xAxisValue = {
    0: {
      underInvestigation: this.underInvestigationInitial,
      pendingCases: this.escalatedInitial,
      pendingOCD: this.pendingOCDInitial,
      closedInLastHours: this.closedInLastHoursInitial
    },
    1: {
      underInvestigation: this.underInvestigationInitial + this.offset,
      pendingCases: this.escalatedInitial + this.offset,
      pendingOCD: this.pendingOCDInitial + this.offset,
      closedInLastHours: this.closedInLastHoursInitial + this.offset
    },
    2: {
      underInvestigation: this.underInvestigationInitial + 2 * this.offset,
      pendingCases: this.escalatedInitial + 2 * this.offset,
      pendingOCD: this.pendingOCDInitial + 2 * this.offset,
      closedInLastHours: this.closedInLastHoursInitial + 2 * this.offset
    },
    3: {
      underInvestigation: this.underInvestigationInitial + 3 * this.offset,
      pendingCases: this.escalatedInitial + 3 * this.offset,
      pendingOCD: this.pendingOCDInitial + 3 * this.offset,
      closedInLastHours: this.closedInLastHoursInitial + 3 * this.offset
    }

  };

  constructor(
    private itsmService: ItsmService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private userActivity: UserActivityService,
    public contextService: ContextService
  ) {
    super();
  }

  ngOnInit(): void {

    const searchCriteria = new SupportCaseSearchCriteria();
    searchCriteria.createdFrom = Utilities.get1MonthAgo();
    searchCriteria.createdTo = Utilities.getToday();
    searchCriteria.securityAnaltyics = true;
    this.isDarkTheme = this.contextService.isDarkTheme();

    this.itsmService.getLiveReport(searchCriteria)
      .then(res => {

        this.cases = res;
        this.loading = false;

        const bussinessCritical = res.filter(a => a.priority == '1 - ' + Priorities.critical);
        const high = res.filter(a => a.priority == '2 - ' + Priorities.high);
        const medium = res.filter(a => a.priority == '3 - ' + Priorities.medium);
        const low = res.filter(a => a.priority == '4 - ' + Priorities.low);

        this.calculatePriorityWiseData(bussinessCritical, 0, Priorities.critical);
        this.calculatePriorityWiseData(high, 1, Priorities.high);
        this.calculatePriorityWiseData(medium, 2, Priorities.medium);
        this.calculatePriorityWiseData(low, 3, Priorities.low);

        const self = this;
        this.timeLineChart.plotOptions.series.point.events.select = function () {
          self.pointSelected = {
            xIndex: this.xIndex,
            yIndex: this.yIndex,
            seriesIndex: this.seriesIndex
          };
        };

        this.timeLineChart.plotOptions.series.point.events.unselect = (e) => {
          if (e.accumulate === false) {
            self.pointSelected = {xIndex: 0, yIndex: null, seriesIndex: null};
          }
        };

        this.timeLineChart.chart.backgroundColor = this.isDarkTheme ? '#333' : '#eee';
        this.timeLineChart.legend.itemStyle.color = this.isDarkTheme ? '#fff' : '#000';

        this.timeLineChart.tooltip.pointFormatter = function () {
          const toTranslate = (x) => {
            switch (x) {
              case PointStages.underInvestigation: return 'pages.securitycase.underInvestigationTooltip';
              case PointStages.pendingCases: return 'pages.securitycase.pendingCustomerTooltip';
              case PointStages.pendingOCD: return 'pages.securitycase.pendingOcdTooltip';
              case PointStages.closedInLastHours: return 'pages.securitycase.closedTooltip';
              default: return 'pages.generic.hello';
            }
          };
          const r = self.translateService.instant(toTranslate(this.stage), { numberCases: this.z, casePriority: this.series.name });
          return `
          <table><tr><th colspan="2" style="text-align: center">Cases</th></tr>
          <tr><td colspan="2">${r}</td></tr>
          </table>`;
        };

        this.updateDonutFlag = true;
        this.displayTimeLineChart = true;
      })
      .catch(err => {
        this.alertService.handlerError(err);
      });
    this.pointSelected.xIndex = 0;
  }

  private calculatePriorityWiseData(data, index, name) {
    const dic = {};

    this.xAxis = {} as Axis;
    this.yAxis = {} as Axis;
    this.chartData = {};
    this.yAxis.customerCases = 0;

    data.forEach(elem => {
      let x = 0; let y = 0; let type = ''; let stage = ''; let xIndex = 0; let yIndex = 0;

      if (dic[elem.status] == undefined) {
        dic[elem.status] = 1;
      }
      else {
        dic[elem.status] = dic[elem.status] + 1;
      }

      //setting Y-Axis coordinate
      if (this.pendingCases.indexOf(elem.status.toLowerCase()) != -1) {
        this.yAxis.customerCases++;
        y = this.yAxisValue[index].customerCases;
        ({ x, stage, xIndex } = this.checkXAxis(elem, index));
        type = AxisType.customer;
        yIndex = 2;
      } else {
        y = this.yAxisValue[index].ocdCases;
        if (this.yAxis.ocdCases == undefined) {
          this.yAxis.ocdCases = 1;
        } else {
          this.yAxis.ocdCases = this.yAxis.ocdCases + 1;
        }
        ({ x, stage, xIndex } = this.checkXAxis(elem, index));
        type = AxisType.ocd;
        yIndex = 1;
      }

      if (x != 0 && y != 0) {
        if (this.chartData[x + ',' + y + ',' + type + ',' + stage + ',' + xIndex + ',' + yIndex] == undefined) {
          this.chartData[x + ',' + y + ',' + type + ',' + stage + ',' + xIndex + ',' + yIndex] = 1;
        }
        else {
          this.chartData[x + ',' + y + ',' + type + ',' + stage + ',' + xIndex + ',' + yIndex] =
            this.chartData[x + ',' + y + ',' + type + ',' + stage + ',' + xIndex + ',' + yIndex] + 1;
        }
      }
    });

    const seriesData = [];

    Object.keys((this.chartData)).forEach(key => {
      seriesData.push({
        x: Number(key.split(',')[0]),
        y: Number(key.split(',')[1]),
        z: this.chartData[key],
        name,
        stage: key.split(',')[3],
        pendingAt: key.split(',')[2],
        seriesIndex: index,
        xIndex: Number(key.split(',')[4]),
        yIndex: Number(key.split(',')[5])
      });
    });

    this.timeLineChart.series.push(
      {
        name,
        data: seriesData,
        marker: {
          fillColor: {
            radialGradient: { cx: 0.4, cy: 0.3, r: 0.7 },
            stops: [
              [0, this.seriesColor0],
              [1, this.colors[index]]
            ]
          },
          states: {
            select: {
              fillColor: {
                radialGradient: { cx: 0.4, cy: 0.3, r: 0.7 },
                stops: [
                  [0, this.seriesColor0],
                  [1, this.colors[index]]
                ]
              },
            }
          }
        }
      });
  }

  private checkXAxis(data: Case, index: number) {
    let x = 0; let xIndex = 0;
    let stage = '';
    const categories = Object.keys(PointStages);

    categories.forEach((category, i) => {
      if (this[category]?.indexOf(data.status.toLowerCase()) != -1) {
        x = this.xAxisValue[index][category];
        xIndex = i;
        stage = PointStages[category];
        this.xAxis[category] = this.xAxis[category] != undefined
          ? this.xAxis[category] + 1
          : 1;
      }
    });
    return { x, stage, xIndex };
  }

  public sortResults(field: string) {
    if (this.sort === field && this.sortBy) {
      this.sortBy = this.sortBy === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.sort = field;
      this.sortBy = 'DESC';
    }
    this.cases.sort( (a, b) =>
        this.sortBy === 'DESC'
          ? a[this.sort].localeCompare(b[this.sort])
          : b[this.sort].localeCompare(a[this.sort]))
    this.showCases = this.cases;
  }

}
