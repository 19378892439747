import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BaseChartComponent } from '../basechart/basechart.component';

interface ExtendedPiePoint extends Highcharts.PointOptionsObject {
  facetValues?: string[]; // Replace 'any' with proper type if available
  facets?: string[]; // Replace 'any' with proper type if available
}

@Component({
  selector: 'app-pie',
  templateUrl: '../basechart/basechart.component.html',
})
export class PieComponent extends BaseChartComponent<Array<Highcharts.SeriesPieOptions>> {
  @Input() suffix = '%';
  @Input() showTotal = false;
  @Input() totalLabel: string;
  @Input() innerSize: string;
  @Input() enabled3D = false;
  @Input() borderRequested: boolean = false;
  @Input() drilldownSeries: Highcharts.SeriesOptionsType[] = undefined;

  options: Highcharts.Options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      options3d: {
        enabled: this.enabled3D,
        alpha: 45,
        beta: 0,
      },
    },
    title: {
      text: undefined,
    },
    tooltip: {
      useHTML: true,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        const point = this.point;
        const color = typeof point.color === 'string' ? point.color : '#000000';
        const tooltipText = `<b><span style="color:${color}">${point.name}</span>:</b> ${Highcharts.numberFormat(
          point.percentage,
          0
        )}%`;

        if ((this.series.chart.options as any).showTotal) {
          const totalLabel = (this.series.chart.options as any).totalLabel || 'Total';
          return `${tooltipText}<br><b><span style="color:${color}">${totalLabel}</span>:</b> ${point.y}`;
        }
        return tooltipText;
      },
    },
    legend: {
      align: 'center',
    },
    plotOptions: {
      pie: {
        innerSize: undefined,
        depth: 60,
        allowPointSelect: true,
        cursor: 'pointer',
        showInLegend: true,
      },
    },
    series: undefined,
  };

  translateSeries(series: Array<Highcharts.SeriesPieOptions>): Array<Highcharts.SeriesPieOptions> {
    this.dataTranslated = [];

    series.forEach((serie) => {
      const translatedSerie: Highcharts.SeriesPieOptions = {
        ...serie,
        type: 'pie',
        data: serie.data.map((point) => {
          if (typeof point === 'object' && 'name' in point) {
            const typedPoint = point as ExtendedPiePoint;
            return {
              name: this.translate.instant(typedPoint.name as string),
              y: typedPoint.y,
              color: typedPoint.color,
              id: typedPoint.id,
              facetValues: typedPoint.facetValues,
              facets: typedPoint.facets,
            };
          }
          return point;
        }),
      };
      this.dataTranslated.push(translatedSerie);
    });

    return this.dataTranslated;
  }

  draw(renderingid: string) {
    this.options.title.text = this.title;
    (this.options as any).showTotal = this.showTotal;
    (this.options as any).totalLabel = this.totalLabel;
    this.options.series = this.translateSeries(this.data);
    if (this.drilldownSeries) this.options.drilldown = { series: this.drilldownSeries };
    this.options.plotOptions.pie.innerSize = this.innerSize;
    if (this.borderRequested) this.options.plotOptions.pie.borderWidth = 3;
    if (this.borderRequested) this.options.plotOptions.pie.borderColor = 'white';
    this.options.chart.options3d.enabled = this.enabled3D;
    this.options.plotOptions.pie.dataLabels = {
      enabled: false,
      format: `<b>{point.y}</b> ${this.suffix}`,
    };
  }
}
