import { AfterViewInit, Component, Input, NgZone, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { getDefaultHighchartOptions, waitForAddedNode } from '../../utils';
import Highcharts, { XAxisOptions, YAxisOptions } from 'highcharts';
import { ContextService } from 'src/app/shared/services/context-service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { XtendedHighchartService } from 'src/app/cybersocxdr/xtended-highchart.service';

/**
 * Basic graph component, wrap the behavior common to all graphs:
 * - to facilitate DOM related operations
 * - data check for draw operations
 * - Destroying the graphs when needed
 */
@Component({
  selector: 'app-basegraph',
  template: '',
})
export abstract class BaseChartComponent<DataType, OptionType extends Highcharts.Options = Highcharts.Options>
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() public data: DataType;
  @Input() title = '';
  @Input() options: OptionType;
  @Input() showChartIfNoData: boolean = false;
  @Input() useDefaultOptions: boolean = true;
  public dataTranslated: DataType;
  containerRenderingId: string;
  internalRenderingId: string;
  isDOMInstanciated = false;
  hasNoData = false;
  subscription: Subscription;
  // Ref to the chart drawn to the DOM
  chart: Highcharts.Chart;

  constructor(
    readonly context: ContextService,
    protected ngZone: NgZone,
    protected readonly translate: TranslateService,
    protected readonly xtendedHighchartService: XtendedHighchartService,
  ) {
    const randomID = `${Math.random()}`.replace('.', '_');
    this.containerRenderingId = `chart_container_${randomID}`;
    this.internalRenderingId = `chart_${randomID}`;
  }

  ngOnInit(): void {
    this.subscription = this.translate.onLangChange.subscribe(() => {
      this.drawGraph();
    });
  }

  ngAfterViewInit(): void {
    this.isDOMInstanciated = true;
    this.drawGraph();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.drawGraph();
  }

  /* Avoid memory leaks by destroying the graphs */
  ngOnDestroy(): void {
    if (this.chart !== undefined && this.chart !== null) {
      this.chart.destroy();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  isReady(): boolean {
    return this.isDOMInstanciated;
  }

  /** Call the real drawing method if conditions are met */
  drawGraph() {
    if (this.isDataEmpty(this.data) || !this.isReady()) {
      if (!this.showChartIfNoData) this.hasNoData = true;
      return;
    }
    this.hasNoData = false;
    waitForAddedNode({
      recursive: false,
      parent: document.getElementById(this.containerRenderingId),
      observedId: this.internalRenderingId,
      done: (element) => {
        this.draw(this.internalRenderingId);
        // Automatic translation for common formats
        this.options = this.translateData(this.options);

        this.ngZone.runOutsideAngular(() => {
          this.createChart(
            this.internalRenderingId,
            this.useDefaultOptions
              ? Highcharts.merge({ ...getDefaultHighchartOptions(this.context.isDarkTheme()) }, { ...this.options })
              : this.options
          );
          this.chart.redraw(true);
        });
      },
    });
  }

  /**
   * Create a chart to attach to the DOM.
   * Overrides may use this to create custom chart type not in the factory function
   */
  createChart(renderingId: string, options: Highcharts.Options): void {
    this.chart = Highcharts.chart(renderingId, options);
  }

  /**
   * For graphs with custom data, evaluate whether the data is empty.
   * Child implementations SHOULD call super
   * @param data The data stored by the graph
   */
  isDataEmpty(data: DataType): boolean {
    return (
      !data ||
      (Array.isArray(data) && data.length === 0) ||
      data[0]?.data?.length === 0 ||
      Object.keys(data).length === 0
    );
  }

  /**
   * Convenience function to call the translate service on all possible translatable fields
   * @param input The input, containing one or more field to translate
   * @returns The translated output of the input
   * 
   * @implNote Children implementations should call super
   */
  protected translateData(input: OptionType): OptionType {
    if (!input) return input;
    if (input?.title?.text) {
      input.title.text = this.translate.instant(input.title.text);
    }
    if (input?.subtitle?.text) {
      input.subtitle.text = this.translate.instant(input.subtitle.text);
    }

    if (input?.series?.length > 0) {
      input.series = input.series.map((serie) => {
        if (serie.name) {
          return {
            ...serie,
            name: this.translate.instant(serie.name),
          };
        }

        return serie;
      });
    }

    input.xAxis = this.translateAxis(input.xAxis);
    input.yAxis = this.translateAxis(input.yAxis);

    return input;
  }
  
  private translateAxis<T extends XAxisOptions | YAxisOptions | (XAxisOptions | YAxisOptions)[]>(input: T): typeof input {
    if (!input) return input;

    if (Array.isArray(input)) {
      return input.map((axis) => this.translateSingleAxis(axis)) as T;
    }
    return this.translateSingleAxis(input) as T;
  }

  private translateSingleAxis<T extends XAxisOptions | YAxisOptions>(input: T): typeof input {
    if (!input) return input;

    if (input?.title?.text) {
      input.title.text = this.translate.instant(input.title.text);
    }
    if (input?.categories) {
      input.categories = input.categories.map((category) => this.translateKey(category));
    }

    return input;
  }

  /**
   * Convenience function to call the translate service
   * @param input The input to translate
   */
  protected translateKey(input: string): string {
    if (!input) return input;
    return this.translate.instant(input);
  }

  /**
   * Draw the highchart data.
   * Implementations are guaranteed to have both a DOM ready and some data to work with
   * That is, as long as the draw() is never called directly
   */
  abstract draw(renderingId: string): void;
}
