<div class="sentinelOne-dashboard">

  <!-- Tabs selection -->
  <ul class="nav nav-tabs">
    <li class="nav-item" data-toggle="tooltip" data-placement="top"
      title="{{ 'pages.updates.sentinelOne.overview' | translate }}">
      <a class="nav-link" [ngClass]="{ 'active' : activeTab === tabs.OVERVIEW }"
        (click)="loadComponent(tabs.OVERVIEW)">
        {{ 'pages.updates.sentinelOne.overview' | translate | titlecase }}
      </a>
    </li>
    <li class="nav-item" data-toggle="tooltip" data-placement="top"
      title="{{ 'pages.updates.sentinelOne.endpointManagement' | translate }}">
      <a class="nav-link" [ngClass]="{ 'active' : activeTab === tabs.ENDPOINT_MANAGEMENT }"
        (click)="loadComponent(tabs.ENDPOINT_MANAGEMENT)">
        {{ 'pages.updates.sentinelOne.endpointManagement' | translate | titlecase }}
      </a>
    </li>
    <li class="nav-item" data-toggle="tooltip" data-placement="top"
      title="{{ 'pages.updates.sentinelOne.threatManagement' | translate }}">
      <a class="nav-link" [ngClass]="{ 'active' : activeTab === tabs.THREAT_MANAGEMENT }"
        (click)="loadComponent(tabs.THREAT_MANAGEMENT)">
        {{ 'pages.updates.sentinelOne.threatManagement' | translate | titlecase }}
      </a>
    </li>
  </ul>

  <!-- Content -->
    <div class="content-section">
      @if (errorMessage !== null) {
        <div class="alert alert-danger alert-sm" role="alert">
          <fa-icon [icon]="['fas', 'info-circle']" class="info-icon mx-2" size="xl" placement="left"></fa-icon>
          <p>{{ errorMessage }}</p>
        </div>
      }
      @switch (activeTab) {
        @case (tabs.OVERVIEW) {
          <app-sentinel-one-overview [customColors]="customColors" (error)="displayError($event)"></app-sentinel-one-overview>
        }
        @case (tabs.ENDPOINT_MANAGEMENT) {
          <app-sentinel-one-endpoint-management [customColors]="customColors" (error)="displayError($event)"></app-sentinel-one-endpoint-management>
        }
        @case (tabs.THREAT_MANAGEMENT) {
          <app-sentinel-one-threat-management [customColors]="customColors" (error)="displayError($event)"></app-sentinel-one-threat-management>
        }
      }
    </div>  
</div>
