import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-ethicalhacking-perimeters-title-box',
  templateUrl: './perimeter-title-box.component.html',
  styleUrls: ['./perimeter-title-box.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class EthicalHackingPerimeterTitleBoxComponent {
  @Input() title: string;
  @Input() class?: string;
}
