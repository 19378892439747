import { Utilities } from "src/app/shared/utilities";
import { EndpointData, GROUPS_POLICY, SentinelOneCardDataItem, SentinelOneGroupEnum, SentinelOneData, ThreatConfidence } from "./sentinel-one-models";

export function getMitigatedThreats(threats: SentinelOneData): number {
  return threats.data.filter( item => item.threatInfo.mitigationStatus === 'mitigated' ).length;
}

export function countEndpointsWithThreats(endpoints: SentinelOneData): number {
  return endpoints.data.filter( endpoint => endpoint.activeThreats > 0).length;
}

export function countActiveThreats(endpoints: SentinelOneData): number {
  let result = 0;
  endpoints.data
    .filter( endpoint => endpoint.activeThreats > 0)
    .forEach( endpoint => result += endpoint.activeThreats);
  return result;
}

export function mapThreatsByConfidenceLevel(threats: SentinelOneData) {
  let result: SentinelOneCardDataItem[] = [];
  let confidenceLevels = [];

  threats.data
    .map( threat => threat['threatInfo'].confidenceLevel)
    .forEach( (level) => confidenceLevels[level] = (confidenceLevels[level] || 0) + 1);

  Object.keys(confidenceLevels).forEach( item =>
    result.push({name: item, textKey: item, value: confidenceLevels[item]})
  );
  return result;
}

export function countThreatsByConfidenceLevel(threats: SentinelOneData, level: ThreatConfidence): number {
  return threats.data.filter( (threat) => threat['threatInfo'].confidenceLevel === level ).length;
}

export function parseGroups(endpoints: SentinelOneData): SentinelOneCardDataItem[] {
  let result: SentinelOneCardDataItem[] = [];
  let groups = [];

  endpoints.data
    .map( item => item.groupName)
    .forEach( (group) => groups[group] = (groups[group] || 0) + 1);

  Object.keys(groups).forEach( item =>
    result.push({name: item, textKey: item, value: groups[item]})
  );
  return result;
}

export function countThreatsByPolicy(policy: GROUPS_POLICY, endpoints: SentinelOneData): number {
  return endpoints.data
    .filter( endpoint => SentinelOneGroupEnum.getPolicy(endpoint.groupName) === policy)
    .reduce( (sum, endpoint) => sum + endpoint.activeThreats, 0);
}

export function getEndpointsWithOpenThreats(endpoints: SentinelOneData): {name: string, threatsCount: number}[] {
  const endpointsThreatsCounts = endpoints.data
    .map( endpoint => {
      return {name: endpoint.computerName, threatsCount: endpoint.activeThreats}
    });
  return endpointsThreatsCounts;
}

export function getTopFiveRiskyEndpoints(endpoints: SentinelOneData): {name: string, threatsCount: number}[] {
  return getEndpointsWithOpenThreats(endpoints)
    .sort( (a, b) => b.threatsCount - a.threatsCount)
    .slice(0, 5);
}

export function getOfflineEndpoints(endpoints: SentinelOneData, sinceNbDays: number): {name: string, lastSeen: string}[] {
  const fromDate = new Date().getTime() - (sinceNbDays*24*3600*1000) /* eq now - sinceNbDays days */;
  return endpoints.data
    .filter( endpoint => (fromDate - Date.parse(endpoint.lastActiveDate)) > 0 )
    .map( endpoint => {
      return {name: endpoint.computerName, lastSeen: endpoint.lastActiveDate}
    });
}

export function getAllEndpointsData(endpoints: SentinelOneData): EndpointData[] {
  return endpoints.data
    .map( endpoint => new EndpointData(
        endpoint.computerName,
        endpoint.machineType,
        endpoint.osName,
        endpoint.lastActiveDate,
        SentinelOneGroupEnum.isCritical(endpoint.groupName),
        SentinelOneGroupEnum.getPolicy(endpoint.groupName)
      )
    );
}

export function getCriticalEnpointsCount(endpoints: SentinelOneData) {
  return endpoints.data.filter( endpoint => SentinelOneGroupEnum.isCritical(endpoint['groupName'])).length;
}

export function getDateFromDateOption(dateOption: string) {
  let result: string;
  switch (dateOption) {
    case '15m':
      result = Utilities.getNowMinusMinutes(15);
      break;
    case '30m':
      result = Utilities.getNowMinusMinutes(30);
      break;
    case '1h':
      result = Utilities.getNowMinusMinutes(60);
      break;
    case '24h':
      result = Utilities.getNowMinusMinutes(60*24);
      break;
    case '7d':
      result = Utilities.getTodayMinusDays(7, Utilities.defaultDateTimeFormat);
      break;
    case '30d':
      result = Utilities.getTodayMinusDays(30, Utilities.defaultDateTimeFormat);
      break;
    default: 
      result = Utilities.getTodayMinusDays(30, Utilities.defaultDateTimeFormat);
  }
  return result;
}
