import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CardComponent } from 'src/app/shared/components/xtended/card/card.component';
import {
  EthicalHackingContactDto,
  YearlyAssessmentCountDto,
} from 'src/app/shared/services/ethicalhacking/model/ethicalHackingDtos';
import { AssessmentsByYearComponent } from '../../components/assessments-by-year/assessments-by-year.component';
import { ContactsTableComponent } from '../../components/contacts-table/contacts-table.component';
import { EthicalHackingPerimetersComponent } from '../../components/ethical-hacking-perimeters/ethical-hacking-perimeters.component';
import { catchError, of } from 'rxjs';
import { AssessmentService } from 'src/app/shared/services/ethicalhacking/assessment.service';
import { ContactService } from 'src/app/shared/services/ethicalhacking/contact.service';

@Component({
  selector: 'app-ethicalhacking-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['../../../layout/xtended/ethical-hacking-layout/styles/ethicalhacking.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CardComponent,
    AssessmentsByYearComponent,
    EthicalHackingPerimetersComponent,
    ContactsTableComponent,
  ],
})
export class EthicalhackingContractComponent implements OnInit {
  private readonly assessmentService: AssessmentService = inject(AssessmentService);
  private readonly contactService: ContactService = inject(ContactService);

  protected contactsSales: EthicalHackingContactDto[] = [];
  protected contactsProject: EthicalHackingContactDto[] = [];
  protected yearlyAssessmentSummary: YearlyAssessmentCountDto[];
  private readonly NUMBER_OF_YEARS = 3; // Get assessments count from the last 3 years
  public tenantName: string;

  public ngOnInit(): void {
    this.getYearlyAssessments();
    this.getSalesContacts();
    this.getProjectContacts();
  }

  private getYearlyAssessments(): void {
    this.assessmentService.getNumberOfAssessmentsByYear(this.NUMBER_OF_YEARS).subscribe((yearlyAssessmentSummary) => {
      this.yearlyAssessmentSummary = yearlyAssessmentSummary;
    });
  }

  private getSalesContacts(): void {
    this.contactService
      .getSalesContacts()
      .pipe(
        catchError(error => {
          console.error('Error fetching sales contacts', error);
          return of([]);
        })
      )
      .subscribe(contactsSales => {
        this.contactsSales = contactsSales;
      });
  }

  private getProjectContacts(): void {
    this.contactService
      .getProjectContacts()
      .pipe(
        catchError(error => {
          console.error('Error fetching project contacts', error);
          return of([]);
        })
      )
      .subscribe(contactsProject => {
        this.contactsProject = contactsProject;
      });
  }

}
