import { Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { XtendedFiltersService } from "src/app/cybersocxdr/xtendedfilters.service";
import { CybersocxdrService } from "src/app/shared/services/xtendedsoc/cybersocxdr.service";
import { saveAs } from 'file-saver';

@Component({
  selector: 'header-generate-report-button',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './generate-report-button.component.html',
  styleUrl: './generate-report-button.component.scss'
})
export class GenerateReportButtonComponent {
  // - Mettre le nom du fichier ailleurs qu'en dur
  private readonly PDF_FILE_NAME: string = 'report.pdf';

  private readonly translate: TranslateService = inject(TranslateService);
  private readonly filterService: XtendedFiltersService = inject(XtendedFiltersService);
  private readonly cybersocxdrService: CybersocxdrService = inject(CybersocxdrService);

  protected isGenerationInProgress: boolean = false;

  /**
   * @protected
   * @desc Generate a report according to the current search criteria.
   */
  protected async generate(): Promise<void> {
    this.isGenerationInProgress = true;
    try {
      const getCurrentLang: string = this.translate.currentLang || 'en';
      const { data }: any = await this.cybersocxdrService.generateReport(
        getCurrentLang,
        this.filterService.searchCriteria
      );

      const byteCharacters = new Uint8Array(data);
      const blob = new Blob([byteCharacters], { type: 'application/pdf' });

      await saveAs(blob, this.PDF_FILE_NAME);

    } catch (error) {
      console.error(error);
    }

    this.isGenerationInProgress = false;
  }
}
