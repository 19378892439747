import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-agent-card',
  templateUrl: './agent-card.component.html',
  styleUrls: ['./agent-card.component.scss', '../../../../layout/xtended/xtended-layout/styles/xtended.scss'],
})
export class AgentCardComponent {
  constructor() {}

  @Input()
  cardTitle: string;

  @Input()
  type: 'connected' | 'offline' | 'lost' | 'quarantined';

  @Input()
  value: string;

  @Input()
  description: string;
}
