<div class="container-fluid p-3 h-100">
  <div class="row justify-content-between">
    <div class="col-md-8 col-12">
      <app-card
        cardTitle="{{
          'pages.ethicalhacking.dashboard.vulnerabilities.title.open-vulnerabilities'
            | translate
        }}"
      >
        <severity [items]="openVulnerabilitiesBySeverity" />
      </app-card>
    </div>

    <div class="col-md-4 col-12">
      <app-card
        cardTitle="{{
          'pages.ethicalhacking.dashboard.vulnerabilities.title.open-and-closed'
            | translate
        }}"
      >
        <div class="row">
          <div class="col-6">
            <app-openclosedcards
              type="open"
              [count]="openVulnerabilitiesCount"
              [title]="
                'pages.ethicalhacking.dashboard.vulnerabilities.title.open-vulnerabilities'
                  | translate
              "
              [colorClass]="'primary'"
            >
            </app-openclosedcards>
          </div>
          <div class="col-6">
            <app-openclosedcards
              type="closed"
              [count]="closedVulnerabilitiesCount"
              [title]="
                'pages.ethicalhacking.dashboard.vulnerabilities.title.closed-vulnerabilities'
                  | translate
              "
              [colorClass]="'light'"
            >
            </app-openclosedcards>
          </div>
        </div>
      </app-card>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <app-insight-carrousel
        [insights]="ethicalHackingInsights"
        [displayHeader]="false"
      />
    </div>
    <div class="col-6">
      <app-insight-carrousel
        [insights]="closedEthicalHackingInsights"
        [displayHeader]="false"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-card>
        <div class="row px-2 mb-2 overflow-x-auto">
          <ul class="nav nav-underline flex-nowrap">
            <li class="nav-item">
              <button
                class="nav-link d-flex align-items-center gap-2 tab-button"
                [ngClass]="{ active: activeTab === 'open' }"
                (click)="switchTab('open')"
                aria-current="page"
              >
                {{
                  'pages.ethicalhacking.dashboard.vulnerabilities.title.open'
                    | translate
                }}
                <span
                  class="notif tag tag-sm border text-body"
                  [ngClass]="{
                    'bg-primary border-primary': activeTab === 'open',
                    'bg-light border-light': activeTab !== 'open'
                  }"
                  style="--bs-bg-opacity: 0.2"
                  >{{ openVulnerabilitiesCount }}</span
                >
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link d-flex align-items-center gap-2 tab-button"
                [ngClass]="{ active: activeTab === 'closed' }"
                (click)="switchTab('closed')"
              >
                {{
                  'pages.ethicalhacking.dashboard.vulnerabilities.title.closed'
                    | translate
                }}
                <span
                  class="notif tag tag-sm border text-body"
                  [ngClass]="{
                    'bg-primary border-primary': activeTab === 'closed',
                    'bg-light border-light': activeTab !== 'closed'
                  }"
                  style="--bs-bg-opacity: 0.2"
                  >{{ closedVulnerabilitiesCount }}</span
                >
              </button>
            </li>
          </ul>
        </div>

        <app-generic-table
          [data]="vulnerabilities"
          [columns]="tableColumns"
          [sortable]="true"
          [pagination]="{
            enabled: true,
            pageSize: searchCriteria.pageSize,
            currentPage: searchCriteria.page,
            total: total
          }"
          (rowClick)="onRowClick($event)"
          (sort)="onSort($event)"
          (pageChange)="onPageChange($event)"
          (pageSizeChange)="onPageSizeChange($event)"
        >
        </app-generic-table>
      </app-card>
    </div>
  </div>
</div>
