<div class="circle-container" [class.is-current-year]="colorVariation">
  <div class="circle">
    <div class="inner-circle">
      <div class="circle-text">
        {{ count }}
      </div>
    </div>
  </div>
  <div class="year">
    {{ year | translate }}
  </div>
</div>
