import { Component, Input, ViewChild, ElementRef, AfterViewInit, Inject, HostListener, OnInit } from '@angular/core';
import { CssTheme } from 'src/app/shared/model/shared-items';
import { Widget } from 'src/app/shared/model/widget';
import { ContextService } from 'src/app/shared/services/context-service';
import { DOCUMENT } from '@angular/common';
import { MttType } from 'src/app/shared/model/itsm';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';

@Component({
  selector: 'app-widgets-dashboard',
  templateUrl: './widgets-dashboard.component.html',
  styleUrls: ['./widgets-dashboard.component.scss'],
})
export class WidgetsDashboardComponent implements OnInit, AfterViewInit {

  @ViewChild('grid') grid: ElementRef<HTMLElement>;

  @ViewChild('worldwatch') worldwatch: ElementRef<HTMLElement>;
  @ViewChild('recentSupportActivity') recentSupportActivity: ElementRef<HTMLElement>;
  @ViewChild('latestActivity') latestActivity: ElementRef<HTMLElement>;
  @ViewChild('pendingCasesOcd') pendingCasesOcd: ElementRef<HTMLElement>;
  @ViewChild('openCases') openCases: ElementRef<HTMLElement>;
  @ViewChild('trueFalsePositives') trueFalsePositives: ElementRef<HTMLElement>;
  @ViewChild('mtta') mtta: ElementRef<HTMLElement>;
  @ViewChild('mttRespond') mttRespond: ElementRef<HTMLElement>;
  @ViewChild('mttResolve') mttResolve: ElementRef<HTMLElement>;

  @Input() widgetSettings;
  @Input() selectedWidgets;
  @Input() libraryWidgets;

  private displayWidgets;
  private readonly smallScreenThreshold = 800;
  public smallScreenMode: boolean;
  public mttTypes = MttType;
  public hasCSOCReportsAccess = false;
  public CSOCAccessChecked = false;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    const isSmallScreen = this.isSmallScreen();
     if (isSmallScreen !== this.smallScreenMode) {
      this.smallScreenMode = isSmallScreen;
      this.customWidgets();
    }
  }

  constructor(
    public contextService: ContextService,
    private userAuthService: UserAuthService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.smallScreenMode = this.isSmallScreen();
    this.getCSOCReportsAccess();
  }

  ngAfterViewInit() {
    this.customWidgets();
  }

  private customWidgets() {

    this.displayWidgets = this.adaptWidgetsRanksToScreen(this.selectedWidgets);

    this.displayWidgets?.forEach((w: Widget) => {

      const widget = this.getWidget(w.name);

      if (widget) {

        const bgTheme = w.rank % 2 ? CssTheme.white : CssTheme.black;
        // remove existing background theme
        widget.classList.remove(CssTheme.black);
        widget.classList.remove(CssTheme.white);
        // add background theme
        widget.classList.add(bgTheme);
        // when on a big screen if the half widget is alone  it's half widget, add empty div to complete the row with the good backgroud color 
        if (this.isHalfWidgetAloneOnRow(w, this.displayWidgets) && !this.smallScreenMode) {
            widget.style.gridColumn = '1 / 3'; // Span all columns
        }

        // place the widget on the selected row
        widget.style.gridRow = '' + (w.rank + 1);

        this.updateWidthClass(w, widget);

      }

    });
  }

  private getWidget(name: string) {
    switch (name) {
      case 'pendingCasesOcd': {
        return this.pendingCasesOcd.nativeElement;
      }
      case 'openCases': {
        return this.openCases.nativeElement;
      }
      case 'worldwatch': {
        return this.worldwatch.nativeElement;
      }
      case 'recentSupportActivity': {
        return this.recentSupportActivity.nativeElement;
      }
      case 'latestActivity': {
        return this.latestActivity.nativeElement;
      }
      case 'truePositivesVSFalsePositives': {
        return this.trueFalsePositives.nativeElement;
      }
      case 'mtta': {
        return this.mtta.nativeElement;
      }
      case 'mttRespond': {
        return this.mttRespond.nativeElement;
      }
      case 'mttResolve': {
        return this.mttResolve.nativeElement;
      }
      default: return null;
    }
  }

  private isHalfWidgetAloneOnRow(widget: Widget, widgets: Widget[]): boolean {
    if(widget.width === 12) {
      return false;
    }
    const widgetsOnRank = widgets.filter((a) => a.rank === widget.rank);
    return widgetsOnRank.length === 1;
  }

  private updateWidthClass(w : Widget, widget: HTMLElement){
    const isHalfWidth = w.width === 6 && !this.smallScreenMode;
    const newWidthClass = isHalfWidth ? 'half-width' : 'full-width';

    // Get current classes as an array
    const currentClasses = widget.className.split(' ');

    // Remove 'half-width' and 'full-width' if present
    const updatedClasses = currentClasses.filter(cls => cls !== 'half-width' && cls !== 'full-width');

    // Add the new width class
    updatedClasses.push(newWidthClass);

    // Set the updated class string
    widget.className = updatedClasses.join(' ');

  }

  private adaptWidgetsRanksToScreen(widgets: Widget[]): Widget[] {
    if (this.smallScreenMode) {
      // For small screens, just sort by rank and reassign ranks
      widgets?.sort((a, b) => a.rank - b.rank);
      return widgets?.map((w, index) => ({ ...w, rank: index + 1 }));
    } else {
      // For larger screens, pair half-width widgets
      let currentRank = 1;
      let previousHalfWidget: Widget | null = null;
  
      // return widgets sorted by ranking
      widgets?.sort((a, b) => a.rank - b.rank);
      return widgets?.map((w) => {
        if (w.width === 6) {
          if (previousHalfWidget) {
            // Pair with previous half-width widget
            w.rank = previousHalfWidget.rank;
            previousHalfWidget = null;
          } else {
            // First of potential pair
            w.rank = currentRank;
            previousHalfWidget = w;
            currentRank++;
          }
        } else {
          // Full-width widget
          w.rank = currentRank;
          currentRank++;
          previousHalfWidget = null;
        }
        return w;
      });
    }
  }
  

  private isSmallScreen() {
    return window.innerWidth <= this.smallScreenThreshold;
  }

  private getCSOCReportsAccess() {
    // Set this.hasCSOCReportsAccess value;
    this.userAuthService.loadUserDetails().then ( (userDetails: any) => {
      const userGroups = this.userAuthService.getUserGroupsFromDetails(userDetails);
      this.hasCSOCReportsAccess = userGroups?.includes('Access_CSOC_Reports') ?? false;
      this.CSOCAccessChecked = true;
    });
    
  }

}
