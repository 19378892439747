<div class="row">
  <div class="col-12 col-lg-6">
    <app-card
      class="mw-100"
      cardTitle="pages.cybersocxdr.dashboard.fieldTitles.contractInformation"
    >
      <div class="row">
        <div class="col-6">
          <h4 class="mb-2">
            {{ 'pages.cybersocxdr.dashboard.fieldTitles.ocdOffer' | translate }}
          </h4>
          <p>
            {{ contract?.service }}
          </p>
        </div>
        <div class="col-6">
          <h4 class="mb-2">
            {{
              'pages.cybersocxdr.dashboard.fieldTitles.solution'
                | translate
                | titlecase
            }}
          </h4>
          <p>
            @for (tech of contract?.technos; track $index) {
            {{ tech.technoName
            }}{{ $index < contract?.technos.length - 1 ? ',' : '' }}
            }
          </p>
        </div>
      </div>
    </app-card>
    <app-card
      class="mw-100"
      cardTitle="pages.cybersocxdr.dashboard.fieldTitles.myAccess"
    >
      <div class="d-flex gap-2">
        <a
          [attr.href]="
            'https://msurvey.orange.com/eXtendedSOC?tenant=' +
            filterService.clientInfo?.uuid
          "
          [ngClass]="[
            'btn',
            'bg-body-tertiary',
            hasAnsweredSurvey ? '' : 'border border-warning'
          ]"
        >
          <svg
            class="me-1"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 1000 1000"
          >
            <defs>
              <style>
                .cls-1 {
                  fill-rule: evenodd;
                }
              </style>
            </defs>
            <path
              class="cls-1"
              d="M938.713,460.564a37.349,37.349,0,0,0-11.029-25.343L900,407V200a75.015,75.015,0,0,0-75.034-75H100V650.3A74.728,74.728,0,0,0,175,725H275l-0.008,150.432L425,725h95L464.191,828.906,426.026,900l71.659-37.031,109.67-56.675,3.457-1.786,2.75-2.753C629.222,786.083,657.293,757.729,690,725H900V515l27.3-26.985c0.375-.353.747-0.721,1.12-1.108A36.286,36.286,0,0,0,938.713,460.564ZM274.992,275.087L725,275a25,25,0,0,1,0,50H275A24.957,24.957,0,1,1,274.992,275.087ZM275,400H725a25,25,0,0,1,0,50H275A25,25,0,0,1,275,400ZM500,575H275a25,25,0,0,1,0-50H500A25,25,0,0,1,500,575Zm354.338-49.56a3.692,3.692,0,0,1-.465.462c-68.845,68.9-213.4,213.541-257.989,258.167L486.213,840.743l58.116-108.257,266.1-265.929,43.434,42.3a12.462,12.462,0,0,1,3.708,8.252A11.02,11.02,0,0,1,854.338,525.44Zm56.144-55.953a5.187,5.187,0,0,1-.451.436l-30.389,30.39-51.563-51.6,38.881-38.883L910,452.9C914.78,457.691,914.994,464.975,910.482,469.487Z"
            />
          </svg>
          {{ 'pages.cybersocxdr.dashboard.feedback' | translate }}
        </a>
        <a
          *ngFor="let techno of contract?.technos"
          [attr.href]="techno.url"
          class="btn bg-body-tertiary"
        >
          <img
            src="{{ technoIconMap[techno.technoName] }}"
            class="techno"
            alt=""
          />
          {{ techno.technoName }}
        </a>
      </div>
    </app-card>
  </div>

  <div class="col-12 col-lg-6">
    <app-card
      class="mw-100"
      cardTitle="pages.cybersocxdr.dashboard.fieldTitles.slaFull"
    >
      <table class="table table-hover table-striped-columns">
        <caption class="d-none">
          {{
            'pages.cybersocxdr.dashboard.fieldTitles.slaFull' | translate
          }}
        </caption>
        <thead>
          <tr>
            <th scope="col">
              {{
                'pages.cybersocxdr.dashboard.fieldTitles.priority' | translate
              }}
            </th>
            <th scope="col">
              {{
                'pages.cybersocxdr.dashboard.fieldTitles.slaSchedule'
                  | translate
              }}
            </th>
            <th scope="col">
              {{
                'pages.cybersocxdr.dashboard.fieldTitles.slaNotificationTime'
                  | translate
              }}
            </th>
            <th scope="col">
              {{
                'pages.cybersocxdr.dashboard.fieldTitles.slaInvestigationTime'
                  | translate
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          @for (sla of contract?.slas; track sla.severity) {
          <tr>
            <th scope="row">P{{ sla.severity }}</th>
            <td>{{ sla.type | anonymise }}</td>
            <td>{{ parseSLA(sla.notificationDelay) | anonymise }}</td>
            <td>{{ parseSLA(sla.investigationDelay) | anonymise }}</td>
          </tr>
          }
        </tbody>
      </table>
    </app-card>
  </div>
</div>

<div class="row">
  <div class="col-12 col-lg-6">
    <app-clientcontactstable
      [clientContacts]="contract?.contacts"
      (onSubmitContacts)="sendNewClientContacts($event)"
    />
  </div>
  <div class="col-12 col-lg-6">
    <app-card
      class="mw-100 mb-lg-0"
      cardTitle="pages.cybersocxdr.dashboard.fieldTitles.ocdSideContacts"
    >
      <div class="row mb-2">
        <div class="col-6">
          @if( filterService.clientInfo?.ocdHotlineCode){
          <h4 class="mb-2">
            {{
              'pages.cybersocxdr.dashboard.fieldTitles.uniqueNumber' | translate
            }}
          </h4>
          <p>{{ filterService.clientInfo?.ocdHotlineCode | anonymise }}</p>
          }
        </div>
        <div class="col-6">
          @if( filterService.clientInfo?.ocdHotlineNumber){
          <h4 class="mb-2">
            {{
              'pages.cybersocxdr.dashboard.fieldTitles.ocdHotline' | translate
            }}
          </h4>
          <p>
            <a
              [attr.href]="'tel:' + filterService.clientInfo?.ocdHotlineNumber"
              >{{ filterService.clientInfo?.ocdHotlineNumber | anonymise }}</a
            >
          </p>
          }
        </div>
      </div>
      <div class="row px-2">
        <table class="table">
          <caption class="d-none">
            {{
              'pages.cybersocxdr.dashboard.fieldTitles.ocdSideContacts'
                | translate
            }}
          </caption>
          <thead>
            <tr>
              <th scope="col">
                {{ 'pages.cybersocxdr.dashboard.fieldTitles.name' | translate }}
              </th>
              <th scope="col">
                {{ 'pages.cybersocxdr.dashboard.fieldTitles.role' | translate }}
              </th>
              <th scope="col" class="col-4">
                {{ 'pages.cybersocxdr.dashboard.fieldTitles.mail' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            @for (ocdContact of contract?.ocdContacts; track ocdContact.name) {
            <tr>
              <th
                [ngClass]="
                  ocdContact.priority === 1 ? 'text-primary-emphasis' : ''
                "
                scope="row"
              >
                {{ ocdContact.name | anonymise }}
              </th>
              <td
                [ngClass]="
                  ocdContact.priority === 1 ? 'text-primary-emphasis' : ''
                "
              >
                {{ ocdContact.role?.name | anonymise }}
              </td>
              <td>
                <a [attr.href]="'mailto:' + ocdContact.mail">{{
                  ocdContact.mail | anonymise
                }}</a>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </app-card>
  </div>
</div>
