import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { Observable } from "rxjs";
import { BreadcrumbService } from "../../framework/breadcrumb/breadcrumb.service";
import { Crumb } from "../../framework/breadcrumb/breadcrumb.types";
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'breadcrumb',
  standalone: true,
  imports: [RouterLink, AsyncPipe],
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit {
  private readonly router: Router = inject(Router);
  private readonly breadcrumbService: BreadcrumbService = inject(BreadcrumbService);

  protected breadcrumbs$: Observable<Crumb[]>;

  ngOnInit(): void {
    this.breadcrumbs$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap((): Promise<Crumb[]> => this.breadcrumbService.getBreadcrumbs())
    );
  }
}
