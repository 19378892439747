<app-tabbar [currentTab]="tab" [tabs]="tabs"/>

<div class="container-fluid p-4 h-100">
  <div class="row">
    @if (tab === 'closed') {
      <div class="col-md-5 col-12">
        <app-filtermenu>
          <app-card cardTitle="pages.cybersocxdr.4A3W.closedIncidentsPerVerdict">
            <app-pie [data]="incidentsClosedByVerdict"
                     title=""
                     suffix=""
                     innerSize="0"/>
          </app-card>
        </app-filtermenu>
      </div>
      <div class="col-md-7 col-12">
        <app-filtermenu>
          <app-card cardTitle="pages.cybersocxdr.4A3W.closedIncidentsPerVerdictOvertime">
            <app-barovertime [data]="incidentsClosedByVerdictOvertime"
                             title=""/>
          </app-card>
        </app-filtermenu>
      </div>
    } @else if (tab === '4A') {
      <div class="col-md-5 col-12">
        <app-card cardTitle="pages.cybersocxdr.4A3W.4ATitle">
          <p class="lh-base">
            {{ 'pages.cybersocxdr.4A3W.4ADescription' | translate }}<br/>
            <b> {{ 'pages.cybersocxdr.4A3W.actor' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.actorDescription' | translate }}<br/>
            <b> {{ 'pages.cybersocxdr.4A3W.action' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.actionDescription' | translate }}<br/>
            <b> {{ 'pages.cybersocxdr.4A3W.asset' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.assetDescription' | translate }}<br/>
            <b> {{ 'pages.cybersocxdr.4A3W.attribute' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.attributeDescription' | translate }}
          </p>
        </app-card>
        <app-card>
          <div class="treemap-container">
            <select class="form-select rounded me-2"
                    id="fourARepartitionField"
                    name="fourARepartitionField"
                    [(ngModel)]="fourARepartitionField"
                    (change)="onSelect4ARepartition($event.target.value)">

              @for (option of fourARepartitionOptions; track $index) {
                <option [value]="option.value">
                  {{ option.translateKey | translate }}
                </option>
              }
            </select>
            <app-treemap [data]="fourARepartitionData"/>
          </div>
        </app-card>
      </div>
      <div class="col-md-7 col-12">
        <app-card>
          <div class="row mx-1">
            <p class="col-4 fw-bold">
              {{ 'pages.cybersocxdr.4A3W.actor' | translate }}
            </p>
            <p class="col-4 text-center fw-bold">
              {{ 'pages.cybersocxdr.4A3W.action' | translate }}
            </p>
            <p class="col-4 text-end fw-bold">
              {{ 'pages.cybersocxdr.4A3W.asset' | translate }}
            </p>
          </div>
          <div class="row">
            <app-sankey [data]="sankey4ASeries" title=""/>
          </div>
        </app-card>
        <app-card>
          <app-insight-carrousel [insights]="fourAInsights"
                                 [displayHeader]="false"/>
        </app-card>
      </div>
      <div class="col-12">
        <app-card>
          <app-pagination-incident-list [currentPage]="truePositiveIncidentsCurrentPage"
                                        (fetchPage)="loadTruePositivePage($event)"
                                        (newFlagEmitter)="onFlag($event)"/>
        </app-card>
      </div>
    } @else if (tab === '3W') {
      <div class="col-md-5 col-12">
        <app-card cardTitle="pages.cybersocxdr.4A3W.3WTitle">
          <p class="lh-base">
            {{ 'pages.cybersocxdr.4A3W.3WDescription' | translate }}<br/>
            <b> {{ 'pages.cybersocxdr.4A3W.why' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.whyDescription' | translate }}<br/>
            <b> {{ 'pages.cybersocxdr.4A3W.who' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.whoDescription' | translate }}<br/>
            <b> {{ 'pages.cybersocxdr.4A3W.what' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.whatDescription' | translate }}
          </p>
        </app-card>
        <app-card>
          <div class="treemap-container">
            <select class="form-select rounded me-2"
                    id="threeWRepartitionField"
                    name="threeWRepartitionField"
                    [(ngModel)]="threeWRepartitionField"
                    (change)="onSelect3WRepartition($event.target.value)">

              @for (option of threeWRepartitionOptions; track $index) {
                <option [value]="option.value">
                  {{ option.translateKey | translate }}
                </option>
              }
            </select>
            <app-treemap [data]="threeWRepartitionData"/>
          </div>
        </app-card>
      </div>
      <div class="col-md-7 col-12">
        <app-card>
          <div class="row mx-1">
            <p class="col-4 fw-bold">
              {{ 'pages.cybersocxdr.4A3W.why' | translate }}
            </p>
            <p class="col-4 text-center fw-bold">
              {{ 'pages.cybersocxdr.4A3W.who' | translate }}
            </p>
            <p class="col-4 text-end fw-bold">
              {{ 'pages.cybersocxdr.4A3W.what' | translate }}
            </p>
          </div>
          <div class="row">
            <app-sankey [data]="sankey3WSeries" title=""/>
          </div>
        </app-card>
        <app-card>
          <app-insight-carrousel [insights]="threeWInsights"
                                 [displayHeader]="false"/>
        </app-card>
      </div>
      <div class="col-12">
        <app-card>
          <app-pagination-incident-list [currentPage]="falsePositiveIncidentsCurrentPage"
                                        (fetchPage)="loadFalsePositivePage($event)"
                                        (newFlagEmitter)="onFlag($event)"/>
        </app-card>
      </div>
    }
  </div>
</div>
