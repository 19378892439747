<div class="switch-theme-button">
  <button (click)="toggle()"
          class="btn">

    <fa-icon [icon]="['fas', darkModeState ? 'moon' : 'sun']"
             size="lg"/>

    <span class="ms-1">
      {{ 'pages.cybersocxdr.layout.dev.' + (darkModeState ? 'lightModeEnable' : 'darkModeEnable') | translate }}
    </span>

  </button>
</div>
