<div class="faq-list px-4">
  @for (faq of faqs; track $index) {
  <button
    class="faq-item p-3 mb-1 mt-1 w-100 text-start border-0 bg-transparent"
    (click)="viewFaq($index)"
  >
    <h5
      class="mb-3 d-flex align-items-center"
      [ngClass]="contextService.isDarkTheme() ? 'text-light' : 'text-dark'"
    >
      {{ faq.question | translate }}
    </h5>
    <p
      class="ms-3 mb-0 lh-base"
      [ngClass]="
        contextService.isDarkTheme()
          ? 'text-light opacity-75'
          : 'text-dark opacity-75'
      "
    >
      {{
        faq.answer.length > 30
          ? (faq.answer | translate | slice : 0 : 30) + '...'
          : (faq.answer | translate)
      }}
    </p>
  </button>
  @if ($index !== faqs.length - 1) {
  <hr class="opacity-25 p-0 m-0" />
  } }
</div>
