import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { DynamicDate } from "src/app/shared/model/cybersocxdr/facetsValues";
import { XtendedFiltersService } from "src/app/cybersocxdr/xtendedfilters.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { CybersocXDRDashboardSearchCriteria } from "src/app/shared/model/searchCriteria";
import { RouterOutlet } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { IconsModule } from "src/app/shared/modules/icons/icons.module";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'filter-date-range',
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    IconsModule,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    DatePipe,
  ],
  templateUrl: './filter-date-range.component.html',
  styleUrl: './filter-date-range.component.scss'
})
export class FilterDateRangeComponent implements OnInit {
  protected readonly periodOptions: DynamicDate[] = [
    DynamicDate.TODAY, DynamicDate.LAST_24H,
    DynamicDate.THIS_WEEK, DynamicDate.LAST_7D,
    DynamicDate.THIS_MONTH, DynamicDate.LAST_30D,
    DynamicDate.LAST_MONTH, DynamicDate.LAST_90D,
    DynamicDate.LAST_180D, DynamicDate.THIS_YEAR,
  ];

  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly filterService: XtendedFiltersService = inject(XtendedFiltersService);
  public translate: TranslateService = inject(TranslateService);

  /**
   * @public
   * @desc The outlet that will contain the component to be rendered after a search.
   */
  @Input()
  public outlet: RouterOutlet;

  protected displayCreated: string;
  protected criteria: CybersocXDRDashboardSearchCriteria;

  public ngOnInit(): void {
    this.criteria = this.filterService.searchCriteria;

    this.initFilterChange();
    this.updateDisplayCreated(this.filterService.searchCriteria);
  }

  /**
   * @private
   * @desc Initializes the filter change subscription.
   */
  private initFilterChange(): void {
    this.filterService.filterEvent
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((searchCriteria: CybersocXDRDashboardSearchCriteria): void => {
        this.updateDisplayCreated(searchCriteria);
        this.performSearch(searchCriteria);
      });
  }

  /**
   * @public
   * @desc Triggers the search with the current criteria.
   */
  protected onSearch(): void {
    this.performSearch(this.criteria);
  }

  /**
   * @public
   * @desc Triggers the search with the current criteria when the date input changes.
   */
  protected onDateChange(): void {
    this.displayCreated = 'custom';
    this.filterService.serializeSeachCriteria();
  }

  /**
   * @public
   * @desc Triggers the search with the current criteria when the period selection changes.
   *
   * @param period - The selected period.
   */
  protected onChangePeriod(period: string): void {
    this.displayCreated = period;
    this.filterService.searchCriteria.createdFrom = period as DynamicDate;
    this.filterService.searchCriteria.createdTo = null;
    this.filterService.serializeSeachCriteria();

    this.performSearch(this.filterService.searchCriteria);
  }

  /**
   * @private
   * @desc Updates the display value of the created date filter based on the search criteria.
   *
   * @param searchCriteria - The search criteria to update the display value from.
   */
  private updateDisplayCreated(searchCriteria: CybersocXDRDashboardSearchCriteria): void {
    this.displayCreated = this.periodOptions.includes(searchCriteria.createdFrom as DynamicDate)
      ? searchCriteria.createdFrom
      : 'custom';
  }

  /**
   * @private
   * @desc Triggers the search with the current criteria.
   *
   * @param criteria - The search criteria to search with.
   */
  private performSearch(criteria: CybersocXDRDashboardSearchCriteria): void {
    this.filterService.checkCurrentPreset();
    this.filterService.serializeSeachCriteria();

    (this?.outlet?.component as any)?.onSearch?.(criteria);
  }
}
