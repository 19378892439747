<div class="row">
  <div class="col-6">
    <app-card
      [cardTitle]="
        'pages.cybersocxdr.myIT.topHostsIncidentsCardTitle' | translate
      "
    >
      <table class="table table-striped">
        <caption class="d-none">
          {{
            'pages.cybersocxdr.myIT.topHostsIncidentsCardTitle' | translate
          }}
        </caption>
        <thead>
          <tr>
            <th>{{ 'pages.cybersocxdr.myIT.hostname' | translate }}</th>
            <th>{{ 'pages.cybersocxdr.myIT.incidentCount' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          @for (host of topHostsData; track host.name) {
          <tr>
            <td>{{ host.name }}</td>
            <td>{{ host.volume }}</td>
          </tr>
          }
        </tbody>
      </table>

      @if (topHostsData.length === 0) {
      <div class="text-center p-3">
        {{ 'pages.cybersocxdr.myIT.noIncidentsData' | translate }}
      </div>
      }
    </app-card>
  </div>

  <div class="col-6">
    <app-card cardTitle="pages.cybersocxdr.myIT.topHostsRepartitionCardTitle">
      <app-pie [data]="hostsRepartitionData" />
    </app-card>
  </div>
</div>
