<div id="#list-incidents" class="row">
  <div class="w-100">
    <div class="row px-2 tab-content border-0">
      @if(title || exportable || selectableColumns){
      <div
        class="d-flex gap-2 flex-wrap justify-content-between align-items-center mb-2"
      >
        <div class="d-flex gap-2 align-items-center">
          @if(title){
          <h3 class="m-0">{{ title | translate }}</h3>
          @if(badgeAnnotation){
          <span
            class="tag tag-sm bg-primary border border-primary text-body"
            style="--bs-bg-opacity: 0.2"
          >
            {{ badgeAnnotation }}
          </span>
          } }
        </div>

        @if(exportable || selectableColumns) {
        <div class="d-flex gap-2">
          @if (exportable) {
          <button
            class="bg-transparent order-0 d-flex fw-bold align-items-center gap-2 rounded"
            style="white-space: nowrap"
            (click)="exportCSV($event)"
            [disabled]="exportLoading"
          >
            @if(exportLoading){
            {{ 'pages.cybersocxdr.incidents.list.exporting' | translate }}
            <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
            } @else {
            {{ 'pages.cybersocxdr.incidents.list.export' | translate }}
            <fa-icon [icon]="['fas', 'file-csv']"></fa-icon>
            }
          </button>
          } @if(selectableColumns){
          <div class="dropdown">
            <button
              class="btn d-flex justify-content-between dropdown-toggle w-100 rounded"
              type="button"
              id="dropdownTable"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ 'pages.cybersocxdr.incidents.list.tableOptions' | translate }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownTable">
              @for (column of getAllColumns(); track column.id) {
              <li>
                <button
                  class="dropdown-item w-100 text-start border-0 bg-transparent"
                  (click)="toggleColumnVisibility($event, column.id)"
                >
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [id]="'field' + '-' + column.id"
                      [checked]="column.visible"
                      (change)="toggleColumnVisibility($event, column.id)"
                    />
                    <label
                      class="form-check-label"
                      [for]="'field' + '-' + column.id"
                    >
                      {{ column.name | translate }}
                    </label>
                  </div>
                </button>
              </li>
              }
            </ul>
          </div>

          }
        </div>
        }
      </div>
      }
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <caption class="d-none">
            {{
              title
            }}
          </caption>
          <thead>
            <tr>
              @if (displayFlag) {
              <th scope="col" class="col-1">
                {{ 'pages.generic.flag' | translate }}
              </th>
              } @for (column of getVisibleColumns(); track column.id) {
              <th
                scope="col"
                class="col-{{ column.size }}"
                [ngClass]="{
                  sortable: column.sortable && column.id && sortable
                }"
                (click)="
                  sortable &&
                    column.sortable &&
                    column.id &&
                    sortResults($event, column.id)
                "
                (keydown)="
                  $event.key === 'Enter' &&
                    sortable &&
                    column.sortable &&
                    column.id &&
                    sortResults($event, column.id)
                "
                [attr.tabindex]="
                  column.sortable && column.id && sortable ? 0 : null
                "
                [attr.role]="
                  column.sortable && column.id && sortable ? 'button' : null
                "
                [attr.aria-label]="
                  column.sortable && column.id && sortable
                    ? (column.name | translate) +
                      ' ' +
                      ('pages.generic.sort' | translate)
                    : null
                "
              >
                <div class="d-flex align-items-center">
                  {{ column.name | alternate : 'severity' | translate }}
                  @if (column.sortable && column.id && sortable) {
                  <span class="ms-1">
                    <fa-icon
                      class="sort-icon"
                      [icon]="
                        currentSort === column.id
                          ? currentSortDirection === 'ASC'
                            ? ['fas', 'sort-up']
                            : ['fas', 'sort-down']
                          : ['fas', 'sort']
                      "
                      [ngClass]="{
                        'current-sort': currentSort === column.id
                      }"
                      [fixedWidth]="true"
                      size="sm"
                    ></fa-icon>
                  </span>
                  }
                </div>
              </th>
              }
            </tr>
          </thead>
          <tbody>
            @for (cc of cases; track cc.id) {
            <tr>
              @if(displayFlag){
              <td class="align-middle">
                <button
                  type="button"
                  [ngClass]="[
                    'p-0',
                    'border-0',
                    'flag',
                    cc.isFlagged ? 'bg-primary' : 'bg-light'
                  ]"
                  style="width: 40px; height: 40px; border-radius: 100px"
                  (click)="flagCase($event, cc)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#000000"
                    class="svg-inline"
                    viewBox="0 0 1000 1000"
                  >
                    <path
                      d="M888 275H588a75 75 0 0 1-75-75 75 75 0 0 0-75-75H188v-12.5A37.5 37.5 0 0 0 150.5 75a37.5 37.5 0 0 0-37.5 37.5V925h75V525h225a75 75 0 0 1 75 75 75 75 0 0 0 75 75h325L700.5 475Z"
                      style="fill-rule: evenodd"
                      fill="#000000"
                    ></path>
                  </svg>
                </button>
              </td>
              } @for (column of getVisibleColumns(); track column.id) {
              <td class="align-middle">
                @if(column.id === XtendedFacet.SEVERITY){
                <span
                  class="py-1 px-2 rounded-pill fw-bold"
                  [ngClass]="'P' + cc.severity | lowercase | removeWhiteSpace"
                >
                  {{ cc.severity | severity | alternate | translate }}
                </span>
                } @else if(column.id === XtendedFacet.CLIENT_TICKET_ID){
                @if(cc.clientTicketNumber &&
                cc.clientTicketNumber.startsWith('OCD_')){
                <a
                  [routerLink]="['/support/view', cc.clientTicketId]"
                  class="text-decoration-none"
                  >{{ cc.clientTicketNumber }}
                </a>
                } @else {
                {{ cc.clientTicketNumber }}
                } } @else if(column.id === XtendedFacet.SUBJECT){
                <a
                  [routerLink]="['/xtended-soc/incidents', cc.id]"
                  class="text-decoration-none"
                  >{{ cc.subject | anonymise }}</a
                >
                } @else if(column.id === XtendedFacet.OWNER){
                {{ cc.owner || 'N/A' | anonymise }}
                } @else if(column.id === XtendedFacet.START_TIME){
                {{ cc.createdDate | date : 'dd/MM/yyyy HH:mm' }}
                } @else {
                {{ cc[column.id] }}
                }
              </td>
              }
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
