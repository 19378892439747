import { Component, OnInit } from '@angular/core';
import FileSaver from 'file-saver';
import { SeriesColumnOptions } from 'highcharts';
import { Subscription } from 'rxjs';
import { Contract } from 'src/app/shared/model/cybersocxdr/clientInfo';
import { XtendedFacet, XtendedGroupbyFacet } from 'src/app/shared/model/cybersocxdr/facets';
import { Severity } from 'src/app/shared/model/cybersocxdr/facetsValues';
import { IncidentDataSource } from 'src/app/shared/model/cybersocxdr/incidentDataSourceEnum';
import { CaseXDR } from 'src/app/shared/model/cybersocxdr/microsocticket';
import { PaginatedList } from 'src/app/shared/model/cybersocxdr/paginatedList';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ContextService } from 'src/app/shared/services/context-service';
import { constants } from '../../../shared/constants';
import { CybersocxdrService } from '../../../shared/services/xtendedsoc/cybersocxdr.service';
import { XtendedSearchable } from '../../pages/xtended-page';
import { XtendedHighchartService } from '../../xtended-highchart.service';
import { XtendedFiltersService } from '../../xtendedfilters.service';
import { handlePromises } from '../../utils';

@Component({
  selector: 'app-xtended-incidents-page',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.scss'],
})
export class XtendedSOCIncidentsComponent implements OnInit, XtendedSearchable {
  //Expose enum to the template
  IncidentDataSource = IncidentDataSource;

  private contractChangeSubscription: Subscription;
  contract: Contract | null = null;

  page: string;

  // data
  casesLoaded: boolean;
  createdFrom: string;
  createdTo: string;
  groupedIncidents: Highcharts.SeriesPieOptions[];
  incidentsCounts: {
    [key: string]: {
      periodTotal: number;
      previousPeriodTotal: number;
      difference: number;
    };
  };

  limit: number;
  caseNumber: string;

  severitiesCount = [Severity.CRITICAL, Severity.HIGH, Severity.MEDIUM, Severity.LOW];

  incidentsByEntityData: SeriesColumnOptions[];

  //filters
  groupBy: XtendedGroupbyFacet = XtendedFacet.SEVERITY;
  groupByOptions: XtendedGroupbyFacet[] = [
    XtendedFacet.SEVERITY,
    XtendedFacet.OWNER,
    XtendedFacet.CATEGORY,
    XtendedFacet.STATUS,
    XtendedFacet.WAITING_FOR,
    XtendedFacet.CLOSURE_VERDICT,
    XtendedFacet.HOSTNAME,
    XtendedFacet.USERNAME,
    XtendedFacet.CATEGORY,
    XtendedFacet.RULE_NAME,
    XtendedFacet.PROCESS_PATH,
    XtendedFacet.CMD_LINE,
  ];

  sortedBy: string;
  sortedType: string;

  incidentsCurrentPage: PaginatedList<CaseXDR>;
  backlogCurrentPage: PaginatedList<CaseXDR>;
  flagCurrentPage: PaginatedList<CaseXDR>;

  incidentSort: { field: string; direction: 'ASC' | 'DESC' } = { field: '', direction: 'ASC' };
  backlogSort: { field: string; direction: 'ASC' | 'DESC' } = { field: '', direction: 'ASC' };
  flagSort: { field: string; direction: 'ASC' | 'DESC' } = { field: '', direction: 'ASC' };

  isDarkThemeEnabled: boolean;

  public exportLoading: boolean = false;

  constructor(
    private readonly cybersocxdrService: CybersocxdrService,
    private readonly globalFilters: XtendedFiltersService,
    private readonly alertService: AlertService,
    public readonly xtendedHighchartService: XtendedHighchartService,
    public readonly contextService: ContextService
  ) {
    this.isDarkThemeEnabled = contextService.isDarkTheme();
  }

  async onSearch() {
    const searchCriteria = this.globalFilters.searchCriteria;
    this.createdTo = searchCriteria.createdTo;
    this.createdFrom = searchCriteria.createdFrom;

    this.loadPages(true);

    searchCriteria.limit = this.limit;
    searchCriteria.skip = 0;

    if (this.sortedBy) {
      searchCriteria.sortBy = {
        field: this.sortedBy,
        type: this.sortedType,
      };
    }

    if (this.globalFilters?.selectedContract?.incidentDataSource !== IncidentDataSource.HUBZH) {
      this.groupedIncidents = [
        this.xtendedHighchartService.pieChartSerie(
          await this.cybersocxdrService.getCasesGroupBy(searchCriteria, this.groupBy),
          this.groupBy
        ),
      ];

      this.incidentsByEntityData = this.xtendedHighchartService.barOvertimeChartSerie(
        await this.cybersocxdrService.getCasesGroupByOvertime(searchCriteria, XtendedFacet.STATUS),
        XtendedFacet.STATUS
      );
    }

    const incidentsBySeverityEvolution = await this.cybersocxdrService.getIncidentBySeverityEvolution(searchCriteria);

    this.incidentsCounts = {
      low: {
        periodTotal: incidentsBySeverityEvolution.low,
        previousPeriodTotal: incidentsBySeverityEvolution.previousLow,
        difference: incidentsBySeverityEvolution.low - incidentsBySeverityEvolution.previousLow,
      },
      medium: {
        periodTotal: incidentsBySeverityEvolution.medium,
        previousPeriodTotal: incidentsBySeverityEvolution.previousMedium,
        difference: incidentsBySeverityEvolution.medium - incidentsBySeverityEvolution.previousMedium,
      },
      high: {
        periodTotal: incidentsBySeverityEvolution.high,
        previousPeriodTotal: incidentsBySeverityEvolution.previousHigh,
        difference: incidentsBySeverityEvolution.high - incidentsBySeverityEvolution.previousHigh,
      },
      critical: {
        periodTotal: incidentsBySeverityEvolution.critical,
        previousPeriodTotal: incidentsBySeverityEvolution.previousCritical,
        difference: incidentsBySeverityEvolution.critical - incidentsBySeverityEvolution.previousCritical,
      },
    };

    this.loadAppropriateIncidentPage(0);
  }

  async exportCases() {
    const searchCriteria = this.globalFilters.searchCriteria;

    if (this.sortedBy) {
      searchCriteria.sortBy = {
        field: this.sortedBy,
        type: this.sortedType,
      };
    }

    this.exportLoading = true;
    (await this.cybersocxdrService.casesCSVExport(searchCriteria)).subscribe({
      next: (val: any) => {
        const file = new Blob([val], { type: 'text/csv' });
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(file);
        } else {
          FileSaver.saveAs(file, 'incidents.csv');
        }
      },
      error: (err) => {
        const message = JSON.parse(err.error.text).message;
        this.alertService.handlerError(message);
      },
      complete: () => {
        this.exportLoading = false;
      },
    });
  }

  ngOnInit() {
    this.page = 'backlog';
    this.casesLoaded = true;
    this.limit = constants.recordsPerPage.microsocticket;

    this.contract = this.globalFilters.selectedContract;
    this.contractChangeSubscription = this.globalFilters.contractChangeEvent.subscribe((contract) => {
      this.contract = contract;
    });
    this.onSearch();
  }

  async ngOnDestroy() {
    if (this.contractChangeSubscription) this.contractChangeSubscription.unsubscribe();
  }

  async updateGroupBy(groupBy: XtendedGroupbyFacet): Promise<void> {
    this.groupBy = groupBy;
    this.groupedIncidents = [
      this.xtendedHighchartService.pieChartSerie(
        await this.cybersocxdrService.getCasesGroupBy(this.globalFilters.searchCriteria, groupBy),
        groupBy
      ),
    ];
  }

  async onFlag(incident: CaseXDR) {
    if (!incident.isFlagged) {
      await this.cybersocxdrService.flagIncident(incident.id, this.contract?.incidentDataSource ?? undefined);
    } else {
      await this.cybersocxdrService.deleteFlagIncident(incident.id, this.contract?.incidentDataSource ?? undefined);
    }
    this.loadPages();
  }

  public async loadIncidentPage(page: number, sort?: { field: string; direction: 'ASC' | 'DESC' }, status?: string[]) {
    const params = {
      ...this.globalFilters.searchCriteria,
      limit: this.incidentsCurrentPage?.pageSize ?? 15,
      skip: page ?? 0,
    };

    if (status) {
      params.status = status;
    }

    if (sort?.field) {
      params.sortBy = {
        field: sort.field,
        type: sort.direction.toLowerCase(),
      };
    }

    return await this.cybersocxdrService.getPaginatedCases(params);
  }

  public async loadFlagIncidentPage(page: number, sort?: { field: string; direction: 'ASC' | 'DESC' }) {
    return await this.cybersocxdrService.getFlaggedPaginatedCases({
      ...this.globalFilters.searchCriteria,
      limit: this.incidentsCurrentPage?.pageSize ?? 15,
      skip: page ?? 0,
      sortBy: {
        field: sort?.field,
        type: sort?.direction?.toLowerCase(),
      },
    });
  }

  public async loadAppropriateIncidentPage(page: number) {
    this.casesLoaded = false;
    switch (this.page) {
      case 'all':
        this.incidentsCurrentPage = await this.loadIncidentPage(page, this.incidentSort);
        break;
      case 'backlog':
        this.backlogCurrentPage = await this.loadIncidentPage(page, this.backlogSort, ['-closed']);
        break;
      case 'flagged':
        this.flagCurrentPage = await this.loadFlagIncidentPage(page, this.flagSort);
        break;
      default:
        console.log('Unknown page type: ' + this.page);
        break;
    }
    this.casesLoaded = true;
  }

  public getDisplayPage(): PaginatedList<CaseXDR> {
    switch (this.page) {
      case 'all':
        return this.incidentsCurrentPage;
      case 'backlog':
        return this.backlogCurrentPage;
      case 'flagged':
        return this.flagCurrentPage;
      default:
        console.log('Unknown page type: ' + this.page);
        return null;
    }
  }

  public async loadPages(reset: boolean = false) {
    [this.incidentsCurrentPage, this.backlogCurrentPage, this.flagCurrentPage] = await handlePromises([
      this.loadIncidentPage(
        reset || !this.incidentsCurrentPage?.page ? 0 : this.incidentsCurrentPage?.page,
        this.incidentSort
      ),
      this.loadIncidentPage(
        reset || !this.backlogCurrentPage?.page ? 0 : this.backlogCurrentPage?.page,
        this.backlogSort,
        ['-closed']
      ),
      this.loadFlagIncidentPage(reset || !this.flagCurrentPage?.page ? 0 : this.flagCurrentPage?.page),
    ]);
  }

  public sortCases(sort: { field: string; direction: 'ASC' | 'DESC' }) {
    switch (this.page) {
      case 'all':
        this.incidentSort = sort;
        this.loadAppropriateIncidentPage(0);
        break;
      case 'backlog':
        this.backlogSort = sort;
        this.loadAppropriateIncidentPage(0);
        break;
      case 'flagged':
        this.flagSort = sort;
        this.loadAppropriateIncidentPage(0);
        break;
    }
  }
}
