import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ClientFileDto } from 'src/app/cybersocxdr/pages/my-space/my-files/client-file.dto';
import { environment } from 'src/environments/environment';
import {
  AgentsVersionByProductTypeDTO,
  EndpointDataDTO,
  OSRepartitionDataDTO,
  PolicyRepartitionByProductTypeDTO,
} from '../../model/cybersocxdr/clientHosts';

export interface ClientEntityDto {
  id: number;
  name: string;
  members: UserInEntityDto[];
}

export interface UserInEntityDto {
  id: number;
  mail: string;
}

const ROOT_URL = environment.endpoints.cybersocxdr;
const CLIENT_URL = ROOT_URL + 'clients';
const ENTITIES_URL = CLIENT_URL + '/entities';
const CLIENT_FILES_URL = CLIENT_URL + '/files';

@Injectable({
  providedIn: 'root',
})
export class XtendedClientService {
  constructor(private readonly http: HttpClient) {}

  async getEntities(): Promise<ClientEntityDto[]> {
    return firstValueFrom(this.http.get<ClientEntityDto[]>(ENTITIES_URL));
  }

  async getEntityById(entityId: number): Promise<ClientEntityDto> {
    return firstValueFrom(this.http.get<ClientEntityDto>(`${ENTITIES_URL}/${entityId}`));
  }

  async addUserToEntity(entityId: number, userMail: string): Promise<void> {
    return firstValueFrom(this.http.post<void>(`${ENTITIES_URL}/${entityId}/users`, null, { params: { userMail } }));
  }

  async removeUserFromEntity(userInEntityId: number): Promise<void> {
    return firstValueFrom(this.http.delete<void>(`${ENTITIES_URL}/users/${userInEntityId}`));
  }

  async getClientFiles(): Promise<ClientFileDto[]> {
    return firstValueFrom(this.http.get<ClientFileDto[]>(`${CLIENT_URL}/files`));
  }

  async getClientFile(fileId: number): Promise<Blob> {
    return firstValueFrom(
      this.http.get(`${CLIENT_FILES_URL}/${fileId}`, {
        responseType: 'blob',
      })
    );
  }

  async getHostsData(techno: string): Promise<EndpointDataDTO> {
    return firstValueFrom(
      this.http.get<EndpointDataDTO>(`${CLIENT_URL}/hosts-data`, {
        params: { techno },
      })
    );
  }

  async getOSRepartitionData(techno: string): Promise<OSRepartitionDataDTO[]> {
    return firstValueFrom(
      this.http.get<OSRepartitionDataDTO[]>(`${CLIENT_URL}/os-repartition`, {
        params: { techno },
      })
    );
  }

  async getAgentsVersionByProductType(techno: string): Promise<AgentsVersionByProductTypeDTO[]> {
    return firstValueFrom(
      this.http.get<AgentsVersionByProductTypeDTO[]>(`${CLIENT_URL}/agents-versions`, {
        params: { techno },
      })
    );
  }

  async getPoliciesRepartitionByProductType(techno: string): Promise<PolicyRepartitionByProductTypeDTO[]> {
    return firstValueFrom(
      this.http.get<PolicyRepartitionByProductTypeDTO[]>(`${CLIENT_URL}/policies-repartition`, {
        params: { techno },
      })
    );
  }
}
