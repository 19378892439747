import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UserPresetItemComponent } from 'src/app/cybersocxdr/components/my-space/settings/user-preset-item/user-preset-item.component';
import { CardComponent } from 'src/app/shared/components/xtended/card/card.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/xtended/dialogs/confirm/confirm.component';
import { AppSharedModule } from '../app-shared.module';
import { AlternateDataSourcePipe } from '../shared/pipes/alternate-data-source.pipe';
import { SeverityPipe } from '../shared/pipes/severity.pipe';
import { XtendedChartService } from '../shared/services/xtendedsoc/xtended-chart.service';
import { XtendedNotificationService } from '../shared/services/xtendedsoc/xtended-notification.service';
import { BacklogSanityScoreComponent } from './components/backlogSanityScore/backlog-sanity-score.component';
import { CybersocxdrIncidentBySeverityCategoryComponent } from './components/charts/incidentbyseveritycategory/incidentbyseveritycategory.component';
import { SlaGaugeComponent } from './components/charts/slagauge/slagauge.component';
import { ClientcontactstableComponent } from './components/clientcontactstable/clientcontactstable.component';
import { DailyInfoComponent } from './components/dailyInfo/daily-info.component';
import { FeedbackReminderComponent } from './components/feedback-reminder/feedback-reminder.component';
import { FiltermenuComponent } from './components/filtermenu/filtermenu.component';
import { FourAThreeWPreviewComponent } from './components/fourAthreeWPreview/four-a-three-w-preview.component';
import { IconInfoComponent } from './components/icon-info/icon-info.component';
import { IncidentlistComponent } from './components/incidentlist/incidentlist.component';
import { IncidentNeedingActionPreviewComponent } from './components/incidentNeedingActionPreview/incident-needing-action-preview.component';
import { AgentCardComponent } from './components/my-it/agent-card/agent-card.component';
import { PaginationIncidentListComponent } from './components/paginationList/paginationIncidentList.component';
import { RouteWithfacetsComponent } from './components/route-withfacets/route-withfacets.component';
import { ScrollableIncidentListComponent } from './components/scrollableIncidentList/scrollableIncidentList.component';
import { SearchabledropdownComponent } from './components/searchabledropdown/searchabledropdown.component';
import { SecuritynavigatormanagerComponent } from './components/securitynavigatormanager/securitynavigatormanager.component';
import { TabBarComponent } from './components/tabbar/tabbar.component';
import { XtendedHelpComponent } from './components/xtended-help/xtended-help.component';
import { XtendedIncidentListComponent } from './components/xtended-incident-list/xtended-incident-list.component';
import { XtendedNotificationCarouselComponent } from './components/xtended-notification-carousel/xtended-notification-carousel.component';
import { CybersocxdrRoutingModule } from './cybersocxdr-routing.module';
import { XtendedSOC4A3WComponent } from './pages/4A3W/4A3W.component';
import { XtendedSOCDashboardComponent } from './pages/dashboard/dashboard.component';
import { XtendedSOCEntityDetailComponent } from './pages/entities/detail/entity-detail.component';
import { XtendedSOCEntitiesComponent } from './pages/entities/entities.component';
import { XtendedSOCHomeComponent } from './pages/home/home.component';
import { XtendedSOCIncidentDetailsComponent } from './pages/incident-details/incident-details.component';
import { XtendedSOCIncidentsComponent } from './pages/incidents/incidents.component';
import { MyItComponent } from './pages/my-it/my-it.component';
import { MyItOverviewTabComponent } from './pages/my-it/overview-tab/overview-tab.component';
import { TechnoTabComponent } from './pages/my-it/techno-tab/techno-tab.component';
import { XtendedSOCContractComponent } from './pages/my-space/contract/contract.component';
import { XtendedSOCMyFilesComponent } from './pages/my-space/my-files/my-files.component';
import { XtendedSOCMySpaceComponent } from './pages/my-space/my-space.component';
import { SettingsComponent } from './pages/my-space/settings/settings.component';
import { PortailReleaseNoteComponent } from './pages/portail-release-note/portail-release-note.component';
import { XtendedSOCSLAComponent } from './pages/sla/sla.component';
import { AnonymisePipe } from './pipes/anonymise.pipe';
import { XtendedHighchartService } from './xtended-highchart.service';
import { XtendedFiltersService } from './xtendedfilters.service';

@NgModule({
  declarations: [
    XtendedSOCDashboardComponent,
    XtendedSOCSLAComponent,
    XtendedSOCContractComponent,
    XtendedSOCEntitiesComponent,
    XtendedSOCEntityDetailComponent,
    SecuritynavigatormanagerComponent,
    IncidentlistComponent,
    XtendedSOCIncidentsComponent,
    XtendedSOCIncidentDetailsComponent,
    FiltermenuComponent,
    SearchabledropdownComponent,
    ScrollableIncidentListComponent,
    PaginationIncidentListComponent,
    XtendedSOC4A3WComponent,
    TabBarComponent,
    BacklogSanityScoreComponent,
    DailyInfoComponent,
    IncidentNeedingActionPreviewComponent,
    FourAThreeWPreviewComponent,
    XtendedSOCHomeComponent,
    ClientcontactstableComponent,
    FeedbackReminderComponent,
    RouteWithfacetsComponent,
    IconInfoComponent,
    PortailReleaseNoteComponent,
    XtendedSOCMySpaceComponent,
    XtendedSOCMyFilesComponent,
    SettingsComponent,
    MyItComponent,
    AgentCardComponent,
    SlaGaugeComponent,
    CybersocxdrIncidentBySeverityCategoryComponent,
    TechnoTabComponent,
    MyItOverviewTabComponent,
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    XtendedHelpComponent,
    XtendedIncidentListComponent,
    AnonymisePipe,
    XtendedNotificationCarouselComponent,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CybersocxdrRoutingModule,
    AppSharedModule,
    InfiniteScrollModule,
    AlternateDataSourcePipe,
    CardComponent,
    UserPresetItemComponent,
    ConfirmDialogComponent,
  ],
  exports: [XtendedSOCDashboardComponent, XtendedSOCSLAComponent, XtendedSOCContractComponent],
  bootstrap: [XtendedSOCDashboardComponent, XtendedSOCSLAComponent, XtendedSOCContractComponent],
  providers: [
    XtendedFiltersService,
    XtendedHighchartService,
    XtendedChartService,
    XtendedNotificationService,
    SeverityPipe,
    AlternateDataSourcePipe,
  ],
})
export class CybersocxdrModule {}
