import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ACTIONS, PAGES, TYPES, constants } from 'src/app/shared/constants';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from 'src/app/shared/services/alert.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';
import { Asset, Location } from 'src/app/shared/model/itsm';
import { RetentionService } from 'src/app/shared/services/retention.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FileExtension, Pages } from 'src/app/shared/model/shared-items';
import { TranslateService } from '@ngx-translate/core';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { FlaggedListComponent } from 'src/app/shared/components/flagged-list/flagged-list.component';
import { QueryStatus } from 'src/app/support/supportlog/filters/asset-and-service/asset-and-service.component';
@Component({
  selector: 'app-assetdashboard',
  templateUrl: './assetdashboard.component.html',
  styleUrls: ['./assetdashboard.component.scss'],
})
export class AssetDashboardComponent extends FlaggedListComponent implements OnInit {

  // scrolling
  public scrollLoading = false;

  public limit = 20;
  public assetFilter = new QueryStatus(this.limit);
  public globalSearch: string;

  // pagination
  perPage = constants.recordsPerPage.assets;
  private assetIndex: number;
  currentPage: number;

  // displayed data
  assets: Asset[] = [];
  flaggedAssets: Asset[];
  public assetsLocations: Location[] = [];
  public locationFilter = new QueryStatus(this.limit);


  allAssets: Asset[];
  public assetsLoaded: boolean;
  public flaggedAssetsLoaded: boolean;
  includeInactive: boolean;
  vendors: { name: string; field: string }[] = [];

  //search
  source: string;
  assetName: string;
  assetSerialNumber: string;
  sortedBy: string;
  sortedOrder: number;
  hostName: string;
  alias: string;

  selectAll = '';
  unSelectAll = '';

  public pdfLabel = FileExtension.pdf.toUpperCase();
  public csvLabel = FileExtension.csv.toUpperCase();

  // multiple criteria for vendor selection
  dropdownSettingsVendors: IDropdownSettings = {
    singleSelection: false,
    selectAllText: this.selectAll,
    unSelectAllText: this.unSelectAll,
    itemsShowLimit: 5,
    idField: 'field',
    textField: 'name',
    allowSearchFilter: false,
  };
  selectedVendors: { name: string; field: string }[] = [];

  // view
  page: string;

  datepipe: DatePipe = new DatePipe(constants.languages.enGB);

  // initial page to load
  loadpage: string;

  //Retention
  searchFlag = false;
  sortFlag = true;

  //Modal
  closeResult: string;
  PDFName = '';
  pdfAccessLevel = '0';
  reportOption = 'pdf';

  //Pages
  public assetsPage = Pages.assets;
  public flaggedPage = Pages.flagged;
  public locationsPage = Pages.locations;

  constructor(
    private itsmService: ItsmService,
    public alertService: AlertService,
    private modalService: NgbModal,
    public userPreferenceService: UserPreferenceService,
    private retentionService: RetentionService,
    private translateService: TranslateService,
    private userActivity: UserActivityService
  ) {
    super(userPreferenceService, alertService);
    this.flagType = constants.local.flags.assets;
    this.viewType = constants.local.views.asset;
    super.checkPreferences();
  }

  ngOnInit() {
    // init labels
    this.selectAll = this.translateService.instant('pages.generic.selectAll');
    this.unSelectAll = this.translateService.instant('pages.generic.unSelectAll');

    // initialise
    this.currentPage = this.retentionService.getAssetPagination();

    const assetSearchObj = this.retentionService.getAssetSearch();
    if (assetSearchObj.source) {
      this.source = assetSearchObj.source;
    }
    if (assetSearchObj.assetName) {
      this.assetName = assetSearchObj.assetName;
    }
    if (assetSearchObj.selectedVendors) {
      this.selectedVendors = assetSearchObj.selectedVendors;
    }
    if (assetSearchObj.assetSerialNumber) {
      this.assetSerialNumber = assetSearchObj.assetSerialNumber;
    }
    if (assetSearchObj.hostName) {
      this.hostName = assetSearchObj.hostName;
    }
    if (assetSearchObj.alias) {
      this.alias = assetSearchObj.alias;
    }
    this.page = this.assetsPage;
    this.includeInactive = false;

    // load assets and locations
    this.loadAssets();
    this.loadLocations(true);
  }


  public loadAssets() {
    if (this.assetFilter.loading || this.assetFilter.offset >= this.assetFilter.totalCount) {
      return;
    }

    this.assetFilter.loading = true;

    this.itsmService.getLazyloadedAssets(this.assetFilter)
      .subscribe({
        next: (loadedAssets) => {
          this.assetFilter.currentCount += loadedAssets.length;
          this.assets = this.assets.concat(loadedAssets);

          // update offset for next call
          this.assetFilter.offset += this.assetFilter.limit;

          // get the total count
          if (loadedAssets.length > 0) {
            for (const asset of loadedAssets) {
              if (asset.totalCount) {
                this.assetFilter.totalCount = asset.totalCount;
                break
              }
            }
          } else {
            this.assetFilter.totalCount = 0;
          }
          
        },
        error: (error) => {
          this.alertService.handlerError(error);
        },
        complete: () => {
          this.assetFilter.loading = false;
        }
      });
  }

  public openModal(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;

          if (result === 'save') {
            //debugger
            this.loadAssetsOld(
              true,
              this.PDFName +
                ' - ' +
                constants.accessLevels[this.pdfAccessLevel] +
                '.' +
                this.reportOption,
              this.pdfAccessLevel
            );
          }
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  public search() {
    this.assets = [];
    this.assetsLocations = [];
    this.assetFilter = new QueryStatus(this.limit, this.globalSearch);
    this.locationFilter = new QueryStatus(this.limit, this.globalSearch);
    this.loadAssets();
    this.loadLocations();
  }

  public reset() {
    delete this.source;
    delete this.assetName;
    delete this.hostName;
    delete this.alias;
    delete this.assetSerialNumber;
    delete this.selectedVendors;
    this.retentionService.setAssetPagination(1);
    this.currentPage = 1;
    this.retentionService.setAssetSearch({});
    this.loadAssets();
  }

  /**
   * Return all the assets
   */
  public loadAssetsOld(
    exportPDF: boolean = false,
    reportName: string = '',
    reportAccessLevel: string = ''
  ) {
    this.assetsLoaded = false;

    this.itsmService
      .getCachedAssets(exportPDF, reportName, reportAccessLevel)
      .then((res) => {
        if (exportPDF) {
          this.alertService.addSuccess(this.translateService.instant('pages.generic.fileuploaded'));
          this.assetsLoaded = true;
        }
        else{
          this.allAssets = res;
          this.vendors = [
            ...new Set(
              this.allAssets
                .map((item) => item.assetVendor)
                .filter((item) => item)
            ),
          ]
            .sort((a, b) => {
              if (a < b) {
                return -1;
              }
              if (a > b) {
                return 1;
              }
              return 0;
            })
            .map((v) => ({ name: v, field: v }));

            if (!this.includeInactive) {
              this.allAssets = this.allAssets.filter((a) => a.active === 'true');
            }

            this.assets = this.allAssets.slice(0, this.perPage);
            this.assetIndex = this.perPage;

          this.assetsLoaded = true;

          if (
            this.assetName ||
            this.selectedVendors.length > 0 ||
            this.assetSerialNumber ||
            this.hostName ||
            this.alias
          ) {
            this.searchFlag = true;
            this.search();
          }
          if (this.retentionService.getAssetSort() != null) {
            const sortObj = this.retentionService.getAssetSort();
            this.sortedBy = sortObj.field;
            this.sortedOrder = sortObj.order;
            this.sortAssets(this.sortedBy);
          }
        }

      })
      .catch((err) => {
        this.alertService.handlerError(err);
      });
  }

  /**
   * Load flagged assets
   */
  public loadFlagged() {
    this.currentPage = 1;
    this.page = Pages.flagged;
    this.flaggedAssetsLoaded = false;
    this.flaggedAssets = this.allAssets.filter((a) => this.flagRefs.includes(a.id));
    this.flaggedAssetsLoaded = true;
  }

  public onSelectLocations() {
    this.page = this.locationsPage;
    this.loadLocations();
  }

  public loadLocations(countOnly = false) {
    if (this.locationFilter.loading || this.locationFilter.offset >= this.locationFilter.totalCount) {
      return;
    }

    this.locationFilter.loading = true;

    this.itsmService.getLazyloadedAssetsLocations(this.locationFilter)
      .subscribe({
        next: (loadedLocations: Location[]) => {
          
          if (!countOnly) {
            this.locationFilter.currentCount += loadedLocations.length;
            this.assetsLocations = this.assetsLocations.concat(loadedLocations);
            // update offset for next call
            this.locationFilter.offset += this.locationFilter.limit;
          }

          // get the total count
          if (loadedLocations.length > 0) {
            for (const location of loadedLocations) {
              if (location.totalCount) {
                this.locationFilter.totalCount = location.totalCount;
                break
              }
            }
          } else {
            this.locationFilter.totalCount = 0;
          }
          this.locationFilter.loading = false;
        },
        error: (error) => {
          this.locationFilter.loading = false;
          this.alertService.handlerError(error);
        }
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  private sortAssets(field: string) {
    this.currentPage = this.sortFlag
      ? this.retentionService.getAssetPagination()
      : 1;

    if (this.sortedBy === field) {
      if (this.sortedOrder == -1) {
        this.sortedOrder = 1;
        this.assets.sort(function (a, b) {
          if (a[field] < b[field]) {
            return 1;
          }
          if (a[field] > b[field]) {
            return -1;
          }
          return 0;
        });
      } else {
        this.sortedOrder = -1;
        this.assets.sort(function (a, b) {
          if (a[field] < b[field]) {
            return -1;
          }
          if (a[field] > b[field]) {
            return 1;
          }
          return 0;
        });
      }
    } else {
      this.sortedBy = field;
      this.sortedOrder = 1;
      this.assets.sort(function (a, b) {
        if (a[field] < b[field]) {
          return -1;
        }
        if (a[field] > b[field]) {
          return 1;
        }
        return 0;
      });
    }
    this.retentionService.setAssetSort(this.sortedBy, this.sortedOrder);
  }

}

interface AssetObj {
  source: string;
  assetName: string;
  selectedVendors: {
    name: string;
    field: string;
  }[];
  assetSerialNumber: string;
  hostName: string;
  alias: string;
}
