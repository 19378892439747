import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaginatedList } from '../../model/cybersocxdr/paginatedList';
import { AssessmentSortableFields, AssessmentStatus } from './model/assessment-sortable-fields';
import { AssessmentDto } from './model/assessment.dto';
import { AssessmentTableItemsDto, YearlyAssessmentCountDto } from './model/ethicalHackingDtos';

const ROOT_URL = environment.endpoints.ethicalhacking;
const ASSESSMENTS_URL = ROOT_URL + 'assessments';
const ASSESSMENTS_YEARLY_COUNT_URL = ASSESSMENTS_URL + '/yearly-summary-count';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  private readonly http: HttpClient = inject(HttpClient);

  public getLatestAssessments(pageSize?: number, page?: number): Observable<PaginatedList<AssessmentTableItemsDto[]>> {
    let queryParams = pageSize ? new HttpParams().set('pageSize', pageSize) : undefined;
    queryParams = page ? queryParams.set('page', page) : queryParams;
    queryParams = queryParams.set('sortBy', AssessmentSortableFields.COMPLETED_DATE);
    queryParams = queryParams.set('sortDir', 'desc');
    queryParams = queryParams.set('status', AssessmentStatus.PUBLISHED);

    return this.http.get<PaginatedList<AssessmentTableItemsDto[]>>(`${ASSESSMENTS_URL}`, {
      params: queryParams,
    });
  }

  public getAssessmentById(id: string): Observable<AssessmentDto> {
    return this.http.get<AssessmentDto>(`${ASSESSMENTS_URL}/${id}`);
  }

  public getNumberOfAssessmentsByYear(numberOfYears: number): Observable<YearlyAssessmentCountDto[]> {
    const queryParams = new HttpParams().set('numberOfYears', numberOfYears);
    return this.http
      .get<YearlyAssessmentCountDto[]>(ASSESSMENTS_YEARLY_COUNT_URL, {
        params: queryParams,
      })
      .pipe(
        catchError((error) => {
          console.error('Error fetching assessments by year:', error);
          return throwError(() => error);
        })
      );
  }

  public getAssessmentDocument(documentId: string): Observable<Blob> {
    return this.http.get(`${ASSESSMENTS_URL}/document/${documentId}`, {
      responseType: 'blob',
    });
  }
}
