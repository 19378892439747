import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-ethicalhacking-assessments-by-year-circle',
  templateUrl: './assessments-by-year-circle.component.html',
  styleUrls: [
    '../../../../layout/xtended/ethical-hacking-layout/styles/ethicalhacking.scss',
    './assessments-by-year-circle.component.scss',
  ],
  standalone: true,
  imports: [TranslateModule],
})
export class AssessmentsByYearCircleComponent implements OnInit {
  @Input() year: string;
  @Input() count: number;
  protected colorVariation: boolean;

  public ngOnInit(): void {
    const isCurrentYearValue = this.isCurrentYear(this.year);
    this.year = isCurrentYearValue ? 'pages.ethicalhacking.contract.assessments-by-year.current-year' : this.year.toString();
    this.colorVariation = isCurrentYearValue;
  }

  protected isCurrentYear(year: string): boolean {
    return year.toString() === new Date().getFullYear().toString();
  }
}
