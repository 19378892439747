import { LabelType, NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { debounceTime, Subscription } from 'rxjs';
import { HttpUsersPreferencesService } from 'src/app/shared/httpServices/http-users-preferences.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SurveySummaryService } from 'src/app/shared/services/survey-summary.service';
import { RatingAnswers } from '../../modal';
import { RatingAnswerQueryStatus } from './ratingAnswerQueryStatus';

@Component({
  selector: 'app-rating-answer-component-modal',
  standalone: true,
  imports: [TranslateModule, NgxSliderModule, ReactiveFormsModule, InfiniteScrollModule],
  templateUrl: './rating-answer-component-modal.component.html',
  styleUrl: './rating-answer-component-modal.component.scss'
})
export class RatingAnswerComponentModalComponent implements OnInit{
  protected reviews : RatingAnswers;
  protected selectedIndex = 0;
  private limit = 15;
  public minimumLoadedReviews = 1;
  protected queryStatus : RatingAnswerQueryStatus;

  // Filter
  filterForm: UntypedFormGroup = new UntypedFormGroup({
    userControl: new FormControl(''),
    sliderControl: new UntypedFormControl([0, 10])
  });
  options: Options = {
    floor: 0,
    ceil: 10,
    step: 1,
    noSwitching: true,
    showTicks: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>min :</b>' + value;
        case LabelType.High:
          return '<b>max :</b>' + value;
        default:
          return value.toString();
      }
    }
  };
  private formSubscription: Subscription;

  constructor(
    protected activeModal : NgbActiveModal,
    protected translateService : TranslateService,
    private surveySummaryService : SurveySummaryService,
    private httpUserPreferencesService : HttpUsersPreferencesService,
    private alertService : AlertService
  ){}

  ngOnInit(): void {
    this.reviews = new RatingAnswers([]);
    this.queryStatus = new RatingAnswerQueryStatus(this.surveySummaryService.selectedSurvey()._id.$oid,this.limit);
    this.loadReviews(false);
    this.setupFormChangeListener();
  }

  public loadReviews(fromScroll : boolean) {
    if (!(this.queryStatus.loading)) {
      this.queryStatus.loading = true;

      if(!fromScroll){
        this.reviews.ratings = []
        this.queryStatus.offset = 0;
        this.queryStatus.currentCount = 0;
        this.queryStatus.totalCount = 0;
      }else if(this.queryStatus.offset >= this.queryStatus.totalCount){
        this.queryStatus.loading = false;
        return;
      }
      this.httpUserPreferencesService.getLazyloadedReviews(this.queryStatus)
        .subscribe({
          next: (loadedReviews) => {

            this.queryStatus.currentCount += loadedReviews.length;
            this.reviews.ratings = this.reviews.ratings.concat(loadedReviews);

            // update offset for next call
            this.queryStatus.offset += this.queryStatus.limit;
            this.queryStatus.totalCount = loadedReviews[0]?.totalCount;
          },
          error: (error) => {
            this.alertService.handlerError(error);
          },
          complete: () => {
            this.queryStatus.loading = false;
          }
        });
    }
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  // Modal Functions
  onSelect(index){
    this.selectedIndex = index;
  }

  close(){
    this.activeModal.close();
  }

  // Filter Functions
  
  resetFilter(){
    this.filterForm.reset({
      userControl: '',
      sliderControl: [0, 10]
    });
    this.queryStatus = new RatingAnswerQueryStatus(this.surveySummaryService.selectedSurvey()._id.$oid,this.limit);
  }

  private setupFormChangeListener(): void {
    this.formSubscription = this.filterForm.valueChanges
    .pipe(debounceTime(500))
    .subscribe(() => {
      // Extract values from the form
      const searchValue = this.filterForm.get('userControl')?.value || '';
      const rateSearchValue = this.filterForm.get('sliderControl')?.value || [0, 0];

      // Update queryStatus with new values
      this.queryStatus.search = searchValue;
      this.queryStatus.rate_search = rateSearchValue;

      // Trigger additional logic
      this.loadReviews(false);
      this.selectedIndex = 0;
    });
  }
}
