import { Component, inject, isDevMode, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AppSharedModule } from 'src/app/app-shared.module';
import { RequestNewAssessmentButtonComponent } from 'src/app/ethicalhacking/components/header/request-new-assessment-button/request-new-assessment-button.component';
import { SwitchThemeButtonComponent } from 'src/app/shared/components/xtended/dev/switch-theme-button/switch-theme-button.component';
import { MenuLink, SidemenuComponent } from 'src/app/shared/components/xtended/sidemenu/sidemenu.component';
import { ContextService } from 'src/app/shared/services/context-service';
import { BreadcrumbComponent } from 'src/app/ethicalhacking/components/breadcrumb/breadcrumb.component';
@Component({
  selector: 'app-ethical-hacking-layout',
  standalone: true,
  imports: [SwitchThemeButtonComponent, SidemenuComponent, RouterOutlet, AppSharedModule, RequestNewAssessmentButtonComponent, BreadcrumbComponent],
  templateUrl: './ethical-hacking-layout.component.html',
  styleUrls: ['styles/ethicalhacking.scss'],
})
export class EthicalHackingLayoutComponent implements OnInit {
  private readonly router: Router = inject(Router);
  private readonly contextService: ContextService = inject(ContextService);

  public isDarkThemeEnabled: boolean;
  protected menuLinks: MenuLink[] = [];

  constructor() {
    this.isDarkThemeEnabled = this.contextService.isDarkTheme();
  }

  async initMenuLinks(): Promise<void> {
    this.menuLinks = [
      {
        name: 'pages.ethicalhacking.menu.dashboard',
        route: '/ethical-hacking',
        saveSearchDisplay: true,
        sourceDisplay: true,
        sourceSelectable: true,
        shouldDisplayMailButton: true,
      },
      {
        name: 'pages.ethicalhacking.menu.vulnerabilities',
        route: '/ethical-hacking/vulnerabilities',
        saveSearchDisplay: true,
        sourceDisplay: true,
        sourceSelectable: true,
      },
      {
        name: 'pages.ethicalhacking.menu.assessments',
        route: '/ethical-hacking/assessments',
        saveSearchDisplay: true,
        sourceDisplay: true,
        sourceSelectable: true,
      },
      {
        name: 'pages.ethicalhacking.menu.contract',
        route: '/ethical-hacking/contract',
        saveSearchDisplay: true,
        sourceDisplay: true,
        sourceSelectable: true,
        shouldDisplayMailButton: true,
      },
    ];
  }

  async ngOnInit(): Promise<void> {
    await this.initMenuLinks();
  }

  getCurrentPage() {
    return (
      this.menuLinks.find(
        (menu) => {
          const currentPath = this.router.url.replace('/ethical-hacking', '') || '';
          const menuPath = menu.route.replace('/ethical-hacking', '') || '';

          return currentPath === menuPath ||
            (currentPath.startsWith(menuPath + '/') && menuPath !== '');
        }
      ) ?? null
    );
  }

  public onActivate() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  protected readonly isDevMode = isDevMode;
}
