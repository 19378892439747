@if(isExpertiseContainer) {
<div class="expertise-container">
  @for(textKey of textKey; track textKey) {
  <div class="{{ class }} text-center">
    <div class="box-title">
      {{
        'pages.ethicalhacking.contract.perimeters.table-box.' +
          textKey +
          '.title' | translate
      }}
    </div>
  </div>
  }
</div>
} @else {
<div class="{{ class }} text-center">
  <div class="box-title">
    {{
      'pages.ethicalhacking.contract.perimeters.table-box.' + textKey + '.title'
        | translate
    }}
  </div>

  @if(hasDescription) {
  <div class="box-description">
    {{
      'pages.ethicalhacking.contract.perimeters.table-box.' +
        textKey +
        '.description' | translate
    }}
  </div>
  } @if(hasIcon) {

  <div class="box-icon">
    @if(isFaIcon) { @for(icon of icons; track icon) {
    <fa-icon [icon]="icon" class="icon" size="2x" />
    } } @else { @for(icon of svgContent; track icon) {
    <div [innerHTML]="icon" class="icon"></div>
    } }
  </div>
  }
</div>
}
