import { Component, inject } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { UserAuthService } from "src/app/shared/services/user-auth.service";

@Component({
    selector: 'app-request-new-assessment-button',
    templateUrl: './request-new-assessment-button.component.html',
    styleUrls: ['./request-new-assessment-button.component.scss'],
    standalone: true,
    imports: [TranslateModule],
})
export class RequestNewAssessmentButtonComponent {
    private readonly translate: TranslateService = inject(TranslateService);
    private readonly userAuthService: UserAuthService = inject(UserAuthService);

    private readonly SALES_COMMON_EMAIL: string = 'sales.email@example.com';

    public sendNewAssessmentRequest(): void {
        const emailSubject: string = this.translate.instant('pages.ethicalhacking.contract.email-subject', { tenantName: this.userAuthService.selectedTenant });
        const emailBody: string = this.translate.instant('pages.ethicalhacking.contract.email-body');

        window.location.href = `mailto:${this.SALES_COMMON_EMAIL}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
    }

}
