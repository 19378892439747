<div [id]="target()"
     class="modal fade"
     tabindex="-1">

  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content rounded">

      <div class="modal-header">
        <h3 class="modal-title">
          {{ title() | translate }}
        </h3>
      </div>

      <div class="modal-body">
        {{ message() | translate }}
      </div>

      <div class="modal-footer">
        <button #dismissBtn
                type="button"
                class="btn btn-secondary rounded"
                data-bs-dismiss="modal">
          {{ dismissLabel() | translate }}
        </button>

        <button class="btn btn-primary rounded ms-1"
                (click)="onConfirmClick()">
          {{ confirmLabel() | translate }}
        </button>

      </div>

    </div>
  </div>

</div>
