export class Anonymous {
  public static readonly ANONYMOUS_STATUS_SESSION_KEY: string = 'cybersocxdr_anonymous';

  /**
   * @public
   * @desc Checks if the anonymous status is enabled
   *
   * @returns {boolean} True if enabled, otherwise false
   */
  public static isEnabled(): boolean {
    return sessionStorage.getItem(Anonymous.ANONYMOUS_STATUS_SESSION_KEY) === 'true';
  }

  /**
   * @public
   * @desc Sets the anonymous status and reloads the page
   *
   * @param {boolean} isEnabled - The new anonymous status
   */
  public static setState(isEnabled: boolean): void {
    sessionStorage.setItem(Anonymous.ANONYMOUS_STATUS_SESSION_KEY, isEnabled.toString());
    window.location.reload();
  }
}


/** The return type from Promise.all */
type NativePromiseAllReturnType<T> = { -readonly [P in keyof T]: Awaited<T[P]> };

/**
 * Similar to Promise.allSettled, but with a custom error handler
 * When one of the promises is rejected, the error handler is called with the reason of the rejection
 *
 * @param promises The promises to execute and handle eventual rejections from
 * @param errorHandler The handler for rejected promises. Defaults to console.error
 * @returns A promise which always resolves to an array of the results of the promises. If a sub-promise was rejected, the result in the array is undefined
 */
export async function handlePromises<T extends unknown[] | []>(
  promises: T,
  errorHandler: (reason: PromiseRejectedResult['reason']) => void = handleRejectedPromise
): Promise<NativePromiseAllReturnType<T>> {
  const results = await Promise.allSettled(promises);
  // Forced typecast, as the value from "promises" are not known
  const returnValue = [] as NativePromiseAllReturnType<T>;
  for (let i = 0; i < results.length; ++i) {
    const result = results[i];
    returnValue[i] = result.status === 'fulfilled' ? result.value : undefined;

    // Handle rejected promises
    if (result.status === 'rejected') {
      errorHandler(result.reason);
    }
  }

  return returnValue;
}

/** Default rejected promises handler */
function handleRejectedPromise(reason: PromiseRejectedResult['reason']) {
  console.error(reason);
}
