import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EthicalHackingContactDto } from './model/ethicalHackingDtos';

const ROOT_URL = environment.endpoints.ethicalhacking;
const CLIENT_URL = ROOT_URL + 'clients';
const SALES_CONTACTS_URL = CLIENT_URL + '/sales-contacts';
const PROJECT_CONTACTS_URL = CLIENT_URL + '/project-contacts';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private readonly http: HttpClient = inject(HttpClient);

  public getSalesContacts(): Observable<EthicalHackingContactDto[]> {
    return this.http.get<EthicalHackingContactDto[]>(SALES_CONTACTS_URL).pipe(
      catchError(error => {
        console.error('Error fetching sales contacts:', error);
        return throwError(() => error);
      })
    );
  }

  public getProjectContacts(): Observable<EthicalHackingContactDto[]> {
    return this.http.get<EthicalHackingContactDto[]>(PROJECT_CONTACTS_URL).pipe(
      catchError(error => {
        console.error('Error fetching project contacts:', error);
        return throwError(() => error);
      })
    );
  }
} 