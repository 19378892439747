import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BaseChartComponent } from '../basechart/basechart.component';

@Component({
  selector: 'app-column',
  templateUrl: '../basechart/basechart.component.html',
})
export class ColumnComponent extends BaseChartComponent<Highcharts.SeriesColumnOptions[]> {
  @Input() xAxisCategories: string[];
  @Input() xAxisTitle: string;
  @Input() yAxisTitle: string;

  options: Highcharts.Options = {
    chart: {
      type: 'column',
      animation: true,
      style: {
        fontFamily: 'inherit',
      },
    },
    title: {
      text: this.title,
      style: {
        fontSize: '1.2em',
      },
    },
    xAxis: {
      title: {
        text: '',
      },
      categories: [],
      crosshair: true,
      accessibility: {
        description: '',
      },
      max: 4, // Show only first 5 categories
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<div class="chart-tooltip"><span>{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table></div>',
      shared: true,
      valueDecimals: 0,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        grouping: true,
        borderRadius: 5,
        states: {
          hover: {
            brightness: 0.1,
            borderColor: '#303030',
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
  };

  draw(renderingId: string) {
    (this.options.xAxis as Highcharts.XAxisOptions).categories = this.xAxisCategories;
    (this.options.xAxis as Highcharts.XAxisOptions).title.text = this.xAxisTitle ?? '';
    (this.options.yAxis as Highcharts.YAxisOptions).title.text = this.yAxisTitle ?? '';
    this.options.series = this.data;
  }
}
