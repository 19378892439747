import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';
import { HeaderProperties, LocalStorageItem } from '../model/shared-items';
import { OctaToken } from '../model/okta';

@Injectable()
export class OktaInterceptor implements HttpInterceptor {
  constructor(private okta: UserAuthService) { }

  private isCybersocOrEthicalHacking(request: HttpRequest<any>, endpoints: any) {
    return (
      request.urlWithParams.startsWith(endpoints.cybersocxdr) ||
      request.urlWithParams.startsWith(endpoints.ethicalhacking)
    );
  }

  // if connecting to an endpoint then add okta token
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only add an access token to service endpoints
    const endpoints = environment.endpoints;
    const allowedOrigins = Object.values(endpoints);
    const oktaMta = LocalStorageItem.oktaMta;

    // if we are going to okta directly for user management we need to use a different token, or if we are using the backend proxy service
    if (
      (request.urlWithParams.startsWith(endpoints.okta) ||
        request.urlWithParams.startsWith(endpoints.oktaProxyService)) &&
      localStorage.getItem(oktaMta)
    ) {
      const headers = request.headers.set(
        HeaderProperties.authorization,
        HeaderProperties.bearer + ' ' + JSON.parse(localStorage.getItem(oktaMta)).o4otoken
      );
      const requestClone = request.clone({ headers });
      return next.handle(requestClone);
    } else if (allowedOrigins.some((url) => request.urlWithParams.startsWith(url))) {
      return from(this.okta.getToken(OctaToken.accessToken)).pipe(
        switchMap((token) => {
          if (token.accessToken) {
            let headers;
            // if the user has multiple tenants available then add the selected on to the request header
            // this needs to be validated at API side to ensure the selected tenant name is one the user is a member of
            const selectedTenant = JSON.parse(localStorage.getItem(LocalStorageItem.oktaSelectedTenant));
            if (selectedTenant) {
              if (this.isCybersocOrEthicalHacking(request, endpoints)) {
                headers = request.headers
                  .set(HeaderProperties.authorization, 'Bearer ' + token.accessToken)
                  .set('Tenant', selectedTenant);
              } else {
                headers = request.headers
                  .set(HeaderProperties.authorization, token.accessToken)
                  .set('Tenant', selectedTenant);
              }
            } else if (this.isCybersocOrEthicalHacking(request, endpoints)) {
              headers = request.headers.set(HeaderProperties.authorization, 'Bearer ' + token.accessToken);
            } else {
              headers = request.headers.set(HeaderProperties.authorization, token.accessToken);
            }

            headers = headers.set(HeaderProperties.oktaOauthNonce, Date.now().toString());
            /*
                                const extentedCookie = `; ${document.cookie}`;
                                const splited = extentedCookie.split(`; okta-oauth-nonce=`);
                                if (splited.length === 2) {
                                  const OktaOauthNonce = splited.pop().split(';').shift();
                                  headers = headers.set(HeaderProperties.oktaOauthNonce, OktaOauthNonce);
                                }
                                */

            const requestClone = request.clone({ headers });
            return next.handle(requestClone);
          } else {
            return next.handle(request);
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
