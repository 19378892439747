import { Pipe, PipeTransform } from '@angular/core';
import { XtendedFiltersService } from 'src/app/cybersocxdr/xtendedfilters.service';
import { IncidentDataSource } from '../model/cybersocxdr/incidentDataSourceEnum';



@Pipe({
  name: 'alternate',
  standalone: true,
  // Due to the constructor value, this pipe is not pure. 
  // Could act as one on a technicality, but it's not.
  pure: false,
})
export class AlternateDataSourcePipe implements PipeTransform {

  constructor(private readonly filtersService: XtendedFiltersService) {}

  /**
   * Change a translation to an alternate variant depending in the data source.
   * The transformation takes the following form:
   * input: 'pages.cybersocxdr.<some_translation>'
   * output: 'pages.cybersocxdr._variants.<data_source>.<some_translation>'
   * 
   * @param value The value to transform
   * @param shouldContain if defined, the value must contain this substring to be transformed
   * @returns 
   */
  transform(value: string | undefined, shouldContain: string | undefined): string {
    if(!value) return value;

    if (!value.startsWith('pages.cybersocxdr')) {
      console.warn("Value does not start with 'pages.cybersocxdr': ", value);
      return value;
    }

    // Exit if the substring is not found (and defined)
    if (shouldContain && !value.includes(shouldContain)) {
      return value;
    }
    
    if(this.filtersService.selectedContract?.incidentDataSource === IncidentDataSource.HUBZH) {
        const splicedKey = value.split('.');
        splicedKey.splice(splicedKey.length-1, 0, '_variants', this.filtersService.selectedContract?.incidentDataSource);
        return splicedKey.join('.');
    }

    return value;
  }
}
