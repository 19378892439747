import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'severity',
  standalone: true,
})
export class SeverityPipe implements PipeTransform {
  transform(value: string | number): string {
    let parsedValue = value;
    if (typeof value === 'string') {
      parsedValue = parseInt(value, 10);
    }

    if (Number.isNaN(parsedValue)) {
      // Found a string, probably something like "low" or "medium"
      return this.mapSeverity(value as string);
    }

    return this.arraySeverity(parsedValue as number);
  }

  private mapSeverity(value: string): string {
    const mappedValue: string | undefined = {
      informational: 'pages.cybersocxdr.dashboard.severity_informational',
      low: 'pages.cybersocxdr.dashboard.severity_low',
      medium: 'pages.cybersocxdr.dashboard.severity_medium',
      high: 'pages.cybersocxdr.dashboard.severity_high',
      critical: 'pages.cybersocxdr.dashboard.severity_critical',
    }[value.toString().toLowerCase()];

    if (!mappedValue) {
      return value;
    }
    return mappedValue;
  }

  private arraySeverity(value: number): string {
    return [
      'pages.cybersocxdr.dashboard.severity_informational',
      'pages.cybersocxdr.dashboard.severity_low',
      'pages.cybersocxdr.dashboard.severity_medium',
      'pages.cybersocxdr.dashboard.severity_high',
      'pages.cybersocxdr.dashboard.severity_critical',
    ][value];
  }
}
