<div class="container-fluid p-3">
  <div class="row">
    <div class="col-md-7">
      <app-card
        [cardTitle]="
          'pages.ethicalhacking.contract.assessments-by-year.title' | translate
        "
      >
        <app-ethicalhacking-assessments-by-year
          [yearlyAssessmentSummary]="yearlyAssessmentSummary"
        />
      </app-card>
      <app-card>
        <app-ethicalhacking-perimeters />
      </app-card>
    </div>
    <div class="col-md-5">
      <app-card
        cardTitle="{{ 'pages.ethicalhacking.contract.contacts' | translate }}"
      >
        <app-ethicalhacking-contacts-table
          [category]="'pages.ethicalhacking.contract.sales' | translate"
          [contacts]="contactsSales"
        />
        <app-ethicalhacking-contacts-table [contacts]="contactsProject" />
      </app-card>
    </div>
  </div>
</div>
