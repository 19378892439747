import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EndpointData, SentinelOneCard, SentinelOneCardTable, SentinelOneItem, SentinelOneData, TableRow } from '../sentinel-one-models';
import { S1_DATA } from '../sentinel-one-constants';
import { SentinelOneHttpService } from '../sentinel-one-http-service/sentinel-one-http.service';
import { getAllEndpointsData, getEndpointsWithOpenThreats, getOfflineEndpoints, getTopFiveRiskyEndpoints } from '../sentinel-one-utilities';

@Component({
  selector: 'app-sentinel-one-endpoint-management',
  templateUrl: './sentinel-one-endpoint-management.component.html',
  styleUrl: './sentinel-one-endpoint-management.component.scss'
})
export class SentinelOneEndpointManagementComponent implements OnInit {
  
  @Input() customColors: string[];
  @Output() error = new EventEmitter<string>;

  public cards: SentinelOneCard[][] = [];
  public loading: boolean;
  private endpoints: SentinelOneData;

  constructor(private sentinelOneHttpService: SentinelOneHttpService) {}

  ngOnInit(): void {
    this.loading = true;
    this.sentinelOneHttpService.getAgents().then( (res) => {
      this.endpoints = res;
      this.loadCards();
      this.loading = false;
    }).catch( err => {
      this.loading = false;
      const errorMessage = err.message.includes("Error: ")
        ? err.message.split("Error: ")[1]
        : err;
      this.error.emit(errorMessage);
    });
  }

  private loadCards() {
    const topRiskyEndpointsCard: SentinelOneCardTable = S1_DATA.endpointManagement.topRiskyEndpointsCard;
    const endpointsWithOpenThreatsCard: SentinelOneCardTable = S1_DATA.endpointManagement.endpointsWithOpenThreatsCard;
    const endpointsOfflineCard: SentinelOneCardTable = S1_DATA.endpointManagement.endpointsOfflineCard;
    const allEndpointsCard: SentinelOneCardTable = S1_DATA.endpointManagement.allEndpointsCard;

    // DATA STRUCTURE
    topRiskyEndpointsCard.data = S1_DATA.endpointManagement.topRiskyEndpointsData;
    endpointsWithOpenThreatsCard.data = S1_DATA.endpointManagement.endpointsWithOpenThreatsData;
    endpointsOfflineCard.data = S1_DATA.endpointManagement.endpointsOfflineData;
    allEndpointsCard.data = S1_DATA.endpointManagement.allEndpointsData;

    // DATA MAPPING
    topRiskyEndpointsCard.data.data =
      getTopFiveRiskyEndpoints(this.endpoints)
      .map( endpoint => new TableRow([endpoint.name, endpoint.threatsCount]));
    endpointsWithOpenThreatsCard.data.data =
      getEndpointsWithOpenThreats(this.endpoints)
      .map( endpoint => new TableRow([endpoint.name, endpoint.threatsCount]));
    endpointsOfflineCard.data.data = 
      getOfflineEndpoints(this.endpoints, 30)
      .map( endpoint => new TableRow([endpoint.name, endpoint.lastSeen]));

    allEndpointsCard.data.data = getAllEndpointsData(this.endpoints).map( (endpoint: EndpointData) => {
      return new TableRow([...Object.values(endpoint)]);
    });

    // FEED CARDS  
    this.cards.push(
      [topRiskyEndpointsCard, endpointsWithOpenThreatsCard, endpointsOfflineCard],
      [allEndpointsCard]
    );
  }

  public getStyles(rowItems: SentinelOneItem[]) {
    return {
      display: 'grid',
      'grid-template-columns': `repeat(${rowItems.length}, auto)`
    }
  }

  public getDataType(data: any) {
    return typeof data;
  }

}
