import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserAuthService } from 'src/app/shared/services/user-auth.service';
import { EthicalHackingService } from '../shared/services/ethicalhacking/ethicalhacking.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EthicalhackingGuard implements CanActivate {
  constructor(
    private readonly authService: UserAuthService,
    private readonly router: Router,
    private readonly ethicalhackingService: EthicalHackingService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return (await this.isAuthenticated()) || this.router.parseUrl('/not-authorized');
  }

  async isAuthenticated(): Promise<boolean> {
    const userClaims = await this.authService.loadUserDetails();
    if (!userClaims) {
      return false;
    }

    return firstValueFrom(this.ethicalhackingService.isEthicalHackingClient());
  }

}
