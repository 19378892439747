<div class="anonymous-button">
  <button (click)="onBtnClick()"
          class="btn">

    <fa-icon [icon]="['far', (state ? 'eye-slash' : 'eye')]"
             class="{{ state ? 'enabled' : 'disabled' }}"
             size="lg"/>

    <span class="ms-1">
        {{ 'pages.cybersocxdr.layout.anonymous' + (state ? 'Enabled' : 'Disabled') | translate }}
      </span>
  </button>
</div>
