import { inject, Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { firstValueFrom } from "rxjs";
import { ResolverConfig } from "../breadcrumb/breadcrumb.types";
import { BreadcrumbResolverUtils } from "../breadcrumb/utils/breadcrumb-resolver.utils";

@Injectable({ providedIn: 'root' })
export class BreadcrumbGenericResolver implements Resolve<Record<string, unknown>> {
  private readonly injector: Injector = inject(Injector);

  public async resolve(route: ActivatedRouteSnapshot): Promise<Record<string, unknown>> {
    const resolvedData: Record<string, unknown> = {};
    const resolverConfig: Record<string, ResolverConfig> = route.data.breadcrumb?.resolvers || {};

    const resolvePromises: Promise<void>[] = Object.entries(resolverConfig)
      .map(
        async ([key, config]: [string, ResolverConfig]): Promise<void> => {
          const { service, method, params } = config;

          // ? Dynamically inject the service
          const serviceInstance: unknown = this.injector.get(service);

          const resolvedParams: Record<string, unknown> = BreadcrumbResolverUtils.resolveParameters(params, route);
          resolvedData[key] = await firstValueFrom(serviceInstance[method](...Object.values(resolvedParams)));
        }
      );

    await Promise.all(resolvePromises);
    return resolvedData;
  }
}

