@if (loading) {
<ng-container [ngTemplateOutlet]="spinner" />
} @else {
<div *ngIf="!loading">
  <ul class="nav nav-tabs" id="supportViewTab" role="tablist">
    <li class="nav-item">
      <a
        class="nav-link active"
        id="nav-linkA"
        data-bs-toggle="tab"
        data-bs-target="#tab1"
        role="tab"
        aria-current="page"
        >Description</a
      >
    </li>
    <li class="nav-item" *ngIf="case.type === caseType.changeRequest">
      <a
        class="nav-link"
        id="nav-linkA"
        data-bs-toggle="tab"
        data-bs-target="#tab2"
        role="tab"
        aria-current="page"
        >Planning Tab</a
      >
    </li>
  </ul>

  <div class="tab-content">
    <div class="tab-pane fade show active" id="tab1" role="tabpanel">
      <!-- SUBJECT -->
      <div>
        <!-- 
          <div class="subject">
            <div class="ms-auto me-4">
                  @if(MESActions.requestInvestigation) {
                    <button class="btn btn-primary me-2" (click)="confirmAction(mesInvestigation);">{{ 'pages.support.dashboard.fieldTitles.requestInvestigation' | translate }}</button>
                  }
                -->
        <!-- @if(MESActions.close) {
                  <button class="btn btn-primary" (click)="confirmAction(mesClosure);">{{ 'pages.support.dashboard.fieldTitles.closeCase' | translate }}</button>
                }
              </div>
          </div>
        -->
        <table class="table table-striped">
          <caption>
            {{
              case.subject
            }}
          </caption>
          <thead>
            <tr style="display: none">
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <!-- case number -->
            <tr>
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.reference' | translate
                }}
              </td>
              <td>{{ case.caseNumber }}</td>
              <td></td>
            </tr>

            <!-- case type -->
            <tr>
              <td>
                {{ 'pages.support.dashboard.fieldTitles.type' | translate }}
              </td>
              <td>
                <div class="d-flex">
                  <span>{{ case.type }}</span>
                  <div class="case-type-tips">
                    <app-help
                      *ngIf="case.type === caseType.changeRequest"
                      size="xl"
                      [message]="
                        'pages.support.dashboard.fieldTitles.changeRequestDesc'
                          | translate
                      "
                    ></app-help>
                    <app-help
                      *ngIf="case.type === caseType.incident"
                      size="xl"
                      [message]="
                        'pages.support.dashboard.fieldTitles.incidentDesc'
                          | translate
                      "
                    ></app-help>
                    <app-help
                      *ngIf="case.type === caseType.normal"
                      size="xl"
                      [message]="
                        'pages.support.dashboard.fieldTitles.normalDesc'
                          | translate
                      "
                    ></app-help>
                    <app-help
                      *ngIf="case.type === caseType.serviceRequest"
                      size="xl"
                      [message]="
                        'pages.support.dashboard.fieldTitles.serviceRequestDesc'
                          | translate
                      "
                    ></app-help>
                  </div>
                </div>
              </td>
              <td></td>
            </tr>

            <!-- changeType -->
            <tr *ngIf="case.changeType">
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.changeType' | translate
                }}
              </td>
              <td>{{ case.changeType }}</td>
              <td></td>
            </tr>

            <!-- priority -->
            <tr>
              <td>
                {{ 'pages.support.dashboard.fieldTitles.priority' | translate }}
              </td>
              <td>{{ case.priority }}</td>
              <td></td>
            </tr>

            <!-- status -->
            <tr>
              <td>
                {{ 'pages.support.dashboard.fieldTitles.status' | translate }}
              </td>
              <td>{{ case.status }}</td>
              <td></td>
            </tr>

            <!-- Contract -->
            <tr *ngIf="case.serviceContract">
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.serviceContrat'
                    | translate
                }}
              </td>
              <td>{{ case.serviceContract }}</td>
              <td></td>
            </tr>

            <!-- asset -->
            <tr>
              <td>
                {{ 'pages.support.dashboard.fieldTitles.asset' | translate }}
              </td>
              <td>{{ assetDisplay }}</td>
              <td></td>
            </tr>

            <!-- customer reference -->
            <tr>
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.customerReference'
                    | translate
                }}
              </td>
              <td>{{ case.customerReference }}</td>
              <td>
                <div class="d-flex justify-content-center">
                  <fa-icon
                    title="{{ 'buttons.action.edit' | translate }}"
                    [icon]="['fas', 'pen']"
                    size="xl"
                    class="cursorpointer action-icon black-or-white"
                    (click)="
                      contactType = 'customerReference'; open(customerReference)
                    "
                  >
                  </fa-icon>
                </div>
              </td>
            </tr>

            <!-- contact -->
            <tr>
              <td>
                {{ 'pages.support.dashboard.fieldTitles.contact' | translate }}
              </td>
              <td>{{ contactDisplay }}</td>
              <td>
                <div class="d-flex justify-content-center">
                  <fa-icon
                    title="{{ 'pages.generic.select' | translate }}"
                    [icon]="['fas', 'pen']"
                    size="xl"
                    class="cursorpointer action-icon black-or-white"
                    (click)="contactType = 'mainContact'; open(contact)"
                  >
                  </fa-icon>
                </div>
              </td>
            </tr>

            <!-- second contact -->
            <tr>
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.secondContact'
                    | translate
                }}
              </td>
              <td>{{ secondContactDisplay }}</td>
              <td>
                <div class="d-flex justify-content-center">
                  <fa-icon
                    title="{{ 'pages.generic.select' | translate }}"
                    [icon]="['fas', 'pen']"
                    size="xl"
                    class="cursorpointer action-icon black-or-white"
                    (click)="contactType = 'secondContact'; open(contact)"
                  >
                  </fa-icon>
                </div>
              </td>
            </tr>

            <!-- world watch reference -->
            <tr *ngIf="case.worldWatchReference">
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.worldWatchReference'
                    | translate
                }}
              </td>
              <td>
                {{ case.worldWatchReference }}
              </td>
              <td>
                <fa-icon
                  *ngIf="hasWorldWatchAccess"
                  title="{{ 'buttons.action.edit' | translate }}"
                  [icon]="['fas', 'pen']"
                  size="xl"
                  class="cursorpointer action-icon black-or-white me-3"
                  (click)="
                    contactType = 'worldwatchReference';
                    open(worldWatchReference)
                  "
                >
                </fa-icon>
                <fa-icon
                  *ngIf="hasWorldWatchAccess"
                  title="{{ 'buttons.action.viewSignal' | translate }}"
                  [icon]="['far', 'eye']"
                  size="xl"
                  class="cursorpointer action-icon black-or-white"
                  (click)="gotoWorldWatchSignal()"
                >
                </fa-icon>
              </td>
            </tr>

            <!-- description -->
            <tr>
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.description' | translate
                }}
              </td>
              <td>
                <div
                  *ngIf="case.description"
                  [innerHTML]="
                    case.description | cveify | signalify | formatHtml
                  "
                ></div>
              </td>
              <td></td>
            </tr>

            <!-- start date -->
            <tr
              *ngIf="case.UTCStartDate && case.type === caseType.changeRequest"
            >
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.startDate' | translate
                }}
              </td>
              <td>
                {{ startDateDisplay | date : 'dd-MM-yyyy HH:mm:ss' }} -
                {{ 'pages.support.view.localTime' | translate }}
              </td>
              <td></td>
            </tr>

            <!-- created -->
            <tr>
              <td>
                {{ 'pages.support.dashboard.fieldTitles.created' | translate }}
              </td>
              <td>{{ case.createdDate | date : 'dd/MM/yyyy HH:mm' }}</td>
              <td></td>
            </tr>

            <!-- Created by -->
            <tr>
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.createdBy' | translate
                }}
              </td>
              <td>{{ case.openedBy }}</td>
              <td></td>
            </tr>

            <!-- updated -->
            <tr>
              <td>
                {{ 'pages.support.dashboard.fieldTitles.updated' | translate }}
              </td>
              <td>{{ case.lastModifiedDate | date : 'dd/MM/yyyy HH:mm' }}</td>
              <td></td>
            </tr>

            <!-- updatedBy -->
            <tr>
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.updatedBy' | translate
                }}
              </td>
              <td>{{ case.updatedBy }}</td>
              <td></td>
            </tr>

            <!-- closed -->
            <tr *ngIf="case.closedDate">
              <td>
                {{ 'pages.support.dashboard.fieldTitles.closed' | translate }}
              </td>
              <td>{{ case.closedDate | date : 'dd/MM/yyyy HH:mm' }}</td>
              <td></td>
            </tr>

            <!-- updates -->
            <tr *ngIf="case.comments.length > 0">
              <td>
                {{ 'pages.support.dashboard.fieldTitles.updates' | translate }}
              </td>
              <td>
                <div
                  class="mb-4"
                  *ngFor="
                    let datedComms of commentsMap | keyvalue;
                    let index = index
                  "
                >
                  <div class="commentsContent">
                    {{ datedComms.key | date : 'dd/MM/yyyy' }}
                  </div>
                  <hr class="separator mt-1 mb-3" />
                  <div class="timeline">
                    <div class="ms-4">
                      <div class="accordion" id="accordion">
                        <div
                          class="accordion-item"
                          *ngFor="let comm of datedComms.value"
                        >
                          <h2 class="accordion-header" id="headingOne">
                            <div
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              [attr.data-bs-target]="'#collapse_' + index"
                              aria-expanded="true"
                              [attr.aria-controls]="'collapse' + index"
                            >
                              <fa-icon
                                class="aside-item me-2"
                                [icon]="['fas', 'circle']"
                              ></fa-icon>
                              <div
                                class="left-item commentsContent d-flex align-items-center"
                              >
                                <div>{{ comm.creatorName }}</div>
                                <div
                                  *ngIf="comm.isEngineer"
                                  class="tag no-border engineer"
                                >
                                  {{
                                    'pages.support.dashboard.engineer'
                                      | translate
                                  }}
                                </div>
                              </div>

                              <div class="right-item commentsContent">
                                {{ comm.createdDate | date : 'HH:mm' }}
                              </div>
                            </div>
                          </h2>
                          <div
                            id="collapse_{{ index }}"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <div>
                                <p
                                  class="mb-1 preserve-whitespace"
                                  [innerHTML]="
                                    comm.commentBody
                                      | cveify
                                      | signalify
                                      | formatHtml
                                  "
                                ></p>
                                <br />
                                <small>
                                  {{ comm.creatorName }} -
                                  {{
                                    comm.createdDate | date : 'dd/MM/yyyy HH:mm'
                                  }}</small
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td></td>

              <!-- comments -->
            </tr>

            <tr *ngIf="!isClosed">
              <td>{{ 'pages.support.view.addComment' | translate }}</td>
              <td>
                <div>
                  @if (postingComment) {
                  <ng-container [ngTemplateOutlet]="spinner" />
                  } @else {
                  <div>
                    <!-- comment successfully posted -->
                    <div
                      *ngIf="commentSuccessful"
                      class="alert alert-success"
                      role="alert"
                    >
                      <span class="alert-icon"
                        ><span class="visually-hidden">Success</span></span
                      >
                      <p>{{ 'pages.support.view.commentSaved' | translate }}</p>
                    </div>

                    <!-- comment post failed -->
                    <div
                      *ngIf="!commentSuccessful && commentError"
                      class="alert alert-danger"
                      role="alert"
                    >
                      <span class="alert-icon"
                        ><span class="visually-hidden">Error</span></span
                      >
                      <p>{{ commentError }}</p>
                    </div>

                    <!-- comment post failed -->
                    <div
                      *ngIf="commentLengthCheck"
                      class="alert alert-danger"
                      role="alert"
                    >
                      <span class="alert-icon"
                        ><span class="visually-hidden">Error</span></span
                      >
                      <p>{{ commentLengthCheckError }}</p>
                    </div>

                    <form>
                      <div class="row">
                        <div class="form-group col-12">
                          <textarea
                            class="form-control"
                            id="desc"
                            rows="8"
                            name="comment"
                            id="comment"
                            aria-describedby="comment"
                            [(ngModel)]="comment"
                          ></textarea>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-12">
                          <button
                            type="button"
                            (click)="postComment(false)"
                            [disabled]="comment?.length === 0"
                            class="btn btn-primary float-end mt-2"
                          >
                            {{ 'pages.generic.update' | translate }}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  }
                </div>
              </td>
              <td></td>
            </tr>

            <!-- attachments -->
            <tr>
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.attachments' | translate
                }}
              </td>
              <td>
                @if(attachmentsLoading) {
                <ng-container [ngTemplateOutlet]="spinner" />
                } @else {
                <div class="list-group">
                  <a
                    class="list-group-item list-group-item-action"
                    *ngFor="let file of attachments; let i = index"
                  >
                    <div
                      class="d-flex w-100 justify-content-between"
                      (click)="downloadAttachment(file.id, file.name, case.id)"
                      style="cursor: pointer"
                    >
                      <p class="mb-1 preserve-whitespace">
                        <fa-icon
                          [icon]="['far', 'file']"
                          size="lg"
                          [styles]="{ stroke: 'orange', color: 'orange' }"
                        ></fa-icon>
                        {{ file.name }}
                      </p>
                      <p class="mb-1 preserve-whitespace">
                        Created:
                        {{ file.createdDate | date : 'dd/MM/yyyy HH:mm' }}
                      </p>
                    </div>
                  </a>
                </div>
                }
              </td>
              <td></td>
            </tr>

            <!-- attachments upload -->
            @if (!isClosed) {
            <tr>
              <td>Add new attachments</td>
              <td>
                <div class="upload">
                  <div>
                    <!-- upload attachment -->
                    <app-upload-files
                      [user]="this.userObject?.email"
                      [existingAttachments]="attachments"
                      [caseRef]="caseRef"
                      (flagEmitter)="refreshAttachments($event)"
                    ></app-upload-files>
                  </div>
                </div>
              </td>
              <td></td>
            </tr>
            }

            <!-- cases child tasks  -->
            <tr *ngIf="case?.childTasks?.length > 0 || parentCase">
              <td>{{ linkedCasesHeading }}</td>
              <td>
                <div class="row pt-3">
                  <div class="row faketableheaderrow">
                    <div
                      class="col-xl-3 col-lg-3 col-md-2 col-sm-4 col-4 faketableheader"
                    >
                      {{
                        'pages.support.dashboard.fieldTitles.reference'
                          | translate
                      }}
                    </div>
                    <div
                      class="col-xl-3 col-lg-3 col-md-2 d-none d-md-block faketableheader"
                    >
                      {{
                        'pages.support.dashboard.fieldTitles.status' | translate
                      }}
                    </div>
                    <div
                      class="col-xl-3 col-lg-3 col-md-8 col-sm-8 col-8 faketableheader"
                    >
                      {{
                        'pages.support.dashboard.fieldTitles.subject'
                          | translate
                      }}
                    </div>
                    <div
                      class="col-xl-3 col-lg-3 d-none d-lg-block faketableheader"
                    >
                      {{
                        'pages.support.dashboard.fieldTitles.created'
                          | translate
                      }}
                    </div>
                  </div>
                  <div
                    class="row faketablerow"
                    *ngFor="let ca of linkedCases"
                    (click)="reloadPage(ca.id)"
                  >
                    <div
                      class="col-xl-3 col-lg-3 col-md-2 col-sm-4 col-4 faketablecell"
                    >
                      {{ ca.caseNumber }}
                    </div>
                    <div
                      class="col-xl-3 col-lg-3 col-md-2 d-none d-md-block faketablecell"
                    >
                      {{ ca.status }}
                    </div>
                    <div
                      class="col-xl-3 col-lg-3 col-md-8 col-sm-8 col-8 faketablecell"
                    >
                      {{ ca.subject }}
                    </div>
                    <div
                      class="col-xl-3 col-lg-3 d-none d-lg-block faketablecell"
                    >
                      {{ ca.createdDate }}
                    </div>
                  </div>
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- TRUE POSITIVE -->
      <div *ngIf="case.truePositive">
        <span>
          <h2>
            {{ 'pages.support.dashboard.fieldTitles.truePositive' | translate }}
          </h2>
        </span>
        <table class="table table-striped">
          <caption>
            True positive informations
          </caption>
          <thead>
            <tr>
              <th scope="col-2"></th>
              <th scope="col-8"></th>
              <th scope="col-2"></th>
            </tr>
          </thead>

          <tbody>
            <!-- action -->
            <tr *ngIf="case.action">
              <td>
                {{ 'pages.support.dashboard.fieldTitles.action' | translate }}
              </td>
              <td>{{ case.action }}</td>
              <td></td>
            </tr>

            <!-- subaction -->
            <tr *ngIf="case.subAction">
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.subaction' | translate
                }}
              </td>
              <td>{{ case.subAction }}</td>
              <td></td>
            </tr>

            <!-- attributes -->
            <tr *ngIf="case.attributes">
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.attributes' | translate
                }}
              </td>
              <td>{{ case.attributes }}</td>
              <td></td>
            </tr>

            <!-- killchain -->
            <tr *ngIf="case.killchain">
              <td>
                {{
                  'pages.support.dashboard.fieldTitles.killchain' | translate
                }}
              </td>
              <td>{{ case.killchain }}</td>
              <td></td>
            </tr>

            <!-- actor -->
            <tr *ngIf="case.actor">
              <td>
                {{ 'pages.support.dashboard.fieldTitles.actor' | translate }}
              </td>
              <td>{{ case.actor }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- TRUE FALSE POSITIVE -->
      <div *ngIf="case.whyFalsePositive">
        <span>
          <h2>
            {{
              'pages.support.dashboard.fieldTitles.falsePositive' | translate
            }}
          </h2>
        </span>
        <table class="table table-striped">
          <caption>
            True False positive informations
          </caption>
          <thead>
            <tr>
              <th scope="col-2"></th>
              <th scope="col-8"></th>
              <th scope="col-2"></th>
            </tr>
          </thead>

          <tbody>
            <!-- why -->
            <tr *ngIf="case.whyFalsePositive">
              <td>
                {{ 'pages.support.dashboard.fieldTitles.why' | translate }}
              </td>
              <td>{{ case.whyFalsePositive }}</td>
              <td></td>
            </tr>

            <!-- what -->
            <tr *ngIf="case.whatFalsePositive">
              <td>
                {{ 'pages.support.dashboard.fieldTitles.what' | translate }}
              </td>
              <td>{{ case.whatFalsePositive }}</td>
              <td></td>
            </tr>

            <!-- who -->
            <tr *ngIf="case.whoFalsePositive">
              <td>
                {{ 'pages.support.dashboard.fieldTitles.who' | translate }}
              </td>
              <td>{{ case.whoFalsePositive }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="tab-pane" id="tab2" role="tabpanel">
      <table class="table table-striped" aria-label="Planning tab informations">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <!-- justification -->
          <tr>
            <td>{{ 'pages.support.view.justification' | translate }}</td>
            <td [innerHTML]="case.justification | formatHtml"></td>
            <td></td>
          </tr>

          <!-- implementationPlan -->
          <tr>
            <td>{{ 'pages.support.view.implementationPlan' | translate }}</td>
            <td [innerHTML]="case.implementationPlan | formatHtml"></td>
            <td></td>
          </tr>

          <!-- testPlan -->
          <tr>
            <td>{{ 'pages.support.view.testPlan' | translate }}</td>
            <td [innerHTML]="case.testPlan | formatHtml"></td>
            <td></td>
          </tr>

          <!-- backoutPlan -->
          <tr>
            <td>{{ 'pages.support.view.backoutPlan' | translate }}</td>
            <td [innerHTML]="case.backoutPlan | formatHtml"></td>
            <td></td>
          </tr>

          <!-- approval -->
          <tr>
            <td>
              {{ 'pages.support.view.approval' | translate }}
              <app-help
                message="{{ 'pages.support.view.approvalTip' | translate }}"
                size="xl"
              ></app-help>
            </td>
            <td [innerHTML]="case.approval | formatHtml"></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="approvalAllowed" class="approval">
        <span class="me-4">{{
          'pages.support.view.changeRequestApproval' | translate
        }}</span>
        <div>
          <button
            class="btn btn-primary me-2"
            (click)="applyChangeRequest('approved')"
          >
            {{ 'pages.support.view.approve' | translate }}
          </button>
          <button
            class="btn btn-secondary"
            (click)="applyChangeRequest('rejected')"
          >
            {{ 'pages.support.view.reject' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!case" class="row justify-content-center p-5">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{
        'pages.generic.loading' | translate
      }}</span>
    </div>
  </div>
</div>
}

<!-- contact template -->
<ng-template #contact let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      {{ 'pages.support.dashboard.fieldTitles.contact' | translate }}
    </h5>
    <button
      type="button"
      class="btn-close"
      (click)="modal.dismiss('Close click')"
    >
      <span class="visually-hidden"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group col-12 pt-2">
      <label for="contact">{{
        'pages.support.dashboard.fieldTitles.contact' | translate
      }}</label>
      <select
        class="form-control"
        name="contact"
        aria-describedby="contact"
        [(ngModel)]="case.contactId"
        [ngClass]="{ 'is-invalid': !case.contactId }"
      >
        <option></option>
        <option value="{{ tt.id }}" *ngFor="let tt of contacts">
          {{ tt.fullName }}
        </option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.dismiss('Close click')"
    >
      Close
    </button>
    <button type="button" class="btn btn-primary" (click)="modal.close('save')">
      {{ 'pages.generic.save' | translate }}
    </button>
  </div>
</ng-template>

<!-- customer reference template -->
<ng-template #customerReference let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="customerReference">
      {{ 'pages.support.dashboard.fieldTitles.customerReference' | translate }}
    </h5>
    <button
      type="button"
      class="btn-close"
      (click)="modal.dismiss('Close click')"
    >
      <span class="visually-hidden"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group col-12 pt-2">
      <div
        class="alert alert-warning mt-2 d-flex align-items-center"
        role="alert"
      >
        <span class="alert-icon"><span class="visually-hidden"></span></span>
        <p>
          {{
            'pages.support.dashboard.fieldTitles.customerReferenceWaring'
              | translate
          }}
        </p>
      </div>
      <label for="customerReference">{{
        'pages.support.dashboard.fieldTitles.customerReference' | translate
      }}</label>
      <input
        class="form-control"
        name="customerReference"
        [placeholder]="case.customerReference"
        [(ngModel)]="updatedCustomerReference"
        [ngClass]="{ 'is-invalid': !case.contactId }"
      />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.dismiss('Close click')"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="!updatedCustomerReference"
      (click)="modal.close('save')"
    >
      {{ 'pages.generic.save' | translate }}
    </button>
  </div>
</ng-template>

<!-- worldWatchReference template -->
<ng-template #worldWatchReference let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="worldWatchReference">
      {{
        'pages.support.dashboard.fieldTitles.worldWatchReference' | translate
      }}
    </h5>
    <button
      type="button"
      class="btn-close"
      (click)="modal.dismiss('Close click')"
    >
      <span class="visually-hidden"></span>
    </button>
  </div>
  <div class="modal-body">
    <form class="form-group col-12 pt-2">
      <div
        *ngIf="worldWatchUpdateWarning"
        class="alert alert-warning mt-2 d-flex align-items-center"
        role="alert"
      >
        <span class="alert-icon"><span class="visually-hidden"></span></span>
        <p>
          {{
            'pages.support.dashboard.fieldTitles.worldWatchReferenceWarning'
              | translate
          }}
        </p>
      </div>
      <div *ngIf="worldWatchLoading" class="row justify-content-center p-1">
        <div
          class="spinner-border spinner-update text-primary"
          role="status"
          style="padding: 1rem"
        >
          <span class="visually-hidden">{{
            'pages.generic.loading' | translate
          }}</span>
        </div>
      </div>
      <label for="wwInput">{{
        'pages.support.dashboard.fieldTitles.worldWatchReference' | translate
      }}</label>
      <div class="worldwatch-signal-section">
        <input
          class="form-control worldwatch-signal-input"
          type="number"
          id="wwInput"
          name="worldWatchReference"
          [placeholder]="case.worldWatchReference"
          [(ngModel)]="updatedWorldWatchReference"
          [ngClass]="{ 'is-invalid': !case.worldWatchReference }"
        />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.dismiss('Close click')"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="!updatedWorldWatchReference"
      (click)="validateWorldWatchUpdate()"
    >
      {{ 'pages.generic.save' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #spinner>
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
