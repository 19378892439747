<div class="row mb-4">
  @for (card of agentsCards; track card.key) {
  <div class="col-3">
    <app-agent-card
      [cardTitle]="card.title"
      [value]="hostsData?.[card.key]"
      [description]="card.description"
      [type]="card.type"
    />
  </div>
  }
</div>

<div class="row">
  <div class="col-6">
    <app-card
      [cardTitle]="
        'pages.cybersocxdr.myIT.agentEvolutionCardTitle'
          | translate
            : {
                count: hostsData?.total_agents ?? '-'
              }
      "
    >
      <app-areaovertime
        [data]="installedAgentSeries"
        [suffix]="'pages.cybersocxdr.myIT.agents' | translate"
      />
    </app-card>
  </div>

  <div class="col-6">
    <app-card cardTitle="pages.cybersocxdr.myIT.osRepartitionCardTitle">
      <app-sunburst [data]="osRepartitionSeries" />
    </app-card>
  </div>
</div>

<div class="row">
  <div class="col-6">
    <app-card
      cardTitle="pages.cybersocxdr.myIT.policiesRepartitionByProductTypeCardTitle"
    >
      <app-barchart
        [data]="policiesRepartitionByProductTypeSeries"
        [xAxisCategories]="policiesRepartitionByProductTypeOptions"
        [titleY]="'pages.cybersocxdr.myIT.agentAmount' | translate"
      />
    </app-card>
  </div>

  <div class="col-6">
    <app-card cardTitle="pages.cybersocxdr.myIT.agentsVersionCardTitle">
      <app-column
        [data]="agentsVersionByProductTypeSeries"
        [xAxisCategories]="agentsVersionOptions"
        [yAxisTitle]="'pages.cybersocxdr.myIT.agentAmount' | translate"
      />
    </app-card>
  </div>
</div>
