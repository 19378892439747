import { Component, Input } from '@angular/core';
import { RouterOutlet } from "@angular/router";
import {
  GenerateReportButtonComponent
} from "src/app/cybersocxdr/components/header/generate-report-button/generate-report-button.component";
import {
  ContractTypeSelectorComponent
} from "src/app/cybersocxdr/components/header/contract-type-selector/contract-type-selector.component";
import {
  FilterDateRangeComponent
} from "src/app/cybersocxdr/components/header/filter-date-range/filter-date-range.component";
import {
  FilterSearchBarComponent
} from "src/app/cybersocxdr/components/header/filter-search-bar/filter-search-bar.component";

@Component({
  selector: 'xtended-header',
  standalone: true,
  imports: [
    GenerateReportButtonComponent,
    ContractTypeSelectorComponent,
    FilterDateRangeComponent,
    FilterSearchBarComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  /**
   * @public
   * @defaultValue false
   * @desc Indicates whether the contract type selector should be visible.
   */
  @Input()
  public isContractTypeSelectable: boolean = false;

  /**
   * @public
   * @desc The router outlet that will display the main content of the application.
   */
  @Input({required: true})
  public outlet: RouterOutlet;
}
