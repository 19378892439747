import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Asset } from '../../model/itsm';

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss']
})
export class AssetListComponent implements OnInit {

  @Input()
  assets: Asset[];

  @Input()
  flagRefs: any[];

  @Input()
  flaggingRef: string;

  @Output()
  flagEmitter = new EventEmitter<any>();

  @Output()
  sortEmitter = new EventEmitter<any>();

  @Input()
  loading: boolean;

  stateExists = false;
  statusExists = false;

  ngOnInit() {
    this.assets.forEach(asset => {
      if(asset.status) {
        this.statusExists = true;
      }
      this.stateExists = false;
    });
  }

  flagAsset(event, assetRef: string): void {
    event.stopPropagation();
    this.flagEmitter.emit(assetRef);
  }

  sortAssets(event, field: string): void {
    event.stopPropagation();
    this.sortEmitter.emit(field);
  }


}
