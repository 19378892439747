<div class="table-card">
  <div
    [ngClass]="[
      card.buttonLink ? 'multi-content' : 'simple-content',
      'card-header'
    ]"
  >
    {{ card.titleKey | translate }}
    <div></div>
    @if (card.buttonLink) {
    <div
      type="button"
      class="btn btn-primary btn-action"
      (click)="gotoCases('support/dashboard')"
    >
      {{ card.buttonLink.textKey }}
    </div>
    }
  </div>

  <div class="card-body">
    <table
      class="table table-striped table-hover"
      attr.aria-label="{{ card.titleKey | translate }}"
    >
      <thead>
        <tr>
          <th
            scope="col"
            *ngFor="
              let columnKey of cardData.columnKeys;
              let columnIndex = index
            "
            [ngStyle]="{
              'text-align': columnIndex !== 0 ? 'center' : 'inherit'
            }"
          >
            {{ columnKey | translate }}
          </th>
        </tr>
      </thead>

      <tbody>
        @if (cardData.data.length > 0) { @for (dataItem of cardData.data; track
        dataItem; let index = $index) {
        <tr>
          @if(dataItem.loading) {
          <td [attr.colspan]="dataItem.value.length">
            <div class="justify-content-center">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">{{
                  'pages.generic.loading' | translate
                }}</span>
              </div>
            </div>
          </td>
          } @else {
          <td
            *ngFor="let dataEntry of dataItem.value; let entryIndex = index"
            class="row-data"
          >
            <div [ngSwitch]="getCellDisplayFormat(dataEntry)">
              <div *ngSwitchCase="'text'">{{ dataEntry }}</div>
              <div *ngSwitchCase="'boolean'">
                <label
                  class="visually-hidden-focusable"
                  for="{{ dataItem[0] }}"
                  >{{ dataItem[0] }}</label
                >
                <input
                  class="form-check-input"
                  id="{{ dataItem[0] }}"
                  type="checkbox"
                  [checked]="dataEntry"
                  disabled
                />
              </div>
              <div *ngSwitchCase="tableActions.VIEW">
                <fa-icon
                  title="{{ 'buttons.action.view' | translate }}"
                  [icon]="['far', 'eye']"
                  size="xl"
                  class="cursorpointer action-icon black-or-white click"
                  [routerLink]="dataEntry.link"
                >
                </fa-icon>
              </div>
              <div *ngSwitchCase="tableActions.INVESTIGATE">
                <fa-icon
                  title="{{
                    'pages.support.dashboard.fieldTitles.requestInvestigation'
                      | translate
                  }}"
                  [icon]="['fas', 'magnifying-glass-chart']"
                  size="xl"
                  class="cursorpointer action-icon black-or-white click"
                  (click)="dataEntry.clickAction()"
                >
                </fa-icon>
              </div>
              <div *ngSwitchCase="tableActions.PROCESS">
                <fa-icon
                  title="{{
                    'pages.support.dashboard.fieldTitles.underInvestigation'
                      | translate
                  }}"
                  [icon]="['fas', 'gears']"
                  size="xl"
                  class="cursorpointer action-icon disabled"
                >
                </fa-icon>
              </div>
              <!-- <div *ngSwitchCase="tableActions.CLOSE">
                      <fa-icon
                        title="{{ 'buttons.action.close' | translate  }}"
                        [icon]="['fas', 'circle-xmark']"
                        size="xl"
                        class="cursorpointer action-icon black-or-white click" (click)="dataEntry.clickAction()">
                      </fa-icon>
                    </div> -->
            </div>
          </td>
          }
        </tr>
        } } @else {
        <div class="m-2">{{ 'pages.generic.noResults' | translate }}</div>
        }
      </tbody>
    </table>
  </div>
</div>
