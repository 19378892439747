import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { dateOptions, GROUPS_POLICY, SentinelOneCard, SentinelOneCardDoubleList, SentinelOneCardList, SentinelOneCardNumber, SentinelOneCardType, SentinelOneItem, SentinelOneData, ThreatConfidence, ThreatsCount } from '../sentinel-one-models';
import { S1_COLORS, S1_DATA } from '../sentinel-one-constants';
import { Utilities } from 'src/app/shared/utilities';
import { clone } from '@okta/okta-auth-js';
import { SupportCaseSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { constants } from 'src/app/shared/constants';
import { SentinelOneHttpService } from '../sentinel-one-http-service/sentinel-one-http.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { countEndpointsWithThreats, countThreatsByConfidenceLevel, countThreatsByPolicy, getCriticalEnpointsCount, getDateFromDateOption, parseGroups } from '../sentinel-one-utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sentinel-one-overview',
  templateUrl: './sentinel-one-overview.component.html',
  styleUrl: './sentinel-one-overview.component.scss'
})
export class SentinelOneOverviewComponent implements OnInit {

  @Input() customColors: string[];
  @Output() error = new EventEmitter<string>;

  private sentinelOneData: { endpoints: SentinelOneData, threats: SentinelOneData, threatsCount: {totalThreats: ThreatsCount, totalMitigatedThreats: ThreatsCount} };
  private snowData: {
    casesPendingCustomer: number,
    casesEscalatedToYou: number,
    threatsDetected: number,
    openCases: number,
    currentlyOpenCases: number
  };
  public cards: SentinelOneCard[][] = [];
  public mesCardType = SentinelOneCardType;
  public lighterColors = S1_COLORS.lighter;
 
  // Threat funnel
  public threatFunnelColors: string[];
  public threatFunnelFromCreatedDate = '';
  public threatFunnelDateOptions: {value: string, textKey: string}[] = dateOptions;
  public threatFunnelDateSelected = '30d';

  // Cards
  private threatFunnelCard: SentinelOneCardList = S1_DATA.overview.threatFunnelCard;
  private threatsCard: SentinelOneCardNumber = S1_DATA.overview.threatsCard;
  private supportCasesCard: SentinelOneCardNumber = S1_DATA.overview.supportCasesCard;
  private endpointsCard: SentinelOneCardNumber = S1_DATA.overview.endpointsCard;
  private investigationCreditsCard: SentinelOneCardList = S1_DATA.overview.investigationCreditsCard;
  private endpointsMonitoredCard: SentinelOneCardDoubleList = S1_DATA.overview.endpointsMonitoredCard;

  // Search criteria
  private pendingCustomerSearchCriteria:SupportCaseSearchCriteria = new SupportCaseSearchCriteria();
  private openCasesSearchCriteria:SupportCaseSearchCriteria = new SupportCaseSearchCriteria();
  private threatsDetectedSearchCriteria:SupportCaseSearchCriteria = new SupportCaseSearchCriteria();
  private threatsEscalatedSearchCriteria: SupportCaseSearchCriteria = new SupportCaseSearchCriteria();
  private currentlyOpenCasesSearchCriteria:SupportCaseSearchCriteria = new SupportCaseSearchCriteria();

  // Loading
  public loading: boolean;

  constructor(
    private sentinelOneHttpService: SentinelOneHttpService,
    private itsmService: ItsmService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}
  
  ngOnInit(): void {
    this.loading = true;

    // Threat funnel presets
    this.threatFunnelColors = Utilities.shiftArray(clone(this.customColors), 1);
    this.threatFunnelFromCreatedDate = getDateFromDateOption(this.threatFunnelDateSelected);

    // Threats
    this.pendingCustomerSearchCriteria.status = [constants.itsm.states.pendingCustomer];

    // Support Cases
    this.openCasesSearchCriteria.status = constants.itsm.globalStates.open;

    // Threats detected
    this.threatsDetectedSearchCriteria.createdFrom = this.threatFunnelFromCreatedDate;

    // Escalated by you
    this.threatsEscalatedSearchCriteria.createdFrom = this.threatFunnelFromCreatedDate;
    this.threatsEscalatedSearchCriteria.escalation = 'To Customer';

    this.currentlyOpenCasesSearchCriteria.status = constants.itsm.globalStates.open;
    this.currentlyOpenCasesSearchCriteria.createdFrom = this.threatFunnelFromCreatedDate;

     Promise.all([
      // "Endpoints" are designated by "agents" backend
      this.sentinelOneHttpService.getAgents(), 
      this.sentinelOneHttpService.getThreats(),
      this.sentinelOneHttpService.getThreatsCount(this.threatFunnelFromCreatedDate),
      this.getFilterdCasesCount(this.pendingCustomerSearchCriteria),
      this.getFilterdCasesCount(this.openCasesSearchCriteria),
      this.getFilterdCasesCount(this.threatsDetectedSearchCriteria),
      this.getFilterdCasesCount(this.threatsEscalatedSearchCriteria),
      this.getFilterdCasesCount(this.currentlyOpenCasesSearchCriteria),
    ]).then( (res) => {
      this.sentinelOneData = { endpoints: res[0], threats: res[1], threatsCount: res[2] };
      this.snowData = {
        casesPendingCustomer: res[3],
        openCases: res[4],
        threatsDetected: res[5],
        casesEscalatedToYou: res[6],
        currentlyOpenCases: res[7]
      };
      this.loadCards();
      this.loading = false;
    }).catch( err => {
      this.loading = false;
      const errorMessage = err.message.includes("Error: ")
        ? err.message.split("Error: ")[1]
        : err;
      this.error.emit(errorMessage);
    });
  }

  private loadCards() {

    // DATA STRUCTURE
    this.threatFunnelCard.data = S1_DATA.overview.threatFunnelData;
    this.endpointsMonitoredCard.data = S1_DATA.overview.endpointsMonitoredData;

    /**
     * MISSING MAPPINGS:
     * line 1: Investigation Credits
     */

    // FIRST ROW MAPPING
    // THreats requiring attentions are active threats from groups with a 'detect' policy
    this.threatsCard.data = countThreatsByPolicy(GROUPS_POLICY.DETECT, this.sentinelOneData.endpoints);
    this.supportCasesCard.data = this.snowData.openCases;
    this.endpointsCard.data = this.sentinelOneData.endpoints.pagination.totalItems;

    // THREATFUNNEL MAPPING
    this.mapThreatFunnelData();
    
    // ENDPOINTS MAPPING
    this.endpointsMonitoredCard.data[0][0].value = this.sentinelOneData.endpoints.pagination.totalItems;
    this.endpointsMonitoredCard.data[1] = parseGroups(this.sentinelOneData.endpoints);
    this.endpointsMonitoredCard.data[2][0].value = getCriticalEnpointsCount(this.sentinelOneData.endpoints);
    this.endpointsMonitoredCard.data[3][0].value = countEndpointsWithThreats(this.sentinelOneData.endpoints);
    this.endpointsMonitoredCard.data[3][1].value = countThreatsByConfidenceLevel(this.sentinelOneData.threats, ThreatConfidence.MALICIOUS);
    this.endpointsMonitoredCard.data[3][2].value = countThreatsByConfidenceLevel(this.sentinelOneData.threats, ThreatConfidence.SUSPICIOUS);
    
    // FEED CARDS
    this.cards.push(
      [this.threatsCard, this.supportCasesCard, this.endpointsCard, this.investigationCreditsCard],
      [this.threatFunnelCard],
      [this.endpointsMonitoredCard]
    );
  }

  private mapThreatFunnelData() {
    this.threatFunnelCard.data[0].value = this.sentinelOneData.threatsCount.totalThreats.totalCount;
    this.threatFunnelCard.data[1].value = this.sentinelOneData.threatsCount.totalMitigatedThreats.totalCount;
    this.threatFunnelCard.data[2].value = this.snowData.casesEscalatedToYou;
    this.threatFunnelCard.data[3].value = this.snowData.currentlyOpenCases;
  }

  private getFilterdCasesCount(searchCriteria: SupportCaseSearchCriteria): Promise<any> {
    searchCriteria.SNCount = true;
    searchCriteria.limit = undefined;
    searchCriteria.byPassSF = true;
    searchCriteria.forceTMLimit = true;
    return new Promise( (resolve, reject) => {
      this.itsmService.caseSearch(searchCriteria)
        .then( res => {
          resolve(res | 0);    
        })
        .catch( (err: Error) => {
          reject(err);
          this.alertService.handlerError(this.translateService.instant('pages.updates.sentinelOne.errors.SNowError'));
        });
    });
  }

  public reloadThreatFunnel() {
    this.threatFunnelFromCreatedDate = getDateFromDateOption(this.threatFunnelDateSelected);

    this.threatsEscalatedSearchCriteria.createdFrom = this.threatFunnelFromCreatedDate;
    this.threatsDetectedSearchCriteria.createdFrom = this.threatFunnelFromCreatedDate;    
    this.currentlyOpenCasesSearchCriteria.createdFrom = this.threatFunnelFromCreatedDate;
    
    Promise.all([
      this.sentinelOneHttpService.getThreatsCount(this.threatFunnelFromCreatedDate),
      this.getFilterdCasesCount(this.threatsEscalatedSearchCriteria),
      this.getFilterdCasesCount(this.currentlyOpenCasesSearchCriteria)
    ]).then( (res) => {
      this.sentinelOneData.threatsCount = res[0];
      this.snowData.casesEscalatedToYou = res[1];
      this.snowData.currentlyOpenCases = res[2];
      this.mapThreatFunnelData();
      this.cards[1] = [this.threatFunnelCard];
    }).catch( err => {
      this.alertService.handlerError(err);
    });
  }

  public getStyles(rowItems: SentinelOneItem[]) {
    return {
      display: 'grid',
      'grid-template-columns': `repeat(${rowItems.length}, 1fr)`
    }
  }

}
