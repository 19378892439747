<div class="xtended-header p-2">
  <div class="filter-date-range">
    <filter-date-range
      [outlet]="outlet"/>
  </div>

  <div class="filter-search-bar ms-lg-1 mt-2 mt-lg-0">
    <filter-search-bar/>
  </div>

  <div class="contract-type-selector ms-1 mt-2 mt-lg-0">
    <contract-type-selector
      [unselectable]="!isContractTypeSelectable"/>
  </div>

  <div class="ms-1">
    <header-generate-report-button/>
  </div>
</div>
