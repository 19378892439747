import { Component, DestroyRef, inject, input, InputSignal, OnInit } from '@angular/core';
import { RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectOption } from 'src/app/shared/model/cybersocxdr/selectOptions';

interface SpacingRule {
  blockedPrefix: string[];
  replacement: string;
}

@Component({
  selector: 'app-card',
  standalone: true,
  templateUrl: './card.component.html',
  imports: [
    TranslateModule,
    RouterLink,
    ReactiveFormsModule
  ],
  styleUrl: './card.component.scss'
})
export class CardComponent implements OnInit {
  /**
   * @private
   * @desc Spacing rules that determine which classes should be added or blocked.
   */
  private readonly spacingRules: SpacingRule[] = [
    { blockedPrefix: ["mb", "my", "m-0"], replacement: "mb-3" },
    { blockedPrefix: ["p"], replacement: "p-3" }
  ];

  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  /**
   * @public
   * @desc The title of the card
   */
  public cardTitle: InputSignal<string | null> = input<string | null>(null);

  /**
   * @public
   * @desc The data to pass for a link to appear on the card, alternatively you can pass a children named action
   */
  public actionTitle: InputSignal<string | null> = input<string | null>(null);

  /**
   * @public
   * @desc The route for the action link on the card
   */
  public actionRoute: InputSignal<string | null> = input<string | null>(null);

  /**
   * @public
   * @desc CSS classes applied to the card component.
   */
  public class: InputSignal<string> = input<string>('');

  /**
   * @public
   * @desc Options available for selection in a dropdown within the card.
   */
  public selectOptions: InputSignal<SelectOption[] | null> = input<SelectOption[]>(null);

  /**
   * @public
   * @desc Function to handle selection changes when an option is selected.
   */
  public selectAction: InputSignal<(value: SelectOption) => void | null> = input<(value: SelectOption) => void | null>(null);

  protected selectControl: FormControl<any> = new FormControl();

  public ngOnInit(): void {
    if (this.selectOptions()?.length) {
      this.selectControl.setValue(this.selectOptions()[0]);
    }

    this.selectControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selectedOption: any): void => {
        if (this.selectAction()) {
          this.selectAction()(selectedOption);
        }
      });
  }

  /**
   * @public
   * @desc Applies spacing rules and returns a cleaned list of CSS classes for the component.
   *
   * @returns A string containing cleaned and properly formatted CSS classes.
   */
  public getCleanedClasses(): string {
    const existingClasses = this.class().split(' ');
    const classList: string[] = [];

    for (const rule of this.spacingRules) {
      if (![...existingClasses].every((cssClass: string): boolean => rule.blockedPrefix.some((prefix: string): boolean => cssClass.startsWith(prefix)))) {
        classList.push(rule.replacement);
      }
    }

    return [].concat(classList, existingClasses).join(' ');
  }
}
