import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { AssetGridComponent } from './shared/components/asset-grid/asset-grid.component';
import { AssetListComponent } from './shared/components/asset-list/asset-list.component';
import { ContactListComponent } from './shared/components/contact-list/contact-list.component';
import { SupportListComponent } from './shared/components/support-list/support-list.component';
import { SupportGridComponent } from './shared/components/support.grid/support-grid.component';
import { CvePipe } from './shared/pipes/cve.pipe';
import { Linkify } from './shared/pipes/linkify.pipe';
import { RemoveWhiteSpacePipe } from './shared/pipes/removewhitespace.pipe';
import { FormatHTML } from './shared/pipes/formatHtml';
import { SignalPipe } from './shared/pipes/signal.pipe';
import { StatFilter } from './shared/pipes/statFilter';
import { UploadFilesComponent } from './shared/components/upload-files/upload-files.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ReplacePipe } from './shared/pipes/replace';
import { HelpComponent } from './shared/components/help/help.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { NotificationBadgeComponent } from './shared/components/notification-badge/notification-badge.component';
import { IncidentsByPriorityComponent } from './shared/components/incidents-by-priority/incidents-by-priority.component';
import { DataBoxesComponent } from './shared/components/data-boxes/data-boxes.component';
import { IncidentsByActorsComponent } from './shared/components/incidents-by-actors/incidents-by-actors.component';
import { IncidentPanelComponent } from './shared/components/incident-panel/incident-panel.component';
import { IncidentsByActionComponent } from './shared/components/incidents-by-action/incidents-by-action.component';
import { IncidentsByAttributeComponent } from './shared/components/incidents-by-attribute/incidents-by-attribute.component';
import { FileViewComponent } from './shared/components/file-view/file-view.component';
import { HumanSizePipe } from './shared/pipes/human-size.pipe';
import { StripHtmlPipe } from './shared/pipes/strip-html.pipe';
import { MFDDragAndDropComponent } from './updates/cert-tools/mfd-services/mdf-drag-and-drop/mdf-drag-and-drop.component';
import { SeverityPipe } from './shared/pipes/severity.pipe';
import { ShortUrlPipe } from './shared/pipes/shorten.pipe';
import { MultiTranslateHttpLoader } from './shared/multi-translate-http-loader';
import { InfiniteSelectComponent } from './shared/components/infinite-select/infinite-select.component';
import { MetricsFilterComponent } from './shared/components/metrics-filter/metrics-filter.component';
import { FileViewIconsComponent } from './shared/components/file-view-icons/file-view-icons.component';
import { AreaovertimeComponent } from './shared/components/xtended/charts/areaovertime/areaovertime.component';
import { CustompourcentchartComponent } from './shared/components/xtended/charts/custompourcentchart/custompourcentchart.component';
import { InsightchartComponent } from './shared/components/xtended/charts/insightchart/insightchart.component';
import { ScatterpyramidComponent } from './shared/components/xtended/charts/scatterpyramid/scatterpyramid.component';
import { SecurityNavigatorComponent } from './shared/components/xtended/charts/securitynavigator/securitynavigator.component';
import { TreemapComponent } from './shared/components/xtended/charts/treemap/treemap.component';
import { SunburstComponent } from './shared/components/xtended/charts/sunburst/sunburst.component';
import { SankeyComponent } from './shared/components/xtended/charts/sankey/sankey.component';
import { PolarComponent } from './shared/components/xtended/charts/polar/polar.component';
import { PieComponent } from './shared/components/xtended/charts/pie/pie.component';
import { IncidentTimelineComponent } from './shared/components/xtended/charts/incident-timeline/incident-timeline.component';
import { BarchartComponent } from './shared/components/xtended/charts/barchart/barchart.component';
import { BarovertimeComponent } from './shared/components/xtended/charts/barovertime/barovertime.component';
import { CybersocxdrIncidentBySeverityComponent } from './shared/components/xtended/charts/incidentbyseverity/incidentbyseverity.component';
import { PackedbubblehostComponent } from './shared/components/xtended/charts/packedbubblehost/packedbubblehost.component';
import { TableComponent } from './shared/components/xtended/charts/table/table.component';
import { WorldmapComponent } from './shared/components/xtended/charts/worldmap/worldmap.component';
import { InsightCarrouselComponent } from './shared/components/xtended/insight/insight-carrousel.component';
import { InsightComponent } from './shared/components/xtended/insight/insight.component';
import { LocationListComponent } from './shared/components/location-list/location-list.component';
import * as Highcharts from 'highcharts';
import HC_map from 'highcharts/modules/map';
import HC_sankey from 'highcharts/modules/sankey';
import HC_solidGauge from 'highcharts/modules/solid-gauge';
import HC_sunburst from 'highcharts/modules/sunburst';
import HC_treemap from 'highcharts/modules/treemap';
import HC_XRange from 'highcharts/modules/xrange';
import HC_heatmap from 'highcharts/modules/heatmap';
import HC_exporting from 'highcharts/modules/exporting';
import HC_accessibility from 'highcharts/modules/accessibility';
import HC_highchartmore from 'highcharts/highcharts-more';
import { ColumnComponent } from './shared/components/xtended/charts/column/column.component';
import { CardComponent } from "src/app/shared/components/xtended/card/card.component";

/** AoT requires an exported function for factories */
export function createTranslateLoader(http: HttpClient) {
  const translationsVersion = environment.translationsVersion || new Date();
  return new MultiTranslateHttpLoader(http, {
    resources: [
      { prefix: './assets/i18n/', suffix: '.json?ver=' + translationsVersion },
      { prefix: './assets/i18n/cybersocxdr/', suffix: '.json?ver=' + translationsVersion },
      { prefix: './assets/i18n/ethical-hacking/', suffix: '.json?ver=' + translationsVersion },
    ],
    defaultLanguage: 'en',
  });
}

HC_highchartmore(Highcharts);
HC_solidGauge(Highcharts);
HC_treemap(Highcharts);
HC_sankey(Highcharts);
HC_sunburst(Highcharts);
HC_XRange(Highcharts);
HC_map(Highcharts);
HC_heatmap(Highcharts);
HC_exporting(Highcharts);
HC_accessibility(Highcharts);

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    HighchartsChartModule,
    InfiniteSelectComponent,
    MetricsFilterComponent,
    FileViewIconsComponent,
    LocationListComponent,
    SeverityPipe,
    RemoveWhiteSpacePipe,
    CardComponent,
  ],
  declarations: [
    FormatHTML,
    StatFilter,
    Linkify,
    SignalPipe,
    CvePipe,
    ReplacePipe,
    SupportGridComponent,
    SupportListComponent,
    AssetListComponent,
    ContactListComponent,
    AssetGridComponent,
    UploadFilesComponent,
    HelpComponent,
    NotificationBadgeComponent,
    IncidentsByPriorityComponent,
    DataBoxesComponent,
    IncidentsByActorsComponent,
    IncidentPanelComponent,
    IncidentsByActionComponent,
    IncidentsByAttributeComponent,
    FileViewComponent,
    HumanSizePipe,
    StripHtmlPipe,
    MFDDragAndDropComponent,
    ShortUrlPipe,
    AreaovertimeComponent,
    BarchartComponent,
    BarovertimeComponent,
    CustompourcentchartComponent,
    IncidentTimelineComponent,
    CybersocxdrIncidentBySeverityComponent,
    InsightchartComponent,
    PackedbubblehostComponent,
    PieComponent,
    PolarComponent,
    SankeyComponent,
    ScatterpyramidComponent,
    SecurityNavigatorComponent,
    SunburstComponent,
    TableComponent,
    TreemapComponent,
    WorldmapComponent,
    InsightCarrouselComponent,
    InsightComponent,
    ColumnComponent,
  ],
  exports: [
    NotificationBadgeComponent,
    TranslateModule,
    FormatHTML,
    StatFilter,
    Linkify,
    SignalPipe,
    ReplacePipe,
    CvePipe,
    RemoveWhiteSpacePipe,
    SupportGridComponent,
    SupportListComponent,
    AssetListComponent,
    ContactListComponent,
    AssetGridComponent,
    HelpComponent,
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    UploadFilesComponent,
    ReactiveFormsModule,
    IncidentsByPriorityComponent,
    DataBoxesComponent,
    IncidentsByActorsComponent,
    IncidentPanelComponent,
    IncidentsByActionComponent,
    IncidentsByAttributeComponent,
    FileViewComponent,
    HumanSizePipe,
    StripHtmlPipe,
    MFDDragAndDropComponent,
    SeverityPipe,
    ShortUrlPipe,
    InfiniteSelectComponent,
    MetricsFilterComponent,
    FileViewIconsComponent,
    AreaovertimeComponent,
    BarchartComponent,
    BarovertimeComponent,
    CustompourcentchartComponent,
    IncidentTimelineComponent,
    CybersocxdrIncidentBySeverityComponent,
    InsightchartComponent,
    PackedbubblehostComponent,
    PieComponent,
    PolarComponent,
    SankeyComponent,
    ScatterpyramidComponent,
    SecurityNavigatorComponent,
    SunburstComponent,
    TableComponent,
    TreemapComponent,
    WorldmapComponent,
    InsightCarrouselComponent,
    LocationListComponent,
    ColumnComponent,
  ],
})
export class AppSharedModule { }
