import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CybersocXDRDashboardSearchCriteria } from 'src/app/shared/model/searchCriteria';
import { ContextService } from 'src/app/shared/services/context-service';
import { XtendedFiltersService } from '../../xtendedfilters.service';

@Component({
  selector: 'app-route-withfacets',
  templateUrl: './route-withfacets.component.html',
})
export class RouteWithfacetsComponent {
  @Input() actionRoute: string;
  @Input({ required: true }) criteriaToApply: Partial<CybersocXDRDashboardSearchCriteria>;
  @Input({ required: true }) text: string;

  constructor(
    public globalFilters: XtendedFiltersService,
    private readonly router: Router,
    public contextService: ContextService
  ) {}

  applyCriteriaAndNavigate() {
    this.globalFilters.searchCriteria = { ...this.globalFilters.searchCriteria, ...this.criteriaToApply };
    this.globalFilters.serializeSeachCriteria();
    this.globalFilters.filterEvent.emit(this.globalFilters.searchCriteria);
    if (this.actionRoute) {
      this.router.navigate([this.actionRoute]);
    }
  }
}
